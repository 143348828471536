import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import MilestoneCard from './Components/MilestoneCard';
import UserCard from './Components/UserCard';
import EarningsCard from './Components/EarningsCard';
import ModalContainer from '../../Components/ModalContainer/ModalContainer';
import ProfileEditForm from './Components/Modals/ProfileEditForm/ProfileEditForm';
import { fetchProfile } from '../../Features/profileSlice';
import FollowingList from './Components/Modals/FollowingList/FollowingList';
import FollowersList from './Components/Modals/FollowersList/FollowersList';
import Milestone from './Components/Modals/Milestone/Milestone';
import PostBox from './Components/PostBox/PostBox';

function User() {
    const dispatch = useDispatch();

    const { userId } = useParams();

    const { profile } = useSelector((state) => state.profile.profile);
    const { type, open } = useSelector((state) => state.profile.modal);

    const [section, setSection] = useState('REVIEWS');

    const sortedUserPosts = useMemo(() => {
        if(Object.keys(profile).length > 0) {
            const userPosts = profile?.post?.filter(post => !post.hasOwnProperty('ratings') || post.ratings < 0);
            return [ ...userPosts ].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        }
    }, [profile]);

    const sortedUserReviews = useMemo(() => {
        if(Object.keys(profile).length > 0) {
            const userReviews = profile?.post?.filter(post => post.hasOwnProperty('ratings') && post.ratings >= 0);
            return [ ...userReviews ].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        }
    }, [profile]);

    useEffect(() => {
        dispatch(fetchProfile(userId));
        setSection('REVIEWS');
    }, [userId]);

    return (
        <div className='min-h-screen pt-20 max-[810px]:pt-14 pb-8 select-none'>
            <div className="mt-4 flex justify-center gap-6">
                <div className="w-[320px] h-fit flex flex-col gap-3">
                    <UserCard />

                    <EarningsCard />

                    <MilestoneCard />
                </div>

                <div className="w-[622px] flex flex-col gap-3">
                    <div className="flex items-center gap-2">
                        <button onClick={() => setSection('REVIEWS')} className={`${section === 'REVIEWS' ? 'border-main text-[#000000]' : 'border-[#E3E3E3] text-[#666666]'} outline-none bg-[#FFFFFF] px-4 py-1 rounded-full text-label1 border`}>My Reviews</button>

                        <button onClick={() => setSection('POSTS')} className={`${section === 'POSTS' ? 'border-main text-[#000000]' : 'border-[#E3E3E3] text-[#666666]'} outline-none bg-[#FFFFFF] px-4 py-1 rounded-full text-label1 border`}>My Posts</button>
                    </div>

                    <div className="grid grid-cols-3 gap-x-2 gap-y-2">
                        {
                            section === 'REVIEWS' && 
                            sortedUserReviews?.map(review => {
                                return (
                                    <PostBox 
                                        key={review.id} 
                                        postData={review} 
                                    />
                                )
                            })
                        }

                        {
                            section === 'POSTS' && 
                            sortedUserPosts?.map(post => {
                                return (
                                    <PostBox 
                                        key={post.id} 
                                        postData={post} 
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            </div>

            {
                open && 
                <ModalContainer>
                    { type === 'profile-edit' && <ProfileEditForm /> }

                    { type === 'following-list' && <FollowingList /> }

                    { type === 'followers-list' && <FollowersList /> }

                    { type === 'milestone-data' && <Milestone /> }
                </ModalContainer>
            }
        </div>
    );
}

export default User;
