import React, { useEffect, useState } from 'react';
import Logo from '../../Assets/Logo.svg';
import Search from '../../Assets/Search.svg';
import Bell from '../../Assets/Bell.svg';
import Bag from '../../Assets/Bag.svg';
import EliteBadge from '../../Assets/EliteBadge.svg';
import HamburgMenu from '../../Assets/HamburgMenu.svg';

import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { closeAccountModal, openAccountModal, openSidebar } from '../../Features/appSlice';
import { getCachedMediaUrl, getInitials } from '../../lib/utils/utils';
import SearchBar from './Search/SearchBar';

const links = [
    { name: 'Home', link: '/' },
    // { name: 'Stores', link: '/stores' },
    // { name: 'Lens', link: '/lens' },
    { name: 'DropTrack', link: '/droptrack' },
    // { name: 'Offer Zone', link: '/offer-zone' },
];

function Navbar({ navbarButtonRef }) {
    const dispatch = useDispatch();
    const location = useLocation();

    const [cartValue, setCartValue] = useState(0);

    const accountModalOpen = useSelector((state) => state.app.accountModalOpen);
    const { user } = useSelector((state) => state.user.user);
    const { cart } = useSelector((state) => state.cart.cart);

    function handleAccountButton() {
        if(accountModalOpen) {
            dispatch(closeAccountModal());
        }
        else {
            dispatch(openAccountModal());
        }
    }

    function handleCartClick() {
        dispatch(openSidebar('my-cart'));
    }

    function handleBellClick() {
        dispatch(openSidebar('notifications'));
    }

    function handleHamburgMenuClick() {
        dispatch(openSidebar('user-menu'));
    }

    useEffect(() => {
        if(cart.length > 0) {
            const totalQuantity = cart.reduce((sum, item) => sum + item.quantity, 0);
            setCartValue(totalQuantity);
        }
    }, [cart]);

    return (
        <div className='select-none fixed z-10 w-full h-20 max-[810px]:h-14 bg-[#FFFFFF] flex items-center border-b border-[#E3E3E3] shadow-[0_0_8px_0_#00000045]'>
            <div className="ml-[50px] max-[1280px]:ml-[35px] max-[810px]:ml-4 h-full flex items-center gap-20 max-[1280px]:gap-12 max-[1080px]:gap-6">
                <Link to="/">
                    <img className='max-[810px]:h-6 w-auto outline-none' src={Logo} alt="" />
                </Link>

                {
                    !location.pathname.includes('my-orders') && 
                    <div className="flex items-center gap-6 h-full max-[1280px]:gap-3 max-[810px]:hidden">
                        {links.map(link => {
                            let isActive;

                            if (link.name === 'Home') {
                                isActive = location.pathname === '/' || location.pathname.startsWith('/user');
                            }
                            else {
                                isActive = location.pathname.includes(link.link);
                            }

                            return (
                                <Link key={link.link} to={link.link} className="flex items-center h-full relative">
                                    <div className="h-full px-2 flex items-center">
                                        <h1 className='text-title3 max-[1200px]:text-label1 text-[#000000]'>{link.name}</h1>
                                    </div>

                                    {
                                        isActive && 
                                        <div className="h-2 w-full bg-main absolute bottom-0"></div>
                                    }
                                </Link>
                            );
                        })}
                    </div>
                }
            </div>

            <div className="ml-auto mr-[50px] max-[1280px]:mr-[35px] max-[810px]:mr-4 flex items-center gap-3">
                <SearchBar />

                <div className="flex items-center gap-3 max-[426px]:gap-2">
                    <button onClick={handleBellClick} className='hover-transition border border-[#BFBFBF] max-[810px]:border-none size-10 max-[810px]:size-6 centered bg-[#F3F3F3] hover:bg-[#C2C2C2] max-[810px]:hover:bg-transparent active:bg-[#929292] max-[810px]:active:bg-transparent max-[810px]:bg-transparent rounded-full max-[810px]:rounded-none'>
                        <img src={Bell} alt="Notification Bell" />
                    </button>

                    <button onClick={handleCartClick} className='hover-transition border border-[#BFBFBF] max-[810px]:border-none size-10 max-[810px]:size-6 centered bg-[#F3F3F3] hover:bg-[#C2C2C2] max-[810px]:hover:bg-transparent active:bg-[#929292]  max-[810px]:active:bg-transparent max-[810px]:bg-transparent rounded-full max-[810px]:rounded-none relative'>
                        <img src={Bag} alt="Shopping Bag" />

                        {
                            cart.length > 0 && 
                            <div className="size-6 max-[426px]:size-4 absolute -top-2 -right-2 max-[426px]:-top-1 max-[426px]:-right-1 bg-main rounded-full centered">
                                <p className='text-body3 max-[426px]:-top-1 text-[#000000]'>{cartValue < 100 ? cartValue : '99+'}</p>
                            </div>
                        }
                    </button>

                    {
                        Object.keys(user).length === 0 && 
                        <>
                            {/* <a className='border-2 border-main select-none outline-none px-4 py-[6.5px] rounded-lg bg-[#FFFFFF] hover:bg-[#E3E3E3] active:bg-[#BFBFBF] text-label1 text-[#000000] max-[810px]:hidden' href="https://seller.hyprcom.in" target='_blank' rel='noreferrer'>Login as Seller</a> */}

                            <Link to='/auth/login' className='select-none outline-none px-4 py-2 rounded-lg bg-main hover:bg-mainHover text-label1 text-[#000000] max-[810px]:hidden'>Login / Register</Link>
                        </>
                    }

                    <button className='hidden max-[810px]:flex max-[810px]:items-center max-[810px]:justify-center max-[810px]:size-6'>
                        <img src={Search} alt="Magnifying Glass" />
                    </button>

                    <button onClick={handleHamburgMenuClick} className='hidden max-[810px]:flex max-[810px]:items-center max-[810px]:justify-center max-[810px]:w-8 max-[810px]:h-6'>
                        <img src={HamburgMenu} alt="Hamburg Menu" />
                    </button>
                </div>

                {
                    Object.keys(user).length > 0 && 
                    <div ref={navbarButtonRef} onClick={handleAccountButton} className="size-[50px] rounded-full bg-[#E3E3E3] centered relative cursor-pointer max-[810px]:hidden">
                        {
                            user?.user?.profilePicUrl ? 
                            <img className='size-full rounded-full object-cover' src={getCachedMediaUrl(user?.user?.profilePicUrl)} alt='' /> : 
                            <h1 className='font-bold text-[16px] leading-[22px] text-[#666666]'>{getInitials(user?.user?.fullname)}</h1>
                        }

                        {
                            user?.subscriber === 'NORMAL' ? 
                            null : 
                            <div className="absolute -bottom-[10px] left-1/2 -translate-x-1/2 size-5 centered rounded-full overflow-hidden bg-[#F3F3F3]">
                                <img className='rounded-full' src={EliteBadge} alt="Elite Badge" />
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    );
}

export default Navbar;
