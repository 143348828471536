import React, { useEffect, useState } from 'react';
import ChevronGray from '../../../../Assets/ChevronGray.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setFilterType } from '../../../../Features/storeDetailsSlice';
import ColorOption from './ColorFilter';
import { colorFilters, discountFilters, materialFilters, styleFilters } from '../../../../lib/arrays/filtersArray';
import MaterialFilter from './MaterialFilter';
import DiscountFilter from './DiscountFilter';
import StyleFilter from './StyleFilter';

function Filter({ name, border }) {
    const dispatch = useDispatch();

    const { type, value } = useSelector((state) => state.storeDetails.filter);

    const [active, setActive] = useState(false);

    function filterOpen() {
        if(name === type) {
            dispatch(setFilterType(null));
        }
        else {
            dispatch(setFilterType(name));
        }
    }

    useEffect(() => {
        if(type === name) {
            setActive(true);
        }
        else {
            setActive(false);
        }
    }, [type]);

    return (
        <div className={`flex flex-col ${border && 'border-b border-[#BFBFBF]'} ${type === name && 'pb-4'}`}>
            <button onClick={filterOpen} className={`select-none outline-none py-4 flex items-center justify-between cursor-pointer`}>
                <p className='w-[calc(100%-24px)] font-medium text-[16px] leading-7 text-[#000000] text-left'>{name}</p>

                <div className="size-6 centered">
                    <img className={`hover-transition ${active && '-rotate-180'}`} src={ChevronGray} alt="" />
                </div>
            </button>

            {
                type === name && 
                <>
                    {
                        type === 'Color' && 
                        <div className='flex flex-col gap-1 pl-6'>
                            {colorFilters.map(item => {
                                return (
                                    <ColorOption 
                                        key={item.id} 
                                        color={item} 
                                    />
                                )
                            })}
                        </div>
                    }

                    {
                        type === 'Size' && 
                        <h1>Hello</h1>
                    }

                    {
                        type === 'Material' && 
                        <div className="flex flex-col gap-1 pl-6">
                            {materialFilters.map(item => {
                                return (
                                    <MaterialFilter 
                                        key={item.id} 
                                        material={item} 
                                    />
                                )
                            })}
                        </div>
                    }

                    {
                        type === 'Category' && 
                        <h1>Hello</h1>
                    }

                    {
                        type === 'Style' && 
                        <div className="flex flex-col gap-1 pl-6">
                            {styleFilters.map(item => {
                                return (
                                    <StyleFilter 
                                        key={item.id} 
                                        style={item} 
                                    />
                                )
                            })}
                        </div>
                    }

                    {
                        type === 'Discount' && 
                        <div className="flex flex-col gap-1 pl-6">
                            {discountFilters.map(item => {
                                return (
                                    <DiscountFilter 
                                        key={item.id} 
                                        discount={item} 
                                    />
                                )
                            })}
                        </div>
                    }

                    {
                        type === 'Price Range' && 
                        <div className="flex flex-col gap-4">
                            <div className="flex flex-col gap-4 px-6">
                                <div className=""></div>

                                <div className="w-full flex items-center gap-2">
                                    <input 
                                        className='outline-none border-none w-1/2 px-2 py-1 bg-[#FFFFFF] rounded-[9px] border border-[#E3E3E3] shadow-[0_0_6px_0_#0000001F] text-title3 placeholder:text-[#666666]' 
                                        type="text" 
                                        placeholder='Min.' 
                                    />

                                    <p className='text-label1 text-[#666666]'>to</p>

                                    <input 
                                        className='outline-none border-none w-1/2 px-2 py-1 bg-[#FFFFFF] rounded-[9px] border border-[#E3E3E3] shadow-[0_0_6px_0_#0000001F] text-title3 placeholder:text-[#666666]' 
                                        type="text" 
                                        placeholder='Max.' 
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col gap-1 pl-6"></div>
                        </div>
                    }
                </>
            }
        </div>
    );
}

export default Filter;
