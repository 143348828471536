import React, { useCallback } from 'react';
import { DotButton, useDotButton } from './EmblaCarouselDotButton';
import Autoplay from 'embla-carousel-autoplay';
import useEmblaCarousel from 'embla-carousel-react';
import './embla.css';
import { useNavigate } from 'react-router-dom';

Autoplay.globalOptions = {
    delay: 8000
}

function CarouselBanner(props) {
    const navigate = useNavigate();

    const { slides, options } = props;
    const [emblaRef, emblaApi] = useEmblaCarousel(options, [Autoplay()]);

    const onNavButtonClick = useCallback((emblaApi) => {
        const autoplay = emblaApi?.plugins()?.autoplay
        if (!autoplay) return
    
        const resetOrStop =
          autoplay.options.stopOnInteraction === false
            ? autoplay.reset
            : autoplay.stop
    
        resetOrStop()
    }, []);

    const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(
        emblaApi,
        onNavButtonClick
    );

    function handleBannerClick(slide) {
        let url = `/stores/${slide.sellerId}`;
        if(slide.typeData === 'CATEGORY') {
            url += `/category/${slide.sectionId}`;
        }
        else if(slide.typeData === 'CATEGORY_COLLECTION_LIST') {
            url += `/collection/${slide.sectionId}`;
        }
        else if(slide.typeData === 'PRODUCT') {
            url += `/products/${slide.productId}`;
        }
        navigate(url);
    }

    return (
        <section className="embla w-full">
            <div className="embla__viewport w-full" ref={emblaRef}>
                <div className="embla__container">
                    {slides.map((slide) => (
                        <div className="embla__slide" key={slide.id}>
                            <img onClick={() => handleBannerClick(slide)} className='w-full cursor-pointer' src={slide.imgUrl} alt="" />
                        </div>
                    ))}
                </div>
            </div>

            <div className="embla__controls">
                <div className="embla__dots">
                    {scrollSnaps.map((_, index) => (
                        <DotButton
                            key={index}
                            onClick={() => onDotButtonClick(index)}
                            className={'embla__dot'.concat(
                                index === selectedIndex ? ' embla__dot--selected' : ''
                            )}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
}

export default CarouselBanner;
