import React from 'react';
import { Link } from 'react-router-dom';
import { getCachedMediaUrl } from '../../../../lib/utils/utils';

function CategoryBox({ category }) {
    return (
        <Link to={`category/${category.id}`} className='flex flex-col items-center gap-1 flex-shrink-0 px-[5px]'>
            <div className="size-[52px] rounded-full overflow-hidden">
                <img className='size-full object-cover' src={getCachedMediaUrl(category.imageLink)} alt={category.name} />
            </div>

            <p className='text-body2 text-[#000000]'>{category.name}</p>
        </Link>
    );
}

export default CategoryBox;
