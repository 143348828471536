import React from 'react';
import Bucket from '../../../Assets/Bucket.svg';
import Cross from '../../../Assets/Cross.svg';
import { getCachedMediaUrl } from '../../../lib/utils/utils';
import { addProductToCart, removeProductFromWishlist } from '../../../lib/utils/apiCalls';
import { useDispatch } from 'react-redux';
import { fetchCart, fetchWishlist } from '../../../Features/cartSlice';
import { changeBagQuantity } from '../../../Features/appSlice';

function ItemWishlist({ product }) {
    const dispatch = useDispatch();

    async function handleRemoveProduct() {
        try {
            const response = await removeProductFromWishlist(product['Product.id'], product['ProductVarient.id']);
            if(response.status === 200) {
                dispatch(fetchWishlist());
            }
        }
        catch(err) {
            console.log(err);
        }
    }

    async function handleAddToCheckout() {
        try {
            const callBody = {
                productId: product['Product.id'],
                varaint: product['ProductVarient.id'],
                quantity: 1,
                reference: false,
            }

            const response = await addProductToCart(callBody);
            if(response.status === 200) {
                dispatch(fetchCart());
                handleRemoveProduct();
                dispatch(changeBagQuantity(1));
            }
        }
        catch(err) {
            console.log(err);
        }
    }

    return (
        <div className='p-3 bg-[#FFFFFF] border border-[#E3E3E3] shadow-[0_0_8px_0_#0000001F] rounded-2xl relative'>
            <div className="flex gap-2 w-full">
                <div className="size-[74px] rounded-lg overflow-hidden opacity-75">
                    <img className='size-full object-cover' src={getCachedMediaUrl(product['Product.productImgUrls'][0])} alt="" />
                </div>

                <div className="w-[calc(100%-74px)] flex flex-col font-normal">
                    <h1 className='text-[14px] leading-[26px] text-[#000000] line-clamp-1'>{product['ProductVarient.VarientName']}</h1>
                    
                    <p className='line-clamp-2 text-[12px] leading-6 text-[#666666]'>{product['Product.productDesc']}</p>

                    <div className="flex items-center gap-[9px] text-[12px] leading-6">
                        <div className="flex items-center gap-1">
                            <h1 className='text-[#666666]'>Color:</h1>

                            <p className='text-[#000000]'>{product['ProductVarient.VarientColor']}</p>
                        </div>

                        <div className="flex items-center gap-1">
                            <h1 className='text-[#666666]'>Size:</h1>

                            <p className='text-[#000000]'>{product['ProductVarient.VarientSize']}</p>
                        </div>
                    </div>
                </div>
            </div>

            <button onClick={handleRemoveProduct} className="absolute top-0 right-0 size-11 centered outline-none">
                <img src={Bucket} alt="" />
            </button>

            <div className="absolute bottom-0 right-0 size-11 pt-3 pl-3 rounded-br-2xl overflow-hidden">
                <button onClick={handleAddToCheckout} className="size-8 rounded-[2px] bg-main hover:bg-mainHover centered outline-none">
                    <div className="size-3 centered">
                        <img className='rotate-45' src={Cross} alt="" />
                    </div>
                </button>
            </div>
        </div>
    );
}

export default ItemWishlist;
