import React from 'react';

function Pricing({ MRP, SellingPrice, discount }) {
    return (
        <div className="flex items-center gap-1">
            <p className='font-semibold text-[20px] leading-8 text-[#000000]'>₹{SellingPrice}</p>

            {
                discount > 0 && 
                <>
                    <p className='font-medium text-[16px] leading-7 text-[#666666] line-through'>₹{MRP}</p>

                    <p className='font-normal text-[14px] leading-[26px] text-main'>{discount}% off</p>
                </>
            }
        </div>
    );
}

export default Pricing;
