import { useEffect } from 'react';

export function useOutsideClickForTwoRef(ref1, ref2, handleClickOutside) {
    useEffect(() => {
        function handleClick(event) {
            if (
                (ref1.current && !ref1.current.contains(event.target)) &&
                (ref2.current && !ref2.current.contains(event.target))
            ) {
                handleClickOutside();
            }
        }

        document.addEventListener("mousedown", handleClick);

        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, [ref1, ref2, handleClickOutside]);
}