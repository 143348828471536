import React from 'react';

function Input({ inputOptions, errorText }) {
    return (
        <div className="flex flex-col gap-1 select-none">
            <input 
                { ...inputOptions } 
                className='w-full outline-none border border-[#E3E3E3] focus:border-[#666666] bg-[#FFFFFF] px-3 py-[11px] rounded-[9px] font-normal text-[14px] leading-[26px] text-[#121212] placeholder:text-[#666666] shadow-[0px_0px_6px_0px_#0000001F]' 
            />

            {
                errorText && 
                <p className='text-[12px] leading-5 text-[#DC2626]'>{errorText}</p>
            }
        </div>
    );
}

export default Input;
