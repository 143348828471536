import React from 'react';
import { formatBadge, getCachedMediaUrl, getInitials } from '../../../lib/utils/utils';
import { useDispatch } from 'react-redux';
import { closeSearchModal } from '../../../Features/appSlice';
import { useNavigate } from 'react-router-dom';

function UserSearchResult({ user }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    function handleUserClick() {
        navigate(`user/${user.uuids}`);
        dispatch(closeSearchModal());
    }

    return (
        Object.keys(user).length > 0 ?
        <div onClick={handleUserClick} className='px-4 py-[10px] bg-[#FFFFFF] hover:bg-[#F5F5F5] flex items-center gap-2 cursor-pointer'>
            <div className="size-[52px] rounded-full overflow-hidden centered bg-[#E3E3E3]">
                {
                    user?.profilePicUrl ? 
                    <img className='size-full object-cover' src={getCachedMediaUrl(user.profilePicUrl)} alt={user.displayname} /> :
                    <p className='font-bold text-[16px] leading-[22px] text-[#666666]'>{getInitials(user.fullname)}</p>
                }
            </div>

            <div className="flex flex-col w-[calc(100%-52px)]">
                <h1 className='text-title3 text-[#000000]'>{user.fullname}</h1>

                {
                    user?.badge && 
                    <p className='text-body2 text-[#666666]'>{formatBadge(user?.badge?.achievement)}</p>
                }
            </div>
        </div> : 
        null
    );
}

export default UserSearchResult;
