import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EstimatedPrice from '../../../../Components/EstimatedPrice/EstimatedPrice';
import { createOrder } from '../../../../lib/utils/apiCalls';
import HyprcomLogo from '../../../../Assets/Hyprcom.jpg';
import { openCheckoutModal } from '../../../../Features/checkoutSlice';

function BillingDetails() {
    const dispatch = useDispatch();

    const [error, setError] = useState('');

    const paymentModeId = useSelector((state) => state.checkout.paymentModeId);
    const { estimatedPrice } = useSelector((state) => state.checkout.estimatedPrice);
    const { user } = useSelector((state) => state.user.user);
    const selectedAddressId = useSelector((state) => state.checkout.selectedAddressId);
    const cartList = useSelector((state) => state.cart.cartList);

    async function makePaymentWithRazorpay() {
        if(paymentModeId) {
            if(error) {
                setError('');
            }

            const allProductIds = [];
            const allQuantities = [];
            const orderDataMap = new Map();

            cartList.forEach(cartItem => {
                const sellerId = cartItem.sellerId;
                const couponId = cartItem.couponId || "no_coupon";

                cartItem.productsList.forEach(product => {
                    allProductIds.push(product.productId);
                    allQuantities.push(product.quantity);

                    if (!orderDataMap.has(sellerId)) {
                        const orderEntry = {
                            productIds: [product.productId],
                            sellerId: sellerId,
                            couponIds: couponId,
                            quantity: [product.quantity],
                            variants: [product.variantId],
                        };
                        orderDataMap.set(sellerId, orderEntry);
                    }
                    else {
                        const existingEntry = orderDataMap.get(sellerId);
                        existingEntry.productIds.push(product.productId);
                        existingEntry.quantity.push(product.quantity);
                    }
                });
            });

            const orderData = Array.from(orderDataMap.values());

            const callBody = {
                orderData,
                orderType: paymentModeId,
                codMain: estimatedPrice?.totalcost > 2500,
                addressId: selectedAddressId,
                shippingMode: "S"
            }

            if(paymentModeId === 'Prepaid') {
                try {
                    const response = await createOrder(callBody);

                    if(response.status === 200) {
                        var options = {
                            key: process.env.REACT_APP_RAZORPAY_KEY,
                            amount: estimatedPrice?.totalcost * 100,
                            currency: "INR",
                            name: "Hyprcom",
                            description: `Payment of ₹${estimatedPrice?.totalcost*100} by ${user?.user?.fullname} for orderId ${response.data.data.razorId}`,
                            image: HyprcomLogo,
                            order_id: response.data.data.razorId,
                            prefill: {
                                contact: `+91${user?.user?.phone}`,
                                email: user?.user?.email
                            },
                            theme: {
                                color: "#00C853",
                            },

                            handler: function (response) {
                                dispatch(openCheckoutModal('payment-success'));
                            },
                        };

                        var pay = new window.Razorpay(options);

                        pay.on('payment.failed', function (response){
                            console.log(response.error.code);
                            console.log(response.error.description);
                            console.log(response.error.source);
                            console.log(response.error.step);
                            console.log(response.error.reason);
                            console.log(response.error.metadata.order_id);
                            console.log(response.error.metadata.payment_id);
                        });

                        pay.open();
                    }
                }
                catch(err) {
                    console.log(err);
                }
            }
            else {
                try{
                    const response = await createOrder(callBody);
                    if(response.status === 200) {
                        dispatch(openCheckoutModal('payment-success'));
                    }
                }
                catch(err) {
                    console.log(err);
                }
            }
        }
        else {
            setError('Please select a payment mode.');
        }
    }

    async function makePaymentWithJuspay() {
        var requestPayload = JSON.stringify({ amount: `${estimatedPrice?.totalcost}`, });

        var requestOptions = {
            method: "POST",
            headers: {
                Authorization: "Basic <merchant api auth token>",
                "Content-Type": "application/json",
            },
            body: requestPayload,
        };

        fetch("https://api.<merchantwebsite>.com/makePayment", requestOptions)
        .then((response) => response.json())
        .then((result) => {
            // window.location.replace(result.link);

            // iFrame
            var paymentPageDiv = window.querySelector("#juspayDiv");
            var juspayIframe = document.createElement("iframe");
            juspayIframe.src = result.link;
            juspayIframe.setAttribute("allow", "payment *;");

            juspayIframe.width = "1000";
            juspayIframe.height = "920";
            paymentPageDiv.appendChild(juspayIframe);
        })
        .catch((error) => console.log("error", error));
    }

    return (
        <div className="w-2/5 h-fit p-6 bg-[#FFFFFF] border border-[#E3E3E3] shadow-[0_0_8px_0_#0000001F] rounded-2xl flex flex-col gap-4">
            <div className="flex flex-col gap-2">
                <h1 className='font-semibold text-[18px] leading-[30px] text-[#666666]'>Order Details</h1>

                <EstimatedPrice />
            </div>

            {/* <div id="juspayDiv"></div> */}

            {
                error && 
                <p className='text-[12px] leading-5 text-[#DC2626]'>{error}</p>
            }

            <button onClick={makePaymentWithRazorpay} className={`outline-none select-none w-full px-8 py-4 rounded-lg font-semibold text-[20px] leading-8 ${paymentModeId ? 'text-[#000000] bg-main hover:bg-mainHover' : 'bg-[#F3F3F3] text-[#999999] cursor-not-allowed'}`}>Place Order</button>
        </div>
    )
}

export default BillingDetails;
