const colorFilters = [
    { id: 'red', color: '#FF0000', text: 'Red' },
    { id: 'green', color: '#00FF00', text: 'Green' },
    { id: 'yellow', color: '#FFEB3B', text: 'Yellow' },
    { id: 'blue', color: '#0000FF', text: 'Blue' },
    { id: 'biege', color: '#F0F4C3', text: 'Biege' },
    { id: 'brown', color: '#7C5419', text: 'Brown' },
    { id: 'white', color: '#FFFFFF', text: 'White' },
    { id: 'purple', color: ' #9D00FF', text: 'Purple' },
    { id: 'pink', color: '#FF69B4', text: 'Pink' },
    { id: 'burgundy', color: '#D81B60', text: 'Burgundy' },
    { id: 'black', color: '#060606', text: 'Black' },
    { id: 'charcoal', color: '#37474F', text: 'Charcoal' },
    { id: 'coral', color: '#FF7043', text: 'Coral' },
];

const sizeFilters = [
    { id: '', size: '' }
]

const materialFilters = [
    { id: 'cotton', material: 'Cotton' },
    { id: 'silk', material: 'Silk' },
    { id: 'leather', material: 'Leather' },
    { id: 'velvet', material: 'Velvet' },
    { id: 'crepe', material: 'Crepe' },
    { id: 'organza', material: 'Organza' },
    { id: 'wool', material: 'Wool' },
    { id: 'taffeta', material: 'Taffeta' },
]

const categoryFilters = [
    { id: '', category: '' }
]

const styleFilters = [
    { id: 'shirt', style: 'Shirt' },
    { id: 'pants', style: 'Pants' },
    { id: 't-shirts', style: 'T-Shirts' },
    { id: 'lounge-shorts', style: 'Lounge Shorts' },
    { id: 'shorts', style: 'Shorts' },
    { id: 'track-pants', style: 'Track Pants' },
    { id: 'gym-wear', style: 'Gym Wear' },
    { id: 'sports-wear', style: 'Sports Wear' },
    { id: 'co-ords', style: 'Co-Ords' },
    { id: 'kurtas', style: 'Kurtas' },
    { id: 'night-suits', style: 'Night Suits' },
    { id: 'tops', style: 'Tops' },
]

const discountFilters = [
    { id: '10+', text: '10% and above', discount: 10, },
    { id: '20+', text: '20% and above', discount: 20, },
    { id: '30+', text: '30% and above', discount: 30, },
    { id: '40+', text: '40% and above', discount: 40, },
    { id: '50+', text: '50% and above', discount: 50, },
    { id: '60+', text: '60% and above', discount: 60, },
    { id: '70+', text: '70% and above', discount: 70, },
    { id: '80+', text: '80% and above', discount: 80, },
]

export {
    colorFilters,
    sizeFilters,
    materialFilters,
    categoryFilters,
    styleFilters,
    discountFilters,
}
