import React from 'react';
import { Link } from 'react-router-dom';
import ProductCard from '../ProductCard/ProductCard';

function OfferSection({ offerData, id }) {
    return (
        <div className='px-4 flex flex-col gap-4'>
            <div className="flex items-center justify-between">
                <h1 className='text-title2 text-[#666666]'>{offerData?.name}</h1>

                <Link to={`collection/${id}`} className='text-label1 text-main'>See all</Link>
            </div>

            <div className="w-full grid gap-x-[11px] gap-y-[11px] grid-cols-2">
                {offerData?.products?.slice(0, 4)?.map(product => {
                    return (
                        <ProductCard 
                            key={product.productId} 
                            product={product} 
                            urlPrefix={'products/'} 
                            productId={product.productId} 
                        />
                    )
                })}
            </div>
        </div>
    );
}

export default OfferSection;
