import { Link, Route, Routes } from 'react-router-dom';
import './App.css';
import ArrowLeft from './Assets/ArrowLeft.svg';
import SharedLayout from './Routes/SharedLayout/SharedLayout';
import HomePageRoute from './Routes/HomePage/HomePage.Route';
import StoresPageRoute from './Routes/StoresPage/StoresPage.Route';
import LensRoute from './Routes/Lens/Lens.Route';
import MyOrdersRoute from './Routes/MyOrders/MyOrders.Route';
import MinimalFooter from './Components/Footer/MinimalFooter';
import CheckoutRoute from './Routes/Checkout/Checkout.Route';
import CheckoutProgress from './Components/CheckoutProgress/CheckoutProgress';
import MarketplaceRoute from './Routes/Marketplace/Marketplace.Route';
import AuthRoute from './Routes/Auth/Auth.Route';
import SettingsRoute from './Routes/Settings/Settings.Route';
import DroptrackRoute from './Routes/Droptrack/Droptrack.Route';
import UserRoute from './Routes/User/User.Route';
import PrivacyPolicy from './Pages/InformationPages/PrivacyPolicy.Page';
import TermsAndConditions from './Pages/InformationPages/TermsAndConditions.Page';
import AboutHyprcom from './Pages/InformationPages/AboutHyprcom.Page';
import Toaster from './Components/Toaster/Toaster';

function App() {
    return (
        <>
            <Toaster />

            <Routes>
                <Route path='/' element={<SharedLayout />}>
                    <Route index element={<HomePageRoute />} />

                    <Route 
                        path='stores/*' 
                        element={
                            <div className='flex flex-col justify-between h-screen max-[768px]:h-[calc(100vh-41px)] max-[426px]:h-screen'>
                                <StoresPageRoute />

                                <MinimalFooter />
                            </div>
                        } 
                    />

                    <Route 
                        path='marketplace/*' 
                        element={<MarketplaceRoute />} 
                    />

                    <Route path='lens/*' element={<LensRoute />} />

                    <Route 
                        path='droptrack/*' 
                        element={
                            <>
                                <DroptrackRoute />

                                <MinimalFooter />
                            </>
                        } 
                    />

                    <Route path='my-orders/*' element={<MyOrdersRoute />} />

                    <Route 
                        path='checkout/*' 
                        element={
                            <div className='pt-20 max-[426px]:pt-14 flex flex-col gap-6'>
                                <div className="hidden max-[426px]:flex p-4 items-center gap-2 -mb-6">
                                    <Link to='..' className="size-6 centered">
                                        <img src={ArrowLeft} alt="" />
                                    </Link>

                                    <p className='text-title2 text-[#000000]'>Checkout</p>
                                </div>

                                <CheckoutProgress />

                                <CheckoutRoute />
                            </div>
                        } 
                    />

                    <Route path='auth/*' element={<AuthRoute />} />

                    <Route path='user/*' element={<UserRoute />} />

                    <Route path='settings' element={<SettingsRoute />} />

                    <Route path='about-hyprcom' element={<AboutHyprcom />} />

                    {/* <Route path='eula' element={<></>} /> */}

                    <Route path='privacy-policy' element={<PrivacyPolicy />} />

                    <Route path='terms-and-conditions' element={<TermsAndConditions />} />
                </Route>
            </Routes>
        </>
    );
}

export default App;
