import React, { useEffect, useState } from 'react';
import StoreCategories from '../../Components/StoreCategories/StoreCategories';
import CarouselBanner from '../../Components/CarouselBanner/CarouselBanner';
import FeaturedBrands from './Components/FeaturedBrands/FeaturedBrands';
import FavoriteBrands from './Components/FavoriteBrands/FavoriteBrands';
import VideoLens from './Components/VideoLens/VideoLens';
import SaleBanner from './Components/SaleBanner/SaleBanner';
import StoreDiscoveryHub from './Components/StoreDiscoveryHub/StoreDiscoveryHub';
import ProductOffers from './Components/ProductOffers/ProductOffers';
import ExclusiveDeals from './Components/ExclusiveDeals/ExclusiveDeals';
import { useSelector } from 'react-redux';
import CustomSection from './Components/CustomSection/CustomSection';
import { useNavigate } from 'react-router-dom';

const OPTIONS = { loop: true, duration: 60, }

function HomePage() {
    const navigate = useNavigate();

    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 500);

    useEffect(() => {
        const handleResize = () => {
            setIsWideScreen(window.innerWidth > 500);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const { homepage } = useSelector((state) => state.homepage.homepage);

    function updateActiveCategory(selection) {
        navigate(`marketplace?category=${selection}`);
    }

    return (
        <div className='flex flex-col pt-20 max-[810px]:pt-14'>
            <StoreCategories 
                activeCategory={null} 
                updateActiveCategory={updateActiveCategory} 
            />

            <div className="flex flex-col gap-12 max-[426px]:gap-8">
                {Object.keys(homepage).map((key, index) => {
                    const value = homepage[key];

                    if(isWideScreen && key === 'webBanner') {
                        if(value.length > 0) {
                            const images = value.map((item, index) => {
                                return (
                                    {
                                        id: index,
                                        ...item,
                                    }
                                );
                            });

                            return (
                                <CarouselBanner 
                                    key={`${key}-${index}`} 
                                    slides={images} 
                                    options={OPTIONS} 
                                />
                            );
                        }
                        else return null;
                    }
                    else if(!isWideScreen && key === 'heroBanner') {
                        if(value.length > 0) {
                            const images = value.map((item, index) => {
                                return (
                                    {
                                        id: index,
                                        ...item,
                                    }
                                );
                            });

                            return (
                                <CarouselBanner 
                                    key={`${key}-${index}`} 
                                    slides={images} 
                                    options={OPTIONS} 
                                />
                            );
                        }
                        else return null;
                    }
                    else if(key === 'featuredBrands') {
                        return (
                            value.length > 0 ?
                            <FeaturedBrands 
                                key={`${key}-${index}`} 
                                featuredBrands={value} 
                            /> : 
                            null
                        );
                    }
                    else if(key === 'favouriteBrands') {
                        return (
                            value.length > 0 ?
                            <FavoriteBrands 
                                key={`${key}-${index}`} 
                                favouriteBrands={value}  
                            /> : 
                            null
                        );
                    }
                    else if(key === 'exclusiveDeals') {
                        return (
                            value.length > 0 ?
                            <ExclusiveDeals 
                                key={`${key}-${index}`} 
                            /> : 
                            null
                        );
                    }
                    else if(key === 'storeDiscovery') {
                        return (
                            value.length > 0 ?
                            <StoreDiscoveryHub 
                                key={`${key}-${index}`} 
                                storeDiscovery={value} 
                                button
                            /> : 
                            null
                        )
                    }
                    else if(key === 'customSections') {
                        return (
                            value.length > 0 ?
                            <CustomSection 
                                key={`${key}-${index}`} 
                                customSectionData={value} 
                            /> :
                            null
                        )
                    }
                    else return null;
                })}

                <div className="w-full bg-[#FFFFFF]"></div>
            </div>
        </div>
    );
}

export default HomePage;
