import React, { useEffect, useRef, useState } from 'react';
import Navbar from '../../Components/Navbar/Navbar';
import { Outlet, useLocation } from 'react-router-dom';
import AccountDropdown from '../../Components/Navbar/AccountDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { closeAccountModal } from '../../Features/appSlice';
import { useOutsideClickForTwoRef } from '../../Hooks/useOutsideClickForTwoRef';
import MyOrders from '../../Components/Sidebar/MyOrders/MyOrders';
import MyCart from '../../Components/Sidebar/MyCart/MyCart';
import Notifications from '../../Components/Sidebar/Notifications/Notifications';
import ModalContainer from '../../Components/ModalContainer/ModalContainer';
import ProductModal from '../../Components/ProductModal/ProductModal';
import CheckoutNavbar from '../../Components/Navbar/CheckoutNavbar';
import UserMenu from '../../Components/Sidebar/UserMenu/UserMenu';
import { fetchUser } from '../../Features/userSlice';
import { fetchCart } from '../../Features/cartSlice';
import { fetchAddresses, fetchHyprcomMoney } from '../../Features/checkoutSlice';
import Filters from '../../Components/Sidebar/Filters/Filters';

function SharedLayout() {
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const navbarButtonRef = useRef(null);
    const modalRef = useRef(null);

    const accountModalOpen = useSelector((state) => state.app.accountModalOpen);
    const searchModalOpen = useSelector((state) => state.app.searchModalOpen);
    const { open, type } = useSelector((state) => state.app.sidebar);
    const productModalOpen = useSelector((state) => state.app.productModal.open);

    const [accountLocalModalOpen, setAccountLocalModalOpen] = useState(false);
    const [searchLocalModalOpen, setSearchLocalModalOpen] = useState(false);

    useOutsideClickForTwoRef(navbarButtonRef, modalRef, () => {
        dispatch(closeAccountModal());
    });

    useEffect(() => {
        if(accountModalOpen) {
            setAccountLocalModalOpen(true);
        }
        else {
            setTimeout(() => {
                setAccountLocalModalOpen(false);
            }, 300);
        }
    }, [accountModalOpen]);

    useEffect(() => {
        if(searchModalOpen) {
            setSearchLocalModalOpen(true);
        }
        else {
            setSearchLocalModalOpen(false);
        }
    }, [searchModalOpen]);

    useEffect(() => {
        dispatch(fetchUser());
        dispatch(fetchHyprcomMoney());
        dispatch(fetchAddresses());
        dispatch(fetchCart());
    }, []);

    return (
        <>
            {
                !pathname.includes('auth') &&
                <>
                    {
                        !pathname.includes('checkout') ? 
                        <Navbar navbarButtonRef={navbarButtonRef} /> : 
                        <div className="flex">
                            <div className="w-full max-[426px]:hidden">
                                <CheckoutNavbar />
                            </div>

                            <div className="w-full hidden max-[426px]:flex">
                                <Navbar navbarButtonRef={navbarButtonRef} />
                            </div>
                        </div>
                    }

                    {
                        (accountModalOpen || accountLocalModalOpen) && 
                        <div className="fixed z-10 left-0 bottom-0 w-full h-[calc(100vh-80px)] bg-[#00000080] backdrop-blur-sm overflow-hidden">
                            <AccountDropdown modalRef={modalRef} />
                        </div>
                    }

                    {
                        (searchModalOpen || searchLocalModalOpen) && 
                        <div style={{ zIndex: 5 }} className="fixed left-0 bottom-0 w-full h-[calc(100vh-80px)] bg-[#00000080] backdrop-blur-sm">
                        </div>
                    }

                    {
                        open && 
                        <ModalContainer>
                            {type === 'my-orders' && <MyOrders />}
                            {type === 'my-cart' && <MyCart />}
                            {type === 'user-menu' && <UserMenu />}
                            {type === 'notifications' && <Notifications />}
                            {type === 'filters' && <Filters />}
                        </ModalContainer>
                    }

                    {
                        productModalOpen && 
                        <ModalContainer>
                            <ProductModal />
                        </ModalContainer>
                    }
                </>
            }
            
            <Outlet />
        </>
    );
}

export default SharedLayout;
