import React, { useEffect, useRef, useState } from 'react';
import OrderBar from './OrderBar';
import Cross from '../../../Assets/Cross.svg';
import { useDispatch, useSelector } from 'react-redux';
import { closeSidebar } from '../../../Features/appSlice';
import { useOutsideClickHandler } from '../../../Hooks/useOutsideClick';
import { fetchOrders } from '../../../Features/ordersSlice';
import ArrowLeft from '../../../Assets/ArrowLeft.svg';

function getOrderStatus(status) {
    switch (status) {
        case 'IN_TRANSIST':
            return 'In Transit';
        // case 'PENDING':
        //     return {
        //         text: 'Order placed on',
        //         color: '#121212'
        //     };
        case 'DELIVERED':
            return {
                text: 'Delivered on',
                color: '#666666'
            };
        case 'CANCELLED':
            return {
                text: 'Order cancelled on',
                color: '#DFA81D'
            };
        case 'RETURN':
            return 'Return';
        case 'EXCHANGE':
            return 'Exchange';
        case 'OUT_FOR_DELIVERY':
            return 'Out for Delivery';
        case 'READY_FOR_PICKUP':
            return {
                text: 'Order placed on',
                color: '#121212'
            };
        default:
            return '';
    }
}

function MyOrders() {
    const dispatch = useDispatch();

    const containerRef = useRef(null);

    useOutsideClickHandler(containerRef, () => {
        if(window.innerWidth > 600) {
            setCartSidebarOpen(false);
            setTimeout(() => {
                dispatch(closeSidebar());
            }, 300);
        }
    });

    const { open } = useSelector((state) => state.app.sidebar);

    const [cartSidebarOpen, setCartSidebarOpen] = useState(false);

    const { orders } = useSelector((state) => state.orders.orders);

    function closeSidebarClick() {
        setCartSidebarOpen(false);
        setTimeout(() => {
            dispatch(closeSidebar());
        }, 300);
    }

    useEffect(() => {
        dispatch(fetchOrders());
    }, []);

    useEffect(() => {
        if(open) {
            setCartSidebarOpen(open);
        }
    }, [open]);

    return (
        <div ref={containerRef} className={`absolute transition-all duration-500 delay-0 ease-in-out top-0 ${cartSidebarOpen ? 'right-0' : '-right-1/3 max-[1024px]:-right-[50%] max-[600px]:-right-[100%]'} h-full w-1/3 max-[1024px]:w-1/2 max-[600px]:w-full bg-[#FFFFFF] flex flex-col`}>
            <div className="p-6 max-[600px]:p-4 flex items-center justify-between bg-[#FFFFFF] border-b border-[#E3E3E3] shadow-[0_0_8px_0_#0000001F]">
                <div className="flex items-center gap-2">
                    <button onClick={closeSidebarClick} className="size-6 centered">
                        <img src={ArrowLeft} alt="" />
                    </button>

                    <h1 className='font-bold text-[24px] leading-9 max-[600px]:font-semibold max-[600px]:text-[18px] max-[600px]:leading-[30px] text-[#000000]'>My Orders</h1>
                </div>

                <button onClick={closeSidebarClick} className="size-10 centered cursor-pointer max-[600px]:hidden">
                    <img src={Cross} alt="" />
                </button>
            </div>

            <div className="h-[calc(100%-84px)] w-full px-6 py-3 flex flex-col gap-2 overflow-y-scroll scrollbar-hide">
                {[ ...orders ].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))?.map(order => {
                    const status = getOrderStatus(order.orderStatus);

                    return (
                        <OrderBar 
                            key={order.orderId} 
                            status={status} 
                            order={order} 
                        />
                    )
                })}
            </div>
        </div>
    );
}

export default MyOrders;
