import React from 'react';
import Categories from '../Categories/Categories';
import CarouselBanner from '../CarouselBanner/CarouselBanner';
import OfferSection from '../OfferSection/OfferSection';
import PersonalizedOffers from '../PersonalizedOffers/PersonalizedOffers';
import { useSelector } from 'react-redux';

const OPTIONS = { loop: true, duration: 60, }

function StoreSection() {
    const { store } = useSelector((state) => state.storeDetails.store);

    return (
        <div className="flex flex-col gap-4 mt-4">
            {store?.storeFront?.storeData.map(data => {
                if(data.typeData === 'CATEGORY') {
                    return (
                        <Categories 
                            categories={data} 
                            key={data.id} 
                        />
                    )
                }
                else if(data.typeData === 'CAROUSEL_BANNER') {
                    return (
                        <CarouselBanner 
                            slides={data.imageLinkList}  
                            options={OPTIONS} 
                            key={data.id} 
                        />
                    )
                }
                else if(data.typeData === 'SINGLE_BANNER') {
                    return null;
                }
                else if(data.typeData === 'OFFER_DISPLAY') {
                    return (
                        <PersonalizedOffers 
                            key={data.id} 
                            offersData={data} 
                        />
                    )
                }
                else if(data.typeData === 'CUSTOM_COLLECTION_LIST') {
                    return (
                        data.products.length > 0 ?
                        <OfferSection 
                            key={data.id} 
                            offerData={data} 
                            id={data.customCollectionList[0]} 
                        /> : 
                        null
                    )
                }
                else if(data.typeData === 'CATEGORY_COLLECTION_LIST') {
                    return (
                        data.products.length > 0 ?
                        <OfferSection 
                            key={data.id} 
                            offerData={data} 
                            id={data.categoryCollectionList[0]} 
                        /> : 
                        null
                    )
                }
                else return null;
            })}
        </div>
    );
}

export default StoreSection;
