import React from 'react';

function ReasonRadio({ reason, inputOptions }) {
    return (
        <label htmlFor={inputOptions.id} className="flex items-center select-none cursor-pointer">
            <div className="size-11 centered">
                <div className={`size-6 rounded-full border-2 ${inputOptions.checked ? 'border-main' : 'border-[#BFBFBF]'} relative`}>
                    {
                        inputOptions.checked && 
                        <div className="size-3 rounded-full bg-main absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"></div>
                    }
                </div>
            </div>

            <p className='w-[calc(100%-44px)]'>{reason}</p>

            <input 
                { ...inputOptions } 
                className='hidden' 
            />
        </label>
    );
}

export default ReasonRadio;
