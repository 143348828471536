import React, { useEffect, useRef, useState } from 'react';
import ProductFilters from './Components/ProductFilters/ProductFilters';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import ArrowLeft from '../../Assets/ArrowLeft.svg';
import Sort from '../../Assets/Sort.svg';
import ProductCard from './Components/ProductCard/ProductCard';
import { fetchStoreCollectionData } from '../../Features/storeDetailsSlice';
import SortKebab from './Components/SortKebab/SortKebab';
import { useOutsideClickForTwoRef } from '../../Hooks/useOutsideClickForTwoRef';
import { openSidebar } from '../../Features/appSlice';
import Filter from '../../Assets/Filter.svg';
import SortSlide from '../StoreCategory/Components/SortSlide/SortSlide';

function StoreCollection() {
    const params = useParams();
    const dispatch = useDispatch();

    const kebabButtonRef = useRef(null);
    const kebabRef = useRef(null);

    const [kebabVisible, setKebabVisible] = useState(false);

    useOutsideClickForTwoRef(kebabRef, kebabButtonRef, () => {
        setKebabVisible(false);
    });

    const { storeCollection } = useSelector((state) => state.storeDetails.storeCollection);

    useEffect(() => {
        if(params.collectionId) {
            dispatch(fetchStoreCollectionData(params.collectionId));
        }
    }, [params.collectionId]);

    function handleOpenKebab() {
        setKebabVisible(!kebabVisible);
    }

    function handleOpenFilterSidebar() {
        dispatch(openSidebar('filters'));
    }

    function closeKebabHandler() {
        setKebabVisible(false);
    }

    function goBack() {
        window.history.back();
    };

    return (
        <div className="flex flex-col w-[1536px] max-[1536px]:w-full mx-auto">
            <div className='flex pt-20 max-[810px]:pt-14 min-h-[calc(100vh-73px)] max-[768px]:min-h-[calc(100vh-41px)] max-[426px]:min-h-screen'>
                <ProductFilters />

                <div className="w-3/4 max-[810px]:w-full pr-[50px] max-[1280px]:pr-[35px] max-[810px]:pr-4">
                    <div className="w-full py-6 pl-6 max-[810px]:pl-4 flex flex-col gap-6 max-[426px]:gap-4">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center gap-2">
                                <div onClick={goBack} className="size-8 max-[426px]:size-6 centered cursor-pointer">
                                    <img src={ArrowLeft} alt="" />
                                </div>

                                <h1 className='text-heading2 max-[426px]:text-title2 text-[#000000]'>{storeCollection?.collection?.CollectionName} &#40;{storeCollection?.product?.length} result{storeCollection?.product?.length > 1 && 's'}&#41;</h1>
                            </div>

                            <div className="flex items-center">
                                <div ref={kebabButtonRef} onClick={handleOpenKebab} className="size-8 max-[426px]:w-10 max-[426px]:h-9 centered cursor-pointer relative">
                                    <img className='size-full max-[810px]:size-[80%]' src={Sort} alt="" />

                                    <SortKebab 
                                        boxRef={kebabRef} 
                                        kebabOpen={kebabVisible} 
                                    />
                                </div>

                                <div onClick={handleOpenFilterSidebar} className="hidden max-[810px]:flex size-8 max-[426px]:w-10 max-[426px]:h-9 items-center justify-center cursor-pointer relative">
                                    <img className='w-4 h-auto' src={Filter} alt="" />
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-4 max-[1200px]:grid-cols-3 max-[810px]:grid-cols-2 gap-x-6 max-[810px]:gap-x-4 gap-y-6 max-[810px]:gap-y-4">
                            {storeCollection?.product?.map(product => {
                                return (
                                    <ProductCard 
                                        key={product.productId} 
                                        product={product} 
                                        productId={product.productId} 
                                        urlPrefix={'../../products/'} 
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>

                <div className={`${kebabVisible ? 'max-[810px]:flex hidden' : 'hidden'} w-screen h-[calc(100vh-56px)] bg-[#00000080] backdrop-blur-sm fixed bottom-0 left-0`}></div>

                <SortSlide 
                    boxRef={kebabRef} 
                    kebabOpen={kebabVisible} 
                    closeKebab={closeKebabHandler}
                />
            </div>
        </div>
    );
}

export default StoreCollection;
