import React from 'react';

function SortOption({ sortType, inputOptions, isLast }) {
    return (
        <label htmlFor={inputOptions.id} className={`${!isLast && 'border-b border-[#E3E3E3]'} px-4 py-1 flex items-center justify-between cursor-pointer`}>
            <p className='w-[calc(100%-32px)] text-title3 line-clamp-1 text-[#000000]'>{sortType.name}</p>

            <div className="size-8 centered">
                <div className={`size-4 rounded-full border-2 ${inputOptions.checked ? 'border-main' : 'border-[#BFBFBF]'} relative`}>
                    {
                        inputOptions.checked && 
                        <div className="size-2 rounded-full bg-main absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"></div>
                    }
                </div>
            </div>

            <input 
                { ...inputOptions } 
                className='hidden' 
            />
        </label>
    );
}

export default SortOption;
