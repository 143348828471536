import React from 'react';
import Lens from '../../Pages/Lens/Lens';

function LensRoute() {
    return (
        <>
            <Lens />
        </>
    );
}

export default LensRoute;
