import React from 'react';
import Settings from '../../Pages/Settings/Settings.Page';

function SettingsRoute() {
    return (
        <Settings />
    );
}

export default SettingsRoute;
