import React from 'react';
// import StoresPage from '../../Pages/StoresPage/StoresPage';
import { Navigate, Route, Routes } from 'react-router-dom';
import ProductDetails from '../../Pages/ProductDetails/ProductDetails.Page';
import StoreCollection from '../../Pages/StoreCollection/StoreCollection.Page';
import StoreCategory from '../../Pages/StoreCategory/StoreCategory.Page';
import StoreOffer from '../../Pages/StoreOffer/StoreOffer.Page';
import Store from '../../Pages/Store/Store.Page';
import TempStorePage from '../../Pages/TempStorePage/TempStorePage';

function StoresPageRoute() {
    return (
        <Routes>
            <Route index element={<Navigate to=".." replace />} />

            <Route path=':storeId'>
                <Route index element={<TempStorePage />} />

                <Route path='category'>
                    <Route index element={<Navigate to=".." replace />} />

                    <Route path=':categoryId' element={<StoreCategory />} />
                </Route>

                <Route path='collection'>
                    <Route index element={<Navigate to=".." replace />} />

                    <Route path=':collectionId' element={<StoreCollection />} />
                </Route>

                <Route path='offer'>
                    <Route index element={<Navigate to=".." replace />} />

                    <Route path=':offerId' element={<StoreOffer />} />
                </Route>

                <Route path='products'>
                    <Route index element={<Navigate to=".." replace />} />

                    <Route path=':productId' element={<ProductDetails />} />
                </Route>
            </Route>
        </Routes>
    )
}

export default StoresPageRoute;
