import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { fetchHomepage } from '../../Features/homepageSlice';
import StoreCategories from '../../Components/StoreCategories/StoreCategories';
import CarouselBanner from '../../Components/CarouselBanner/CarouselBanner';
import FeaturedBrands from '../HomePage/Components/FeaturedBrands/FeaturedBrands';
import FavoriteBrands from '../HomePage/Components/FavoriteBrands/FavoriteBrands';
import ExclusiveDeals from '../HomePage/Components/ExclusiveDeals/ExclusiveDeals';
import StoreDiscoveryHub from '../HomePage/Components/StoreDiscoveryHub/StoreDiscoveryHub';
import CustomSection from '../HomePage/Components/CustomSection/CustomSection';

const OPTIONS = { loop: true, duration: 60, }

function Marketplace() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 500);
    
    useEffect(() => {
        const handleResize = () => {
            setIsWideScreen(window.innerWidth > 500);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [dots, setDots] = useState('.');

    const { homepage: categoryData } = useSelector((state) => state.homepage.homepage);

    function updateActiveCategory(selection) {
        navigate(`/marketplace?category=${selection}`);
    }

    useEffect(() => {
        const category = searchParams.get('category');

        dispatch(fetchHomepage(category));
    }, [searchParams]);

    useEffect(() => {
        const interval = setInterval(() => {
            setDots(prev => (prev === '. . .' ? '.' : prev + ' .'));
        }, 750);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className='flex flex-col min-h-[calc(100vh-444px)] max-[426px]:min-h-[calc(100vh-514px)] pt-20 max-[810px]:pt-14'>
            <StoreCategories 
                activeCategory={searchParams.get('category')} 
                updateActiveCategory={updateActiveCategory} 
            />

            <div className="flex flex-col gap-12 max-[426px]:gap-8">
                {/* <div className="mx-auto mt-2">
                    <button className='w-[200px] px-4 py-2 border-b-2 border-main hover:bg-[#F5F5F5]'>My Deals</button>

                    <button className='w-[200px] px-4 py-2 border-b-2 border-main hover:bg-[#F5F5F5]'>Shop by Brands</button>
                </div> */}

                {
                    Object.keys(categoryData).filter(key => Array.isArray(categoryData[key])).every(key => categoryData[key].length === 0) ? 
                    (
                        <div className="py-28 max-[810px]:py-20 centered">
                            <h1 className='select-none text-title1 text-[#666666] max-[810px]:text-[18px] font-mulish'>Coming soon {dots}</h1>
                        </div>
                    ) : 
                    (Object.keys(categoryData).map((key, index) => {
                        const value = categoryData[key];

                        if(isWideScreen && key === 'webBanner') {
                            if(value.length > 0) {
                                const images = value.map((item, index) => {
                                    return (
                                        {
                                            id: index,
                                            ...item,
                                        }
                                    );
                                });

                                return (
                                    <CarouselBanner 
                                        key={`${key}-${index}`} 
                                        slides={images} 
                                        options={OPTIONS} 
                                    />
                                );
                            }
                            else return null;
                        }
                        else if(!isWideScreen && key === 'heroBanner') {
                            if(value.length > 0) {
                                const images = value.map((item, index) => {
                                    return (
                                        {
                                            id: index,
                                            ...item,
                                        }
                                    );
                                });

                                return (
                                    <CarouselBanner 
                                        key={`${key}-${index}`} 
                                        slides={images} 
                                        options={OPTIONS} 
                                    />
                                );
                            }
                            else return null;
                        }
                        else if(key === 'featuredBrands') {
                            return (
                                value.length > 0 ?
                                <FeaturedBrands 
                                    key={`${key}-${index}`} 
                                    featuredBrands={value} 
                                /> : 
                                null
                            );
                        }
                        else if(key === 'favouriteBrands') {
                            return (
                                value.length > 0 ?
                                <FavoriteBrands 
                                    key={`${key}-${index}`} 
                                    favouriteBrands={value}  
                                /> : 
                                null
                            );
                        }
                        else if(key === 'exclusiveDeals') {
                            return (
                                value.length > 0 ?
                                <ExclusiveDeals 
                                    key={`${key}-${index}`} 
                                /> : 
                                null
                            );
                        }
                        else if(key === 'storeDiscovery') {
                            return (
                                value.length > 0 ?
                                <StoreDiscoveryHub 
                                    key={`${key}-${index}`} 
                                    storeDiscovery={value} 
                                /> : 
                                null
                            )
                        }
                        else if(key === 'customSections') {
                            return (
                                value.length > 0 ?
                                <CustomSection 
                                    key={`${key}-${index}`} 
                                    customSectionData={value} 
                                /> :
                                null
                            )
                        }
                        else return null;
                    }))
                }

                <div className="w-full bg-[#FFFFFF]"></div>
            </div>
        </div>
    );
}

export default Marketplace;
