import React, { useEffect } from 'react';
import ArrowLeft from '../../Assets/ArrowLeft.svg';
import { Link, useParams } from 'react-router-dom';
import ProductCard from './Components/ProductCard';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStoreOffer } from '../../Features/storeDetailsSlice';

function StoreOffer() {
    const { offerId } = useParams();
    const dispatch = useDispatch();

    const { storeOffer } = useSelector((state) => state.storeDetails.storeOffer);

    useEffect(() => {
        if(offerId) {
            dispatch(fetchStoreOffer(offerId));
        }
    }, [offerId]);

    function goBack() {
        window.history.back();
    };

    return (
        <div className="flex flex-col w-[1536px] max-[1536px]:w-full mx-auto">
            <div className='flex flex-col pt-20 max-[768px]:pt-12 max-[426px]:pt-12 min-h-[calc(100vh-73px)] max-[768px]:min-h-[calc(100vh-41px)] max-[426px]:min-h-screen'>
                <div className='flex flex-col gap-6 max-[426px]:gap-4 mx-[50px] max-[1280px]:mx-[35px] max-[768px]:mx-4 my-6'>
                    <div className="flex items-center gap-2">
                        <div onClick={goBack} className="size-8 max-[768px]:size-6 centered cursor-pointer">
                            <img src={ArrowLeft} alt="" />
                        </div>

                        <h1 className='font-semibold text-[20px] max-[768px]:text-[18px] leading-8 max-[768px]:leading-[30px] text-[#000000]'>{storeOffer?.coupon?.couponName}</h1>
                    </div>

                    <div className="grid grid-cols-6 max-[1366px]:grid-cols-5 max-[1080px]:grid-cols-4 max-[768px]:grid-cols-3 max-[426px]:grid-cols-2 gap-x-4 gap-y-4">
                        {storeOffer?.products?.map(product => {
                            return (
                                <ProductCard 
                                    key={product.productId} 
                                    product={product} 
                                    urlPrefix={'../../products/'} 
                                    productId={product.productId} 
                                />
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StoreOffer;
