import React from 'react';
import { useSelector } from 'react-redux';
import OkHand from '../../../../Assets/OkHand.svg';

function Alert() {
    const { droptrackProductDetails } = useSelector((state) => state.app.droptrackProductDetails);

    return (
        <div className='w-full p-3 rounded-lg bg-[#BFBFBF] flex gap-1'>
            <div className="w-5">
                <img className='w-full h-auto' src={OkHand} alt="" />
            </div>

            <div className="w-[calc(100%-20px)] text-body1 text-[#000000]">The best time to purchase this product is now, as the price has been reduced by up to {droptrackProductDetails.diff}%. There is a possibility that the price may increase in the future.</div>
        </div>
    );
}

export default Alert;
