import React from 'react';
import { useDispatch } from 'react-redux';
import { applyCoupon } from '../../../../Features/cartSlice';
import { closeCheckoutModal } from '../../../../Features/checkoutSlice';

function Coupon({ coupon }) {
    const dispatch = useDispatch();

    function handleApplyCoupon() {
        const ids = {
            sellerId: coupon.SellerUserId,
            couponId: coupon.id
        }
        dispatch(applyCoupon(ids));
        dispatch(closeCheckoutModal());
    }

    return (
        <div className='p-4 rounded-2xl bg-[#FFFFFF] border border-[#E3E3E3] shadow-[0_0_8px_0_#0000001F] flex flex-col gap-1'>
            <div className="flex items-center justify-between">
                <h1 className='text-title3 text-[#000000]'>{coupon?.couponName}</h1>

                <button onClick={handleApplyCoupon} className='select-none outline-none text-label1 text-main'>Apply</button>
            </div>

            {
                coupon?.typeCoupon === 'PERCENTAGE_DISCOUNTS' && 
                <p className='text-body1 text-[#666666]'>
                    Save {coupon?.discountPercent}% upto 1500{coupon?.purchaseRequired ? ` on minimum purchase of Rs ${coupon?.purchaseValue}` : ''}
                </p>
            }

            <p className='text-body1 text-[#999999]'>Expires on {coupon?.endDate}</p>
        </div>
    );
}

export default Coupon;
