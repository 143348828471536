import React from 'react'

function SectionTitle({ title }) {
    return (
        <div className="border-b-2 border-main px-3 max-[426px]:px-2 pb-3 max-[426px]:pb-1 rounded-br-xl rounded-bl-xl w-fit">
            <h1 className='text-display max-[1200px]:text-3xl max-[426px]:text-[16px] max-[426px]:leading-7 text-[#000000] select-none'>{title}</h1>
        </div>
    );
}

export default SectionTitle;
