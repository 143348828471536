import React from 'react';
import { getCachedMediaUrl } from '../../../lib/utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../../Features/profileSlice';
import { getMilestoneImage } from '../../../Components/MilestoneImage/MilestoneImage';

function MilestoneCard() {
    const dispatch = useDispatch();

    const { profile } = useSelector((state) => state.profile.profile);

    function handleOpenMilestoneDetails() {
        dispatch(openModal('milestone-data'));
    }

    return (
        <button onClick={handleOpenMilestoneDetails} className="outline-none w-full h-fit bg-[#FFFFFF] border border-[#E3E3E3] p-2 pb-4 flex flex-col gap-4 rounded-lg shadow-[0_0_6px_0_#0000001F]">
            <div className={`w-full rounded-md overflow-hidden ${profile.hasOwnProperty('achieve') ? (profile.achieve ? '' : 'bg-main centered h-[253px]') : ''}`}>
                {
                    profile.hasOwnProperty('achieve') ? 
                    (
                        profile.achieve ? 
                        <img className="w-full h-auto" src={getCachedMediaUrl(profile.achieve.coverImgUrl)} alt="" /> : 
                        <img className="w-full h-auto blur-sm" src={getMilestoneImage('RISING_CRITIC')} alt="" />
                    ) : 
                    (
                        profile?.user?.badge ? 
                        <img className={`w-full h-auto ${profile.user.badge === 'No Review' && 'blur-sm'}`} src={getMilestoneImage(profile.user.badge)} alt="" /> : 
                        <img className="w-full h-auto blur-sm" src={getMilestoneImage('RISING_CRITIC')} alt="" />
                    )
                }
            </div>

            <div className="flex flex-col gap-1">
                <h1 className='text-title1 text-[#000000]'>Start your journey!!!</h1>

                <p className='text-body1 text-[#666666]'>Your next promotion to beginner reviewer awaits at your 1st review. Keep sharing your insights, and you're on your way to the next level!</p>
            </div>
        </button>
    )
}

export default MilestoneCard;
