import React, { useEffect, useRef, useState } from 'react';
import Cross from '../../../../Assets/Cross.svg';
import Search from '../../../../Assets/Search.svg';
import TickMark from '../../../../Assets/TickMark.svg';
import Input from './Input';
import { useDispatch } from 'react-redux';
import { closeCheckoutModal, fetchAddresses } from '../../../../Features/checkoutSlice';
import { useOutsideClickHandler } from '../../../../Hooks/useOutsideClick';
import { createAddress, fetchAreaDetails } from '../../../../lib/utils/apiCalls';

function AddressForm() {
    const dispatch = useDispatch();

    const formRef = useRef(null);

    useOutsideClickHandler(formRef, () => {
        dispatch(closeCheckoutModal());
    });

    const defaultFormInputs = {
        name: '',
        phone: '',
        pinCode: '',
        addressMain: '',
        locality: '',
        city: '',
        state: '',
    }

    const defaultFormErrors = {
        name: '',
        phone: '',
        pinCode: '',
        addressMain: '',
        locality: '',
        city: '',
        state: '',
    }

    const [formInputs, setFormInputs] = useState(defaultFormInputs);
    const [formErrors, setFormErrors] = useState(defaultFormErrors);
    const [defaultAddress, setDefaultAddress] = useState(false);

    const [buttonAvailable, setButtonAvailable] = useState(false);

    const [searchResults, setSearchResults] = useState([]);
    const [suggestionsOpen, setSugegstionsOpen] = useState(false);

    function changeHandler(e) {
        const { name, value } = e.target;
        if(name === 'pinCode') {
            if(value.length === 7) {
                return;
            }
        }
        setFormInputs({ ...formInputs, [name]: value });
    }

    async function handleSaveAddressButton() {
        const validationErrors = {};

        if(!formInputs.name) {
            validationErrors.name = 'Required field';
        }

        if(!formInputs.phone) {
            validationErrors.phone = 'Required field';
        }

        if(!formInputs.pinCode) {
            validationErrors.pinCode = 'Required field';
        }

        if(!formInputs.addressMain) {
            validationErrors.addressMain = 'Required field';
        }

        if(!formInputs.locality) {
            validationErrors.locality = 'Required field';
        }

        if(!formInputs.city) {
            validationErrors.city = 'Required field';
        }

        if(!formInputs.state) {
            validationErrors.state = 'Required field';
        }

        if(Object.keys(validationErrors).length === 0) {
            setFormErrors(defaultFormErrors);
            try {
                const callBody = {
                    ...formInputs,
                    defaultAddress
                };

                const response = await createAddress(callBody);
                if(response.status === 200) {
                    dispatch(fetchAddresses());
                    dispatch(closeCheckoutModal());
                }
            }
            catch(err) {
                console.log(err);
            }
        }
        else {
            setFormErrors(validationErrors);
            return;
        }
    }

    function closeAddressForm() {
        dispatch(closeCheckoutModal());
    }

    useEffect(() => {
        const allInputsFilled = Object.values(formInputs).every(input => input.trim() !== '');
        setButtonAvailable(allInputsFilled);
    }, [formInputs]);

    function handledefaultAddressChange() {
        setDefaultAddress(!defaultAddress);
    }

    async function handleLocalityKeyDown(e) {
        if(e.key === 'Enter') {
            if(e.target.name === 'locality') {
                try {
                    const response = await fetchAreaDetails(e.target.value);
                    if(response.status === 200) {
                        const areaList = response.data[0].PostOffice;
                        if(areaList) {
                            setSugegstionsOpen(true);
                            setSearchResults(areaList);
                        }
                        else {
                            if(searchResults.length > 0) {
                                setSearchResults([]);
                                setSugegstionsOpen(false);
                            }
                        }
                    }
                }
                catch(err) {
                    console.log(err);
                }
            }
        }
    }

    function handleSuggestionsCloseClick() {
        setSugegstionsOpen(false);
    }

    function handleSelectSuggestion(area) {
        setFormInputs({
            ...formInputs,
            locality: area.Name,
            city: area.District,
            state: area.State,
            pinCode: area.Pincode,
        });
        setSugegstionsOpen(false);
        setSearchResults([]);
    }

    return (
        <div ref={formRef} className='w-[35%] max-[1280px]:w-[39%] max-[1024px]:w-[42%] bg-[#FFFFFF] rounded-3xl overflow-hidden'>
            <div className="bg-[#FFFFFF] p-6 flex items-center justify-between shadow-[0px_0px_6px_0px_#0000001F]">
                <h1 className='font-bold text-[24px] leading-9 text-[#000000]'>Add Address</h1>

                <button onClick={closeAddressForm} className="size-10 centered">
                    <img src={Cross} alt="" />
                </button>
            </div>

            <div className="m-6 mt-4 flex flex-col gap-4">
                <div className="w-full flex flex-col gap-3">
                    <Input 
                        inputOptions={{
                            placeholder: 'Name of the receiver',
                            type: 'text',
                            name: 'name',
                            id: 'name',
                            value: formInputs.name,
                            onChange: changeHandler,
                        }} 
                        errorText={formErrors.name} 
                    />

                    <Input 
                        inputOptions={{
                            placeholder: 'Mobile number (only india)',
                            type: 'number',
                            name: 'phone',
                            id: 'phone',
                            value: formInputs.phone,
                            onChange: changeHandler,
                        }} 
                        errorText={formErrors.phone} 
                    />

                    <div className="w-full border-b border-[#E3E3E3]"></div>

                    <Input 
                        inputOptions={{
                            placeholder: 'Address (House No, Building, Street. Area)',
                            type: 'text',
                            name: 'addressMain',
                            id: 'addressMain',
                            value: formInputs.addressMain,
                            onChange: changeHandler,
                        }} 
                        errorText={formErrors.addressMain} 
                    />

                    <div className="flex flex-col gap-1 select-none relative">
                        <div className="flex items-center gap-[10px] w-full border border-[#E3E3E3] focus-within:border-[#666666] bg-[#FFFFFF] px-3 py-[11px] rounded-[9px] shadow-[0px_0px_6px_0px_#0000001F]">
                            <input 
                                placeholder='Locality' 
                                type='text' 
                                name='locality' 
                                id='locality' 
                                value={formInputs.locality} 
                                onChange={changeHandler} 
                                onKeyDown={handleLocalityKeyDown} 
                                className='w-[calc(100%-24px)] outline-none font-normal text-[14px] leading-[26px] text-[#121212] placeholder:text-[#666666]' 
                            />

                            <div className="size-6 centered">
                                <img src={Search} alt="" />
                            </div>
                        </div>

                        {
                            formErrors.locality && 
                            <p className='text-[12px] leading-5 text-[#DC2626]'>{formErrors.locality}</p>
                        }

                        {
                            suggestionsOpen && 
                            <div className="w-full absolute top-14 left-0 bg-[#FFFFFF] rounded-[9px] overflow-hidden shadow-[0px_0px_6px_0px_#0000001F]">
                                <div className="px-4 py-2 flex items-center justify-between border-b border-[#E3E3E3] shadow-[0px_0px_6px_0px_#0000001F]">
                                    <p className='text-title3 text-[#666666] w-[calc(100%-24px)]'>Suggestions</p>

                                    <button onClick={handleSuggestionsCloseClick} className="size-6 centered outline-none border-none">
                                        <img src={Cross} alt="" />
                                    </button>
                                </div>

                                <div className="flex flex-col max-h-44 overflow-y-scroll scrollbar-hide">
                                    {searchResults.map((area, index) => {
                                        const isLast = index === searchResults.length - 1;

                                        return (
                                            <div key={index} onClick={() => handleSelectSuggestion(area)} className={`w-full cursor-pointer hover:bg-[#F3F3F3] px-4 py-1 ${!isLast && 'border-b border-[#E3E3E3]'}`}>
                                                <p className='text-label1 line-clamp-1 text-[#000000]'>{area.Name}, <span className='text-label2 text-[#999999]'>{area.District}, {area.State} - {area.Pincode}</span></p>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        }
                    </div>

                    <Input 
                        inputOptions={{
                            placeholder: 'City/District',
                            type: 'text',
                            name: 'city',
                            id: 'city',
                            value: formInputs.city,
                            onChange: changeHandler,
                        }} 
                        errorText={formErrors.city} 
                    />

                    <div className="grid grid-cols-2 gap-x-3">
                        <Input 
                            inputOptions={{
                                placeholder: 'Pincode',
                                type: 'number',
                                name: 'pinCode',
                                id: 'pinCode',
                                value: formInputs.pinCode,
                                onChange: changeHandler,
                            }} 
                            errorText={formErrors.pinCode} 
                        />

                        <Input 
                            inputOptions={{
                                placeholder: 'State',
                                type: 'text',
                                name: 'state',
                                id: 'state',
                                value: formInputs.state,
                                onChange: changeHandler,
                            }} 
                            errorText={formErrors.state} 
                        />
                    </div>
                </div>

                <div className="py-3 flex items-center justify-between">
                    <p className='select-none font-normal text-[14px] leading-[26px] text-[#000000]'>Mark as the default address</p>

                    <input 
                        type="checkbox" 
                        name="defaultAddress" 
                        id="defaultAddress" 
                        className='hidden' 
                        checked={defaultAddress} 
                        onChange={handledefaultAddressChange}
                    />

                    <label htmlFor="defaultAddress" className={`select-none cursor-pointer size-6 rounded-md bg-[#F3F3F3] centered border ${defaultAddress ? 'border-[#00C853]' : 'border-[#BFBFBF]'}`}>
                        {
                            defaultAddress && 
                            <img src={TickMark} alt="" />
                        }
                    </label>
                </div>

                <button onClick={handleSaveAddressButton} className={`select-none outline-none px-8 py-4 ${buttonAvailable ? 'text-[#000000] bg-main hover:bg-mainHover' : 'bg-[#F3F3F3] text-[#999999] cursor-not-allowed'} rounded-lg font-semibold text-[20px] leading-8`}>Save Address</button>
            </div>
        </div>
    );
}

export default AddressForm;
