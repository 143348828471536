import React, { useEffect, useState } from 'react';
import Cross from '../../../../Assets/Cross.svg';
import SortOption from '../SortKebab/SortOption';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

const sortOptions = [
    { id: 'featured', name: 'Featured' },
    { id: 'new', name: 'New Arrivals' },
    { id: 'high-to-low', name: 'Price: High to Low' },
    { id: 'low-to-high', name: 'Price: Low to High' },
    { id: 'ratings', name: 'Customer Ratings' },
    { id: 'bestseller', name: 'Bestsellers' },
];

function SortSlide({ kebabOpen, boxRef, closeKebab }) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [visible, setVisible] = useState(false);
    const [searchParams] = useSearchParams();
    const categorySortType = searchParams.get('sort');

    useEffect(() => {
        if(kebabOpen) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    }, [kebabOpen]);

    function handleOptionChange(e) {
        const updatedSearchParams = new URLSearchParams(searchParams);
        updatedSearchParams.set('sort', e.target.value);
        navigate(`${pathname}?${updatedSearchParams.toString()}`);
        closeKebab();
        // dispatch(setCategorySortType(e.target.value));
    }

    return (
        <div ref={boxRef} className={`${visible ? 'bottom-0' : '-bottom-80'} hover-transition hidden max-[810px]:flex fixed z-20 left-0 w-full bg-[#FFFFFF] rounded-tl-2xl rounded-tr-2xl flex-col overflow-hidden pb-2`}>
            <div className="p-4 flex items-center justify-between w-full border-b border-[#E3E3E3] shadow-[0_0_8px_0_#0000001F]">
                <p className='text-title3 text-[#000000]'>Sort by</p>

                <button onClick={closeKebab} className="size-8 centered outline-none border-none">
                    <img src={Cross} alt="" />
                </button>
            </div>

            <div className="flex flex-col">
                {sortOptions.map((option, index) => {
                    const isLast = index === (sortOptions.length - 1);

                    return (
                        <SortOption 
                            inputOptions={{
                                id: option.id,
                                value: option.id,
                                type: 'radio',
                                checked: categorySortType === option.id,
                                onChange: handleOptionChange,
                            }}
                            sortType={option} 
                            key={option.id} 
                            isLast={isLast} 
                        />
                    )
                })}
            </div>
        </div>
    );
}

export default SortSlide;
