import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import User from '../../Pages/User/User.Page';

function UserRoute() {
    return (
        <Routes>
            <Route index element={<Navigate to=".." replace />} />

            <Route path=':userId' element={<User />} />
        </Routes>
    );
}

export default UserRoute;
