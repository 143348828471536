import React from 'react';
import { useSelector } from 'react-redux';

function HyprcomMoney() {
    const { hyprcomMoney } = useSelector((state) => state.checkout.hyprcomMoney);

    return (
        <div className="flex flex-col gap-2">
            <div className="border bg-[#FFFFFF] border-[#E3E3E3] shadow-[0_0_8px_0_#0000001F] p-4 rounded-2xl flex flex-col gap-1 select-none">
                <div className="flex flex-col text-[#666666]">
                    <h1 className='font-semibold text-[20px] leading-8'>Hyprcom Money Balance</h1>

                    <p className='font-medium text-[16px] leading-7'>Wallet no: {hyprcomMoney?.walletData?.id.split('-').reverse()[0]}</p>
                </div>

                <p className='font-bold text-[24px] leading-9 text-[#000000]'>₹{hyprcomMoney?.walletData?.money?.toFixed(2)}</p>
            </div>

            <button className='w-fit outline-none font-semibold text-[14px] leading-[26px] text-main'>Apply Hyprcom money</button>
        </div>
    );
}

export default HyprcomMoney;
