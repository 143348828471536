import React, { useEffect, useRef, useState } from 'react';
import countryCode from 'country-codes-list/countriesData';
import { useOutsideClickForTwoRef } from '../../../Hooks/useOutsideClickForTwoRef';
import { verifyEmail } from '../../../lib/utils/utils';
import { checkUsernameAvailable, getOtpFromOtplessForSignup } from '../../../lib/utils/apiCalls';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import FormInput from './FormInput/FormInput';
import ChevronGray from '../../../Assets/ChevronGray.svg';
import Search from '../../../Assets/Search.svg';
import TickMark from '../../../Assets/TickMark.svg';

function SignupForm({ sendOtp }) {
    const navigate = useNavigate();
    const location = useLocation();

    const dropdownButtonRef = useRef();
    const countryListDropdownRef = useRef();

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const [searchText, setSearchText] = useState('');
    const [filteredCountryList, setFilteredCountryList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({});

    useOutsideClickForTwoRef(countryListDropdownRef, dropdownButtonRef, () => {
        setDropdownOpen(false);
        setSearchText('');
    });

    function handleDropdownToggle() {
        if(dropdownOpen) {
            setSearchText('');
        }
        setDropdownOpen(!dropdownOpen);
    }

    function handleSelectOption(selection) {
        setSelectedCountry(selection);
        setDropdownOpen(false);
        setSearchText('');
    }

    function handleSearchTextChange(e) {
        setSearchText(e.target.value);
    }

    const defaultFormInputs = {
        displayname: '',
        fullname: '',
        email: '',
        phone: '',
        country_code: '',
        oauth: false,
    }

    const defaultFormErrors = {
        displayname: '',
        fullname: '',
        email: '',
        phone: '',
    }

    const [formInputs, setFormInputs] = useState(defaultFormInputs);
    const [formErrors, setFormErrors] = useState(defaultFormErrors);

    const [acceptTerms, setAcceptTerms] = useState(false);
    const [buttonAvailable, setButtonAvailable] = useState(false);

    function changeHandler(e) {
        const { name, value } = e.target;
        setFormInputs({ ...formInputs, [name]: value });
    }

    function handleRoute(isAvailable) {
        navigate({
            pathname: location.pathname,
            search: isAvailable ? `?code=${selectedCountry.countryCallingCode}&phoneNumber=${formInputs.phone}` : ''
        });
    };

    async function handleGetCode() {
        const validationErrors = {};

        if(!formInputs.displayname) {
            validationErrors.displayname = 'Required field';
        }

        if(!formInputs.fullname) {
            validationErrors.fullname = 'Required field';
        }

        if(!formInputs.email) {
            validationErrors.email = 'Required field';
        }
        else if(!verifyEmail(formInputs.email)) {
            validationErrors.email = 'Invalid email format';
        }

        if(!formInputs.phone) {
            validationErrors.phone = 'Required field';
        }

        if(Object.keys(validationErrors).length === 0) {
            try {
                const callBody = {
                    ...formInputs,
                    country_code: selectedCountry?.countryCallingCode,
                }

                const response = await getOtpFromOtplessForSignup(callBody);
                if(response.status === 200) {
                    sendOtp(true);
                    handleRoute(true);
                }
            }
            catch(err) {
                console.log(err);
            }
        }
        else {
            setFormErrors(validationErrors);
            return;
        }
    }

    function handleCheckMarkClick() {
        setAcceptTerms(!acceptTerms);
    }

    useEffect(() => {
        const filteredCode = countryCode.find(country => country.countryCallingCode === '91');
        setSelectedCountry(filteredCode);
    }, []);

    useEffect(() => {
        if(searchText) {
            const filteredList = countryCode.filter(item => item.countryNameEn.toLowerCase().includes(searchText.toLowerCase()));
            setFilteredCountryList(filteredList);
        }
        else {
            setFilteredCountryList(countryCode);
        }
    }, [searchText]);

    useEffect(() => {
        setButtonAvailable(acceptTerms);
    }, [acceptTerms]);

    useEffect(() => {
        async function checkDisplaynameAvailable(name) {
            try {
                const response = await checkUsernameAvailable(name);
                if(response.status === 200) {
                    if(!response.data.data) {
                        setFormErrors({
                            ...formErrors,
                            displayname: 'Display name not available',
                        });
                    }
                    else {
                        setFormErrors({
                            ...formErrors,
                            displayname: '',
                        });
                    }
                }
            }
            catch(err) {
                console.log(err);
            }
        }

        if(formInputs.displayname && formInputs.displayname.length > 1) {
            checkDisplaynameAvailable(formInputs.displayname);
        }
        else {
            setFormErrors({
                ...formErrors,
                displayname: '',
            });
        }
    }, [formInputs.displayname]);

    return (
        <div className="flex flex-col items-center gap-8 max-[600px]:gap-6 w-full">
                    <div className="flex flex-col gap-4 w-full">
                        <div className="flex items-center justify-between w-full">
                            <h1 className='text-display max-[600px]:text-[24px] max-[600px]:leading-9 text-[#000000] select-none'>Welcome to <span className='text-main'>Hyprcom</span></h1>

                            <Link to='/' className='font-bold text-[14px] leading-6 text-main'>Skip Signup</Link>
                        </div>

                        <div className="flex flex-col gap-2">
                            <FormInput 
                                inputOptions={{
                                    placeholder: 'enter display name',
                                    type: 'text',
                                    name: 'displayname',
                                    id: 'displayname',
                                    value: formInputs.displayname,
                                    onChange: changeHandler,
                                }} 
                                labelText={'Display Name'} 
                                errorText={formErrors.displayname} 
                            />

                            <FormInput 
                                inputOptions={{
                                    placeholder: 'enter full name',
                                    type: 'text',
                                    name: 'fullname',
                                    id: 'fullname',
                                    value: formInputs.fullname,
                                    onChange: changeHandler,
                                }} 
                                labelText={'Full Name'} 
                                errorText={formErrors.fullname} 
                            />

                            <FormInput 
                                inputOptions={{
                                    placeholder: 'enter email address',
                                    type: 'email',
                                    name: 'email',
                                    id: 'email',
                                    value: formInputs.email,
                                    onChange: changeHandler,
                                }} 
                                labelText={'Email Address'} 
                                errorText={formErrors.email} 
                            />

                            <div className="flex flex-col gap-2 max-[600px]:gap-1">
                                <div className="flex items-center justify-between">
                                    <label className='text-title3 text-[#000000]' htmlFor="phoneNumber">Phone Number</label>

                                    <p className='text-label2 text-[#EF4444]'>{formErrors.phone}</p>
                                </div>

                                <div className="relative px-3 max-[600px]:px-4 py-4 max-[600px]:py-2 border border-[#F3F3F3] bg-[#F3F3F3] rounded-[9px] flex items-center gap-[10px]">
                                    <button ref={dropdownButtonRef} onClick={handleDropdownToggle} className="flex items-center gap-[10px] outline-none border-none">
                                        <p className='text-title3 text-[#666666]'>+{selectedCountry?.countryCallingCode?.split(' ').join('-')}</p>

                                        <div className="size-6 centered">
                                            <img src={ChevronGray} alt="" />
                                        </div>
                                    </button>

                                    <input 
                                        type="number" 
                                        id='phone' 
                                        name='phone' 
                                        className='flex-grow outline-none border-none bg-transparent text-title3 text-[#000000] placeholder:text-[#BFBFBF]' 
                                        placeholder='enter phone number' 
                                        onChange={changeHandler} 
                                        value={formInputs.phone} 
                                    />

                                    {
                                        dropdownOpen && 
                                        <div ref={countryListDropdownRef} className="py-2 absolute max-[600px]:top-12 top-16 left-0 w-full border border-[#E3E3E3] bg-[#FFFFFF] rounded-lg max-h-60 overflow-y-scroll scrollbar-hide">
                                            <div className="px-4 py-2">
                                                <div className="w-full p-2 rounded-lg flex items-center gap-2 bg-[#F3F3F3] border border-[#BFBFBF]">
                                                    <div className="size-6 centered">
                                                        <img src={Search} alt="" />
                                                    </div>

                                                    <input 
                                                        id='search' 
                                                        name='search' 
                                                        className='w-[calc(100%-24px)] bg-transparent outline-none border-none text-body2 placeholder:text-[#999999] text-[#121212]' 
                                                        type="text" 
                                                        placeholder='Search for country code....' 
                                                        value={searchText} 
                                                        onChange={handleSearchTextChange} 
                                                    />
                                                </div>
                                            </div>

                                            {filteredCountryList?.sort((a, b) => a.countryNameEn.localeCompare(b.countryNameEn)).map((country, index) => {
                                                const isLastItem = index === countryCode.length - 1;

                                                return (
                                                    <div key={index} onClick={() => handleSelectOption(country)} className={`text-title3 cursor-pointer ${!isLastItem ? 'border-b border-[#E3E3E3]' : ''} px-4 py-1 flex items-center gap-4`}>
                                                        <p className='text-[#666666] w-1/5'>+&#40;{country.countryCallingCode.replace(/\s+/g, '-')}&#41;</p>

                                                        <p className='text-[#000000] w-4/5'>{country.countryNameEn}</p>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="py-3 max-[600px]:py-2 flex gap-3">
                                <button onClick={handleCheckMarkClick} className={`outline-none size-6 centered border ${acceptTerms ? 'border-main' : 'border-[#BFBFBF]'} rounded-md bg-[#F3F3F3]`}>
                                    {
                                        acceptTerms && 
                                        <img src={TickMark} alt="" />
                                    }
                                </button>

                                <p className='select-none w-[calc(100%-24px)] font-normal text-[14px] leading-[26px] max-[600px]:text-[12px] max-[600px]:leading-[22px] text-[#666666]'>
                                    I agree to Hyprcom's <span className='text-main'>End User License Agreement</span>, <Link to='/terms-and-conditions' className='text-main hover:underline'>Terms and Conditions</Link> and <Link to='/privacy-policy' className='text-main hover:underline'>Privacy Policy</Link>.
                                </p>
                            </div>
                        </div>

                        <button onClick={handleGetCode} className={`select-none outline-none px-8 max-[600px]:px-6 py-4 max-[600px]:py-3 ${buttonAvailable ? 'bg-main hover:bg-mainHover text-[#000000]' : 'bg-[#E3E3E3] cursor-not-allowed text-[#666666]'} rounded-lg text-heading2 max-[600px]:text-[16px] max-[600px]:leading-7`}>Get the code</button>
                    </div>

                    <div className="text-[14px] leading-6 flex items-center gap-1">
                        <p className='font-medium text-[#000000]'>Already have an Account?</p>

                        <Link className='font-bold text-main' to='/auth/login'>Login here</Link>
                    </div>
                </div>
    );
}

export default SignupForm;
