import React from 'react';
import { useDispatch } from 'react-redux';
import { openModal, setAddressId } from '../../../../Features/settingsSlice';

function AddressCard({ address }) {
    const dispatch = useDispatch();

    // function handleEditAddress() {
    //     dispatch(openModal('address-form'));
    //     dispatch(setAddressId(address.id));
    // }

    function handleDeleteAddress() {
        dispatch(openModal('address-delete-confirm'));
        dispatch(setAddressId(address.id));
    }

    return (
        <div className='px-4 rounded-2xl bg-[#F3F3F3] flex flex-col gap-1 py-[10px]'>
            <div className="flex items-center justify-between text-label1">
                <p className='text-[#000000]'>{address?.name}</p>

                <div className="flex items-center gap-1 text-[#B0B0B0]">
                    {/* <button onClick={handleEditAddress} className='px-[10px]'>Edit</button>

                    <p>|</p> */}

                    <button onClick={handleDeleteAddress} className='px-[10px]'>Delete</button>
                </div>
            </div>

            <div className="flex flex-col text-body2 text-[#666666]">
                <p>{address?.addressMain}, {address?.locality}</p>

                <p>{address?.city}</p>

                <p>{address?.state} - {address?.pinCode}</p>
            </div>
        </div>
    );
}

export default AddressCard;
