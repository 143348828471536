import React, { useEffect, useState } from 'react';
import ProductCard from '../ProductCard';
import { Link } from 'react-router-dom';
import SectionTitle from '../../../../Components/SectionTitle/SectionTitle';

function ProductOffers({ offerData }) {
    const [sliceCount, setSliceCount] = useState(10);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1024) {
                setSliceCount(5);
            } else {
                setSliceCount(10);
            }
        };

        handleResize();

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div className='w-[1536px] max-[1536px]:w-full mx-auto px-[50px] max-[1280px]:px-[35px] max-[1024px]:px-0 flex flex-col gap-8 max-[426px]:gap-4'>
            <div className="hidden max-[1024px]:flex max-[1024px]:px-6 items-center justify-between">
                <h1 className='text-title1 text-[#000000] max-[426px]:text-[16px] max-[426px]:leading-7'>{offerData?.customSectionName}</h1>

                {/* <Link to={`/collection/`} className='text-label1 text-main'>See all</Link> */}
            </div>
    
            <div className="mx-auto flex max-[1024px]:hidden">
                <SectionTitle 
                    title={offerData?.customSectionName} 
                />
            </div>

            <div className="max-[1024px]:px-6 w-full grid grid-cols-5 gap-x-4 gap-y-4 max-[1024px]:flex max-[1024px]:overflow-x-scroll max-[1024px]:gap-3 scrollbar-hide">
                {offerData?.productIds?.slice(0, sliceCount)?.map(product => (
                    <ProductCard 
                        key={product.id} 
                        product={product} 
                        urlPrefix={`/stores/${product.SellerUserId}/products/`} 
                        productId={product.id} 
                    />
                ))}
            </div>

            {/* <Link to={`/collection/`} className='hover-transition rounded-lg mx-auto mt-4 w-fit px-4 py-2 border-2 border-[#E3E3E3] hover:border-[#000000] active:bg-[#00000033] text-title3 text-[#121212] max-[1024px]:hidden'>View all</Link> */}
        </div>
    );
}

export default ProductOffers;
