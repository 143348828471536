import React from 'react';
import UPIModes from './Components/UPIModes/UPIModes';
import BillingDetails from './Components/BillingDetails/BillingDetails';
import Rupee from '../../Assets/Payments/Rupee.png';
import BHIM from '../../Assets/Payments/BHIM.svg';

import { useDispatch, useSelector } from 'react-redux';
import { setPaymentModeId } from '../../Features/checkoutSlice';
import ModalContainer from '../../Components/ModalContainer/ModalContainer';
import PaymentSuccess from './Components/PaymentSuccess';

const upiModes = [
    { id: 'Cod', name: 'Cash on Delivery', image: Rupee },
];

function PaymentGateway() {
    const dispatch = useDispatch();

    const paymentModeId = useSelector((state) => state.checkout.paymentModeId);
    const { type, open } = useSelector((state) => state.checkout.modal);

    function handleOptionChange(e) {
        dispatch(setPaymentModeId(e.target.value));
    }

    return (
        <div className="mx-[50px] max-[1280px]:mx-[35px] flex gap-6 mb-8">
            <div className="w-3/5 bg-[#FFFFFF] border border-[#E3E3E3] shadow-[0_0_8px_0_#0000001F] p-6 rounded-2xl flex flex-col gap-6 h-fit">
                <div className="flex flex-col gap-2">
                    <h1 className='font-semibold text-[20px] leading-8 text-[#666666]'>Select Payment Mode</h1>

                    <div className="flex flex-col rounded-xl overflow-hidden border border-[#BFBFBF]">
                        {upiModes.map(mode => {
                            return (
                                <UPIModes 
                                    inputOptions={{
                                        id: mode.id,
                                        value: mode.id,
                                        type: 'radio',
                                        checked: paymentModeId === mode.id,
                                        onChange: handleOptionChange,
                                    }}
                                    upiMode={mode} 
                                    key={mode.id} 
                                />
                            );
                        })}

                        <label htmlFor="Prepaid" className={`flex items-center gap-[10px] justify-between px-3 py-[14px] select-none cursor-pointer ${paymentModeId === 'Prepaid' ? 'bg-[#E6FAEE]' : 'bg-[#FFFFFF]'}`}>
                            <div className="w-[calc(100%-32px)] flex items-center gap-2">
                                <div className="w-10 h-7 rounded-md overflow-hidden border border-[#757575] bg-[#FFFFFF] centered">
                                    <img className='size-full' src={BHIM} alt="" />
                                </div>

                                <p className='font-semibold text-[14px] leading-[26px] text-[#000000]'>Prepaid</p>

                                <input 
                                    id='Prepaid' 
                                    value='Prepaid' 
                                    type='radio' 
                                    checked={paymentModeId === 'Prepaid'} 
                                    onChange={handleOptionChange} 
                                    className='hidden' 
                                />
                            </div>

                            <div className="size-8 centered">
                                <div className={`size-4 rounded-full border-2 ${paymentModeId === 'Prepaid' ? 'border-main' : 'border-[#BFBFBF]'} relative`}>
                                    {
                                        paymentModeId === 'Prepaid' && 
                                        <div className="size-2 rounded-full bg-main absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"></div>
                                    }
                                </div>
                            </div>
                        </label>
                    </div>
                </div>
            </div>

            <BillingDetails />

            {
                open && 
                <ModalContainer>
                    { type === 'payment-success' && <PaymentSuccess /> }
                </ModalContainer>
            }
        </div>
    );
}

export default PaymentGateway;
