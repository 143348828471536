import React, { useContext } from 'react'
import Button from './Button';
import { PersonalizationFormContext } from '../../../../Context/personalizationFormContext';

function SectionBox({ title, subtitle, array }) {
    const {
        productCategories,
        fashionTaste,
        offerType,
        gender,
        paymentType,
    } = useContext(PersonalizationFormContext);

    return (
        <div className="flex flex-col gap-4 p-4 rounded-2xl border border-[#BFBFBF]">
            <div className="flex flex-col">
                <h1 className='text-title1 max-[600px]:text-[16px] max-[600px]:leading-7 text-[#000000]'>{title}</h1>

                {
                    subtitle && 
                    <p className='text-body1 max-[600px]:text-[12px] max-[600px]:leading-6 text-[#666666]'>{subtitle}</p>
                }
            </div>

            <div className="flex flex-wrap items-center gap-2">
                {array.map(item => {
                    let isSelected;

                    if(item.id === gender) {
                        isSelected = true;
                    }

                    if(productCategories.includes(item.id)) {
                        isSelected = true;
                    }

                    if(fashionTaste.includes(item.id)) {
                        isSelected = true;
                    }

                    if(paymentType.includes(item.id)) {
                        isSelected = true;
                    }

                    if(offerType.includes(item.id)) {
                        isSelected = true;
                    }

                    return (
                        <Button 
                            item={item} 
                            key={item.id} 
                            isSelected={isSelected} 
                        />
                    );
                })}
            </div>
        </div>
    );
}

export default SectionBox;
