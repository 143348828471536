import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedVariant } from '../../../../Features/productSlice';
import { getCachedMediaUrl } from '../../../../lib/utils/utils';

function ColorButton({ color }) {
    const dispatch = useDispatch();
    const selectedVariant = useSelector((state) => state.product.selectedVariant);

    const mediaContainerRef = useRef(null);

    const [mediaContainerHeight, setMediaContainerHeight] = useState(0);

    function handleColorClick(color) {
        if(selectedVariant.color !== color) {
            dispatch(setSelectedVariant({
                ...selectedVariant,
                color: color,
            }));
        }
    }

    useEffect(() => {
        const updateHeight = () => {
            if (mediaContainerRef.current) {
                setMediaContainerHeight(((1296 / 1080) * mediaContainerRef.current.clientWidth));
            }
        };

        const resizeObserver = new ResizeObserver(() => {
            updateHeight();
        });

        if (mediaContainerRef.current) {
            resizeObserver.observe(mediaContainerRef.current);
        }

        updateHeight();

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    return (
        <button onClick={() => handleColorClick(color.color)} className={`outline-none max-[426px]:flex-shrink-0 w-1/5 max-[1080px]:w-1/4 max-[768px]:w-[30%] rounded-xl overflow-hidden bg-[#F3F3F3] border-b-2 ${selectedVariant.color === color.color ? 'border-main' : 'border-[#F3F3F3]'}`}>
            <img ref={mediaContainerRef} style={{ height: `${mediaContainerHeight}px` }} className='w-full object-cover rounded-xl' src={getCachedMediaUrl(color.imageUrl)} alt="" />

            <h1 className='text-label1 max-[1080px]:text-[12px] text-[#000000] w-fit mx-auto px-1 mt-1 line-clamp-1'>{color.color}</h1>
        </button>
    );
}

export default ColorButton;
