import React, { useRef } from 'react';
import Cross from '../../../../Assets/Cross.svg';
import { useDispatch } from 'react-redux';
import { closeOrderModal } from '../../../../Features/orderDetailsSlice';
import { useOutsideClickHandler } from '../../../../Hooks/useOutsideClick';

function TrackExchangeStatus() {
    const containerRef = useRef(null);
    const dispatch = useDispatch();

    useOutsideClickHandler(containerRef, () => {
        dispatch(closeOrderModal());
    });

    function handleCloseModal() {
        dispatch(closeOrderModal());
    }

    return (
        <div ref={containerRef} className='w-[35%] max-[1280px]:w-[39%] max-[1024px]:w-[42%] bg-[#FFFFFF] rounded-2xl overflow-hidden flex flex-col gap-4'>
            <div className="p-6 flex items-center justify-between bg-[#F3F3F3]">
                <h1 className='font-bold text-[24px] leading-9 text-[#000000]'>Exchange Status</h1>

                <button onClick={handleCloseModal} className="size-10 centered outline-none">
                    <img src={Cross} alt="" />
                </button>
            </div>

            <div className="px-6 pb-6 flex flex-col gap-4">
                <div className=""></div>
            </div>
        </div>
    );
}

export default TrackExchangeStatus;
