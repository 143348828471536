import React from 'react';
import { useSelector } from 'react-redux';

function EstimatedPrice() {
    const { estimatedPrice } = useSelector((state) => state.checkout.estimatedPrice);

    return (
        <div className="p-4 rounded-2xl flex flex-col gap-2 font-medium text-[12px] leading-6 text-[#000000]">
            <div className="flex items-center justify-between">
                <h1>Price</h1>

                <p>₹{estimatedPrice?.orderCost}</p>
            </div>

            <div className="flex items-center justify-between">
                <h1>Tax</h1>

                <p>₹{estimatedPrice?.tax}</p>
            </div>

            <div className="flex items-center justify-between">
                <h1>Discount</h1>

                <p className='text-main'>- ₹{estimatedPrice?.MainDiscount}</p>
            </div>

            <div className="flex items-center justify-between">
                <h1>Applied Coupon</h1>

                {
                    estimatedPrice?.couponData?.length > 0 ? 
                    <p className='text-main'>- ₹{estimatedPrice?.couponData[0]?.discount.toFixed(2)}</p> : 
                    <p className='text-[#000000]'>-</p>
                }
            </div>

            <div className="flex items-center justify-between">
                <h1>Shipping Charge</h1>

                <p>{estimatedPrice?.deliveryFee > 0 ? `₹${estimatedPrice?.deliveryFee}` : 'FREE'}</p>
            </div>

            <div className="w-full border border-[#666666] border-dashed"></div>

            <div className="flex items-center justify-between font-semibold text-[14px] leading-[26px] text-[#000000]">
                <h1>Total Price</h1>

                <p>₹{estimatedPrice?.totalcost}</p>
            </div>
        </div>
    );
}

export default EstimatedPrice;
