import React from 'react';

function UPIModes({ upiMode, inputOptions }) {
    return (
        <label htmlFor={inputOptions.id} className={`flex items-center gap-[10px] justify-between px-3 py-[14px] ${inputOptions.checked ? 'bg-[#E6FAEE]' : 'bg-[#FFFFFF]'} border-b border-[#BFBFBF] select-none cursor-pointer`}>
            <div className="w-[calc(100%-32px)] flex items-center gap-2">
                <div className="w-10 h-7 rounded-md overflow-hidden border border-[#757575] bg-[#FFFFFF] centered">
                    <img className='h-4 w-auto' src={upiMode.image} alt={upiMode.name} />
                </div>

                <p className='font-semibold text-[14px] leading-[26px] text-[#000000]'>{upiMode.name}</p>

                <input 
                    { ...inputOptions } 
                    className='hidden' 
                />
            </div>

            <div className="size-8 centered">
                <div className={`size-4 rounded-full border-2 ${inputOptions.checked ? 'border-main' : 'border-[#BFBFBF]'} relative`}>
                    {
                        inputOptions.checked && 
                        <div className="size-2 rounded-full bg-main absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"></div>
                    }
                </div>
            </div>
        </label>
    );
}

export default UPIModes;
