import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../Auth/Interceptor';

const initialStateValue = {
    orders: {
        orders: [],
        loading: 'idle',
        error: null,
    },
}

const fetchOrders = createAsyncThunk('orders/fetchOrders', async () => {
    try {
        const response = await api.get(`orders/orderGet`);
        return response.data.data;
    }
    catch(error) {
        throw error;
    }
});

const ordersSlice = createSlice({
    name: 'orders',
    initialState: initialStateValue,
    reducers: {
        // openCheckoutModal: (state, action) => {
        //     state.modal.type = action.payload;
        // },
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchOrders.pending, (state) => {
            state.orders.loading = 'loading';
        })
        .addCase(fetchOrders.fulfilled, (state, action) => {
            state.orders.loading = 'succeeded';
            state.orders.orders = action.payload;
        })
        .addCase(fetchOrders.rejected, (state, action) => {
            state.orders.loading = 'failed';
            state.orders.error = action.error.message;
        });
    },
});

export const {
} = ordersSlice.actions;

export {
    fetchOrders,
}

export default ordersSlice.reducer;
