import React from 'react';
import ProductOffers from '../ProductOffers/ProductOffers';

function CustomSection({ customSectionData }) {
    return (
        <>
            {customSectionData?.map((sectionData, index) => {
                if(sectionData.dataType === 'PRODUCT') {
                    return (
                        <ProductOffers 
                            key={index} 
                            offerData={sectionData} 
                        />
                    )
                }
                else {
                    return null;
                }
            })}
        </>
    );
}

export default CustomSection;
