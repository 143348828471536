import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DotButton, useDotButton } from './EmblaCarouselDotButton';
import useEmblaCarousel from 'embla-carousel-react';
import './embla.css';

function ProductImageCarousel(props) {
    const mediaContainerRef = useRef(null);

    const [mediaContainerHeight, setMediaContainerHeight] = useState(0);

    const { slides, options } = props;
    const [emblaRef, emblaApi] = useEmblaCarousel(options, []);

    const onNavButtonClick = useCallback((emblaApi) => {
        const autoplay = emblaApi?.plugins()?.autoplay
        if (!autoplay) return
    
        const resetOrStop =
          autoplay.options.stopOnInteraction === false
            ? autoplay.reset
            : autoplay.stop
    
        resetOrStop()
    }, []);

    const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(
        emblaApi,
        onNavButtonClick
    );

    useEffect(() => {
        const updateHeight = () => {
            if (mediaContainerRef.current) {
                setMediaContainerHeight((1296 / 1080) * mediaContainerRef.current.clientWidth);
            }
        };

        const resizeObserver = new ResizeObserver(() => {
            updateHeight();
        });

        if (mediaContainerRef.current) {
            resizeObserver.observe(mediaContainerRef.current);
        }

        updateHeight();

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    return (
        <section className="embla w-full">
            <div className="embla__viewport w-full" ref={emblaRef}>
                <div ref={mediaContainerRef} className="embla__container w-full" style={{ height: `${mediaContainerHeight}px` }}>
                    {slides.map((slide) => (
                        <div className="embla__slide size-full centered bg-[#F3F3F3]" key={slide.id}>
                            <img className='max-w-full max-h-full' src={slide.link} alt="" />
                        </div>
                    ))}
                </div>
            </div>

            <div className="embla__controls">
                <div className="embla__dots">
                    {scrollSnaps.map((_, index) => (
                        <DotButton
                            key={index}
                            onClick={() => onDotButtonClick(index)}
                            className={'embla__dot'.concat(
                                index === selectedIndex ? ' embla__dot--selected' : ''
                            )}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
}

export default ProductImageCarousel;
