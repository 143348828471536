import React, { useEffect, useRef, useState } from 'react';
import { getCachedMediaUrl } from '../../../../lib/utils/utils';
import ImageCard from './ImageCard';
import DropTrack from '../../../../Assets/DropTrack.svg';
import { useDispatch, useSelector } from 'react-redux';
import { addToLocalCartList, changeBagQuantity, setImages } from '../../../../Features/appSlice';
import { addProductToCart, addProductToDroptrack, checkProductInDroptrack } from '../../../../lib/utils/apiCalls';
import { fetchCart } from '../../../../Features/cartSlice';
import { showToast } from '../../../Toaster/Toaster';
import { v4 as uuidv4 } from 'uuid';

function ImageSection() {
    const dispatch = useDispatch();
    const mediaContainerRef = useRef(null);

    const product = useSelector((state) => state.app.product.product);
    const selectedVariant = useSelector((state) => state.app.selectedVariant);
    const images = useSelector((state) => state.app.images);
    const { productId } = useSelector((state) => state.app.productModal);
    const bagQuantity = useSelector((state) => state.app.bagQuantity);
    const { user } = useSelector((state) => state.user.user);

    const [mediaContainerHeight, setMediaContainerHeight] = useState(0);

    const [productInDroptrack, setProductInDroptrack] = useState(false);

    useEffect(() => {
        if(mediaContainerRef.current) {
            setMediaContainerHeight(mediaContainerRef.current.clientWidth);
        }
    }, [mediaContainerRef]);

    useEffect(() => {
        if(selectedVariant.size && selectedVariant.color) {
            if((product.product.productSpecs.size === selectedVariant.size) && (product.product.productSpecs.color === selectedVariant.color)) {
                dispatch(setImages({
                    variantImages: product.product.productImgUrls,
                    activeImageUrl: product.product.productImgUrls[0],
                }));
            }
            else {
                const filteredVariant = product.varient.filter(variant => variant.VarientColor.trim() === selectedVariant.color.trim() && variant.VarientSize.trim() === selectedVariant.size.trim());
                if(filteredVariant.length > 0) {
                    dispatch(setImages({
                        variantImages: filteredVariant[0].VarientImgUrl,
                        activeImageUrl: filteredVariant[0].VarientImgUrl[0],
                    }));
                }
            }
        }
    }, [selectedVariant.color]);

    async function handleAddToCart() {
        if(Object.keys(user).length > 0) {
            if(selectedVariant.quantity > 0) {
                if(bagQuantity > 0) {
                    try {
                        const callBody = {
                            productId: productId,
                            varaint: selectedVariant.variantId,
                            quantity: bagQuantity,
                            reference: false,
                        }
        
                        const response = await addProductToCart(callBody);
                        if(response.status === 200) {
                            dispatch(fetchCart());
                            dispatch(changeBagQuantity(1));
                        }
                    }
                    catch(err) {
                        console.log(err);
                    }
                }
                else {
                    showToast('Add quantity first', 'warning');
                }
            }
            else {
                showToast('Variant out of stock', 'warning');
            }
        }
        else {
            if(selectedVariant.quantity > 0) {
                if(bagQuantity > 0) {
                    const obj = { id: uuidv4(), product: product.product, quantity: bagQuantity, variantId: selectedVariant.variantId, size: selectedVariant.size, color: selectedVariant.color };
                    dispatch(addToLocalCartList(obj));
                    showToast('Product added to bag', 'success');
                }
                else {
                    showToast('Add quantity first', 'warning');
                }
            }
            else {
                showToast('Variant out of stock', 'warning');
            }
        }
    }

    async function handleAddProductToDroptrack() {
        if(Object.keys(user).length > 0) {
            try {
                const callBody = {
                    ProductId: productId,
                };
    
                const response = await addProductToDroptrack(callBody);
                if(response.status === 200) {
                    setProductInDroptrack(true);
                }
            }
            catch(err) {
                console.log(err);
            }
        }
        else {
            showToast('Log in to continue.', 'warning');
        }
    }

    useEffect(() => {
        async function fetchProductDroptrackStatus(id) {
            try{
                const response = await checkProductInDroptrack(id);
                if(response.status === 200) {
                    setProductInDroptrack(response.data.data);
                }
            }
            catch(err) {
                console.log(err);
            }
        }

        if(productId) {
            if(Object.keys(user).length > 0) {
                fetchProductDroptrackStatus(productId);
            }
        }
    }, [productId, user]);

    return (
        <div className="w-[45%] flex gap-3">
            <div className="w-[60px] h-fit flex flex-col gap-3">
                {images?.variantImages?.map(image => {
                    return (
                        <ImageCard 
                            key={image} 
                            image={image} 
                        />
                    )
                })}
            </div>

            <div className="flex flex-col gap-6 w-[calc(100%-100px)]">
                <div ref={mediaContainerRef} className="w-full centered" style={{ height: `${mediaContainerHeight}px` }}>
                    <img className='max-w-full max-h-full' src={getCachedMediaUrl(images.activeImageUrl)} alt="" />
                </div>

                <div className="w-full flex items-center gap-3">
                    <button onClick={handleAddToCart} className={`${productInDroptrack ? 'w-full' : 'w-[calc(100%-64px)]'} px-8 py-4 bg-main hover:bg-mainHover rounded-lg font-semibold text-[20px] leading-8 text-[#000000] text-nowrap`}>Add To Bag</button>

                    {
                        !productInDroptrack && 
                        <button onClick={handleAddProductToDroptrack} className={`size-16 p-4 rounded-lg bg-[#BFBFBF] ${Object.keys(user).length === 0 && 'cursor-not-allowed'} centered`}>
                            <img src={DropTrack} alt="" />
                        </button>
                    }
                </div>
            </div>
        </div>
    );
}

export default ImageSection;
