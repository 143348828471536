import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../Auth/Interceptor';

const initialStateValue = {
    cart: {
        cart: [],
        loading: 'idle',
        error: null,
    },
    wishlist: {
        wishlist: [],
        loading: 'idle',
        error: null,
    },
    cartList: [],
}

const fetchCart = createAsyncThunk('cart/fetchCart', async () => {
    try {
        const response = await api.get('userCart');
        return response.data.data;
    }
    catch(error) {
        throw error;
    }
});

const fetchWishlist = createAsyncThunk('cart/fetchWishlist', async () => {
    try {
        const response = await api.get('wishlist');
        return response.data.data;
    }
    catch(error) {
        throw error;
    }
});

const userSlice = createSlice({
    name: 'cart',
    initialState: initialStateValue,
    reducers: {
        setCartList: (state, action) => {
            state.cartList = action.payload;
        },
        applyCoupon: (state, action) => {
            const { sellerId, couponId } = action.payload;
            const filteredSeller = state.cartList.find(item => item.sellerId === sellerId);
            filteredSeller.couponId = couponId;
        },
        removeCoupon: (state, action) => {
            const filteredSeller = state.cartList.find(item => item.sellerId === action.payload);
            filteredSeller.couponId = '';
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchCart.pending, (state) => {
            state.cart.loading = 'loading';
        })
        .addCase(fetchCart.fulfilled, (state, action) => {
            state.cart.loading = 'succeeded';
            state.cart.cart = action.payload;
        })
        .addCase(fetchCart.rejected, (state, action) => {
            state.cart.loading = 'failed';
            state.cart.error = action.error.message;
        })
        .addCase(fetchWishlist.pending, (state) => {
            state.wishlist.loading = 'loading';
        })
        .addCase(fetchWishlist.fulfilled, (state, action) => {
            state.wishlist.loading = 'succeeded';
            state.wishlist.wishlist = action.payload;
        })
        .addCase(fetchWishlist.rejected, (state, action) => {
            state.wishlist.loading = 'failed';
            state.wishlist.error = action.error.message;
        });
    },
});

export const {
    setCartList,
    applyCoupon,
    removeCoupon,
} = userSlice.actions;

export {
    fetchCart,
    fetchWishlist,
}

export default userSlice.reducer;
