import React from 'react';
import Droptrack from '../../Pages/Droptrack/Droptrack.Page';
import { Navigate, Route, Routes } from 'react-router-dom';
import DroptrackProductDetails from '../../Pages/DroptrackProductDetails/DroptrackProductDetails';

function DroptrackRoute() {
    return (
        <Routes>
            <Route index element={<Droptrack />} />

            <Route path='product'>
                <Route index element={<Navigate to=".." replace />} />

                <Route path=':productId' element={<DroptrackProductDetails />} />
            </Route>
        </Routes>
    );
}

export default DroptrackRoute;
