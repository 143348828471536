import React from 'react';
import { useSelector } from 'react-redux';
import SpecificationInfo from './SpecificationInfo';

function Specifications() {
    const product = useSelector((state) => state.app.product.product);

    return (
        <div className="bg-[#F3F3F3] p-3 rounded-lg flex flex-col gap-2">
            <h1 className='font-semibold text-[14px] leading-[26px] text-[#000000]'>Specification</h1>

            <div className="flex flex-col gap-2">
                {
                    Object.keys(product?.product?.productSpecs)?.map(key => {
                    const value = product?.product?.productSpecs[key];
                    if (!value) return null;
                    return (
                        <SpecificationInfo 
                            key={key} 
                            title={key} 
                            value={value} 
                        />
                    );
                })}
            </div>
        </div>
    );
}

export default Specifications;
