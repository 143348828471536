import React, { useState } from 'react'
import Logo from '../../Assets/Logo.svg';
import SignupForm from './Components/SignupForm';
import SignupOtpInput from './Components/SignupOtpInput';
import SignupMessage from './Components/SignupMessage';
import PersonalizationForm from './Components/PersonalizationForm/PersonalizationForm';
import { PersonalizationFormProvider } from '../../Context/personalizationFormContext';

function Signup() {
    const [signupSuccess, setSignupSuccess] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [personalizeMessage, setPersonalizeMessage] = useState(false);

    return (
        <div className='max-w-screen h-screen bg-[#FFFFFF] flex flex-col items-center'>
            <div className="w-full p-10 max-[600px]:hidden"></div>

            <div className="w-[600px] max-[600px]:w-full p-10 max-[600px]:p-4 border border-[#BFBFBF] max-[600px]:border-none rounded-2xl max-[600px]:rounded-none bg-[#FFFFFF] flex flex-col items-center gap-20 max-[600px]:gap-10">
                <div className="h-11 max-[600px]:h-6">
                    <img className='h-full w-auto' src={Logo} alt="" />
                </div>

                {
                    !signupSuccess ? 
                    <>
                        {
                            otpSent ? 
                            <SignupOtpInput 
                                openPersonalizeMessage={(value) => setPersonalizeMessage(value)} 
                                updateSignupSuccess={(value) => setSignupSuccess(value)} 
                            /> : 
                            <SignupForm 
                                sendOtp={(value) => setOtpSent(value)} 
                            />
                        }
                    </> : 
                    <>
                        {
                            personalizeMessage ? 
                            <SignupMessage 
                                closeSignupMessage={() => setPersonalizeMessage(false)} 
                            /> :
                            <PersonalizationFormProvider>
                                <PersonalizationForm />
                            </PersonalizationFormProvider>
                        }
                    </>
                }
            </div>

            <div className="w-full p-10"></div>
        </div>
    );
}

export default Signup;
