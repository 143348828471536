import React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import TickMark from '../../../Assets/TickMark.svg';

function ColorFilter({ color }) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [searchParams] = useSearchParams();
    const colorFilter = searchParams.get('color');

    function colorOptionClick() {
        const updatedSearchParams = new URLSearchParams(searchParams);
        const currentColor = updatedSearchParams.get('color');
        if (currentColor === color.text) {
            updatedSearchParams.delete('color');
        }
        else {
            updatedSearchParams.set('color', color.text);
        }
        navigate(`${pathname}?${updatedSearchParams.toString()}`);
    }

    return (
        <div className="flex items-center gap-2 cursor-pointer" onClick={colorOptionClick}>
            <div className={`size-6 centered bg-[#F3F3F3] rounded-md border ${colorFilter === color.text ? 'border-main' : 'border-[#BFBFBF]'}`}>
                {
                    colorFilter === color.text && 
                    <img src={TickMark} alt="" />
                }
            </div>

            <div style={{ backgroundColor: `${color.color}` }} className="size-4 rounded-full shadow-[0_0_2px_2px_#0000001F]"></div>

            <p className='text-title3 text-[#666666]'>{color.text}</p>
        </div>
    );
}

export default ColorFilter;
