import React from 'react';
import StatusCard from './StatusCard';
import { setSettingsSection } from '../../../../Features/settingsSlice';
import { useDispatch } from 'react-redux';
import ArrowLeft from '../../../../Assets/ArrowLeft.svg';

function RefundStatus() {
    const dispatch = useDispatch();

    function handleBack() {
        dispatch(setSettingsSection(null));
    }

    return (
        <div className={`w-full h-fit border border-[#BFBFBF] rounded-lg p-4 mt-6 flex flex-col gap-2 max-[768px]:rounded-none max-[768px]:border-none max-[768px]:mt-0`}>
            <div className="flex items-center gap-2">
                <button onClick={handleBack} className="size-6 max-[768px]:flex items-center justify-center outline-none hidden">
                    <img src={ArrowLeft} alt="" />
                </button>

                <h1 className='text-title1 text-[#000000]'>Refund Status</h1>
            </div>

            <div className="flex flex-col gap-3">
                {/* <StatusCard /> */}
            </div>
        </div>
    );
}

export default RefundStatus;
