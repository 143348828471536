import React, { useEffect, useRef, useState } from 'react';
import FormInput from './FormInput';
import { useDispatch, useSelector } from 'react-redux';
import { useOutsideClickHandler } from '../../../../../Hooks/useOutsideClick';
import { closeModal, fetchProfile } from '../../../../../Features/profileSlice';
import { fetchUser } from '../../../../../Features/userSlice';
import Cross from '../../../../../Assets/Cross.svg';
import ImageAdd from '../../../../../Assets/ImageAdd.svg';
import { getMediaUrl, loadImage, verifyEmail, verifyPhoneNumber } from '../../../../../lib/utils/utils';
import { v4 as uuidv4 } from 'uuid';
import { updateProfile } from '../../../../../lib/utils/apiCalls';

function ProfileEditForm() {
    const dispatch = useDispatch();

    const containerRef = useRef(null);

    const { profile } = useSelector((state) => state.profile.profile);

    const [hoverOnDiv, setHoverOnDiv] = useState(false);
    const [buttonVisible, setButtonVisible] = useState(false);

    useEffect(() => {
        if(hoverOnDiv) {
            setButtonVisible(true);
        }
        else {
            setButtonVisible(false);
        }
    }, [hoverOnDiv]);

    useOutsideClickHandler(containerRef, () => {
        dispatch(closeModal());
    });

    function handleCloseModal() {
        dispatch(closeModal());
    }

    const defaultFormInputs = {
        displayname: '',
        fullname: '',
        email: '',
        phone: '',
    }

    const defaultFormErrors = {
        displayname: '',
        fullname: '',
        email: '',
        phone: '',
        dob: '',
    }

    const [formInputs, setFormInputs] = useState(defaultFormInputs);
    const [formErrors, setFormErrors] = useState(defaultFormErrors);
    const [dob, setDob] = useState("");

    const [profileImage, setProfileImage] = useState({});

    function changeHandler(e) {
        const { name, value } = e.target;

        setFormInputs({ ...formInputs, [name]: value });
    }

    const handleDateInput = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, "");
        let formattedValue = "";

        if (value.length > 0) {
            formattedValue += value.slice(0, 2);
        }
        if (value.length > 2) {
            formattedValue += "-" + value.slice(2, 4);
        }
        if (value.length > 4) {
            formattedValue += "-" + value.slice(4, 8);
        }

        setDob(formattedValue);
    };

    function handleImageChange(e) {
        const file = e.target.files[0];
        processFile(file);
        e.target.value = null;
    }

    async function processFile(file) {
        if(file) {
            const img = await loadImage(file);
            const selectedImage = {
                id: uuidv4(),
                url: img.src,
                file: file
            };
            setProfileImage(selectedImage);
        }
        else {
            return;
        }
    }

    async function handleSave() {
        const validationErrors = {};

        if(!formInputs.displayname) {
            validationErrors.displayname = 'Required field';
        }

        if(!formInputs.fullname) {
            validationErrors.fullname = 'Required field';
        }

        if(!formInputs.email) {
            validationErrors.email = 'Required field';
        }
        else if(!verifyEmail(formInputs.email)) {
            validationErrors.email = 'Invalid email address';
        }

        if(!formInputs.phone) {
            validationErrors.phone = 'Required field';
        }
        // else if(!verifyPhoneNumber(formInputs.phone)) {
        //     validationErrors.phone = 'Invalid phone number';
        // }

        if (!dob) {
            validationErrors.dob = 'Required field';
        }
        else {
            const dobRegex = /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-(19|20)\d{2}$/;
            if (!dobRegex.test(dob)) {
                validationErrors.dob = 'Invalid date format';
            }
        }

        if(Object.keys(validationErrors).length === 0) {
            try {
                const callBody = {
                    ...formInputs,
                    birthday: dob,
                }

                let imageUrl = '';

                if(profileImage.file) {
                    imageUrl = await getMediaUrl(profileImage.file);
                    callBody.profilePicUrl = imageUrl;
                }
                
                const response = await updateProfile(callBody);
                if(response.status === 200) {
                    handleCloseModal();
                    dispatch(fetchUser());
                    dispatch(fetchProfile());
                }
            }
            catch(err) {
                console.log(err);
            }
        }
        else {
            setFormErrors(validationErrors);
        }
    }

    useEffect(() => {
        if(Object.keys(profile).length > 0) {
            // console.log(profile);
            setFormInputs({
                displayname: profile.user.displayname,
                fullname: profile.user.fullname,
                email: profile.user.email,
                phone: profile.user.phone,
            });

            setProfileImage({
                id: '',
                url: profile.user.profilePicUrl || '',
                file: null,
            });

            setDob(profile.user.birthday || '');
        }
    }, [profile]);

    return (
        <div ref={containerRef} className='w-[500px] px-4 py-8 bg-[#FFFFFF] rounded-2xl flex flex-col items-center gap-4 relative'>
            <button onClick={handleCloseModal} className="outline-none absolute size-8 top-4 right-4 centered">
                <img src={Cross} alt="" />
            </button>

            <div className="flex flex-col items-center gap-2 w-fit">
                <div className="size-[100px] rounded-full overflow-hidden bg-[#E3E3E3] centered">
                    {
                        profileImage.url ? 
                        <div 
                            onMouseEnter={() => setHoverOnDiv(true)} 
                            onMouseLeave={() => setHoverOnDiv(false)} 
                            className="size-full relative"
                        >
                            <img className='size-full object-cover' src={profileImage.url} alt="" />
                            {
                                <label
                                    htmlFor="profile-image"
                                    className={`absolute size-full top-0 left-0 bg-[#00000095] centered cursor-pointer transition-opacity duration-200 ${buttonVisible ? "opacity-100 visible" : "opacity-0 invisible"}`}
                                >
                                    <img className="scale-75" src={ImageAdd} alt="" />
                                </label>
                            }
                        </div> : 
                        <p className='text-display text-[#666666]'>SA</p>
                    }
                </div>

                <input 
                    className='hidden' 
                    name='profile-image' 
                    id='profile-image' 
                    type="file" 
                    accept='image/*' 
                    onChange={handleImageChange} 
                />

                <div className="py-1">
                    <label htmlFor='profile-image' className='text-label1 text-main cursor-pointer'>Update display picture</label>
                </div>
            </div>

            <div className="w-full flex flex-col gap-4 max-h-60 overflow-y-scroll scrollbar-hide">
                <FormInput 
                    inputOptions={{
                        placeholder: 'Enter display name',
                        type: 'text',
                        name: 'displayname',
                        id: 'displayname',
                        value: formInputs.displayname,
                        onChange: changeHandler,
                    }} 
                    labelText='Display Name' 
                    errorText={formErrors.displayname} 
                />

                <FormInput 
                    inputOptions={{
                        placeholder: 'Enter full name',
                        type: 'text',
                        name: 'fullname',
                        id: 'fullname',
                        value: formInputs.fullname,
                        onChange: changeHandler,
                    }} 
                    labelText='Full Name' 
                    errorText={formErrors.fullname} 
                />

                <FormInput 
                    inputOptions={{
                        placeholder: 'Enter email address',
                        type: 'email',
                        name: 'email',
                        id: 'email',
                        value: formInputs.email,
                        onChange: changeHandler,
                    }} 
                    labelText='Email Address' 
                    errorText={formErrors.email} 
                />

                <FormInput 
                    inputOptions={{
                        placeholder: 'Enter phone number',
                        type: 'text',
                        name: 'phone',
                        id: 'phone',
                        value: formInputs.phone,
                        onChange: changeHandler,
                    }} 
                    labelText='Phone number' 
                    errorText={formErrors.phone} 
                />

                <div className="w-full flex flex-col gap-2">
                    <div className="flex items-center justify-between">
                        <label htmlFor='dob' className='text-title3 text-[#000000]'>Date of Birth</label>

                        <p className='text-[12px] leading-5 text-[#DC2626]'>{formErrors.dob}</p>
                    </div>

                    <input 
                        placeholder="DD-MM-YYYY" 
                        type='text' 
                        id="dob" 
                        name='dob' 
                        maxLength="10" 
                        onChange={handleDateInput} 
                        value={dob} 
                        className='w-full outline-none border border-[#E3E3E3] focus:border-[#666666] bg-[#FFFFFF] px-3 py-[11px] rounded-[9px] font-normal text-[14px] leading-[26px] text-[#121212] placeholder:text-[#666666] shadow-[0px_0px_6px_0px_#0000001F]' 
                    />
                </div>
            </div>

            <button onClick={handleSave} className='w-full rounded-lg outline-none px-8 py-4 hover-transition bg-main hover:bg-mainHover text-heading2 text-[#000000]'>Save</button>
        </div>
    );
}

export default ProfileEditForm;
