import React, { useContext, useRef, useState } from 'react';
import { useOutsideClickForTwoRef } from '../../../../Hooks/useOutsideClickForTwoRef';
import { bankCardsList } from '../../../../lib/arrays/settingsArrays';
import Chevron from '../../../../Assets/ChevronDark.svg';
import DropdownOption from '../DropdownOption';
import { PreferenceFormContext } from '../../../../Context/preferenceFormContext';

function BankCards() {
    const dropdownButtonRef = useRef(null);
    const dropdownListRef = useRef(null);

    const [dropdownOpen, setDropdownOpen] = useState(false);

    useOutsideClickForTwoRef(dropdownButtonRef, dropdownListRef, () => {
        setDropdownOpen(false);
    });

    const {
        paymentType: selectedBankCards,
        setPaymentType,
    } = useContext(PreferenceFormContext);

    function handleDropdownButton() {
        setDropdownOpen(!dropdownOpen);
    }

    function handleChange(e) {
        const selectedOption = bankCardsList.find(bank => bank.id === e.target.value);
        if(!selectedBankCards.includes(selectedOption)) {
            const list = [ ...selectedBankCards, selectedOption ];
            setPaymentType(list);
        }
        else {
            const filteredList = selectedBankCards.filter(bank => bank.id !== selectedOption.id);
            setPaymentType(filteredList);
        }
    }

    return (
        <div className="flex flex-col gap-2">
            <p className='text-title3 text-[#000000]'>Which bank cards do you use for shopping?</p>

            <div onClick={handleDropdownButton} ref={dropdownButtonRef} className="outline-none select-none bg-[#F3F3F3] border border-[#BFBFBF] rounded-lg flex items-center justify-between px-3 py-4 relative cursor-pointer">
                <p className='text-title3 text-[#000000] line-clamp-1'>{selectedBankCards.map(bank => bank.bank).join(', ')}</p>

                <div className="size-6 centered">
                    <img src={Chevron} alt="" />
                </div>

                {
                    dropdownOpen && 
                    <div ref={dropdownListRef} className="absolute z-10 left-0 top-16 w-full flex flex-col border border-[#BFBFBF] rounded-xl overflow-hidden bg-[#FFFFFF]">
                        {bankCardsList.map(bank => {
                            const checked = selectedBankCards.includes(bank);

                            return (
                                <DropdownOption 
                                    key={bank.id} 
                                    inputOptions={{
                                        id: bank.id,
                                        value: bank.id,
                                        type: 'checkbox',
                                        checked: checked,
                                        onChange: handleChange,
                                    }} 
                                    value={bank.bank} 
                                />
                            )
                        })}
                    </div>
                }
            </div>
        </div>
    );
}

export default BankCards;
