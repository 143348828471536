import React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import TickMark from '../../../../Assets/TickMark.svg';

function MaterialFilter({ material }) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [searchParams] = useSearchParams();
    const materialFilter = searchParams.get('materials');

    function materialOptionClick() {
        const updatedSearchParams = new URLSearchParams(searchParams);
        const currentColor = updatedSearchParams.get('materials');
        if (currentColor === material.material) {
            updatedSearchParams.delete('materials');
        }
        else {
            updatedSearchParams.set('materials', material.material);
        }
        navigate(`${pathname}?${updatedSearchParams.toString()}`);
    }

    return (
        <div className="flex items-center gap-2 cursor-pointer" onClick={materialOptionClick}>
            <div className={`size-6 centered bg-[#F3F3F3] rounded-md border ${materialFilter === material.material ? 'border-main' : 'border-[#BFBFBF]'}`}>
                {
                    materialFilter === material.material && 
                    <img src={TickMark} alt="" />
                }
            </div>

            <p className='text-title3 text-[#666666]'>{material.material}</p>
        </div>
    );
}

export default MaterialFilter;
