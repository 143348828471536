import React, { useEffect } from 'react';
import ProductCard from './Components/ProductCard/ProductCard';
import { useDispatch, useSelector } from 'react-redux';
import ModalContainer from '../../Components/ModalContainer/ModalContainer';
import DeleteConfirm from './Components/DeleteConfirm/DeleteConfirm';
import { fetchDroptrackProducts } from '../../Features/appSlice';
import DropTrack from '../../Assets/DropTrack.svg';

function Droptrack() {
    const dispatch = useDispatch();

    const { droptrackProducts } = useSelector((state) => state.app.droptrackProducts);
    const { open } = useSelector((state) => state.app.droptrackModal);

    useEffect(() => {
        dispatch(fetchDroptrackProducts());
    }, []);

    return (
        <div className='min-h-[calc(100vh-75px)] max-[810px]:min-h-[calc(100vh-42px)] pt-20 max-[810px]:pt-14'>
            {
                droptrackProducts?.length > 0 ? 
                <div className="mx-[50px] max-[1280px]:mx-[35px] my-8 max-[1280px]:my-6 grid grid-cols-4 max-[1200px]:grid-cols-3 gap-x-6 gap-y-6 max-[768px]:flex max-[768px]:flex-col max-[768px]:gap-3 max-[768px]:w-[375px] max-[768px]:mx-auto max-[768px]:px-4">
                    {droptrackProducts?.map(product => {
                        return (
                            <ProductCard 
                                key={product.id} 
                                product={product} 
                            />
                        );
                    })}
                </div> : 
                <div className="h-[calc(100vh-80px-76px)] max-[810px]:h-[calc(100vh-80px-20px)] centered">
                    <div className="flex flex-col items-center gap-4 w-[480px] max-[480px]:px-4 select-none">
                        <div className="size-[100px]">
                            <img className='size-full opacity-65' src={DropTrack} alt="" />
                        </div>

                        <h1 className='text-heading3 text-[#000000] text-center'>Looks like your DropTrack is empty!</h1>

                        <p className='text-body1 text-[#666666] text-center'>Start adding your favorite products and let us track the best deals for you. Don't worry. We'll kepe an eye on them while you shop without FOMO!</p>
                    </div>
                </div>
            }

            {
                open && 
                <ModalContainer>
                    <DeleteConfirm />
                </ModalContainer>
            }
        </div>
    );
}

export default Droptrack;
