import React from 'react';
import OrderDetails from '../../Pages/OrderDetails/OrderDetails.Page';
import { Navigate, Route, Routes } from 'react-router-dom';

function MyOrdersRoute() {
    return (
        <Routes>
            <Route index element={<Navigate to=".." replace />} />

            <Route path=':orderId' element={<OrderDetails />} />
        </Routes>
    );
}

export default MyOrdersRoute;
