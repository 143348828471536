import React, { useEffect, useRef, useState } from 'react'
import { useOutsideClickHandler } from '../../../../Hooks/useOutsideClick';
import { closeCheckoutModal, setSellerId } from '../../../../Features/checkoutSlice';
import { useDispatch, useSelector } from 'react-redux';
import Cross from '../../../../Assets/Cross.svg';
import { fetchSellerCoupons } from '../../../../lib/utils/apiCalls';
import Coupon from './Coupon';

function AllCouponList() {
    const dispatch = useDispatch();

    const formRef = useRef(null);

    const sellerId = useSelector((state) => state.checkout.sellerId);
    const { cart } = useSelector((state) => state.cart.cart);

    const [couponList, setCouponList] = useState([]);

    useOutsideClickHandler(formRef, () => {
        dispatch(setSellerId(null));
        dispatch(closeCheckoutModal());
    });

    function closeAddressForm() {
        dispatch(closeCheckoutModal());
    }

    useEffect(() => {
        const productIds = cart.filter(item => item['Product.SellerUserId'] === sellerId).map(item => item.productId);

        async function getSellerCoupons(ids) {
            try {
                const response = await fetchSellerCoupons(ids);
                if(response.status === 200) {
                    setCouponList(response.data.data);
                }
            }
            catch(err) {
                console.log(err);
            }
        }

        if(productIds.length > 0) {
            getSellerCoupons(productIds);
        }
    }, [sellerId, cart]);

    return (
        <div ref={formRef} className='w-[35%] max-[1280px]:w-[39%] max-[1024px]:w-[42%] bg-[#FFFFFF] rounded-3xl overflow-hidden'>
            <div className="bg-[#FFFFFF] border-b border-[#E3E3E3] shadow-[0_0_8px_0_#0000001F] p-6 flex items-center justify-between">
                <h1 className='font-bold text-[24px] leading-9 text-[#000000]'>All Coupon</h1>

                <button onClick={closeAddressForm} className="size-10 centered">
                    <img src={Cross} alt="" />
                </button>
            </div>

            <div className="m-6 mt-4 flex flex-col gap-3">
                <h1 className='text-title1 text-[#666666]'>Offers Applicable</h1>

                <div className="flex flex-col gap-2">
                    {couponList?.map(coupon => {
                        return (
                            <Coupon 
                                key={coupon.id} 
                                coupon={coupon} 
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    );
}

export default AllCouponList;
