import React from 'react';
import BrandCard from '../BrandCard';
import SectionTitle from '../../../../Components/SectionTitle/SectionTitle';

function FeaturedBrands({ featuredBrands }) {
    return (
        <div className='w-[1536px] max-[1536px]:w-full mx-auto px-[50px] max-[1280px]:px-[35px] max-[1024px]:px-0 flex flex-col gap-8 max-[1024px]:gap-6 items-center'>
            <SectionTitle 
                title='Featured Brands' 
            />

            <div className="w-full grid grid-cols-5 max-[1200px]:grid-cols-4 gap-x-6 gap-y-6 max-[1024px]:flex max-[1024px]:gap-4 max-[1024px]:flex-nowrap max-[1024px]:overflow-x-scroll scrollbar-hide max-[1024px]:mx-auto max-[1024px]:px-4">
                {featuredBrands?.map(brand => {
                    return (
                        <BrandCard 
                            key={brand.id} 
                            brand={brand} 
                        />
                    );
                })}
            </div>
        </div>
    );
}

export default FeaturedBrands;
