import React from 'react'

function OrderInfo() {
    return (
        <div className="flex justify-between bg-[#F3F3F3]">
            <div className="w-1/2 px-6 py-3">
                <div className="w-full flex items-center justify-between font-normal text-[14px] leading-[26px] text-[#000000]">
                    <p>Order date</p>

                    <p>27 December 2023</p>
                </div>

                <div className="w-full flex items-center justify-between font-normal text-[14px] leading-[26px] text-[#000000]">
                    <p>Order id</p>

                    <p>12274647446</p>
                </div>

                <div className="w-full flex items-center justify-between font-normal text-[14px] leading-[26px] text-[#000000]">
                    <p>Order amount</p>

                    <p>₹516.50</p>
                </div>
            </div>

            <div className="p-3 flex flex-col gap-1 w-[144px] h-fit">
                <h1 className='font-semibold text-[14px] leading-[26px] text-[#666666]'>Payment type</h1>

                <p className='font-normal text-[14px] leading-[26px] text-[#000000]'>PhonePe UPI</p>
            </div>
        </div>
    );
}

export default OrderInfo;
