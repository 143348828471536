import React, { useContext } from 'react';
import SectionBox from './SectionBox';
import { genders, shoppingCategories, fashionTasteList, bankCardsList, offersList } from '../../../../lib/arrays/settingsArrays';
import { PersonalizationFormContext } from '../../../../Context/personalizationFormContext';
import { addUserPreferences } from '../../../../lib/utils/apiCalls';
import { useNavigate } from 'react-router-dom';

function PersonalizationForm() {
    const navigate = useNavigate();

    const {
        productCategories,
        fashionTaste,
        offerType,
        gender,
        paymentType,
        resetValues,
    } = useContext(PersonalizationFormContext);

    async function handleSubmitClick() {
        try {
            const callBody = {
                product_category: productCategories,
                fashion_taste: fashionTaste,
                offer_type: offerType,
                gender: gender,
                payment_type: paymentType,
            }

            const response = await addUserPreferences(callBody);
            if(response.status === 200) {
                resetValues();
                navigate('/');
                window.location.reload();
            }
        }
        catch(err) {
            console.log(err);
        }
    }

    return (
        <div className='w-full flex flex-col gap-8 max-[600px]:gap-6'>
            <div className="flex flex-col gap-4">
                <p className='text-title2 text-main'>One Final Step!!</p>

                <SectionBox 
                    title={'Let\'s get started! What is your gender?'} 
                    array={genders} 
                />

                <SectionBox 
                    title={'Which product category do you shop for the most?'} 
                    array={shoppingCategories} 
                />

                <SectionBox 
                    title={'Tell us about your fashion taste'} 
                    subtitle={'Help to curate a personalized shopping experience'} 
                    array={fashionTasteList} 
                />

                <SectionBox 
                    title={'Do you use credit/debit cards for shopping?'} 
                    subtitle={'This Info helps you to view bank-related offers and discounts on your card, helping you save money'} 
                    array={bankCardsList} 
                />

                <SectionBox 
                    title={'What Type of deals or offers do you prefer'} 
                    array={offersList} 
                />
            </div>

            <button onClick={handleSubmitClick} className='select-none outline-none px-8 max-[600px]:px-6 py-4 max-[600px]:py-3 bg-main hover:bg-mainHover rounded-lg text-heading2 max-[600px]:text-[16px] max-[600px]:leading-7 text-[#000000]'>Let’s Begin Shopping!</button>
        </div>
    );
}

export default PersonalizationForm;
