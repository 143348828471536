import React from 'react';
import Logo from '../../Assets/Logo.svg';
import { Link } from 'react-router-dom';

function CheckoutNavbar() {
    return (
        <div className='fixed z-10 w-full h-20 bg-[#FFFFFF] border-b border-[#E3E3E3] shadow-[0_0_8px_0_#0000001F] flex items-center select-none'>
            <Link to='/' className="ml-[50px] max-[1280px]:ml-[35px] h-full flex items-center gap-20 max-[1280px]:gap-12 max-[1080px]:gap-6">
                <img src={Logo} alt="" />
            </Link>

            <h1 className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 font-bold text-[24px] leading-9 text-[#000000]">Checkout</h1>
        </div>
    )
}

export default CheckoutNavbar;
