import React from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import './embla.css';
import VideoPost from './Components/VideoPost/VideoPost';
import ProductCard from './Components/ProductCard/ProductCard';
import ImagePost from './Components/ImagePost/ImagePost';
import { getCachedMediaUrl } from '../../lib/utils/utils';

const OPTIONS = { loop: true, duration: 60, }

function LensFeed(props) {
    const { slides, options } = props;
    const [emblaRef, emblaApi] = useEmblaCarousel(options);

    return (
        <section className="lens_embla">
            <div className="lens_embla__viewport" ref={emblaRef}>
                <div className="lens_embla__container">
                    {slides.map((slide) => {
                        if(slide.videoUrls) {
                            return (
                                <div className="lens_embla__slide flex gap-4" key={slide.id}>
                                    <VideoPost 
                                        userId={slide.SellerUserId || slide.UserId} 
                                        videoUrl={slide.videoUrls} 
                                    />
                                
                                    <div className=""></div>

                                    {
                                        slide.productId && 
                                        <ProductCard 
                                            productId={slide.productId} 
                                            storeId={slide.SellerUserId} 
                                        />
                                    }
                                </div>
                            );
                        }
                        else if(slide.imageUrls) {
                            const slides = slide.imageUrls?.map((image, index) => ({
                                id: index,
                                link: getCachedMediaUrl(image),
                            }));

                            return (
                                <div className="lens_embla__slide flex gap-4" key={slide.id}>
                                    <ImagePost 
                                        userId={slide.SellerUserId || slide.UserId} 
                                        slides={slides} 
                                        options={OPTIONS} 
                                    />

                                    <div className=""></div>

                                    {
                                        slide.productId && 
                                        <ProductCard 
                                            productId={slide.productId} 
                                            storeId={slide.SellerUserId} 
                                        />
                                    }
                                </div>
                            );
                        }
                        else return null;
                    })}
                </div>
            </div>
        </section>
    );
}

export default LensFeed;
