import React, { useEffect, useRef, useState } from 'react';
import Cross from '../../../../Assets/Cross.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useOutsideClickHandler } from '../../../../Hooks/useOutsideClick';
import { addImageToList, closeOrderModal, openOrderModal } from '../../../../Features/orderDetailsSlice';
import ReasonRadio from '../ReasonRadio';
import ImageAdd from '../../../../Assets/ImageAdd.svg';
import { loadImage } from '../../../../lib/utils/utils';
import { v4 as uuidv4 } from 'uuid';
import ImagePreview from './ImagePreview';

const returnOptions = [
    { id: 'exchange-size', reason: 'Exchange with right size' },
    { id: 'exchange-color', reason: 'Exchange with right color' },
    { id: 'refund', reason: 'Refund to original payment method' },
];

function ReturnOption() {
    const containerRef = useRef(null);
    const dispatch = useDispatch();

    const [selectedReturnOptionId, setSelectedReturnOptionId] = useState('');
    const [buttonAvailable, setButtonAvailable] = useState(false);
    const [optionList, setOptionList] = useState([]);

    const { images, reasonType } = useSelector((state) => state.orderDetails.return);

    useOutsideClickHandler(containerRef, () => {
        dispatch(closeOrderModal());
    });

    function handleCloseModal() {
        dispatch(closeOrderModal());
    }

    function handleOptionChange(e) {
        setSelectedReturnOptionId(e.target.value);
    }

    function confirmReturnOption() {
        if(buttonAvailable) {
            if(selectedReturnOptionId === 'exchange-size') {
                dispatch(openOrderModal(selectedReturnOptionId));
            }
            else if(selectedReturnOptionId === 'exchange-color') {
                dispatch(openOrderModal(selectedReturnOptionId));
            }
            else {
                // Send to return-confirm page:
                // Call api
                // When api gives success
                // then
                dispatch(openOrderModal('return-success'));
            }
        }
    }

    async function handleImageSelection(e) {
        const file = e.target.files[0];
        processFile(file);
        e.target.value = null;
    }

    async function processFile(file) {
        if(file) {
            const img = await loadImage(file);
            const selectedImage = {
                id: uuidv4(),
                url: img.src,
                file: file
            };
            dispatch(addImageToList(selectedImage));
        }
        else {
            return;
        }
    }

    function handleDrop(e) {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        processFile(file);
    };

    function handleDragOver(e) {
        e.preventDefault();
        e.stopPropagation();
    };

    function handleDragLeave(e) {
        e.preventDefault();
        e.stopPropagation();
    };

    useEffect(() => {
        setButtonAvailable(selectedReturnOptionId && images.length > 0);
    }, [selectedReturnOptionId, images]);

    useEffect(() => {
        if(Object.keys(reasonType).length > 0) {
            const { id: reasonId } = reasonType;

            let filteredOptions = returnOptions;

            if (reasonId === 'wrong-size') {
                filteredOptions = returnOptions.filter(option => option.id !== 'exchange-color');
            }
            else if (reasonId === 'wrong-color') {
                filteredOptions = returnOptions.filter(option => option.id !== 'exchange-size');
            }
            else {
                filteredOptions = returnOptions.filter(option => option.id === 'refund');
                setSelectedReturnOptionId('refund');
            }

            setOptionList(filteredOptions);
        }
    }, [reasonType]);

    return (
        <div ref={containerRef} className='w-[35%] max-[1280px]:w-[39%] max-[1024px]:w-[42%] bg-[#FFFFFF] rounded-2xl overflow-hidden flex flex-col gap-4'>
            <div className="p-6 flex items-center justify-between bg-[#F3F3F3]">
                <h1 className='font-bold text-[24px] leading-9 text-[#000000]'>Return Order</h1>

                <button onClick={handleCloseModal} className="size-10 centered outline-none">
                    <img src={Cross} alt="Close Modal" />
                </button>
            </div>

            <div className="px-6 pb-6 flex flex-col gap-4">
                <div className="flex flex-col gap-4">
                    <div className="flex flex-col gap-1">
                        <h1 className='font-medium text-[16px] leading-7 text-[#000000]'>Upload clear and detailed pictures before initiating the return process.</h1>

                        <p className='font-normal text-[14px] leading-[26px] text-[#666666]'>Your visuals will help us better understand the condition for a smoother return experience.</p>
                    </div>

                    <label 
                        onDrop={handleDrop} 
                        onDragOver={handleDragOver} 
                        onDragLeave={handleDragLeave} 
                        htmlFor='image-add' 
                        className="w-full py-[19px] px-7 bg-[#F3F3F3] border border-[#BFBFBF] rounded-xl centered cursor-pointer"
                    >
                        <div className="flex flex-col items-center">
                            <div className="size-12 centered p-[6px]">
                                <img src={ImageAdd} alt="" />
                            </div>

                            <p className='font-normal text-[14px] leading-[26px] text-[#BFBFBF]'>Upload Picture</p>
                        </div>

                        <input 
                            onChange={handleImageSelection} 
                            name='image-add' 
                            id='image-add' 
                            type="file" 
                            className='hidden' 
                            accept="image/*" 
                        />
                    </label>

                    {
                        images.length > 0 && 
                        <div className="flex flex-wrap items-center gap-2">
                            {images.map(image => {
                                return (
                                    <ImagePreview 
                                        key={image.id} 
                                        image={image} 
                                    />
                                );
                            })}
                        </div>
                    }

                    <div className="flex flex-col gap-2 font-semibold text-[#666666]">
                        <h1 className='text-[18px] leading-[30px]'>Return Option</h1>

                        <div className="flex flex-col text-[14px] leading-[26px]">
                            {optionList.map(reason => {
                                return (
                                    <ReasonRadio 
                                        key={reason.id} 
                                        reason={reason.reason} 
                                        inputOptions={{
                                            id: reason.id,
                                            value: reason.id,
                                            type: 'radio',
                                            checked: selectedReturnOptionId === reason.id,
                                            onChange: handleOptionChange,
                                        }} 
                                    />
                                )
                            })}
                        </div>
                    </div>
                </div>

                <button onClick={confirmReturnOption} className={`px-8 py-4 rounded-lg font-semibold text-[20px] leading-8 ${buttonAvailable ? 'text-[#FFFFFF] bg-[#EF4444]' : 'bg-[#F3F3F3] text-[#999999] cursor-not-allowed'}`}>Next</button>
            </div>
        </div>
    );
}

export default ReturnOption;
