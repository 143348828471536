import React, { useEffect } from 'react';
import SuccessGIF from '../../../../Assets/Success.gif';
import { useDispatch } from 'react-redux';
import { closeOrderModal } from '../../../../Features/orderDetailsSlice';
import { useNavigate } from 'react-router-dom';

function CancelSuccess() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            dispatch(closeOrderModal());
            navigate('/');
        }, 1950);
    }, []);

    return (
        <div className='w-[420px] p-10 rounded-2xl flex flex-col items-center bg-[#FFFFFF] select-none'>
            <div className="size-[200px] centered">
                <img src={SuccessGIF} alt="" />
            </div>

            <div className="flex flex-col gap-3 text-center -mt-4">
                <h1 className='font-semibold text-[20px] leading-8 text-[#000000]'>Order Cancelled Successfully</h1>

                <p className='font-normal text-[14px] leading-[26px] text-[#666666]'>Order has been cancelled. we will return the money within 2-4 working day in the original mode of transaction</p>
            </div>
        </div>
    );
}

export default CancelSuccess;
