import React from 'react';
import { useSelector } from 'react-redux';

function ModalContainer({ children }) {
    const { type } = useSelector((state) => state.app.sidebar);

    return (
        <div className={`fixed z-20 top-0 left-0 w-screen h-screen ${(type === 'my-cart' || type === 'my-orders') && 'max-[600px]:h-[calc(100vh-56px)] max-[600px]:mt-14'} bg-[#00000080] backdrop-blur-sm centered`}>
            {children}
        </div>
    );
}

export default ModalContainer;
