import React from 'react';

function EarningsCard() {
    return (
        <div className="w-full p-4 rounded-lg border border-[#E3E3E3] flex flex-col gap-4 shadow-[0_0_6px_0_#0000001F]">
            <h1 className='text-title1 text-[#000000]'>Earning</h1>

            <div className="w-full flex flex-col gap-1 text-label1 text-[#666666]">
                <div className="flex items-center justify-between">
                    <p>Total Referral</p>

                    <p>0</p>
                </div>

                <div className="flex items-center justify-between">
                    <p>Total Earning</p>

                    <p>₹0</p>
                </div>
            </div>
        </div>
    );
}

export default EarningsCard;
