import React from 'react';
// import Checkout from '../../Pages/Checkout/Checkout.Page';
import { Navigate, Route, Routes } from 'react-router-dom';
import OrderSummary from '../../Pages/OrderSummary/OrderSummary.Page';
import SelectShippingAddress from '../../Pages/SelectShippingAddress/SelectShippingAddress.Page';
import PaymentGateway from '../../Pages/PaymentGateway/PaymentGateway.Page';

function CheckoutRoute() {
    return (
        <Routes>
            <Route index element={<Navigate to=".." replace />} />

            <Route path='order-summary' element={<OrderSummary />} />

            <Route path='address' element={<SelectShippingAddress />} />

            <Route path='payment' element={<PaymentGateway />} />
        </Routes>
    );
}

export default CheckoutRoute;
