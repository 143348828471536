import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TransactionCard from './TransactionCard';
import { setSettingsSection } from '../../../../Features/settingsSlice';
import ArrowLeft from '../../../../Assets/ArrowLeft.svg';

function HyprcomMoney() {
    const dispatch = useDispatch();

    function handleBack() {
        dispatch(setSettingsSection(null));
    }

    const { hyprcomMoney } = useSelector((state) => state.checkout.hyprcomMoney);

    return (
        <div className={`w-full h-fit  border border-[#BFBFBF] rounded-lg p-4 mt-6 flex flex-col gap-4 max-[768px]:rounded-none max-[768px]:border-none max-[768px]:mt-0`}>
            <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                    <button onClick={handleBack} className="size-6 max-[768px]:flex items-center justify-center outline-none hidden">
                        <img src={ArrowLeft} alt="" />
                    </button>

                    <h1 className='text-title1 text-[#000000]'>Hyprcom Money</h1>
                </div>

                <div className="p-4 bg-[#F3F3F3] rounded-2xl flex flex-col gap-3">
                    <div className="flex flex-col text-title3 text-[#666666]">
                        <p>My Wallet Number</p>

                        <p>{hyprcomMoney?.walletData?.id.split('-').reverse()[0]}</p>
                    </div>

                    <p className='text-display text-[#000000]'>₹{hyprcomMoney?.walletData?.money?.toFixed(2)}</p>
                </div>
            </div>

            <div className="flex flex-col gap-2">
                <h1 className='text-title1 text-[#000000]'>Transactions</h1>

                {
                    hyprcomMoney?.transactionData.length > 0 ? 
                    <>
                        {hyprcomMoney.transactionData.map(transaction => {
                            return (
                                <TransactionCard 
                                    key={transaction.id} 
                                    transaction={transaction} 
                                />
                            )
                        })}
                    </> : 
                    <p className='text-center text-title3 text-[#000000]'>No transactions are done yet</p>
                }
            </div>
        </div>
    );
}

export default HyprcomMoney;
