import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import api from '../Auth/Interceptor';
import axios from 'axios';

const initialStateValue = {
    store: {
        store: {},
        loading: 'idle',
        error: null,
    },
    storeOffer: {
        storeOffer: {},
        loading: 'idle',
        error: null,
    },
    storeCategory: {
        storeCategory: {},
        loading: 'idle',
        error: null,
    },
    storeCollection: {
        storeCollection: {},
        loading: 'idle',
        error: null,
    },
    categorySortType: null,
    collectionSortType: null,
    filter: {
        type: null,
        value: null,
    }
}

const fetchStoreDetails = createAsyncThunk('store/fetchStoreDetails', async (storeId) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_HYPRCOM_API}userstore/${storeId}`);
        return response.data.data;
    }
    catch(error) {
        throw error;
    }
});

const fetchStoreOffer = createAsyncThunk('store/fetchStoreOffer', async (offerId) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_HYPRCOM_API}userstore/offers/${offerId}`);
        return response.data.data;
    }
    catch(error) {
        throw error;
    }
});

const fetchStoreCategoryData = createAsyncThunk('store/fetchStoreCategoryData', async (categoryId) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_HYPRCOM_API}userstore/category/${categoryId}`);
        return response.data.data;
    }
    catch(error) {
        throw error;
    }
});

const fetchStoreCollectionData = createAsyncThunk('store/fetchStoreCollectionData', async (collectionId) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_HYPRCOM_API}userstore/collection/${collectionId}`);
        return response.data.data;
    }
    catch(error) {
        throw error;
    }
});

const storeDetailsSlice = createSlice({
    name: 'storeDetails',
    initialState: initialStateValue,
    reducers: {
        setCategorySortType: (state, action) => {
            state.categorySortType = action.payload;
        },
        setCollectionSortType: (state, action) => {
            state.collectionSortType = action.payload;
        },
        setFilterType: (state, action) => {
            state.filter.type = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchStoreDetails.pending, (state) => {
            state.store.loading = 'loading';
        })
        .addCase(fetchStoreDetails.fulfilled, (state, action) => {
            state.store.loading = 'succeeded';
            state.store.store = action.payload;
        })
        .addCase(fetchStoreDetails.rejected, (state, action) => {
            state.store.loading = 'failed';
            state.store.error = action.error.message;
        })
        .addCase(fetchStoreOffer.pending, (state) => {
            state.storeOffer.loading = 'loading';
        })
        .addCase(fetchStoreOffer.fulfilled, (state, action) => {
            state.storeOffer.loading = 'succeeded';
            state.storeOffer.storeOffer = action.payload;
        })
        .addCase(fetchStoreOffer.rejected, (state, action) => {
            state.storeOffer.loading = 'failed';
            state.storeOffer.error = action.error.message;
        })
        .addCase(fetchStoreCategoryData.pending, (state) => {
            state.storeCategory.loading = 'loading';
        })
        .addCase(fetchStoreCategoryData.fulfilled, (state, action) => {
            state.storeCategory.loading = 'succeeded';
            state.storeCategory.storeCategory = action.payload;
        })
        .addCase(fetchStoreCategoryData.rejected, (state, action) => {
            state.storeCategory.loading = 'failed';
            state.storeCategory.error = action.error.message;
        })
        .addCase(fetchStoreCollectionData.pending, (state) => {
            state.storeCollection.loading = 'loading';
        })
        .addCase(fetchStoreCollectionData.fulfilled, (state, action) => {
            state.storeCollection.loading = 'succeeded';
            state.storeCollection.storeCollection = action.payload;
        })
        .addCase(fetchStoreCollectionData.rejected, (state, action) => {
            state.storeCollection.loading = 'failed';
            state.storeCollection.error = action.error.message;
        })
    },
});

export const {
    setCategorySortType,
    setCollectionSortType,
    setFilterType,
} = storeDetailsSlice.actions;

export {
    fetchStoreDetails,
    fetchStoreOffer,
    fetchStoreCategoryData,
    fetchStoreCollectionData,
}

export default storeDetailsSlice.reducer;
