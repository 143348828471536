import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getOtpFromOtpless } from '../../../lib/utils/apiCalls';
import ChevronGray from '../../../Assets/ChevronGray.svg';
import Search from '../../../Assets/Search.svg';
import countryCode from 'country-codes-list/countriesData';
import { useOutsideClickForTwoRef } from '../../../Hooks/useOutsideClickForTwoRef';
import { showToast } from '../../../Components/Toaster/Toaster';

function PhoneInput({ sendOtp }) {
    const navigate = useNavigate();
    const location = useLocation();

    const dropdownButtonRef = useRef();
    const countryListDropdownRef = useRef();

    useOutsideClickForTwoRef(countryListDropdownRef, dropdownButtonRef, () => {
        setDropdownOpen(false);
        setSearchText('');
    });

    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [selectedCountry, setSelectedCountry] = useState({});

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const [searchText, setSearchText] = useState('');
    const [filteredCountryList, setFilteredCountryList] = useState([]);

    function handleInputChange(e) {
        setPhoneNumber(e.target.value);
    }

    function handleDropdownToggle() {
        if(dropdownOpen) {
            setSearchText('');
        }
        setDropdownOpen(!dropdownOpen);
    }

    function handleSelectOption(selection) {
        setSelectedCountry(selection);
        setDropdownOpen(false);
        setSearchText('');
    }

    function handleRoute(isAvailable) {
        navigate({
            pathname: location.pathname,
            search: isAvailable ? `?code=${selectedCountry.countryCallingCode}&phoneNumber=${phoneNumber}` : ''
        });
    };

    async function handleGetCode() {
        let error = '';
    
        if(!phoneNumber) {
            error = 'Enter a phone number';
        }

        if(!error) {
            setPhoneError('');

            try {
                const callBody = {
                    phoneNumber,
                    country_code: `+${selectedCountry.countryCallingCode}`
                };

                const response = await getOtpFromOtpless(callBody);
                if(response.status === 200) {
                    sendOtp(true);
                    handleRoute(true);
                    // setTimeout(() => {
                    //     sendOtp(false);
                    //     handleRoute(false);
                    //     alert('OTP expired!');
                    // }, 15*60*1000);
                }
            }
            catch(err) {
                showToast(err, 'error');
            }
        }
        else {
            setPhoneError(error);
        }
    }

    function handleSearchTextChange(e) {
        setSearchText(e.target.value);
    }

    useEffect(() => {
        const filteredCode = countryCode.find(country => country.countryCallingCode === '91');
        setSelectedCountry(filteredCode);
    }, []);

    useEffect(() => {
        if(searchText) {
            const filteredList = countryCode.filter(item => item.countryNameEn.toLowerCase().includes(searchText.toLowerCase()));
            setFilteredCountryList(filteredList);
        }
        else {
            setFilteredCountryList(countryCode);
        }
    }, [searchText]);

    return (
        <div className="flex flex-col items-center gap-8 max-[600px]:gap-6 w-full">
            <div className="flex flex-col gap-4 w-full">
                <h1 className='text-display max-[600px]:text-[24px] max-[600px]:leading-9 text-[#000000]'>Welcome Back!!</h1>

                <div className="flex flex-col gap-8 max-[600px]:gap-4">
                    <div className="flex flex-col gap-2 max-[600px]:gap-1">
                        <div className="flex items-center justify-between">
                            <label className='text-title3 text-[#000000]' htmlFor="phoneNumber">Phone Number</label>

                            <p className='text-[12px] leading-5 text-[#DC2626]'>{phoneError}</p>
                        </div>

                        <div className="relative px-3 max-[600px]:px-4 py-4 max-[600px]:py-2 border border-[#F3F3F3] bg-[#F3F3F3] rounded-[9px] flex items-center gap-[10px]">
                            <button ref={dropdownButtonRef} onClick={handleDropdownToggle} className="flex items-center gap-[10px] outline-none border-none">
                                <p className='text-title3 text-[#666666]'>+{selectedCountry?.countryCallingCode?.split(' ').join('-')}</p>

                                <div className="size-6 centered">
                                    <img src={ChevronGray} alt="" />
                                </div>
                            </button>

                            <input 
                                type="number" 
                                id='phoneNumber' 
                                name='phoneNumber' 
                                className='flex-grow outline-none border-none bg-transparent text-title3 text-[#000000] placeholder:text-[#BFBFBF]' 
                                placeholder='enter phone number' 
                                onChange={handleInputChange} 
                                value={phoneNumber} 
                            />

                            {
                                dropdownOpen && 
                                <div ref={countryListDropdownRef} className="py-2 absolute max-[600px]:top-12 top-16 left-0 w-full border border-[#E3E3E3] bg-[#FFFFFF] rounded-lg max-h-60 overflow-y-scroll scrollbar-hide">
                                    <div className="px-4 py-2">
                                        <div className="w-full p-2 rounded-lg flex items-center gap-2 bg-[#F3F3F3] border border-[#BFBFBF]">
                                            <div className="size-6 centered">
                                                <img src={Search} alt="" />
                                            </div>

                                            <input 
                                                id='search' 
                                                name='search' 
                                                className='w-[calc(100%-24px)] bg-transparent outline-none border-none text-body2 placeholder:text-[#999999] text-[#121212]' 
                                                type="text" 
                                                placeholder='Search for country code....' 
                                                value={searchText} 
                                                onChange={handleSearchTextChange} 
                                            />
                                        </div>
                                    </div>

                                    {filteredCountryList?.sort((a, b) => a.countryNameEn.localeCompare(b.countryNameEn)).map((country, index) => {
                                        const isLastItem = index === countryCode.length - 1;

                                        return (
                                            <div key={index} onClick={() => handleSelectOption(country)} className={`text-title3 cursor-pointer ${!isLastItem ? 'border-b border-[#E3E3E3]' : ''} px-4 py-1 flex items-center gap-4`}>
                                                <p className='text-[#666666] w-1/5'>+&#40;{country.countryCallingCode.replace(/\s+/g, '-')}&#41;</p>

                                                <p className='text-[#000000] w-4/5'>{country.countryNameEn}</p>
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                        </div>
                    </div>

                    <button onClick={handleGetCode} className='select-none outline-none px-8 max-[600px]:px-6 py-4 max-[600px]:py-3 bg-main hover:bg-mainHover rounded-lg text-heading2 max-[600px]:text-[16px] max-[600px]:leading-7 text-[#000000]'>Get the code</button>
                </div>
            </div>

            <div className="text-[14px] leading-6 flex items-center gap-1">
                <p className='font-medium text-[#000000]'>Don't have an account?</p>

                <Link className='font-bold text-main' to='/auth/signup'>Register</Link>
            </div>
        </div>
    );
}

export default PhoneInput;
