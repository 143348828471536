import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddressCard from './AddressCard';
import { openModal, setSettingsSection } from '../../../../Features/settingsSlice';
import ArrowLeft from '../../../../Assets/ArrowLeft.svg';

function AddressBook() {
    const dispatch = useDispatch();

    const { addresses } = useSelector((state) => state.checkout.addresses);

    function handleAddAddress() {
        dispatch(openModal('address-form'));
    }

    function handleBack() {
        dispatch(setSettingsSection(null));
    }

    return (
        <div className={`w-full h-fit border border-[#BFBFBF] rounded-lg p-4 mt-6 flex flex-col gap-4 max-[768px]:rounded-none max-[768px]:border-none max-[768px]:mt-0`}>
            <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                    <button onClick={handleBack} className="size-6 max-[768px]:flex items-center justify-center outline-none hidden">
                        <img src={ArrowLeft} alt="" />
                    </button>
                    
                    <h1 className='text-title1 text-[#000000]'>Address Book</h1>
                </div>

                {addresses.map(address => {
                    return (
                        <AddressCard 
                            key={address.id} 
                            address={address} 
                        />
                    )
                })}
            </div>

            <button onClick={handleAddAddress} className='rounded-lg px-3 py-6 bg-[#E3E3E3] border-dashed border border-[#666666] text-label1 text-[#666666]'>Add address</button>
        </div>
    );
}

export default AddressBook;
