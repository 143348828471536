import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setImages } from '../../../../Features/productSlice';
import ImageCard from './ImageCard';
import { getCachedMediaUrl } from '../../../../lib/utils/utils';
import DropTrack from '../../../../Assets/DropTrack.svg';
import { addProductToCart, addProductToDroptrack, checkProductInDroptrack } from '../../../../lib/utils/apiCalls';
import { useLocation, useParams } from 'react-router-dom';
import { fetchCart } from '../../../../Features/cartSlice';
import { changeBagQuantity } from '../../../../Features/productSlice';
import { addToLocalCartList, openSidebar } from '../../../../Features/appSlice';
import { v4 as uuidv4 } from 'uuid';
import { showToast } from '../../../../Components/Toaster/Toaster';

function ImageSection() {
    const dispatch = useDispatch();
    const location = useLocation();
    const referralState = location.state?.referralState || false;
    const { productId } = useParams();

    const { varient,  product} = useSelector((state) => state.product.product.product);
    const { size, color, variantId, quantity: stock } = useSelector((state) => state.product.selectedVariant);
    const images = useSelector((state) => state.product.images);
    const bagQuantity = useSelector((state) => state.product.bagQuantity);
    const { user } = useSelector((state) => state.user.user);

    const mediaContainerRef = useRef(null);
    const listContainerRef = useRef(null);
    const droptrackButtonRef = useRef(null);

    const [mediaContainerHeight, setMediaContainerHeight] = useState(0);
    const [listContainerWidth, setListContainerWidth] = useState(0);
    const [droptrackButtonHeight, setDroptrackButtonHeight] = useState(0);

    const [productInDroptrack, setProductInDroptrack] = useState(false);

    async function handleAddToCart() {
        if(Object.keys(user).length > 0) {
            if(stock > 0) {
                if(bagQuantity > 0) {
                    try {
                        const callBody = {
                            productId: productId,
                            varaint: variantId,
                            quantity: bagQuantity,
                            reference: referralState,
                        }
            
                        const response = await addProductToCart(callBody);
                        if(response.status === 200) {
                            dispatch(fetchCart());
                            dispatch(changeBagQuantity(1));
                            dispatch(openSidebar('my-cart'));
                        }
                    }
                    catch(err) {
                        console.log(err);
                    }
                }
                else {
                    showToast('Add quantity first', 'warning');
                }
            }
            else {
                showToast('Variant out of stock', 'warning');
            }
        }
        else {
            if(stock > 0) {
                if(bagQuantity > 0) {
                    dispatch(addToLocalCartList({ id: uuidv4(), product, quantity: bagQuantity, variantId: variantId, size, color }));
                    dispatch(openSidebar('my-cart'));
                }
                else {
                    showToast('Add quantity first', 'warning');
                }
            }
            else {
                showToast('Variant out of stock', 'warning');
            }
        }
    }

    async function handleAddProductToDroptrack() {
        if(Object.keys(user).length > 0) {
            try {
                const callBody = {
                    ProductId: productId,
                };
    
                const response = await addProductToDroptrack(callBody);
                if(response.status === 200) {
                    setProductInDroptrack(true);
                }
            }
            catch(err) {
                console.log(err);
            }
        }
        else {
            showToast('Log in to continue', 'warning');
        }
    }

    useEffect(() => {
        async function fetchProductDroptrackStatus(id) {
            try{
                const response = await checkProductInDroptrack(id);
                if(response.status === 200) {
                    setProductInDroptrack(response.data.data);
                }
            }
            catch(err) {
                console.log(err);
            }
        }

        if(productId) {
            if(Object.keys(user).length > 0) {
                fetchProductDroptrackStatus(productId);
            }
        }
    }, [productId, user]);

    useEffect(() => {
        if(size && color) {
            if((product.productSpecs.size === size) && (product.productSpecs.color === color)) {
                dispatch(setImages({
                    variantImages: product.productImgUrls,
                    activeImageUrl: product.productImgUrls[0],
                }));
            }
            else {
                const filteredVariant = varient.filter(variant => variant.VarientColor.trim() === color.trim() && variant.VarientSize.trim() === size.trim());
                if(filteredVariant.length > 0) {
                    dispatch(setImages({
                        variantImages: filteredVariant[0].VarientImgUrl,
                        activeImageUrl: filteredVariant[0].VarientImgUrl[0],
                    }));
                }
            }
        }
    }, [color]);

    useEffect(() => {
        const updateHeight = () => {
            if (mediaContainerRef.current) {
                setMediaContainerHeight((1296 / 1080) * mediaContainerRef.current.clientWidth);
            }
        };

        const resizeObserver = new ResizeObserver(() => {
            updateHeight();
        });

        if (mediaContainerRef.current) {
            resizeObserver.observe(mediaContainerRef.current);
        }

        updateHeight();

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    useEffect(() => {
        const updateWidth = () => {
            if (listContainerRef.current) {
                setListContainerWidth((1080 / 1296) * ((listContainerRef.current.clientHeight - 12*4)/5));
            }
        };

        const resizeObserver = new ResizeObserver(() => {
            updateWidth();
        });

        if (listContainerRef.current) {
            resizeObserver.observe(listContainerRef.current);
        }

        updateWidth();

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    useEffect(() => {
        const updateHeight = () => {
            if (droptrackButtonRef.current) {
                setDroptrackButtonHeight(droptrackButtonRef.current.clientWidth);
            }
        };

        const resizeObserver = new ResizeObserver(() => {
            updateHeight();
        });

        if (droptrackButtonRef.current) {
            resizeObserver.observe(droptrackButtonRef.current);
        }

        updateHeight();

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    return (
        <div className="w-1/2 max-[426px]:hidden flex gap-3 justify-between">
            <div ref={listContainerRef} className="w-[15%] max-[1280px]:w-1/5 flex flex-col gap-3" style={{ maxHeight: `${mediaContainerHeight}px`, width: `${listContainerWidth}px` }}>
                {images?.variantImages?.map(image => {
                    return (
                        <ImageCard 
                            key={image} 
                            image={image} 
                        />
                    );
                })}
            </div>

            <div className="flex flex-col gap-6 w-[85%] max-[1280px]:w-4/5">
                <div ref={mediaContainerRef} className="w-full centered" style={{ height: `${mediaContainerHeight}px` }}>
                    <img className='max-w-full max-h-full' src={getCachedMediaUrl(images.activeImageUrl)} alt="" />
                </div>

                <div className="w-full flex items-center gap-3">
                    <button style={{ width: productInDroptrack  ? '100%' : `calc(100% - ${droptrackButtonHeight}px)`, height: `${droptrackButtonHeight}px` }} onClick={handleAddToCart} className="hover-transition px-8 bg-main hover:bg-mainHover rounded-lg text-heading2 max-[1024px]:text-[16px] text-[#000000]">
                        Add To Bag
                    </button>

                    {
                        !productInDroptrack && 
                        <button ref={droptrackButtonRef} style={{ height: `${droptrackButtonHeight}px` }} onClick={handleAddProductToDroptrack} className={`w-[12%] max-[1024px]:w-[17%] rounded-lg bg-[#BFBFBF] ${Object.keys(user).length === 0 && 'cursor-not-allowed'} centered`}>
                            <img className='max-[768px]:w-[40%] h-auto' src={DropTrack} alt="" />
                        </button>
                    }
                </div>
            </div>
        </div>
    );
}

export default ImageSection;
