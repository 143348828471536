import React, { useContext, useRef, useState } from 'react';
import { useOutsideClickForTwoRef } from '../../../../Hooks/useOutsideClickForTwoRef';
import Chevron from '../../../../Assets/ChevronDark.svg';
import DropdownOption from '../DropdownOption';
import { offersList } from '../../../../lib/arrays/settingsArrays';
import { PreferenceFormContext } from '../../../../Context/preferenceFormContext';

function Offers() {
    const dropdownButtonRef = useRef(null);
    const dropdownListRef = useRef(null);

    const [dropdownOpen, setDropdownOpen] = useState(false);

    useOutsideClickForTwoRef(dropdownButtonRef, dropdownListRef, () => {
        setDropdownOpen(false);
    });

    const {
        offerType: selectedOffers,
        setOfferType,
    } = useContext(PreferenceFormContext);

    function handleDropdownButton() {
        setDropdownOpen(!dropdownOpen);
    }

    function handleChange(e) {
        const selectedOption = offersList.find(offer => offer.id === e.target.value);
        if(!selectedOffers.includes(selectedOption)) {
            const list = [ ...selectedOffers, selectedOption ];
            setOfferType(list);
        }
        else {
            const filteredList = selectedOffers.filter(offer => offer.id !== selectedOption.id);
            setOfferType(filteredList);
        }
    }

    return (
        <div className="flex flex-col gap-2">
            <p className='text-title3 text-[#000000]'>What type of deals or offers do you prefer?</p>

            <div onClick={handleDropdownButton} ref={dropdownButtonRef} className="outline-none select-none bg-[#F3F3F3] border border-[#BFBFBF] rounded-lg flex items-center justify-between px-3 py-4 relative cursor-pointer">
                <p className='text-title3 text-[#000000] line-clamp-1'>{selectedOffers.map(offer => offer.offer).join(', ')}</p>

                <div className="size-6 centered">
                    <img src={Chevron} alt="" />
                </div>

                {
                    dropdownOpen && 
                    <div ref={dropdownListRef} className="absolute z-10 left-0 top-16 w-full flex flex-col border border-[#BFBFBF] rounded-xl overflow-hidden bg-[#FFFFFF]">
                        {offersList.map(offer => {
                            const checked = selectedOffers.includes(offer);

                            return (
                                <DropdownOption 
                                    key={offer.id} 
                                    inputOptions={{
                                        id: offer.id,
                                        value: offer.id,
                                        type: 'checkbox',
                                        checked: checked,
                                        onChange: handleChange,
                                    }} 
                                    value={offer.offer} 
                                />
                            )
                        })}
                    </div>
                }
            </div>
        </div>
    );
}

export default Offers;
