import React from 'react';
import { Link } from 'react-router-dom';
import { getCachedMediaUrl } from '../../../../lib/utils/utils';

function OfferCard({ offer }) {
    return (
        <Link to={`offer/${offer.id}`} className='w-[45%] h-auto rounded-lg overflow-hidden flex-shrink-0 bg-[#666666]'>
            <img className='size-full object-cover' src={getCachedMediaUrl(offer.couponImage)} alt="" />
        </Link>
    );
}

export default OfferCard;
