import React from 'react';
import { Title, TextMainContainer, SubText } from './Components/TextComponents';

function PrivacyPolicy() {
    return (
        <div className='flex flex-col pt-20 max-[810px]:pt-14 select-none'>
            <div className="flex flex-col gap-40 py-40 mx-[50px] max-[1280px]:mx-[35px] max-[810px]:mx-4">
                <h1 className='font-bold text-[52px] leading-[64px] max-[810px]:text-[44px] max-[810px]:leading-[56px] text-[#121212]'><span className='text-main'>Privacy Policy</span> at Hyprcom</h1>

                <div className="flex flex-col gap-6">
                    <p className='font-normal text-[16px] leading-5 max-[810px]:text-[14px] max-[810px]:leading-[18px] text-[#666666]'>Welcome to Hyprcom! This Privacy Policy is designed to inform you about how we collect, use, disclose, and safeguard your personal information. By using Hyprcom, you agree to the terms outlined in this policy.</p>

                    <div className="flex flex-col gap-6">
                        {/* 1 */}
                        <div className="flex flex-col gap-4">
                            <Title 
                                title='1. Information Collected:' 
                                subtitle='We collect various types of personal information, including but not limited to:' 
                            />

                            <TextMainContainer>
                                <SubText>
                                    <li>Contact information &#40;name, email, address&#41;</li>
                                    <li>Account details</li>
                                    <li>Purchase history</li>
                                    <li>User preferences</li>
                                </SubText>

                                <p>This information may be collected directly from users during account creation, transactions, or interactions with our platform. Additionally, we may receive data from third-party sources to enhance our services.</p>
                            </TextMainContainer>
                        </div>

                        {/* 2 */}
                        <div className="flex flex-col gap-4">
                            <Title 
                                title='2. How Information is Collected:' 
                                subtitle='We utilize various methods to collect information, including:' 
                            />

                            <TextMainContainer>
                                <SubText>
                                    <li>User registration forms</li>
                                    <li>Cookies and tracking technologies</li>
                                    <li>Analytics tools</li>
                                </SubText>

                                <p>These methods help us tailor your experience on Hyprcom, providing personalized services and improving our overall offering.</p>
                            </TextMainContainer>
                        </div>

                        {/* 3 */}
                        <div className="flex flex-col gap-4">
                            <Title 
                                title='3. Purpose of Collection:' 
                                subtitle='Your information is collected for the following purposes:' 
                            />

                            <TextMainContainer>
                                <SubText>
                                    <li>To provide and personalize our services</li>
                                    <li>To process and fulfill orders</li>
                                    <li>To improve our products and services</li>
                                    <li>To communicate with users</li>
                                    <li>To offer a personalized and enhanced user experience</li>
                                </SubText>
                            </TextMainContainer>
                        </div>

                        {/* 4 */}
                        <div className="flex flex-col gap-4">
                            <Title 
                                title='4. Third-Party Sharing:' 
                            />

                            <TextMainContainer>
                                <p>We may share your information with trusted third parties, including service providers and marketing partners, to enhance our services and offer a more personalized experience. These third parties are obligated to maintain the confidentiality of your information.</p>
                            </TextMainContainer>
                        </div>

                        {/* 5 */}
                        <div className="flex flex-col gap-4">
                            <Title 
                                title='5. Cookies and Tracking Technologies:' 
                            />

                            <TextMainContainer>
                                <p>Hyprcom uses cookies and similar technologies to enhance user experience. You have the option to manage your cookie preferences through our platform settings.</p>
                            </TextMainContainer>
                        </div>

                        {/* 6 */}
                        <div className="flex flex-col gap-4">
                            <Title 
                                title='6. User Rights:' 
                                subtitle='You have the right to:' 
                            />

                            <TextMainContainer>
                                <SubText>
                                    <li>Access and review your personal information</li>
                                    <li>Correct inaccuracies in your information</li>
                                    <li>Delete your information under certain circumstances</li>
                                    <li>Instructions on exercising these rights are provided on our platform.</li>
                                </SubText>
                            </TextMainContainer>
                        </div>

                        {/* 7 */}
                        <div className="flex flex-col gap-4">
                            <Title 
                                title='7. Security Measures:' 
                            />

                            <TextMainContainer>
                                <p>We employ robust security measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction. Your information is handled with the utmost care to ensure its confidentiality and integrity.</p>
                            </TextMainContainer>
                        </div>

                        {/* 8 */}
                        <div className="flex flex-col gap-4">
                            <Title 
                                title='8. Data Retention:' 
                            />

                            <TextMainContainer>
                                <p>We retain your information for as long as necessary to fulfill the purposes outlined in this policy. The criteria for data retention are based on the nature of your interactions with Hyprcom.</p>
                            </TextMainContainer>
                        </div>

                        {/* 9 */}
                        <div className="flex flex-col gap-4">
                            <Title 
                                title='9. Policy Changes:' 
                            />

                            <TextMainContainer>
                                <p>This privacy policy may be updated to reflect changes in our practices or legal requirements. Users will be notified of significant changes, and we encourage you to review the policy periodically.</p>
                            </TextMainContainer>
                        </div>

                        {/* 10 */}
                        <div className="flex flex-col gap-4">
                            <Title 
                                title='10. Contact Information:' 
                            />

                            <TextMainContainer>
                                <p>For privacy-related inquiries, please contact us at <a href="mailto:contact@hyprcom.in" style={{ fontWeight: 700 }} className='hover-transition hover:text-[#316FF6] hover:underline'>contact@hyprcom.in</a>.</p>
                            </TextMainContainer>
                        </div>

                        {/* 11 */}
                        <div className="flex flex-col gap-4">
                            <Title 
                                title='11. Legal Compliance:' 
                            />

                            <TextMainContainer>
                                <p>Hyprcom is committed to complying with relevant data protection laws and regulations. This policy is governed by the jurisdiction applicable to Hyprcom.</p>
                            </TextMainContainer>
                        </div>
                    </div>

                    <p className='font-normal text-[16px] leading-5 max-[810px]:text-[14px] max-[810px]:leading-[18px] text-[#666666]'>Thank you for entrusting Hyprcom with your information. Your privacy and trust are of the utmost importance to us.</p>
                </div>
            </div>
        </div>
    );
}

export default PrivacyPolicy;
