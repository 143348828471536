import React, { useEffect, useRef, useState } from 'react';
import Cross from '../../../../Assets/Cross.svg';
import { useDispatch } from 'react-redux';
import { closeOrderModal, openOrderModal } from '../../../../Features/orderDetailsSlice';
import { useOutsideClickHandler } from '../../../../Hooks/useOutsideClick';
import ReasonRadio from '../ReasonRadio';

const imageUrl = 'https://s3-alpha-sig.figma.com/img/3da7/5034/33934ac100a3c07900707fd3a179f2c2?Expires=1730678400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Y-KPNBv41~9G8nfL9S4gpSx4zyj8qe-3XvegWBnFbmjbhRQJgm~CsJsR9tAXgZcWWNEY2Hw6ckVC3R-FUc6g4RUKcYSu9ARpYuuu5YLrYBkSnJWKUH40ps3hYQBYy~6VHEyQjYscvhOhMwg1wl~4RPEdT~hWhutxyvW7LhDfdZMyYpTLyKntlXajCa3rS0f1r-1dhoSXRrS4gzbfJyAaE2JgjcEGSx2QrdW0TURDH-GT0LdKP3upFgP~-SUmjYUEi~M1hSMLtv03PnWnkOD0fpO0WPpGYUIo34jTONbfzqo0X~u3C2RD2QMYptR0qgdvSE4VIOj1H20I3Koyo~~bbw__';

const cancelReasons = [
    { id: 'mind-change', reason: 'Change of Mind' },
    { id: 'no-need', reason: 'Item no longer needed' },
    { id: 'long-time', reason: 'Delivery taking too long' },
    { id: 'mistake', reason: 'Ordering by Mistake' },
    { id: 'quality-issue', reason: 'Quality Concerns' },
    { id: 'got-gift', reason: 'Received as a Gift' },
    { id: 'other', reason: 'Others' },
];

function CancelReasonSelect() {
    const containerRef = useRef(null);
    const dispatch = useDispatch();

    const [selectedCancellationReasonId, setSelectedCancellationReasonId] = useState('');
    const [buttonAvailable, setButtonAvailable] = useState(false);
    const [reasonText, setReasonText] = useState('');

    useOutsideClickHandler(containerRef, () => {
        dispatch(closeOrderModal());
    });

    function handleCloseModal() {
        dispatch(closeOrderModal());
    }

    function handleOptionChange(e) {
        setSelectedCancellationReasonId(e.target.value);
    }

    function handleReasonChange(e) {
        setReasonText(e.target.value);
    }

    function confirmCancel() {
        if(buttonAvailable) {
            // Cancellation Logic and API call
            dispatch(openOrderModal('cancel-success'));
        }
    }

    useEffect(() => {
        if (selectedCancellationReasonId) {
            if (selectedCancellationReasonId !== 'other') {
                setButtonAvailable(true);
            } else {
                if (reasonText) {
                    setButtonAvailable(true);
                } else {
                    setButtonAvailable(false);
                }
            }
        } else {
            setButtonAvailable(false);
        }
    }, [selectedCancellationReasonId, reasonText]);

    return (
        <div ref={containerRef} className='w-[35%] max-[1280px]:w-[39%] max-[1024px]:w-[42%] bg-[#FFFFFF] rounded-2xl overflow-hidden flex flex-col gap-4'>
            <div className="p-6 flex items-center justify-between bg-[#F3F3F3]">
                <h1 className='font-bold text-[24px] leading-9 text-[#000000]'>Cancel Order</h1>

                <button onClick={handleCloseModal} className="size-10 centered outline-none">
                    <img src={Cross} alt="" />
                </button>
            </div>

            <div className="px-6 pb-6 flex flex-col gap-4">
                <div className="flex flex-col gap-4">
                    <div className="flex gap-2">
                        <div className="size-[74px] rounded-lg opacity-75 overflow-hidden">
                            <img className='size-full object-cover' src={imageUrl} alt="" />
                        </div>

                        <div className="w-[calc(100%-74px)] flex flex-col font-normal">
                            <h1 className='text-[14px] leading-[26px] text-[#000000] line-clamp-1'>Prada</h1>

                            <p className='text-[12px] leading-6 text-[#666666] line-clamp-2'>Women White And Blue Perforated Lightweight Sneakers</p>
                        </div>
                    </div>

                    <div className="flex flex-col gap-2 font-semibold text-[#666666]">
                        <h1 className='text-[18px] leading-[30px]'>Reason for cancellation</h1>

                        <div className="flex flex-col text-[14px] leading-[26px]">
                            {cancelReasons.map(reason => {
                                return (
                                    <ReasonRadio 
                                        key={reason.id} 
                                        reason={reason.reason} 
                                        inputOptions={{
                                            id: reason.id,
                                            value: reason.id,
                                            type: 'radio',
                                            checked: selectedCancellationReasonId === reason.id,
                                            onChange: handleOptionChange,
                                        }} 
                                    />
                                )
                            })}
                        </div>

                        {
                            selectedCancellationReasonId === 'other' && 
                            <textarea 
                                className='bg-[#E3E3E3] outline-none rounded-lg p-[10px] font-normal text-[14px] leading-[26px] text-[#000000] placeholder:text-[#999999]' 
                                placeholder='Write your reason' 
                                name="" 
                                id="" 
                                value={reasonText} 
                                onChange={handleReasonChange} 
                            />
                        }
                    </div>
                </div>

                <button onClick={confirmCancel} className={`px-8 py-4 rounded-lg font-semibold text-[20px] leading-8 ${buttonAvailable ? 'text-[#000000] bg-main hover:bg-mainHover' : 'bg-[#F3F3F3] text-[#999999] cursor-not-allowed'}`}>Confirm order cancellation</button>
            </div>
        </div>
    );
}

export default CancelReasonSelect;
