import React from 'react';

function DeliveryDetails() {
    return (
        <div className="p-3 bg-[#BFBFBF] rounded-lg flex flex-col">
            <div className="flex items-center gap-1">
                <p className='font-normal text-[14px] leading-[26px] text-[#000000]'>Your delivery Partner is</p>

                <div className=""></div>
            </div>

            <div className="flex flex-col font-semibold text-[14px] leading-[26px] text-[#000000]">
                <p>Tracking id: 128474563273</p>

                <p>Expected delivery on 2 January 2024</p>
            </div>
        </div>
    );
}

export default DeliveryDetails;
