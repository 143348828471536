import React, { useEffect, useRef, useState } from 'react';
import Bucket from '../../../../Assets/Bucket.svg';
import { formatDate, getCachedMediaUrl } from '../../../../lib/utils/utils';
import { useDispatch } from 'react-redux';
import { openDroptrackModal } from '../../../../Features/appSlice';
import { Link } from 'react-router-dom';

function ProductCard({ product }) {
    const dispatch = useDispatch();
    const mediaContainerRef = useRef(null);

    const [mediaContainerHeight, setMediaContainerHeight] = useState(0);

    function handleRemoveProductFromDroptrack(e) {
        e.preventDefault();
        e.stopPropagation();
        dispatch(openDroptrackModal(product.productId));
    }

    useEffect(() => {
        const updateHeight = () => {
            if (mediaContainerRef.current) {
                setMediaContainerHeight((1296 / 1080) * mediaContainerRef.current.clientWidth);
            }
        };

        const resizeObserver = new ResizeObserver(() => {
            updateHeight();
        });

        if (mediaContainerRef.current) {
            resizeObserver.observe(mediaContainerRef.current);
        }

        updateHeight();

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    return (
        <Link to={`product/${product.productId}`} className='p-3 rounded-3xl max-[768px]:rounded-2xl bg-[#FFFFFF] border border-[#E3E3E3] shadow-[0_0_8px_0_#0000001F] flex flex-col gap-2 relative'>
            <div className="absolute top-0 right-0 size-[72px] centered max-[768px]:hidden">
                <button onClick={handleRemoveProductFromDroptrack} className='hover-transition size-8 rounded-full centered bg-[#FFFFFF66] border border-[#E3E3E366] hover:bg-[#FFFFFF99] hover:border-[#E3E3E399] active:bg-[#FFFFFF] active:border-[#E3E3E3]'>
                    <img src={Bucket} alt="" />
                </button>
            </div>

            <div ref={mediaContainerRef} className="flex w-full rounded-xl overflow-hidden max-[768px]:hidden" style={{ height: `${mediaContainerHeight}px` }}>
                <img className='size-full object-cover' src={getCachedMediaUrl(product?.product?.productImg[0])} alt={product?.product?.name} />
            </div>

            <div className="flex max-[768px]:items-center gap-2 w-full">
                <div className="hidden size-[72px] rounded-md overflow-hidden max-[768px]:flex">
                    <img className='size-full object-cover' src={getCachedMediaUrl(product?.product?.productImg[0])} alt={product?.product?.name} />
                </div>

                <div className="flex flex-col px-3 w-full max-[768px]:w-[calc(100%-72px)] max-[768px]:px-0">
                    <p className='text-title3 max-[768px]:font-medium max-[768px]:text-base text-[#000000] line-clamp-2'>{product?.storeName} - {product?.product?.name}</p>

                    <p className='text-label3 text-[#666666]'>{formatDate(product?.updatedAt)}</p>
                </div>
            </div>

            <div className="p-3 bg-[#F3F3F3] grid grid-cols-3 gap-x-3 rounded-lg mt-auto">
                <div className="flex flex-col items-center gap-2">
                    <h1 className='text-body2 text-[#666666] text-center'>Original price</h1>

                    <p className='text-title2 text-[#000000]'>₹{product.originalPrice}</p>
                </div>

                <div className="flex flex-col items-center gap-2">
                    <h1 className='text-body2 text-[#666666] text-center'>Current price</h1>

                    <p className='text-title2 text-[#000000]'>₹{product.currentPrice}</p>
                </div>

                <div className="flex flex-col items-center gap-2">
                    <h1 className='text-body2 text-[#666666] text-center'>% Diff Rate</h1>

                    <p className="text-title2 text-[#000000] mt-auto">
                        {parseFloat(product.diff).toFixed(2)}%
                    </p>
                </div>
            </div>
        </Link>
    );
}

export default ProductCard;
