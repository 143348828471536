import React from 'react';
import { Title, TextMainContainer, SubText } from './Components/TextComponents';

function TermsAndConditions() {
    return (
        <div className='flex flex-col pt-20 max-[810px]:pt-14 select-none'>
            <div className="flex flex-col gap-40 py-40 mx-[50px] max-[1280px]:mx-[35px] max-[810px]:mx-4">
                <h1 className='font-bold text-[52px] leading-[64px] max-[810px]:text-[44px] max-[810px]:leading-[56px] text-[#121212]'><span className='text-main'>Terms and Conditions</span> of Use for Hyprcom</h1>

                <div className="flex flex-col gap-6">
                    <div className="flex flex-col gap-6">
                        {/* 1 */}
                        <div className="flex flex-col gap-4">
                            <Title 
                                title='1. Acceptance of Terms:' 
                            />

                            <TextMainContainer>
                                <p>By accessing and using the Hyprcom platform &#40;"Hyprcom&#41;, you agree to comply with and be bound by these Terms and Conditions. Your use of Hyprcom constitutes your acceptance of these terms. If you do not agree to these terms, please refrain from using Hyprcom.</p>
                            </TextMainContainer>
                        </div>

                        {/* 2 */}
                        <div className="flex flex-col gap-4">
                            <Title 
                                title='2.Use of Hyprcom' 
                            />

                            <TextMainContainer>
                                <SubText>
                                    <li><span className='font-semibold'>2.1 Eligibility:</span> You must be at least 18 years old or have the legal capacity to enter into contracts to use Hyprcom. You agree to use Hyprcom for lawful purposes and in accordance with these Terms and Conditions.</li>
                                    <li><span className='font-semibold'>2.2 User Accounts:</span> To access certain features of Hyprcom, you may be required to create an account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</li>
                                </SubText>
                            </TextMainContainer>
                        </div>

                        {/* 3 */}
                        <div className="flex flex-col gap-4">
                            <Title 
                                title='3. User Conduct:' 
                                subtitle='You agree not to:' 
                            />
                        
                            <TextMainContainer>
                                <SubText>
                                    <li>Violate any applicable laws or regulations.</li>
                                    <li>Infringe on the rights of others.</li>
                                    <li>Use Hyprcom for any fraudulent or unlawful purpose.</li>
                                    <li>Post or transmit any content that is harmful, offensive, or violates the rights of others.</li>
                                </SubText>
                            </TextMainContainer>
                        </div>

                        {/* 4 */}
                        <div className="flex flex-col gap-4">
                            <Title 
                                title='4. Intellectual Property:' 
                            />

                            <TextMainContainer>
                                <p>All content on Hyprcom, including but not limited to text, graphics, logos, images, and software, is the property of Hyprcom or its licensors and is protected by intellectual property laws.</p>
                            </TextMainContainer>
                        </div>

                        {/* 5 */}
                        <div className="flex flex-col gap-4">
                            <Title 
                                title='5. Third-Party Links:' 
                            />

                            <TextMainContainer>
                                <p>Hyprcom may contain links to third-party websites. These links are provided for your convenience, and Hyprcom is not responsible for the content of linked websites.</p>
                            </TextMainContainer>
                        </div>

                        {/* 6 */}
                        <div className="flex flex-col gap-4">
                            <Title 
                                title='6. Product Listings and Purchases:' 
                            />

                            <TextMainContainer>
                                <SubText>
                                    <li><span className='font-semibold'>6.1 Product Representations:</span> Hyprcom facilitates transactions between buyers and sellers. Sellers are responsible for accurately representing their products, and buyers are responsible for reviewing product details before making a purchase.</li>
                                    <li><span className='font-semibold'>6.2 Transactions:</span> Transactions conducted on Hyprcom are subject to additional terms outlined in our Purchase and Sale Agreement.</li>
                                </SubText>
                            </TextMainContainer>
                        </div>

                        {/* 7 */}
                        <div className="flex flex-col gap-4">
                            <Title 
                                title='7. Privacy:' 
                            />

                            <TextMainContainer>
                                <p>The collection and use of personal information on Hyprcom are governed by our Privacy Policy. By using Hyprcom, you consent to the practices outlined in the Privacy Policy.</p>
                            </TextMainContainer>
                        </div>

                        {/* 8 */}
                        <div className="flex flex-col gap-4">
                            <Title 
                                title='8. Disclaimers:' 
                            />

                            <TextMainContainer>
                                <SubText>
                                    <li><span className='font-semibold'>8.1 Platform Availability:</span> Hyprcom is provided on an "as is" and "as available" basis. We make no warranties, express or implied, regarding the accuracy, completeness, or availability of the platform.</li>
                                    <li><span className='font-semibold'>8.2 Content Accuracy:</span> While we strive to provide accurate information, we do not guarantee the accuracy of content on Hyprcom, including product descriptions, pricing, and availability.</li>
                                </SubText>
                            </TextMainContainer>
                        </div>

                        {/* 9 */}
                        <div className="flex flex-col gap-4">
                            <Title 
                                title='9. Limitation of Liability:' 
                            />

                            <TextMainContainer>
                                <p>To the extent permitted by law, Hyprcom and its affiliates, officers, directors, and employees will not be liable for any indirect, consequential, or incidental damages arising out of or in connection with the use of Hyprcom.</p>
                            </TextMainContainer>
                        </div>

                        {/* 10 */}
                        <div className="flex flex-col gap-4">
                            <Title 
                                title='10. Changes to Terms:' 
                            />

                            <TextMainContainer>
                                <p>We reserve the right to update or modify these Terms and Conditions at any time. Changes will be effective immediately upon posting. Please review these terms regularly.</p>
                            </TextMainContainer>
                        </div>

                        {/* 11 */}
                        <div className="flex flex-col gap-4">
                            <Title 
                                title='11. Termination:' 
                            />

                            <TextMainContainer>
                                <p>We reserve the right to terminate or suspend your access to Hyprcom at our discretion, without notice, for any reason, including violation of these Terms and Conditions.</p>
                            </TextMainContainer>
                        </div>

                        {/* 12 */}
                        <div className="flex flex-col gap-4">
                            <Title 
                                title='12. Governing Law:' 
                            />

                            <TextMainContainer>
                                <p>These Terms and Conditions are governed by and construed in accordance with the laws of jurisdiction. Any disputes arising under or in connection with these terms shall be subject to the exclusive jurisdiction of the courts in jurisdiction.</p>
                            </TextMainContainer>
                        </div>

                        {/* 13 */}
                        <div className="flex flex-col gap-4">
                            <Title 
                                title='13. Contact Information:' 
                            />

                            <TextMainContainer>
                                <p>For inquiries related to these Terms and Conditions, please contact us at <a href="mailto:contact@hyprcom.in" style={{ fontWeight: 700 }} className='hover-transition hover:text-[#316FF6] hover:underline'>contact@hyprcom.in</a>.</p>
                            </TextMainContainer>
                        </div>
                    </div>

                    <p className='font-normal text-[16px] leading-5 text-[#666666]'>Thank you for using Hyprcom responsibly.</p>
                </div>
            </div>
        </div>
    );
}

export default TermsAndConditions;
