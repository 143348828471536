import React from 'react';
import { getCachedMediaUrl } from '../../../../lib/utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedVariant } from '../../../../Features/appSlice';

function ColorButton({ color }) {
    const dispatch = useDispatch();
    const selectedVariant = useSelector((state) => state.app.selectedVariant);

    function handleColorClick(color) {
        if(selectedVariant.color !== color) {
            dispatch(setSelectedVariant({
                ...selectedVariant,
                color: color,
            }));
        }
    }

    return (
        <button onClick={() => handleColorClick(color.color)} className={`outline-none w-[100px] h-[168px] rounded-xl overflow-hidden bg-[#F3F3F3] border-b-2 ${selectedVariant.color === color.color ? 'border-main' : 'border-[#F3F3F3]'}`}>
            <img className='w-full h-[134px] object-cover rounded-xl' src={getCachedMediaUrl(color.imageUrl)} alt="" />

            <h1 className='font-semibold text-[14px] leading-[26px] text-[#000000] w-fit mx-auto mt-1'>{color.color}</h1>
        </button>
    );
}

export default ColorButton;
