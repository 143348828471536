import React from 'react';
import Bucket from '../../../Assets/Bucket.svg';
import { getCachedMediaUrl } from '../../../lib/utils/utils';
import { useDispatch } from 'react-redux';
import { decreaseQuantity, increaseQuantity, removeItem } from '../../../Features/appSlice';
import { showToast } from '../../../Components/Toaster/Toaster';

function ItemLocalCheckout({ id, product, item }) {
    const dispatch = useDispatch();

    function handleDecreaseQuantity() {
        if (item.quantity > 1) {
            dispatch(decreaseQuantity(id));
        }
        else {
            dispatch(removeItem(id));
        }
    }

    async function handleIncreaseQuantity() {
        if(item.quantity < 5) {
            dispatch(increaseQuantity(id));
        }
        else {
            showToast('Max quantity is 5 for one product.', 'warning');
        }
    }

    function handleDeleteProductFromCart() {
        dispatch(removeItem(id));
    }

    return (
        <div className='p-3 bg-[#FFFFFF] border border-[#E3E3E3] shadow-[0_0_8px_0_#0000001F] rounded-2xl flex flex-col gap-2 relative'>
            <div className="flex gap-2 w-full">
                <div className="size-[74px] rounded-lg overflow-hidden opacity-75">
                    <img className='size-full object-cover' src={getCachedMediaUrl(product.productImgUrls[0])} alt="" />
                </div>

                <div className="w-[calc(100%-74px)] flex flex-col font-normal">
                    <h1 className='text-[14px] leading-[26px] text-[#000000] line-clamp-1'>{product.productName}</h1>
                    
                    <p className='line-clamp-2 text-[12px] leading-6 text-[#666666]'>{product.productDesc}</p>

                    <div className="flex items-center gap-[9px] text-[12px] leading-6">
                        <div className="flex items-center gap-1">
                            <h1 className='text-[#666666]'>Color:</h1>

                            <p className='text-[#000000]'>{item.color}</p>
                        </div>

                        <div className="flex items-center gap-1">
                            <h1 className='text-[#666666]'>Size:</h1>

                            <p className='text-[#000000]'>{item.size}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex items-center justify-between">
                <div className="flex items-center gap-1">
                    <p className='font-semibold text-[20px] leading-8 text-[#000000]'>₹{product.productSellingPrice}</p>

                    {
                        product.productSellingPrice < product.productMRP && 
                        <>
                            <p className='font-medium text-[16px] leading-7 text-[#666666] line-through'>₹{product.productMRP}</p>

                            <p className='font-semibold text-[12px] leading-6 text-main'>{Math.round(((product.productMRP - product.productSellingPrice) / product.productMRP) * 100)}% off</p>
                        </>
                    }
                </div>

                <div className="flex items-center gap-2 bg-[#F3F3F3] rounded-3xl overflow-hidden w-fit leading-7">
                    <button onClick={handleDecreaseQuantity} className='size-8 font-semibold text-[20px]'>-</button>

                    <p className='font-semibold text-[16px] text-[#000000]'>{item.quantity}</p>

                    <button onClick={handleIncreaseQuantity} className='size-8 font-semibold text-[20px]'>+</button>
                </div>
            </div>

            <button onClick={handleDeleteProductFromCart} className="absolute top-0 right-0 size-11 centered outline-none">
                <img src={Bucket} alt="" />
            </button>
        </div>
    );
}

export default ItemLocalCheckout;
