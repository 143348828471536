import React from 'react';
import ProductBar from './ProductBar';
import DeliveryDetails from './DeliveryDetails';
import { useDispatch } from 'react-redux';
import { openOrderModal } from '../../../../Features/orderDetailsSlice';

function ProductDetails() {
    const dispatch = useDispatch();

    function handleTrackOrder() {
        dispatch(openOrderModal('track-order'));
    }

    function handleTrackRefundStatus() {
        dispatch(openOrderModal('refund-status'));
    }

    function handleTrackExchangeStatus() {
        dispatch(openOrderModal('exchange-status'));
    }

    return (
        <div className="px-6 py-4 flex flex-col gap-2">
            <ProductBar />

            <DeliveryDetails />

            <button onClick={handleTrackOrder} className='px-4 py-2 rounded w-fit font-semibold text-[14px] leading-[26px] text-[#000000] bg-main hover:bg-mainHover'>Track Order</button>

            {/* <button onClick={handleTrackRefundStatus} className='px-4 py-2 rounded w-fit font-semibold text-[14px] leading-[26px] text-[#000000] bg-main hover:bg-mainHover'>Refund Status</button> */}

            {/* <button onClick={handleTrackExchangeStatus} className='px-4 py-2 rounded w-fit font-semibold text-[14px] leading-[26px] text-[#000000] bg-main hover:bg-mainHover'>Exchange Status</button> */}
        </div>
    );
}

export default ProductDetails;
