import React from 'react';
import SuccessGIF from '../../../Assets/Success.gif';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { closeCheckoutModal } from '../../../Features/checkoutSlice';

function PaymentSuccess() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    function handleContinue() {
        navigate('/');
        dispatch(closeCheckoutModal());
        window.location.reload();
    }

    return (
        <div className='w-screen h-screen centered bg-[#FFFFFF]'>
            <div className="flex flex-col gap-20 w-[340px]">
                <div className="flex flex-col items-center gap-2">
                    <div className="size-[200px] centered">
                        <img src={SuccessGIF} alt="" />
                    </div>

                    <h1 className='text-heading1 text-[#000000]'>Order Placed</h1>

                    <p className='text-title3 text-[#666666] text-center'>Thanks for the order. You have choosen the right brand.</p>
                </div>

                <button onClick={handleContinue} className='outline-none text-title3 text-main'>Continue shopping</button>
            </div>
        </div>
    );
}

export default PaymentSuccess;
