import React, { useEffect } from 'react';
import ModalContainer from '../../Components/ModalContainer/ModalContainer';
import AddressForm from './Components/AddressForm/AddressForm';
import { useDispatch, useSelector } from 'react-redux';
import { openCheckoutModal, setSelectedAddressId } from '../../Features/checkoutSlice';
import AddressRadioOption from './Components/AddressRadioOption/AddressRadioOption';
import BillingDetails from './Components/BillingDetails/BillingDetails';

function SelectShippingAddress() {
    const dispatch = useDispatch();

    const { type, open } = useSelector((state) => state.checkout.modal);
    const selectedAddressId = useSelector((state) => state.checkout.selectedAddressId);
    const { addresses } = useSelector((state) => state.checkout.addresses);

    function openAddressForm() {
        dispatch(openCheckoutModal('address'));
    }

    function handleOptionChange(e) {
        dispatch(setSelectedAddressId(e.target.value));
    }

    useEffect(() => {
        if(addresses.length > 0) {
            if(!selectedAddressId) {
                const defaultAddress = addresses.filter(address => address.defaultAddress);
                if(defaultAddress.length > 0) {
                    dispatch(setSelectedAddressId(defaultAddress[0].id));
                }
            }
        }
    }, [addresses]);

    return (
        <div className="mx-[50px] max-[1280px]:mx-[35px] flex gap-6 mb-8">
            <div className="w-3/5 p-6 bg-[#FFFFFF] border
             border-[#E3E3E3] shadow-[0_0_8px_0_#0000001F] rounded-2xl flex flex-col gap-6 h-fit">
                {/* {
                    addresses.length > 0 && 
                    <div className="p-3 bg-[#E3E3E3] rounded-2xl flex flex-col gap-2">
                        <h1 className='text-title3 text-[#666666]'>Expected delivery on</h1>

                        <p className='text-label1 text-[#000000]'>Tuesday 26 Dec 2023</p>
                    </div>
                } */}

                <div className="flex flex-col gap-2">
                    <h1 className='text-heading2 text-[#000000]'>Select an address</h1>

                    {[ ...addresses ]
                        ?.sort((a, b) => (a.defaultAddress === b.defaultAddress) ? 0 : a.defaultAddress ? -1 : 1)
                        ?.map(address => {
                            return (
                                <AddressRadioOption 
                                    key={address.id} 
                                    address={address} 
                                    inputOptions={{
                                        id: address.id,
                                        value: address.id,
                                        type: 'radio',
                                        checked: selectedAddressId === address.id,
                                        onChange: handleOptionChange,
                                    }}
                                />
                            );
                        })
                    }
                </div>

                {
                    addresses.length === 0 && 
                    <p className='text-heading2 text-[#666666] mx-auto'>No addresses yet saved</p>
                }

                <button onClick={openAddressForm} className='px-3 py-6 border border-dashed border-[#666666] rounded-lg bg-[#F3F3F3] text-label1 text-[#666666]'>Add address</button>
            </div>

            <BillingDetails />

            {
                open && 
                <ModalContainer>
                    {type === 'address' && <AddressForm />}
                </ModalContainer>
            }
        </div>
    );
}

export default SelectShippingAddress;
