import axios from "axios";
import { fetchPreSignedURL, uploadFileToPresignedURL } from "./apiCalls";

function getCachedMediaUrl(url) {
    let base = 'https://d1oqz0dfhj1qt5.cloudfront.net/';

    if(!url) {
        return '';
    }
    else if(url.includes('storeFront')) {
        let extended = url.split('storeFront')[1];
        return `${base}storeFront${extended}`;
    }
    else {
        let extended = url.split('/').reverse()[0];
        return `${base}${extended}`;
    }
}

function formatDescription(text) {
    if(!text) {
        return '';
    }

    const hashtagRegex = /#\w+/g;
    const linkRegex = /\b(?:https?:\/\/)?(?:www\.)?([\w.-]+\.\w{2,})(\/\S*)?\b/g;

    return text.replace(hashtagRegex, (hashtag) => 
        `<span class="text-[#1055EB] hover:underline cursor-pointer">${hashtag}</span>`
    ).replace(linkRegex, (link) => {
        const formattedLink = link.includes('http') ? link : `http://${link}`;
        return `<a href="${formattedLink}" target="_blank" class="text-[#1055EB] hover:underline cursor-pointer">${link}</a>`;
    })
    .replace(/\n/g, '<br/>')
    .replace(/(<br\/>){3,}/g, '<br/><br/>');
};

function camelCaseToReadable(camelCase) {
    return camelCase
        ?.replace(/([a-z])([A-Z])/g, '$1 $2')
        ?.replace(/^./, str => str.toUpperCase());
};

function sortSizes(sizeList) {
    const sizeOrder = {
        "XXS": 0,
        "xxs": 0,
        "XS": 1,
        "xs": 1,
        "S": 2,
        "s": 2,
        "Small": 2,
        "small": 2,
        "M": 3,
        "m": 3,
        "Medium": 3,
        "medium": 3,
        "L": 4,
        "l": 4,
        "Large": 4,
        "large": 4,
        "XL": 5,
        "xl": 5,
        "XXL": 6,
        "xxl": 6,
        "2xl": 6,
        "2XL": 6,
        "XXXL": 7,
        "xxxl": 7,
        "3xl": 7,
        "3XL": 7,
        "4xl": 8,
        "4XL": 8,
        "5xl": 9,
        "5XL": 9,
        "6xl": 10,
        "6XL": 10,
        "7xl": 11,
        "7XL": 11,
    };

    let sortedList = sizeList.sort((a, b) => {
        const aValue = sizeOrder[a] !== undefined ? sizeOrder[a] : parseInt(a);
        const bValue = sizeOrder[b] !== undefined ? sizeOrder[b] : parseInt(b);
        return aValue - bValue;
    });

    return sortedList;
};

function loadImage(file) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => resolve(img);
        img.onerror = reject;
    });
};

async function fetchProductDetails(productId) {
    try {
        const response = await axios.get(`${process.env.REACT_APP_HYPRCOM_API}product/${productId}`);

        if (!response || response.status !== 200) {
            throw new Error(`Failed to get product with ID: ${productId}`);
        }
        return response;
    }
    catch (err) {
        throw err;
    }
}

function getInitials(name) {
    if (!name) return "";

    const initials = name
        .trim()
        .split(" ")
        .filter(Boolean)
        .slice(0, 2)
        .map((word) => word[0]?.toUpperCase() || "")
        .join("");

    return initials;
};

function formatBadge(badge) {
    if (!badge) return "No Review";

    return badge
        .toLowerCase()
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

function formatDate(dateString) {
    if(!dateString) {
        return null;
    }
    const date = new Date(dateString);
    
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    
    return `${month} ${day}, ${year}`;
}

function verifyEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
}

function verifyPhoneNumber(phone) {
    const phoneRegex = /^\+?[1-9]\d{1,14}$/;

    return phoneRegex.test(phone);
}

async function getMediaUrl(image) {
    const { extension, fileType } = getFileExtensionAndType(image);
    
    try {
        const fileURL = await fetchPreSignedURL(extension, fileType);
        await uploadFileToPresignedURL(image, fileURL.preSignedUrl);
        return fileURL.file;
    }
    catch (err) {
        console.log(err);
    }
}

function getFileExtensionAndType(file) {
    if (file) {
        const fileName = file.name;
        const extension = fileName.split(".").pop();
        const fileType = file.type;
        return { extension, fileType };
    }
    else {
        return { extension: "", fileType: "" };
    }
}

function formatTimeDifference(timestamp) {
    const parsedDate = new Date(timestamp);
    const currentDate = new Date();

    const timeDifferenceInSeconds = Math.floor((currentDate - parsedDate) / 1000);

    if (timeDifferenceInSeconds < 60) {
        if(timeDifferenceInSeconds < 0) {
            return `0s`
        }
        else {
            return `${timeDifferenceInSeconds}s`;
        }
    }
    else if (timeDifferenceInSeconds < 3600) {
        const minutes = Math.floor(timeDifferenceInSeconds / 60);
        return `${minutes}m`;
    }
    else if (timeDifferenceInSeconds < 86400) {
        const hours = Math.floor(timeDifferenceInSeconds / 3600);
        return `${hours}h`;
    }
    else if (timeDifferenceInSeconds < 31536000) {
        const days = Math.floor(timeDifferenceInSeconds / 86400);
        return `${days}d`;
    }
    else {
        const years = Math.floor(timeDifferenceInSeconds / 31536000);
        return `${years}y`;
    }
}

function formatCount(number) {
    if(number >= 1000000000) {
        const value = (number/1000000000);
        return String(value.toFixed(1)) + 'b';
    }
    else if(number >= 1000000) {
        const value = (number/1000000);
        return String(value.toFixed(1)) + 'm';
    }
    else if(number >= 1000) {
        const value = (number/1000);
        return String(value.toFixed(1)) + 'k';
    }
    else {
        return number.toLocaleString('en-IN', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).split('.')[0];
    }
}

function getMilestoneBackground(badge) {
    switch (badge) {
        case 'RISING_CRITIC':
            return 'bg-[linear-gradient(90deg,_#792E13_0%,_#FFA07A_50%,_#792E13_100%)]';
        case 'TRENDSETTER':
            return 'bg-[linear-gradient(90deg,_#7F7F7F_0%,_#FFFFFF_50%,_#7F7F7F_100%)]';
        case 'THE_TREND_MAGICIAN':
            return 'bg-[linear-gradient(90deg,_#A97800_0%,_#FFD700_50%,_#A97800_100%)]';
        case 'ELITE_ADVISOR':
            return 'bg-[linear-gradient(90deg,_#483D8B_0%,_#9687EF_50%,_#483D8B_100%)]';
        case 'FASHION_ICON':
            return 'bg-[linear-gradient(90deg,_#C71585_0%,_#FFB6C1_50%,_#C71585_100%)]';
        case 'TREND_ARCHITECT':
            return 'bg-[linear-gradient(90deg,_#0A730A_0%,_#61DB61_50%,_#0A730A_100%)]';
        case 'VISIONARY_VIRTUOSO':
            return 'bg-[linear-gradient(90deg,_#8B0000_0%,_#FF9C8A_50%,_#8B0000_100%)]';
        case 'INFLUENTIAL_MAVEN':
            return 'bg-[linear-gradient(90deg,_#008B8B_0%,_#BCFFF8_50%,_#008B8B_100%)]';
        case 'STYLE_SOVEREIGN':
            return 'bg-[linear-gradient(90deg,_#C54033_0%,_#D2B48C_50%,_#C54033_100%)]';
        case 'THE_INFLUENCER':
            return 'bg-[linear-gradient(90deg,_#0057AB_0%,_#77BCFF_50%,_#0057AB_100%)]';
        case 'THE_BEAUTY_BARRISTER':
            return 'bg-[linear-gradient(90deg,_#5F01A5_0%,_#E097FF_50%,_#5F01A5_100%)]';
        case 'COUTURE_CONQUEROR':
            return 'bg-[linear-gradient(90deg,_#A0522D_0%,_#FFBCA2_50%,_#A0522D_100%)]';
        case 'STYLE_SENTINEL':
            return 'bg-[linear-gradient(90deg,_#2F4F4F_0%,_#87BABA_50%,_#2F4F4F_100%)]';
        case 'THE_LEGEND_OF_LENS':
            return 'bg-[linear-gradient(90deg,_#8B4513_0%,_#FFD700_50%,_#8B4513_100%)]';
        case 'HYPRCOM_TITANS':
            return 'bg-[linear-gradient(90deg,_#DAA520_0%,_#FFFFE0_47%,_#DAA520_100%)]';
        default:
            return 'bg-[#AFB7FF]';
    }
}

export {
    getCachedMediaUrl,
    formatDescription,
    camelCaseToReadable,
    sortSizes,
    loadImage,
    fetchProductDetails,
    getInitials,
    formatBadge,
    formatDate,
    verifyEmail,
    verifyPhoneNumber,
    getMediaUrl,
    formatTimeDifference,
    formatCount,
    getMilestoneBackground
}
