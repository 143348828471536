import React, { useContext } from 'react';
import { PersonalizationFormContext } from '../../../../Context/personalizationFormContext';

function Button({ item, isSelected }) {
    const {
        productCategories,
        fashionTaste,
        offerType,
        // gender,
        paymentType,
        setProductCategories,
        setFashionTaste,
        setOfferType,
        setGender,
        setPaymentType,
    } = useContext(PersonalizationFormContext);

    function handleButtonClick() {
        if(item.gender) {
            setGender(item.id);
        }
        else if(item.category) {
            if(productCategories.includes(item.id)) {
                const filteredList = productCategories.filter(category => category !== item.id);
                setProductCategories(filteredList);
            }
            else {
                setProductCategories([ ...productCategories, item.id ]);
            }
        }
        else if(item.taste) {
            if(fashionTaste.includes(item.id)) {
                const filteredList = fashionTaste.filter(taste => taste !== item.id);
                setFashionTaste(filteredList);
            }
            else {
                setFashionTaste([ ...fashionTaste, item.id ]);
            }
        }
        else if(item.bank) {
            if(paymentType.includes(item.id)) {
                const filteredList = paymentType.filter(bank => bank !== item.id);
                setPaymentType(filteredList);
            }
            else {
                setPaymentType([ ...paymentType, item.id ]);
            }
        }
        else if(item.offer) {
            if(offerType.includes(item.id)) {
                const filteredList = offerType.filter(offer => offer !== item.id);
                setOfferType(filteredList);
            }
            else {
                setOfferType([ ...offerType, item.id ]);
            }
        }
    }

    return (
        <button onClick={handleButtonClick} className={`rounded-[37px] px-6 py-[7px] ${isSelected ? 'bg-main hover:bg-mainHover text-[#0000000]' : 'bg-[#E3E3E3] text-[#666666]'} text-label1 max-[600px]:text-[14px] max-[600px]:leading-[26px]`}>{item.gender || item.category || item.taste || item.bank || item.offer}</button>
    );
}

export default Button;
