import React from 'react';

const user = 'https://s3-alpha-sig.figma.com/img/18db/5ab2/aee7fdb52c34a5549eb251d3dfcd13f3?Expires=1729468800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ahjACbuxgZFbFpiFQFrUafJp1Vq2pc2oflgSmIwzNGa18PpjvN5498hUCT8frM76i-htEIOiQC8dH4AN7dUjdBX~Lj2aTrYmT6oYqluGD6-67WmrXD1poh5KDl58ZZ2vKjN~vc1-28XQelx0H~u9QjK6pIVLHE5bzudEOZZ-pYMhWcR04Xn7sS77xbBIRv7xnw4tYfgbRovi8GPqXu~nVTg2vdgWOjITBTD~BfyWt5aXe6aCv98pk4U8JMwrL3m-90or3xkexnUy5Ap2zhdsJnrxKH-uLgmbCzgNnlVUDZgmNXcrixBTiGKqixGD6eJ6V~AMyuhh~bFGiI9l55YNmA__';

function VideoPost({ videoUrl, userId }) {
    return (
        <div className="lens_embla__slide__number w-80 overflow-hidden relative">
            <video controls className='size-full object-cover' src={videoUrl} alt="" />

            <div className="absolute left-0 bottom-0 bg-[linear-gradient(180deg,_rgba(0,0,0,0)_0%,_#000000_141.86%)] w-full p-4">
                <div className="flex flex-col gap-1">
                    <div className="flex gap-2 w-fit">
                        <div className="size-[60px] rounded-full overflow-hidden">
                            <img className='size-full object-cover' src={user} alt="" />
                        </div>

                        <div className="flex flex-col">
                            <h1 className='font-semibold text-[20px] leading-8 text-[#FFFFFF]'>Jenifer Morgan</h1>

                            <p className='font-medium text-[16px] leading-7 text-[#BBBBBB]'>Top Reviewer</p>
                        </div>
                    </div>

                    <p className='line-clamp-2 font-medium text-[12px] leading-6 text-[#FFFFFF]'>From an impossible idea to athlete - empowering innovation, Nike Air has come a long w... see more</p>
                </div>
            </div>
        </div>
    );
}

export default VideoPost;
