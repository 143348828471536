import React, { useEffect, useState } from 'react';
import { requestFeature } from '../../../../lib/utils/apiCalls';
import { useDispatch } from 'react-redux';
import { openModal, setSettingsSection } from '../../../../Features/settingsSlice';
import ArrowLeft from '../../../../Assets/ArrowLeft.svg';
import { showToast } from '../../../../Components/Toaster/Toaster';

function RequestAFeature() {
    const dispatch = useDispatch();

    const [text, setText] = useState('');
    const [buttonAvailable, setButtonAvailable] = useState(false);

    function handleChange(e) {
        setText(e.target.value);
    }

    function handleBack() {
        dispatch(setSettingsSection(null));
    }

    async function sendRequest() {
        if(buttonAvailable) {
            try {
                const callBody = {
                    msg: text,
                }

                const response = await requestFeature(callBody);

                if(response.status === 200) {
                    dispatch(openModal('feature-request-success'));
                    setText('');
                }
            }
            catch(err) {
                console.log(err);
            }
        }
        else {
            showToast('No input found', 'warning');
        }
    }

    useEffect(() => {
        if(text && text.length > 2) {
            setButtonAvailable(true);
        }
        else {
            setButtonAvailable(false);
        }
    }, [text]);

    return (
        <div className={`w-full h-fit border border-[#BFBFBF] rounded-lg p-4 mt-6 flex flex-col gap-4 max-[768px]:rounded-none max-[768px]:border-none max-[768px]:mt-0`}>
            <div className="flex items-center gap-2">
                <button onClick={handleBack} className="size-6 max-[768px]:flex items-center justify-center outline-none hidden">
                    <img src={ArrowLeft} alt="" />
                </button>

                <h1 className='text-title1 text-[#000000]'>Request a Feature</h1>
            </div>

            <div className="flex flex-col gap-2">
                <label htmlFor="request-text" className='text-title3 text-[#666666] select-none'>What's on your mind? The founders of Hyprcom are dedicated to crafting products that resonate with people. We value your ideas and would love to hear from you. Expect to hear back from us within 7 days.</label>

                <textarea 
                    className='scrollbar-hide h-32 resize-none outline-none p-[10px] border border-[#BFBFBF] bg-[#F3F3F3] rounded-lg text-body1 placeholder:text-[#666666] text-[#000000]' 
                    name="request-text" 
                    id="request-text" 
                    placeholder='Write your thoughts here' 
                    onChange={handleChange} 
                    value={text} 
                />
            </div>

            <button onClick={sendRequest} className={`${buttonAvailable ? 'bg-main hover:bg-mainHover text-[#000000]' : 'bg-[#F3F3F3] text-[#999999] cursor-not-allowed'} max-[768px]:w-full px-4 py-2 rounded-lg text-title2 w-fit ml-auto outline-none select-none`}>Share Your Ideas with Us</button>
        </div>
    );
}

export default RequestAFeature;
