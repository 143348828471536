import React, { useEffect, useState } from 'react';
import SortOption from './SortOption';
// import { useDispatch, useSelector } from 'react-redux';
// import { setCategorySortType } from '../../../../Features/storeDetailsSlice';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

const sortOptions = [
    { id: 'featured', name: 'Featured' },
    { id: 'new', name: 'New Arrivals' },
    { id: 'high-to-low', name: 'Price: High to Low' },
    { id: 'low-to-high', name: 'Price: Low to High' },
    { id: 'ratings', name: 'Customer Ratings' },
    { id: 'bestseller', name: 'Bestsellers' },
];

function SortKebab({ kebabOpen, boxRef }) {
    // const dispatch = useDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [searchParams] = useSearchParams();
    const categorySortType = searchParams.get('sort');

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if(kebabOpen) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    }, [kebabOpen]);

    function handleOptionChange(e) {
        const updatedSearchParams = new URLSearchParams(searchParams);
        updatedSearchParams.set('sort', e.target.value);
        navigate(`${pathname}?${updatedSearchParams.toString()}`);
    }

    return (
        <div ref={boxRef} className={`${visible ? 'opacity-100 visible' : 'opacity-0 invisible'} transition-all duration-150 ease-in-out z-10 w-[300px] absolute top-8 right-0 bg-[#FFFFFF] rounded-2xl overflow-hidden flex flex-col gap-[10px] py-1 shadow-[0px_0px_6px_0px_#0000001F] max-[810px]:hidden`}>
            <h1 className='px-4 text-title3 text-[#666666]'>Sort by</h1>

            <div className="flex flex-col">
                {sortOptions.map((option, index) => {
                    const isLast = index === (sortOptions.length - 1);

                    return (
                        <SortOption 
                            inputOptions={{
                                id: option.id,
                                value: option.id,
                                type: 'radio',
                                checked: categorySortType === option.id,
                                onChange: handleOptionChange,
                            }}
                            sortType={option} 
                            key={option.id} 
                            isLast={isLast} 
                        />
                    )
                })}
            </div>
        </div>
    );
}

export default SortKebab;
