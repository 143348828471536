import { createSlice } from '@reduxjs/toolkit';

const initialStateValue = {
    modal: {
        type: '',
        open: false,
    },
    return: {
        reasonType: null,
        reasonText: null,
        option: null,
        images: [],
    },
}

const orderDetailsSlice = createSlice({
    name: 'orderDetails',
    initialState: initialStateValue,
    reducers: {
        openOrderModal: (state, action) => {
            state.modal.type = action.payload;
            state.modal.open = true;
        },
        closeOrderModal: (state, _) => {
            state.modal = initialStateValue.modal;
            state.return = initialStateValue.return;
        },
        setReturnReason: (state, action) => {
            state.return.reasonType = action.payload;
        },
        setReturnReasonText: (state, action) => {
            state.return.reasonText = action.payload;
        },
        addImageToList: (state, action) => {
            state.return.images.push(action.payload);
        },
        removeImageFromList: (state, action) => {
            const { id } = action.payload;
            const filteredList = state.return.images.filter(image => image.id !== id);
            state.return.images = filteredList;
        },
    },
});

export const {
    openOrderModal,
    closeOrderModal,
    setReturnReason,
    setReturnReasonText,
    addImageToList,
    removeImageFromList,
} = orderDetailsSlice.actions;

export default orderDetailsSlice.reducer;
