import React, { useEffect, useState } from 'react';
import LensFeed from './LensFeed';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLensFeed } from '../../Features/lensSlice';

const OPTIONS = { axis: 'y' }

function Lens() {
    const dispatch = useDispatch();

    const [activeSection, setActiveSection] = useState('all');
    const { feedPosts } = useSelector((state) => state.lens.feedPosts);

    useEffect(() => {
        dispatch(fetchLensFeed());
    }, []);

    return (
        <div className='flex flex-col pt-20'>
            <div className="w-full px-[50px] py-4 flex items-center gap-2">
                <button onClick={() => {setActiveSection('all')}} className={`px-4 py-2 rounded-lg font-semibold text-[18px] leading-[30px] text-[#000000] ${activeSection === 'all' ? 'bg-[#BFBFBF]' : 'bg-[#F3F3F3]'}`}>All Lens</button>

                <button onClick={() => {setActiveSection('video')}} className={`px-4 py-2 rounded-lg font-semibold text-[18px] leading-[30px] text-[#000000] ${activeSection === 'video' ? 'bg-[#BFBFBF]' : 'bg-[#F3F3F3]'}`}>Video Lens</button>
            </div>

            <div className="h-[calc(100vh-80px-78px)] w-full">
                <LensFeed 
                    slides={feedPosts} 
                    options={OPTIONS} 
                />
            </div>
        </div>
    );
}

export default Lens;
