import React, { useEffect, useState } from 'react';
import Search from '../../../Assets/Search.svg';
import { useDispatch, useSelector } from 'react-redux';
import { closeSearchModal, openSearchModal } from '../../../Features/appSlice';
import ProductSearchResult from './ProductSearchResult';
import UserSearchResult from './UserSearchResult';
import { searchUsers } from '../../../lib/utils/apiCalls';
import { showToast } from '../../../Components/Toaster/Toaster';

function SearchBar() {
    const dispatch = useDispatch();

    const [searchText, setSearchText] = useState('');
    const [searchedUsers, setSearchedUsers] = useState([]);
    const [searchSection, setSearchSection] = useState('PRODUCTS');

    const searchModalOpen = useSelector((state) => state.app.searchModalOpen);

    function handleChange(e) {
        setSearchText(e.target.value);
    }

    async function handleKeyDown(e) {
        if(e.key === 'Enter') {
            if(searchText) {
                if(searchSection === 'ACCOUNTS') {
                    try {
                        const response = await searchUsers(searchText);
                        if(response.status === 200) {
                            setSearchedUsers(response.data.data);
                        }
                    }
                    catch(err) {
                        console.log(err);
                    }
                }
            }
            else {
                showToast('No input found', 'warning');
            }
        }
    }

    function handleSectionButtonClick(section) {
        setSearchSection(section);
    }

    useEffect(() => {
        if(searchText) {
            dispatch(openSearchModal());
        }
        else {
            dispatch(closeSearchModal());
            setSearchedUsers([]);
        }
    }, [searchText]);

    return (
        <div className="w-[320px] max-[1200px]:w-[260px] max-[1080px]:w-[250px] p-2 h-10 bg-[#F3F3F3] rounded-lg flex items-center max-[810px]:hidden border focus-within:border-main relative border-[#BFBFBF]">
            <div className="size-6 centered">
                <img src={Search} alt="" />
            </div>

            <input 
                className='w-[calc(100%-24px)] outline-none border-none bg-transparent font-normal text-[12px] leading-6 placeholder:text-[#999999] text-[#121212]' 
                type="text" 
                placeholder='Search product, brands and more...' 
                onChange={handleChange} 
                value={searchText} 
                onKeyDown={handleKeyDown} 
            />

            {
                searchModalOpen && 
                <div className="absolute top-12 left-0 w-[500px] bg-[#FFFFFF] pt-4">
                    <div className="mx-2 flex items-center">
                        <button onClick={() => handleSectionButtonClick('PRODUCTS')} className={`select-none outline-none hover:bg-[#F5F5F5] w-1/3 py-1 border-b-2 ${searchSection === 'PRODUCTS' ? 'border-main' : 'border-transparent'} text-title3 text-[#000000]`}>Products</button>

                        <button onClick={() => handleSectionButtonClick('ACCOUNTS')} className={`select-none outline-none hover:bg-[#F5F5F5] w-1/3 py-1 border-b-2 ${searchSection === 'ACCOUNTS' ? 'border-main' : 'border-transparent'} text-title3 text-[#000000]`}>Accounts</button>

                        <button onClick={() => handleSectionButtonClick('BRANDS')} className={`select-none outline-none hover:bg-[#F5F5F5] w-1/3 py-1 border-b-2 ${searchSection === 'BRANDS' ? 'border-main' : 'border-transparent'} text-title3 text-[#000000]`}>Brands</button>
                    </div>

                    <div className="flex flex-col max-h-[400px] overflow-y-scroll">
                        {
                            searchSection === 'PRODUCTS' && 
                            <>
                                <ProductSearchResult />
                            </>
                        }

                        {
                            searchSection === 'ACCOUNTS' && 
                            <>
                                {searchedUsers?.map(user => {
                                    return (
                                        <UserSearchResult 
                                            key={user.uuids} 
                                            user={user} 
                                        />
                                    )
                                })}
                            </>
                        }
                    </div>
                </div>
            }
        </div>
    );
}

export default SearchBar;
