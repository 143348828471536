import React, { useEffect } from 'react';
import BillingDetails from './Components/BillingDetails';
import HyprcomMoney from './Components/HyprcomMoney';
import ProductBar from './Components/ProductBar';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEstimatedPrice, openCheckoutModal, setSellerId } from '../../Features/checkoutSlice';
import Cross from '../../Assets/Cross.svg';
import AllCouponList from './Components/AllCouponList/AllCouponList';
import ModalContainer from '../../Components/ModalContainer/ModalContainer';
import { removeCoupon, setCartList } from '../../Features/cartSlice';

function OrderSummary() {
    const dispatch = useDispatch();

    const { type, open } = useSelector((state) => state.checkout.modal);
    const { cart } = useSelector((state) => state.cart.cart);
    const cartList = useSelector((state) => state.cart.cartList);

    useEffect(() => {
        if(cart.length > 0) {
            const cartDataMap = new Map();

            cart.forEach(product => {
                const sellerId = product['Product.SellerUserId'];

                if (!cartDataMap.has(sellerId)) {
                    cartDataMap.set(sellerId, { 
                        sellerId: sellerId, 
                        productsList: [product],
                        couponId: '',
                    });
                }
                else {
                    const existingEntry = cartDataMap.get(sellerId);
                    existingEntry.productsList.push(product);
                }
            });

            const cartData = Array.from(cartDataMap.values());
            dispatch(setCartList(cartData));
        }
    }, [cart]);

    function applyCoupon(sellerId) {
        dispatch(setSellerId(sellerId));
        dispatch(openCheckoutModal('apply-coupon'));
    }

    function handleRemoveCoupon(sellerId) {
        dispatch(removeCoupon(sellerId));
    }

    useEffect(() => {
        const allProductIds = [];
        const allQuantities = [];
        const orderDataMap = new Map();

        cartList.forEach(cartItem => {
            const sellerId = cartItem.sellerId;
            const couponId = cartItem.couponId || "no_coupon";

            cartItem.productsList.forEach(product => {
                allProductIds.push(product.productId);
                allQuantities.push(product.quantity);

                if (!orderDataMap.has(sellerId)) {
                    const orderEntry = {
                        productIds: [product.productId],
                        sellerId: sellerId,
                        couponIds: couponId,
                        quantity: [product.quantity]
                    };
                    orderDataMap.set(sellerId, orderEntry);
                }
                else {
                    const existingEntry = orderDataMap.get(sellerId);
                    existingEntry.productIds.push(product.productId);
                    existingEntry.quantity.push(product.quantity);
                }
            });
        });

        const orderData = Array.from(orderDataMap.values());

        const callBody = {
            allProductIds,
            allquantites: allQuantities,
            orderData,
        };

        dispatch(fetchEstimatedPrice(callBody));
    }, [cartList]);

    return (
        <div className="mx-[50px] max-[1280px]:mx-[35px] max-[426px]:mx-4 flex max-[426px]:flex-col gap-6 mb-8">
            <div className="w-3/5 max-[426px]:w-full flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                    {cartList?.map(item => {
                        return (
                            <div key={item.sellerId} className="flex flex-col gap-2">
                                {item?.productsList?.map(product => {
                                    return (
                                        <ProductBar 
                                            key={product.variantId} 
                                            product={product} 
                                        />
                                    );
                                })}

                                <div className="flex items-center justify-between">
                                    {
                                        item.couponId ? 
                                        <p className='text-title3 text-[#666666]'>Coupon Applied</p> : 
                                        <button onClick={() => applyCoupon(item.sellerId)} className='select-none outline-none text-label1 text-main'>
                                            Apply coupon
                                        </button>
                                    }

                                    {
                                        item.couponId && 
                                        <div className="flex items-center gap-[10px]">
                                            <p className='text-title3 text-main'>-800</p>

                                            <button onClick={() => handleRemoveCoupon(item.sellerId)} className="select-none outline-none size-8 centered rounded-full bg-[#F3F3F3]">
                                                <img className='size-3' src={Cross} alt="" />
                                            </button>
                                        </div>
                                    }
                                </div>
                            </div>
                        )
                    })}
                </div>

                <HyprcomMoney />
            </div>

            <BillingDetails />

            {
                open && 
                <ModalContainer>
                    {type === 'apply-coupon' && <AllCouponList />}
                </ModalContainer>
            }
        </div>
    );
}

export default OrderSummary;

