import React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import TickMark from '../../../Assets/TickMark.svg';

function StyleFilter({ style }) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [searchParams] = useSearchParams();
    const styleFilter = searchParams.get('style');

    function materialOptionClick() {
        const updatedSearchParams = new URLSearchParams(searchParams);
        const currentDiscount = updatedSearchParams.get('style');
        if (currentDiscount === style.style) {
            updatedSearchParams.delete('style');
        }
        else {
            updatedSearchParams.set('style', style.style);
        }
        navigate(`${pathname}?${updatedSearchParams.toString()}`);
    }

    return (
        <div className="flex items-center gap-2 cursor-pointer" onClick={materialOptionClick}>
            <div className={`size-6 centered bg-[#F3F3F3] rounded-md border ${styleFilter === style.style ? 'border-main' : 'border-[#BFBFBF]'}`}>
                {
                    styleFilter === style.style && 
                    <img src={TickMark} alt="" />
                }
            </div>

            <p className='text-title3 text-[#666666]'>{style.style}</p>
        </div>
    );
}

export default StyleFilter;
