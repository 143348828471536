import React, { useEffect, useRef, useState } from 'react';
import { getCachedMediaUrl } from '../../../../lib/utils/utils';
import { useDispatch } from 'react-redux';
import { setDroptrackImageUrl } from '../../../../Features/appSlice';

function ImageCard({ image, isActive }) {
    const dispatch = useDispatch();

    const mediaContainerRef = useRef(null);

    const [mediaContainerHeight, setMediaContainerHeight] = useState(0);

    function updateImage() {
        dispatch(setDroptrackImageUrl(image));
    }

    useEffect(() => {
        const updateHeight = () => {
            if (mediaContainerRef.current) {
                setMediaContainerHeight((1296 / 1080) * mediaContainerRef.current.clientWidth);
            }
        };

        const resizeObserver = new ResizeObserver(() => {
            updateHeight();
        });

        if (mediaContainerRef.current) {
            resizeObserver.observe(mediaContainerRef.current);
        }

        updateHeight();

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    return (
        <div 
            onMouseOver={updateImage} 
            ref={mediaContainerRef} 
            className={`w-full rounded-lg overflow-hidden cursor-pointer border-2 ${isActive ? 'border-main' : 'border-transparent'}`} 
            style={{ height: `${mediaContainerHeight}px` }}
        >
            <img className='size-full object-cover hover:opacity-85' src={getCachedMediaUrl(image)} alt="" />
        </div>
    );
}

export default ImageCard;
