import React, { useEffect } from 'react';
import SuccessGIF from '../../../../Assets/Success.gif';
import { useDispatch } from 'react-redux';
import { closeModal } from '../../../../Features/settingsSlice';
import { fetchAddresses } from '../../../../Features/checkoutSlice';

function AddressAddSuccess() {
    const dispatch = useDispatch();

    useEffect(() => {
        setTimeout(() => {
            dispatch(fetchAddresses());
            dispatch(closeModal());
        }, 1950);
    }, []);

    return (
        <div className='w-[35%] p-10 bg-[#FFFFFF] rounded-2xl flex flex-col items-center'>
            <div className="size-[200px] centered">
                <img src={SuccessGIF} alt="" />
            </div>

            <p className='text-heading3 text-[#000000] -mt-4'>New Address Added Successfully</p>
        </div>
    );
}

export default AddressAddSuccess;
