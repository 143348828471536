import { configureStore } from "@reduxjs/toolkit";
import homepageReducer from "../Features/homepageSlice";
import storesReducer from "../Features/storesSlice";
import storeDetailsReducer from "../Features/storeDetailsSlice";
import productReducer from "../Features/productSlice";
import appReducer from "../Features/appSlice";
import checkoutReducer from "../Features/checkoutSlice";
import orderDetailsReducer from "../Features/orderDetailsSlice";
import ordersReducer from "../Features/ordersSlice";
import lensReducer from "../Features/lensSlice";
import userReducer from "../Features/userSlice";
import cartReducer from "../Features/cartSlice";
import settingsReducer from "../Features/settingsSlice";
import profileReducer from "../Features/profileSlice";

export const store = configureStore({
    reducer: {
        app: appReducer,
        homepage: homepageReducer,
        stores: storesReducer,
        storeDetails: storeDetailsReducer,
        product: productReducer,
        checkout: checkoutReducer,
        orderDetails: orderDetailsReducer,
        orders: ordersReducer,
        lens: lensReducer,
        user: userReducer,
        cart: cartReducer,
        settings: settingsReducer,
        profile: profileReducer,
    },
});
