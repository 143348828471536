import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../Auth/Interceptor';

const initialStateValue = {
    profile: {
        profile: {},
        loading: 'idle',
        error: null,
    },
    modal: {
        type: '',
        open: false,
    },
}

const fetchProfile = createAsyncThunk('profile/fetchProfile', async (userId) => {
    try {
        if(userId === 'me') {
            const response = await api.get('user/profile');
            return response.data.data;
        }
        else {
            const response = await api.get(`user/profile/${userId}`);
            return response.data.data;
        }
    }
    catch(error) {
        throw error;
    }
});

const profileSlice = createSlice({
    name: 'profile',
    initialState: initialStateValue,
    reducers: {
        openModal: (state, action) => {
            state.modal.type = action.payload;
            state.modal.open = true;
        },
        closeModal: (state, _) => {
            state.modal = initialStateValue.modal;
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchProfile.pending, (state) => {
            state.profile.loading = 'loading';
        })
        .addCase(fetchProfile.fulfilled, (state, action) => {
            state.profile.loading = 'succeeded';
            state.profile.profile = action.payload;
        })
        .addCase(fetchProfile.rejected, (state, action) => {
            state.profile.loading = 'failed';
            state.profile.error = action.error.message;
        });
    },
});

export const {
    openModal,
    closeModal,
} = profileSlice.actions;

export {
    fetchProfile,
}

export default profileSlice.reducer;
