import React from 'react';
import { getCachedMediaUrl } from '../../../lib/utils/utils';
import { Link } from 'react-router-dom';

function ProductBar({ product }) {
    return (
        <div className='w-full bg-[#FFFFFF] border border-[#E3E3E3] shadow-[0_0_8px_0_#0000001F] p-3 rounded-2xl flex flex-col gap-2'>
            <div className="flex gap-2 w-full">
                <div className="size-[74px] rounded-lg overflow-hidden opacity-75">
                    <img className='size-full object-cover' src={getCachedMediaUrl(product['ProductVarient.VarientImgUrl'][0])} alt="" />
                </div>

                <div className="w-[calc(100%-74px)] flex flex-col">
                    <div className="flex flex-col w-full">
                        <Link to={`/stores/${product['Product.SellerUserId']}/products/${product.productId}`} className='w-full line-clamp-1 font-semibold text-[20px] leading-8 text-[#000000]'>{product['Product.productName']}</Link>

                        <p className='w-full line-clamp-2 font-normal text-[14px] leading-[26px] text-[#666666]'>{product['Product.productDesc']}</p>
                    </div>

                    <div className="flex items-center gap-[9px] font-normal text-[14px] leading-[26px]">
                        <div className="flex items-center gap-1">
                            <p className='text-[#666666]'>Color:</p>

                            <p className='text-[#000000]'>{product['ProductVarient.VarientColor']}</p>
                        </div>

                        <div className="flex items-center gap-1">
                            <p className='text-[#666666]'>Size:</p>

                            <p className='text-[#000000]'>{product['ProductVarient.VarientSize']}</p>
                        </div>

                        <div className="flex items-center gap-1">
                            <p className='text-[#666666]'>Qty:</p>

                            <p className='text-[#000000]'>{product.quantity}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex items-center gap-1">
                <p className='font-semibold text-[20px] leading-8 text-[#000000]'>₹{product['Product.productSellingPrice']}</p>

                {
                    product['Product.productSellingPrice'] < product['Product.productMRP'] && 
                    <>
                        <p className='font-medium text-[16px] leading-7 text-[#666666] line-through'>₹{product['Product.productMRP']}</p>

                        <p className='font-semibold text-[12px] leading-6 text-main'>{Math.round(((product['Product.productMRP'] - product['Product.productSellingPrice']) / product['Product.productMRP']) * 100)}% off</p>
                    </>
                }
            </div>
        </div>
    );
}

export default ProductBar;
