import React from 'react';
import { useDispatch } from 'react-redux';
import { openOrderModal } from '../../../Features/orderDetailsSlice';

function BillingDetails() {
    const dispatch = useDispatch();

    function handleCancelOrder() {
        dispatch(openOrderModal('confirm-cancel'));
    }

    function handleReturnOrder() {
        dispatch(openOrderModal('confirm-return'));
    }

    return (
        <div className="w-2/5 h-fit p-6 border border-[#BFBFBF] rounded-2xl flex flex-col gap-4">
            <div className="flex flex-col gap-2">
                <h1 className='font-semibold text-[18px] leading-[30px] text-[#666666]'>Order Details</h1>

                <div className="p-4 bg-[#F3F3F3] rounded-2xl flex flex-col gap-2 font-medium text-[12px] leading-6 text-[#000000]">
                    <div className="flex items-center justify-between">
                        <h1>Price</h1>

                        <p>₹839</p>
                    </div>

                    <div className="flex items-center justify-between">
                        <h1>Tax</h1>

                        <p>₹16.50</p>
                    </div>

                    <div className="flex items-center justify-between">
                        <h1>Discount</h1>

                        <p className='text-main'>- ₹799</p>
                    </div>

                    <div className="flex items-center justify-between">
                        <h1>Applied Coupon</h1>

                        <p className='text-main'>- ₹399</p>
                    </div>

                    <div className="flex items-center justify-between">
                        <h1>Shipping Charge</h1>

                        <p>FREE</p>
                    </div>

                    <div className="w-full border border-[#666666] border-dashed"></div>

                    <div className="flex items-center justify-between font-semibold text-[14px] leading-[26px] text-[#000000]">
                        <h1>Total Price</h1>

                        <p>₹516.50</p>
                    </div>
                </div>
            </div>

            <div className="flex items-center gap-2">
                {/* <button onClick={handleCancelOrder} className='w-1/2 px-8 py-4 max-[1280px]:px-6 max-[1024px]:px-4 max-[1280px]:py-3 max-[1024px]:py-2 rounded-lg font-semibold text-[20px] leading-8 max-[1280px]:text-[16px] max-[1024px]:leading-7 text-[#FFFFFF] bg-[#EF4444] text-nowrap'>Cancel order</button> */}
                <button onClick={handleReturnOrder} className='w-1/2 px-8 py-4 max-[1280px]:px-6 max-[1024px]:px-4 max-[1280px]:py-3 max-[1024px]:py-2 rounded-lg font-semibold text-[20px] leading-8 max-[1280px]:text-[16px] max-[1024px]:leading-7 text-[#FFFFFF] bg-[#EF4444] text-nowrap'>Return order</button>

                <button className='w-1/2 px-8 py-4 max-[1280px]:px-6 max-[1024px]:px-4 max-[1280px]:py-3 max-[1024px]:py-2 rounded-lg font-semibold text-[20px] leading-8 max-[1280px]:text-[16px] max-[1024px]:leading-7 text-[#000000] bg-[#E3E3E3] text-nowrap'>Download Invoice</button>
            </div>

            <p className="font-normal text-[12px] leading-6 text-[#666666]">If you wish to return your order, you can do so within 2 days of receiving it. We want your shopping experience to be smooth, and our return process is designed to ensure your satisfaction.</p>
        </div>
    );
}

export default BillingDetails;
