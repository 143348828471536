import React from 'react';
import { Link } from 'react-router-dom';

function Bar() {
    return (
        <div className="w-3 border border-[#666666] rotate-90"></div>
    )
}

function MinimalFooter() {
    return (
        <div className="relative bottom-0 w-full px-[74px] max-[1280px]:px-[35px] py-6 max-[768px]:py-3 max-[768px]:px-4 bg-[#FFFFFF] flex items-center justify-between text-body2 max-[768px]:font-normal max-[768px]:text-[8px] max-[768px]:leading-4 text-[#666666] select-none max-[426px]:hidden shadow-[0px_0px_6px_0px_#0000001F] border-t border-[#E3E3E3]">
            <div className="flex items-center gap-[9px] max-[768px]:gap-1">
                <Link to='/terms-and-conditions'>Terms & Conditions</Link>

                <Bar />

                <Link to='/privacy-policy'>Privacy Policy</Link>

                {/* <Bar /> */}

                {/* <p>Return/Exchange Policy</p> */}
            </div>

            <p className='absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2'>© 2024 · Hyprcom</p>

            <div className="flex items-center gap-[9px]">
                {/* <Link to='/about-hyprcom'>About Us</Link> */}

                {/* <Bar /> */}

                {/* <p>FAQ's</p> */}

                {/* <Bar /> */}

                {/* <p>Blogs</p> */}
            </div>
        </div>
    );
}

export default MinimalFooter;
