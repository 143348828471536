import React, { useEffect, useState } from 'react';
import Specifications from '../Specifications/Specifications';
import { formatDescription } from '../../../../lib/utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import SizeButton from './SizeButton';
import ColorButton from './ColorButton';
import Coupon from './Coupon';
import BookmarkGray from '../../../../Assets/BookmarkGray.svg';
import BookmarkFilled from '../../../../Assets/BookmarkFilled.svg';
import Pricing from './Pricing';
import { Link, useParams } from 'react-router-dom';
import { changeBagQuantity, openSizeChart } from '../../../../Features/productSlice';
import { addProductToWishlist, checkProductWishlistStatus, fetchSellerCoupons, removeProductFromWishlist } from '../../../../lib/utils/apiCalls';

function ProductDetailsSection() {
    const dispatch = useDispatch();
    const { productId } = useParams();

    const [isAddedToWishlist, setIsAddedToWishlist] = useState(false);
    const [productCoupons, setProductCoupons] = useState([]);

    const { storeName, product } = useSelector((state) => state.product.product.product);
    const { sizes, colors } = useSelector((state) => state.product.variants);
    const bagQuantity = useSelector((state) => state.product.bagQuantity);
    const { sellingPrice, MRP, quantity, variantId } = useSelector((state) => state.product.selectedVariant);
    const { user } = useSelector((state) => state.user.user);

    function handleSizeChartButton() {
        dispatch(openSizeChart());
    }

    async function handleWishlistButton() {
        if(isAddedToWishlist) {
            try {
                const response = await removeProductFromWishlist(productId, variantId);
                if(response.status === 200) {
                    setIsAddedToWishlist(false);
                }
            }
            catch(err) {
                console.log(err);
            }
        }
        else {
            try {
                const callBody = {
                    productId: productId,
                    varientId: variantId,
                }
    
                const response = await addProductToWishlist(callBody);
                if(response.status === 200) {
                    setIsAddedToWishlist(true);
                }
            }
            catch(err) {
                console.log(err);
            }
        }
    }

    useEffect(() => {
        async function checkProductIsAddedToWishlist(pid, vid) {
            try {
                const response = await checkProductWishlistStatus(pid, vid);
                if(response.status === 200) {
                    setIsAddedToWishlist(response.data.data);
                }
            }
            catch(err) {
                console.log(err);
            }
        }

        if(productId && variantId) {
            if(Object.keys(user).length > 0) {
                checkProductIsAddedToWishlist(productId, variantId);
            }
        }
    }, [productId, variantId, user]);

    useEffect(() => {
        async function getSellerCouponsForProduct(productIds) {
            try {
                const response = await fetchSellerCoupons(productIds);
                if(response.status === 200) {
                    setProductCoupons(response.data.data);
                }
            }
            catch(err) {
                console.log(err);
            }
        }

        if(productId) {
            getSellerCouponsForProduct([productId]);
        }
    }, [productId]);

    function handleQuantityMinus() {
        if(bagQuantity > 0) {
            dispatch(changeBagQuantity(bagQuantity-1));
        }
    }

    function handleQuantityPlus() {
        dispatch(changeBagQuantity(bagQuantity+1));
    }

    return (
        <div className="w-1/2 max-[426px]:w-full flex flex-col gap-4">
            <div className="flex flex-col">
                <div className="flex items-center justify-between max-[426px]:mx-4">
                    <Link to={`/stores/${product.SellerUserId}`} className='text-title1 max-[426px]:text-title2 text-[#000000]'>{storeName}</Link>

                    {
                        Object.keys(user).length > 0 && 
                        <button onClick={handleWishlistButton} className="flex items-center gap-2 outline-none select-none">
                            <p className={`text-title3 ${isAddedToWishlist ? 'text-[#999999]' : 'text-[#666666]'} max-[426px]:hidden`}>{isAddedToWishlist ? 'Remove from' : 'Add to'} wishlist</p>

                            <div className="w-4">
                                <img className='w-full h-auto' src={isAddedToWishlist ? BookmarkFilled : BookmarkGray} alt="" />
                            </div>
                        </button>
                    }
                </div>

                <p className='text-title3 text-[#666666] max-[426px]:mx-4'>{product?.productName}</p>

                <div className="flex flex-col gap-2 max-[426px]:mx-4">
                    <Pricing 
                        SellingPrice={sellingPrice} 
                        MRP={MRP} 
                        discount={Math.round(((MRP - sellingPrice) / MRP) * 100)}
                    />

                    {
                        quantity > 0 ? 
                        <div className="flex items-center gap-2 bg-[#F3F3F3] rounded-3xl overflow-hidden w-fit leading-7">
                            <button onClick={handleQuantityMinus} className='size-8 font-semibold text-[20px]'>-</button>

                            <p className='font-semibold text-[16px] text-[#000000]'>{bagQuantity}</p>

                            <button onClick={handleQuantityPlus} className='size-8 font-semibold text-[20px]'>+</button>
                        </div> : 
                        <p className='text-2xl text-[#EF4444] select-none'>Out of stock!</p>
                    }
                </div>
            </div>

            <div className="flex flex-col gap-2">
                <h1 className='font-semibold text-[20px] leading-8 text-[#666666] max-[426px]:mx-4'>Colors</h1>

                <div className="flex flex-wrap max-[426px]:flex-nowrap max-[426px]:overflow-x-scroll max-[426px]:px-4 max-[426px]:scrollbar-hide items-center gap-3">
                    {colors?.map(color => {
                        return (
                            <ColorButton 
                                key={color.id} 
                                color={color} 
                            />
                        )
                    })}
                </div>
            </div>

            <div className="flex flex-col gap-2">
                <div className="flex items-center justify-between max-[426px]:mx-4">
                    <h1 className='font-semibold text-[20px] leading-8 text-[#666666]'>Size</h1>

                    {/* <button onClick={handleSizeChartButton} className='text-label1 text-main'>Size Chart</button> */}
                </div>

                <div className="flex flex-wrap items-center gap-3 max-[426px]:overflow-y-scroll max-[426px]:scrollbar-hide max-[426px]:px-4">
                    {sizes?.map(size => {
                        return (
                            <SizeButton 
                                key={size} 
                                size={size} 
                            />
                        )
                    })}
                </div>
            </div>

            {/* <div className="flex flex-col gap-2 max-[426px]:mx-4">
                {
                    productCoupons?.length > 0 ? 
                    (
                        productCoupons?.map(coupon => {
                            return (
                                <Coupon 
                                    key={coupon.id} 
                                    coupon={coupon} 
                                />
                            );
                        })
                    ) : 
                    <p className='text-title1 text-[#666666]'>No coupons available!</p>
                }
            </div> */}

            <div className="flex flex-col gap-2 max-[426px]:mx-4">
                <h1 className='text-title3 text-[#000000]'>Product Description</h1>

                <p className='text-body1 text-[#666666]'>
                    {
                        product?.productDesc ? 
                        <span dangerouslySetInnerHTML={{ __html: formatDescription(product?.productDesc) }} /> : 
                        'No description'
                    }
                </p>
            </div>

            <Specifications />
        </div>
    );
}

export default ProductDetailsSection;
