import React from 'react';
import { formatDate } from '../../../../lib/utils/utils';

function TransactionCard({ transaction }) {
    return (
        <div className='bg-[#F3F3F3] rounded-lg p-3 flex justify-between gap-1'>
            <div className="flex flex-col">
                <p className='text-body2 text-[#999999]'>{formatDate(transaction.updatedAt)}</p>

                {
                    transaction.type === "order" && 
                    <h1 className='text-label1 text-[#666666]'>Order id: {transaction.orderId.split('-').reverse()[0]}</h1>
                }
            </div>

            <p className={`text-label1 ${transaction.type === 'order' && 'text-[#EF4444]'}`}> {transaction.type === "order" && '-'}₹{transaction.money.toFixed(2)}</p>
        </div>
    );
}

export default TransactionCard;
