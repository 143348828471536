import React from 'react';
import BrandCard from '../BrandCard';
import SectionTitle from '../../../../Components/SectionTitle/SectionTitle';

function FavoriteBrands({ favouriteBrands }) {
    return (
        <div className='w-full px-[50px] max-[1280px]:px-[35px] flex flex-col gap-8 max-[426px]:gap-6 items-center'>
            <SectionTitle 
                title='Your Favorite Brands'
            />

            <div className="w-full grid grid-cols-4 gap-x-6 gap-y-6 max-[1152px]:grid-cols-3">
                {favouriteBrands?.map(brand => {
                    return (
                        <BrandCard 
                            key={brand.id} 
                            brand={brand} 
                        />
                    );
                })}
            </div>

            <button className='bg-[#FFFFFF] border-2 border-[#999999] px-4 py-2 rounded-lg font-medium text-[16px] leading-7 text-[#000000]'>View all</button>
        </div>
    );
}

export default FavoriteBrands;
