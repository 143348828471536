import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import api from '../Auth/Interceptor';

const initialStateValue = {
    sidebar: {
        type: null,
        open: false,
    },
    accountModalOpen: false,
    searchModalOpen: false,
    productModal: {
        productId: null,
        open: false,
    },
    product: {
        product: {},
        loading: 'idle',
        error: null,
    },
    variants: {
        sizes: [],
        colors: [],
    },
    selectedVariant: {
        size: '',
        color: '',
        sellingPrice: '',
        MRP: '',
        quantity: '',
        variantId: '',
    },
    images: {
        variantImages: [],
        activeImageUrl: '',
    },
    bagQuantity: 0,
    droptrackProducts: {
        droptrackProducts: [],
        loading: 'idle',
        error: null,
    },
    droptrackProductDetails: {
        droptrackProductDetails: {},
        loading: 'idle',
        error: null,
    },
    droptrackProductVariant: {
        sellingPrice: '',
        MRP: '',
        quantity: '',
        variantId: '',
        droptrackImages: [],
        activeImageUrl: '',
        product: {},
    },
    droptrackBagQuantity: 0,
    droptrackModal: {
        productId: null,
        open: false,
    },
    localCartList: [],
}

const fetchModalProduct = createAsyncThunk('products/fetchModalProduct', async (productId) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_HYPRCOM_API}product/${productId}`);
        return response.data.data;
    }
    catch(error) {
        throw error;
    }
});


const fetchDroptrackProducts = createAsyncThunk('droptrack/fetchDroptrackProducts', async () => {
    try {
        const response = await api.get('droptrack');
        return response.data.data;
    }
    catch(error) {
        throw error;
    }
});

const fetchDroptrackProductDetails = createAsyncThunk('droptrack/fetchDroptrackProductDetails', async (productId) => {
    try {
        const response = await api.get(`droptrack/product/${productId}`);
        return response.data.data;
    }
    catch(error) {
        throw error;
    }
});

const appSlice = createSlice({
    name: 'app',
    initialState: initialStateValue,
    reducers: {
        openAccountModal: (state, _) => {
            state.accountModalOpen = true;
        },
        closeAccountModal: (state, _) => {
            state.accountModalOpen = false;
        },
        openSearchModal: (state, _) => {
            state.searchModalOpen = true;
        },
        closeSearchModal: (state, _) => {
            state.searchModalOpen = false;
        },
        openSidebar: (state, action) => {
            state.sidebar.open = true;
            state.sidebar.type = action.payload;
        },
        closeSidebar: (state, _) => {
            state.sidebar = initialStateValue.sidebar;
        },
        openProductModal: (state, action) => {
            state.productModal.open = true;
            state.productModal.productId = action.payload;
        },
        closeProductModal: (state, _) => {
            state.productModal = initialStateValue.productModal;
            state.product = initialStateValue.product;
            state.variants = initialStateValue.variants;
            state.selectedVariant = initialStateValue.selectedVariant;
            state.images = initialStateValue.images;
        },
        setProductVarients: (state, action) => {
            state.variants = action.payload;
        },
        setSelectedVariant: (state, action) => {
            state.selectedVariant = action.payload;
        },
        setImages: (state, action) => {
            state.images = action.payload;
        },
        setActiveImageUrl: (state, action) => {
            state.images.activeImageUrl = action.payload;
        },
        changeBagQuantity: (state, action) => {
            state.bagQuantity = action.payload;
        },
        changeDroptrackBagQuantity: (state, action) => {
            state.droptrackBagQuantity = action.payload;
        },
        openDroptrackModal: (state, action) => {
            state.droptrackModal.open = true;
            state.droptrackModal.productId = action.payload;
        },
        closeDroptrackModal: (state, _) => {
            state.droptrackModal = initialStateValue.droptrackModal;
        },
        addToLocalCartList: (state, action) => {
            const item = action.payload;
            const findItem = state.localCartList.find(object => object.variantId === item.variantId);
        
            if (findItem) {
                // Find the index of the item to update
                const findItemIndex = state.localCartList.findIndex(object => object.variantId === item.variantId);
                
                // Create a new array with the updated item
                state.localCartList = state.localCartList.map((cartItem, index) => {
                    if (index === findItemIndex) {
                        return {
                            ...cartItem,
                            quantity: cartItem.quantity + item.quantity
                        };
                    }
                    return cartItem;
                });
            } else {
                state.localCartList = [...state.localCartList, item];
            }
        },
        increaseQuantity: (state, action) => {
            const item  = state.localCartList.find(item => item.id === action.payload);
            item.quantity = item.quantity + 1;
        },
        decreaseQuantity: (state, action) => {
            const item  = state.localCartList.find(item => item.id === action.payload);
            item.quantity = item.quantity - 1;
        },
        removeItem: (state, action) => {
            const filteredItems = state.localCartList.filter(item => item.id !== action.payload);
            state.localCartList = filteredItems;
        },
        resetCartList: (state, _) => {
            state.localCartList = initialStateValue.localCartList;
        },
        setDroptrackVariant: (state, action) => {
            state.droptrackProductVariant = action.payload;
        },
        setDroptrackImageUrl: (state, action) => {
            state.droptrackProductVariant.activeImageUrl = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchModalProduct.pending, (state) => {
            state.product.loading = 'loading';
        })
        .addCase(fetchModalProduct.fulfilled, (state, action) => {
            state.product.loading = 'succeeded';
            state.product.product = action.payload;
        })
        .addCase(fetchModalProduct.rejected, (state, action) => {
            state.product.loading = 'failed';
            state.product.error = action.error.message;
        })
        .addCase(fetchDroptrackProducts.pending, (state) => {
            state.droptrackProducts.loading = 'loading';
        })
        .addCase(fetchDroptrackProducts.fulfilled, (state, action) => {
            state.droptrackProducts.loading = 'succeeded';
            state.droptrackProducts.droptrackProducts = action.payload;
        })
        .addCase(fetchDroptrackProducts.rejected, (state, action) => {
            state.droptrackProducts.loading = 'failed';
            state.droptrackProducts.error = action.error.message;
        })
        .addCase(fetchDroptrackProductDetails.pending, (state) => {
            state.droptrackProductDetails.loading = 'loading';
        })
        .addCase(fetchDroptrackProductDetails.fulfilled, (state, action) => {
            state.droptrackProductDetails.loading = 'succeeded';
            state.droptrackProductDetails.droptrackProductDetails = action.payload;
        })
        .addCase(fetchDroptrackProductDetails.rejected, (state, action) => {
            state.droptrackProductDetails.loading = 'failed';
            state.droptrackProductDetails.error = action.error.message;
        });
    },
});

export const {
    openAccountModal,
    closeAccountModal,
    openSidebar,
    closeSidebar,
    openProductModal,
    closeProductModal,
    setProductVarients,
    setSelectedVariant,
    setImages,
    setActiveImageUrl,
    changeBagQuantity,
    openSearchModal,
    closeSearchModal,
    openDroptrackModal,
    closeDroptrackModal,
    addToLocalCartList,
    increaseQuantity,
    decreaseQuantity,
    removeItem,
    resetCartList,
    setDroptrackVariant,
    setDroptrackImageUrl,
    changeDroptrackBagQuantity,
} = appSlice.actions;

export {
    fetchModalProduct,
    fetchDroptrackProducts,
    fetchDroptrackProductDetails,
}

export default appSlice.reducer;
