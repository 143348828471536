import React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import TickMark from '../../../../Assets/TickMark.svg';

function DiscountFilter({ discount }) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [searchParams] = useSearchParams();
    const discountFilter = searchParams.get('discount');

    function materialOptionClick() {
        const updatedSearchParams = new URLSearchParams(searchParams);
        const currentDiscount = updatedSearchParams.get('discount');
        if (currentDiscount == discount.discount) {
            updatedSearchParams.delete('discount');
        }
        else {
            updatedSearchParams.set('discount', discount.discount);
        }
        navigate(`${pathname}?${updatedSearchParams.toString()}`);
    }

    return (
        <div className="flex items-center gap-2 cursor-pointer" onClick={materialOptionClick}>
            <div className={`size-6 centered bg-[#F3F3F3] rounded-md border ${discountFilter == discount.discount ? 'border-main' : 'border-[#BFBFBF]'}`}>
                {
                    discountFilter == discount.discount && 
                    <img src={TickMark} alt="" />
                }
            </div>

            <p className='text-title3 text-[#666666]'>{discount.text}</p>
        </div>
    );
}

export default DiscountFilter;
