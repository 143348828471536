import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSettingsSection } from '../../Features/settingsSlice';

import ModalContainer from '../../Components/ModalContainer/ModalContainer';

import SectionMenu from './Components/SectionMenu/SectionMenu';
import MyPreference from './Components/MyPreference/MyPreference';
import AddressBook from './Components/AddressBook/AddressBook';
import AddressForm from './Components/AddressForm/AddressForm';
import HyprcomMoney from './Components/HyprcomMoney/HyprcomMoney';
import ReferAndEarn from './Components/ReferAndEarn/ReferAndEarn';
import RequestAFeature from './Components/RequestAFeature/RequestAFeature';
import HyprcomSupport from './Components/HyprcomSupport/HyprcomSupport';
import RefundStatus from './Components/RefundStatus/RefundStatus';
import AboutHyprcom from './Components/AboutHyprcom/AboutHyprcom';
import AddressAddSuccess from './Components/AddressForm/AddressAddSuccess';
import AddressDeleteConfirm from './Components/AddressBook/AddressDeleteConfirm';
import FeatureRequestSuccess from './Components/RequestAFeature/FeatureRequestSuccess';
import { PreferenceFormProvider } from '../../Context/preferenceFormContext';

function Settings() {
    const dispatch = useDispatch();

    const settingsSection = useSelector((state) => state.settings.settingsSection);
    const { type, open } = useSelector((state) => state.settings.modal);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        if(windowWidth > 768) {
            if(!settingsSection) {
                dispatch(setSettingsSection('MY_PREFERENCE'));
            }
        }
    }, [windowWidth, settingsSection]);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className='flex gap-6 min-h-screen pt-20 max-[810px]:pt-14 pb-20'>
            <SectionMenu />

            <div className={`${settingsSection ? 'w-[70%] max-[768px]:w-full' : 'w-[70%] max-[768px]:hidden'} mr-[50px] max-[768px]:mr-0 flex`}>
                {
                    settingsSection === 'MY_PREFERENCE' && 
                    <PreferenceFormProvider>
                        <MyPreference />
                    </PreferenceFormProvider>
                }

                {settingsSection === 'ADDRESS_BOOK' && <AddressBook />}

                {settingsSection === 'HYPRCOM_MONEY' && <HyprcomMoney />}

                {settingsSection === 'REFER_AND_EARN' && <ReferAndEarn />}

                {settingsSection === 'REQUEST_A_FEATURE' && <RequestAFeature />}

                {settingsSection === 'HYPRCOM_SUPPORT' && <HyprcomSupport />}

                {settingsSection === 'REFUND_STATUS' && <RefundStatus />}

                {settingsSection === 'ABOUT_HYPRCOM' && <AboutHyprcom />}
            </div>

            {
                open && 
                <ModalContainer>
                    {type === 'address-form' && <AddressForm />}

                    {type === 'address-add-success' && <AddressAddSuccess />}
                
                    {type === 'address-delete-confirm' && <AddressDeleteConfirm />}

                    {type === 'feature-request-success' && <FeatureRequestSuccess />}
                </ModalContainer>
            }
        </div>
    );
}

export default Settings;
