import { createSlice } from '@reduxjs/toolkit';

const initialStateValue = {
    settingsSection: null,
    modal: {
        type: '',
        open: false,
    },
    selectedAddressId: null,
}

const settingsSlice = createSlice({
    name: 'settings',
    initialState: initialStateValue,
    reducers: {
        setSettingsSection: (state, action) => {
            state.settingsSection = action.payload;
        },
        openModal: (state, action) => {
            state.modal.type = action.payload;
            state.modal.open = true;
        },
        closeModal: (state, _) => {
            state.modal = initialStateValue.modal;
        },
        setAddressId: (state, action) => {
            state.selectedAddressId = action.payload;
        }
    },
});

export const {
    setSettingsSection,
    openModal,
    closeModal,
    setAddressId,
} = settingsSlice.actions;

export default settingsSlice.reducer;
