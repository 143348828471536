import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOutsideClickHandler } from '../../../Hooks/useOutsideClick';
import { closeSidebar, openSidebar } from '../../../Features/appSlice';
import Cross from '../../../Assets/Cross.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatBadge, getCachedMediaUrl, getInitials } from '../../../lib/utils/utils';
import EliteBadge from '../../../Assets/EliteBadge.svg';
import Triangle from '../../../Assets/Triangle.svg';
import { setSettingsSection } from '../../../Features/settingsSlice';

const links = [
    { name: 'Home', link: '/' },
    // { name: 'Stores', link: '/stores' },
    // { name: 'Lens', link: '/lens' },
    { name: 'DropTrack', link: '/droptrack' },
    // { name: 'Offer Zone', link: '/offer-zone' },
];

const profileLinks = [
    { title: 'Hyprcom wallet', link: 'hyprcom-wallet' },
    { title: 'Refer & Earn', link: 'refer-and-earn' },
    { title: 'Settings', link: 'settings' },
];

function UserMenu() {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const { user } = useSelector((state) => state.user.user);
    const { open } = useSelector((state) => state.app.sidebar);

    const [accountDropdownOpen, setAccountDropdownOpen] = useState(false);

    function handleAccountDropdownClick() {
        setAccountDropdownOpen(!accountDropdownOpen);
    }

    const containerRef = useRef(null);

    useOutsideClickHandler(containerRef, () => {
        setUserMenuOpen(false);
        setTimeout(() => {
            dispatch(closeSidebar());
        }, 250);
    });

    const [userMenuOpen, setUserMenuOpen] = useState(false);

    function closeSidebarClick() {
        setUserMenuOpen(false);
        setTimeout(() => {
            dispatch(closeSidebar());
        }, 250);
    }

    useEffect(() => {
        if(open) {
            setUserMenuOpen(open);
        }
    }, [open]);

    function handleLinkClick(link) {
        navigate(link);
        closeSidebarClick();
    }

    function handleMyAccountClick() {
        closeSidebarClick();
        navigate('user/me');
    }

    function handleHyprcomMoneyClick() {
        closeSidebarClick();
        dispatch(setSettingsSection('HYPRCOM_MONEY'));
        navigate('/settings');
    }

    function handleMyOrdersClick() {
        setUserMenuOpen(false);
        dispatch(openSidebar('my-orders'));
    }

    function handleReferClick() {
        closeSidebarClick();
        dispatch(setSettingsSection('REFER_AND_EARN'));
        navigate('/settings');
    }

    function handleSettingsClick() {
        closeSidebarClick();
        navigate('/settings');
    }

    function handleLogOut() {
        closeSidebarClick();
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');

        if (location.pathname !== '/') {
            navigate('/');
        }
        window.location.reload();
    }

    return (
        <div ref={containerRef} className={`hidden max-[810px]:flex absolute transition-all duration-500 delay-0 ease-in-out top-0 ${userMenuOpen ? 'right-0' : 'max-[810px]:-right-1/2 max-[426px]:-right-[80%]'} h-full max-[810px]:w-1/2 max-[426px]:w-4/5 bg-[#FFFFFF] flex flex-col gap-2 p-4`}>
            <button onClick={closeSidebarClick} className="size-8 centered cursor-pointer">
                <img src={Cross} alt="" />
            </button>

            {
                Object.keys(user).length > 0 ? 
                <div className="flex flex-col">
                    <div className={`${user?.subscriber === 'NORMAL' ? 'pb-1' : 'pb-3'} border-b border-[#E3E3E3] flex items-center gap-1`}>
                        <div className="size-[50px] rounded-full bg-[#E3E3E3] centered relative">
                            {
                                user?.user?.profilePicUrl ? 
                                <img className='size-full rounded-full object-cover' src={getCachedMediaUrl(user?.user?.profilePicUrl)} alt={user?.user?.displayname} /> : 
                                <p className='font-bold text-[16px] leading-[22px] text-[#666666]'>{getInitials(user?.user?.fullname)}</p>
                            }

                            {
                                user?.subscriber === 'NORMAL' ? 
                                null : 
                                <div className="size-5 rounded-full bg-[#FFFFFF] absolute -bottom-[10px]">
                                    <img className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2' src={EliteBadge} alt="" />
                                </div>
                            }
                        </div>

                        <div className="w-[calc(100%-50px-14px)] flex flex-col">
                            <h1 className='text-label1'>{user?.user?.fullname}</h1>

                            {
                                user?.achieve && 
                                <p className='text-body2 text-[#666666]'>{formatBadge(user?.achieve?.achievement)}</p>
                            }
                        </div>

                        <button onClick={handleAccountDropdownClick} className="w-[14px] border-none outline-none">
                            <img className={`transition-all delay-50 ease-linear ${accountDropdownOpen && '-rotate-180'}`} src={Triangle} alt="" />
                        </button>
                    </div>

                    {
                        accountDropdownOpen && 
                        <ul className="mt-2 flex flex-col gap-1 text-label1 text-[#666666]">
                            <li className='pb-1 border-b border-[#E3E3E3]'>
                                <button onClick={handleMyAccountClick}>My Account</button>
                            </li>
                            <li className='pb-1 border-b border-[#E3E3E3]'>
                                <button onClick={handleMyOrdersClick}>My Orders</button>
                            </li>
                            <li className='pb-1 border-b border-[#E3E3E3]'>
                                <button onClick={handleHyprcomMoneyClick}>Hyprcom Money</button>
                            </li>
                            <li className='pb-1 border-b border-[#E3E3E3]'>
                                <button onClick={handleReferClick}>Refer & Earn</button>
                            </li>
                            <li className='pb-1 border-b border-[#E3E3E3]'>
                                <button onClick={handleSettingsClick}>Settings</button>
                            </li>
                            <li>
                                <button className='text-[#EF4444]' onClick={handleLogOut}>Log-out</button>
                            </li>
                        </ul>
                    }
                </div> : 
                <div className="flex items-center gap-2 mt-2">
                    <button onClick={() => handleLinkClick('/auth/login')} className='w-1/2 px-4 py-2 rounded-lg bg-main hover:bg-mainHover text-label1 text-[#000000]'>Login / Register</button>

                    {/* <a href='https://seller.hyprcom.in' target='_blank' rel='noreferrer' className='text-center w-1/2 border-2 border-main px-4 py-[6.5px] rounded-lg bg-[#FFFFFF] hover:bg-main text-label1 text-[#000000]'>Login as Seller</a> */}
                </div>
            }

            <div className="flex flex-col">
                {links.map((link, index) => {
                    let isActive;
                    let isLast;
                    if (
                        link.link.length === 1 &&
                        location.pathname.length > 1
                    ) {
                        isActive = false;
                    }
                    else {
                        isActive = location.pathname.includes(link.link);
                    }

                    if(index === (links.length-1)) {
                        isLast = true;
                    }

                    return (
                        <button key={link.link} onClick={() => handleLinkClick(link.link)} className={`px-2 py-1 text-left border-b text-label1 ${isActive ? 'text-main' : 'text-[#666666]'} ${isLast ? 'border-transparent' : 'border-[#E3E3E3]'}`}>{link.name}</button>
                    );
                })}
            </div>
        </div>
    );
}

export default UserMenu;
