import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedVariant } from '../../../../Features/appSlice';

function SizeButton({ size }) {
    const dispatch = useDispatch();
    const selectedVariant = useSelector((state) => state.app.selectedVariant);

    function handleSizeClick(size) {
        if(selectedVariant.size !== size) {
            dispatch(setSelectedVariant({
                ...selectedVariant,
                size: size,
            }));
        }
    }

    return (
        <button onClick={() => handleSizeClick(size)} className={`outline-none p-2 min-w-[44px] h-11 centered rounded-[56px] ${selectedVariant.size === size ? 'bg-main hover:bg-mainHover' : 'bg-[#F3F3F3]'}`}>
            <p className='font-semibold text-[14px] leading-[26px] text-[#000000]'>{size}</p>
        </button>
    );
}

export default SizeButton;
