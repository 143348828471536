import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ImageCard from './ImageCard';
import { getCachedMediaUrl } from '../../../../lib/utils/utils';
import api from '../../../../Auth/Interceptor';
import { changeBagQuantity, openSidebar, setDroptrackVariant } from '../../../../Features/appSlice';
import { fetchCart } from '../../../../Features/cartSlice';
import { addProductToCart } from '../../../../lib/utils/apiCalls';
import { showToast } from '../../../../Components/Toaster/Toaster';

function ImageSection() {
    const dispatch = useDispatch();
    const { productId } = useParams();

    const {
        quantity: stock,
        variantId,
        droptrackImages,
        activeImageUrl
    } = useSelector((state) => state.app.droptrackProductVariant);

    const droptrackBagQuantity = useSelector((state) => state.app.droptrackBagQuantity);

    const mediaContainerRef = useRef(null);
    const listContainerRef = useRef(null);

    const [mediaContainerHeight, setMediaContainerHeight] = useState(0);
    const [listContainerWidth, setListContainerWidth] = useState(0);

    useEffect(() => {
        const updateHeight = () => {
            if (mediaContainerRef.current) {
                setMediaContainerHeight((1296 / 1080) * mediaContainerRef.current.clientWidth);
            }
        };

        const resizeObserver = new ResizeObserver(() => {
            updateHeight();
        });

        if (mediaContainerRef.current) {
            resizeObserver.observe(mediaContainerRef.current);
        }

        updateHeight();

        return () => {
            resizeObserver.disconnect();
        };
    }, []);
    
    useEffect(() => {
        const updateWidth = () => {
            if (listContainerRef.current) {
                setListContainerWidth((1080 / 1296) * ((listContainerRef.current.clientHeight - 12*4)/5));
            }
        };

        const resizeObserver = new ResizeObserver(() => {
            updateWidth();
        });

        if (listContainerRef.current) {
            resizeObserver.observe(listContainerRef.current);
        }

        updateWidth();

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    useEffect(() => {
        async function fetchProductDetails(productId) {
            try {
                const response = await api.get(`product/${productId}`);
                if(response.status === 200) {

                    // console.log(response.data.data);
                    const variantObject = response.data.data.varient.find(item => item.id === productId);

                    const object = {
                        sellingPrice: variantObject.VarientPrice,
                        MRP: variantObject.VarientMrp,
                        quantity: variantObject.VarientQuantity,
                        variantId: variantObject.id,
                        droptrackImages: variantObject.VarientImgUrl,
                        activeImageUrl: variantObject.VarientImgUrl[0],
                        product: { ...response.data.data.product, storeName: response.data.data.storeName},
                    }

                    dispatch(setDroptrackVariant(object));
                }
            }
            catch(err) {
                console.log(err);
            }
        }

        productId && fetchProductDetails(productId);
    }, [productId]);

    async function handleAddToCart() {
        if(stock > 0) {
            if(droptrackBagQuantity > 0) {
                try {
                    const callBody = {
                        productId: productId,
                        varaint: variantId,
                        quantity: droptrackBagQuantity,
                        reference: false,
                    }
        
                    const response = await addProductToCart(callBody);
                    if(response.status === 200) {
                        dispatch(fetchCart());
                        dispatch(changeBagQuantity(1));
                        dispatch(openSidebar('my-cart'));
                    }
                }
                catch(err) {
                    console.log(err);
                }
            }
            else {
                showToast('Add quantity first', 'warning');
            }
        }
        else {
            showToast('Variant out of stock', 'warning');
        }
    }

    return (
        <div className="w-1/2 max-[426px]:hidden flex gap-3 justify-between">
            <div ref={listContainerRef} className="w-[15%] max-[1280px]:w-1/5 flex flex-col gap-3" style={{ maxHeight: `${mediaContainerHeight}px`, width: `${listContainerWidth}px` }}>
                {droptrackImages?.map(image => {
                    const isActive = image === activeImageUrl

                    return (
                        <ImageCard 
                            key={image} 
                            image={image} 
                            isActive={isActive} 
                        />
                    );
                })}
            </div>

            <div className="flex flex-col gap-6 w-[85%] max-[1280px]:w-4/5">
                <div ref={mediaContainerRef} className="w-full centered" style={{ height: `${mediaContainerHeight}px` }}>
                    <img className='max-w-full max-h-full' src={getCachedMediaUrl(activeImageUrl)} alt="" />
                </div>

                <button onClick={handleAddToCart} className="w-full hover-transition px-8 py-4 bg-main hover:bg-mainHover rounded-lg text-heading2 max-[1024px]:text-[16px] text-[#000000]">
                    Add To Bag
                </button>
            </div>
        </div>
    );
}

export default ImageSection;
