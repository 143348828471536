import React from 'react';

import FashionApparels from '../../Assets/Categories/FashionApparels.jpeg';
import BeautyAndPersonalCare from '../../Assets/Categories/BeautyAndPersonalCare.jpeg';
import BabyAndKids from '../../Assets/Categories/BabyAndKids.jpeg';
import Watches from '../../Assets/Categories/Watches.jpeg';
import Bags from '../../Assets/Categories/Bags.jpeg';
import Jewelleries from '../../Assets/Categories/Jewelleries.jpeg';

function StoreCategories({ activeCategory, updateActiveCategory }) {
    return (
        <div className="select-none h-20 max-[1080px]:h-[72px] max-[1024px]:h-14 w-full bg-[#FFFFFF] border-t border-[#FFFFFF] centered">
            <div className="flex items-center gap-2 text-[#000000] text-title3 max-[1200px]:text-body1 max-[1080px]:text-body2 max-[1024px]:w-full max-[1024px]:overflow-x-scroll max-[1024px]:scrollbar-hide max-[1024px]:px-4">
                <button onClick={() => updateActiveCategory('fashion')} className={`hover-transition outline-none max-[1024px]:flex-shrink-0 py-1 pl-2 pr-2 flex items-center gap-2 w-fit rounded-lg cursor-pointer hover:bg-[#E3E3E3] ${activeCategory === 'fashion' && 'border-b-2 border-main bg-[#E3E3E3]'}`}>
                    <div className="size-12 max-[1200px]:size-10 max-[1024px]:size-8 centered rounded-md overflow-hidden bg-[#E3E3E3] flex-shrink-0">
                        <img className='size-full object-cover' src={FashionApparels} alt="" />
                    </div>

                    <p>Fashion & Accessories</p>
                </button>

                <div className="h-8 w-[1px] bg-[#BFBFBF] max-[1024px]:flex-shrink-0"></div>

                <button onClick={() => updateActiveCategory('personal-care')} className={`hover-transition outline-none max-[1024px]:flex-shrink-0 py-1 pl-2 pr-2 flex items-center gap-2 w-fit rounded-lg cursor-pointer hover:bg-[#E3E3E3] ${activeCategory === 'personal-care' && 'border-b-2 border-main bg-[#E3E3E3]'}`}>
                    <div className="size-12 max-[1200px]:size-10 max-[1024px]:size-8 centered rounded-md overflow-hidden bg-[#E3E3E3] flex-shrink-0">
                        <img className='size-full object-cover' src={BeautyAndPersonalCare} alt="" />
                    </div>

                    <p>Beauty & Personal care</p>
                </button>

                <div className="h-8 w-[1px] bg-[#BFBFBF] max-[1024px]:flex-shrink-0"></div>

                <button onClick={() => updateActiveCategory('kids')} className={`hover-transition outline-none max-[1024px]:flex-shrink-0 py-1 pl-2 pr-2 flex items-center gap-2 w-fit rounded-lg cursor-pointer hover:bg-[#E3E3E3] ${activeCategory === 'kids' && 'border-b-2 border-main bg-[#E3E3E3]'}`}>
                    <div className="size-12 max-[1200px]:size-10 max-[1024px]:size-8 centered rounded-md overflow-hidden bg-[#E3E3E3] flex-shrink-0">
                        <img className='size-full object-cover' src={BabyAndKids} alt="" />
                    </div>

                    <p>Baby & Kids</p>
                </button>

                <div className="h-8 w-[1px] bg-[#BFBFBF] max-[1024px]:flex-shrink-0"></div>

                <button onClick={() => updateActiveCategory('watches')} className={`hover-transition outline-none max-[1024px]:flex-shrink-0 py-1 pl-2 pr-2 flex items-center gap-2 w-fit rounded-lg cursor-pointer hover:bg-[#E3E3E3] ${activeCategory === 'watches' && 'border-b-2 border-main bg-[#E3E3E3]'}`}>
                    <div className="size-12 max-[1200px]:size-10 max-[1024px]:size-8 centered rounded-md overflow-hidden bg-[#E3E3E3] flex-shrink-0">
                        <img className='size-full object-cover' src={Watches} alt="" />
                    </div>

                    <p>Watches</p>
                </button>

                <div className="h-8 w-[1px] bg-[#BFBFBF] max-[1024px]:flex-shrink-0"></div>

                <button onClick={() => updateActiveCategory('bags')} className={`hover-transition outline-none max-[1024px]:flex-shrink-0 py-1 pl-2 pr-2 flex items-center gap-2 w-fit rounded-lg cursor-pointer hover:bg-[#E3E3E3] ${activeCategory === 'bags' && 'border-b-2 border-main bg-[#E3E3E3]'}`}>
                    <div className="size-12 max-[1200px]:size-10 max-[1024px]:size-8 centered rounded-md overflow-hidden bg-[#E3E3E3] flex-shrink-0">
                        <img className='size-full object-cover' src={Bags} alt="" />
                    </div>

                    <p>Bags</p>
                </button>

                <div className="h-8 w-[1px] bg-[#BFBFBF] max-[1024px]:flex-shrink-0"></div>

                <button onClick={() => updateActiveCategory('jewellery')} className={`hover-transition outline-none max-[1024px]:flex-shrink-0 py-1 pl-2 pr-2 flex items-center gap-2 w-fit rounded-lg cursor-pointer hover:bg-[#E3E3E3] ${activeCategory === 'jewellery' && 'border-b-2 border-main bg-[#E3E3E3]'}`}>
                    <div className="size-12 max-[1200px]:size-10 max-[1024px]:size-8 centered rounded-md overflow-hidden bg-[#E3E3E3] flex-shrink-0">
                        <img className='size-full object-cover' src={Jewelleries} alt="" />
                    </div>

                    <p>Jewellery</p>
                </button>
            </div>
        </div>
    );
}

export default StoreCategories;
