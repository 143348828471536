import React, { useState } from 'react';
import ReferImage from '../../../../Assets/Settings/ReferImage.svg';
import WhatsApp from '../../../../Assets/Settings/WhatsApp.svg';
import { setSettingsSection } from '../../../../Features/settingsSlice';
import { useDispatch } from 'react-redux';
import ArrowLeft from '../../../../Assets/ArrowLeft.svg';

function ReferAndEarn() {
    const dispatch = useDispatch();

    function handleBack() {
        dispatch(setSettingsSection(null));
    }

    const [section, setSection] = useState('INVITE');

    function handleCopyLink() {}

    function handleInviteViaWhatsApp() {}

    return (
        <div className={`w-full h-fit border border-[#BFBFBF] rounded-lg p-4 mt-6 flex flex-col gap-4 max-[768px]:rounded-none max-[768px]:border-none max-[768px]:mt-0`}>
            <div className="flex items-center gap-2">
                <button onClick={handleBack} className="size-6 max-[768px]:flex items-center justify-center outline-none hidden">
                    <img src={ArrowLeft} alt="" />
                </button>

                <h1 className='text-title1 text-[#000000]'>Refer & Earn</h1>
            </div>

            <div className="flex max-[768px]:flex-col gap-4">
                <div className="w-2/5 max-[768px]:w-full">
                    <img className='w-full h-auto' src={ReferImage} alt="" />
                </div>

                <div className="w-3/5 max-[768px]:w-full flex flex-col items-center gap-4">
                    <div className="max-[768px]:w-full flex items-center text-title3">
                        <button onClick={() => setSection('INVITE')} className={`w-40 max-[768px]:w-1/2 outline-none border-b-2 py-1 ${section === 'INVITE' ? 'border-main text-[#000000]' : 'border-transparent text-[#666666]'}`}>Send Invite</button>

                        <button onClick={() => setSection('STATUS')} className={`w-40 max-[768px]:w-1/2 outline-none border-b-2 py-1 ${section === 'STATUS' ? 'border-main text-[#000000]' : 'border-transparent text-[#666666]'}`}>Referral Status</button>
                    </div>

                    {
                        section === 'INVITE' && 
                        <>
                            <div className="w-full flex flex-col gap-4 border border-[#BFBFBF] p-4 rounded-md">
                                <div className="flex flex-col gap-1">
                                    <h1 className='text-heading3 text-[#000000]'>Invite your friends</h1>

                                    <p className='text-body1 text-[#666666]'>Upon successful delivery of your referred friend's first order, you will receive a reward of up to ₹500.</p>
                                </div>

                                <div className="flex items-center gap-3">
                                    <button onClick={handleCopyLink} className='outline-none px-3 py-2 bg-[#E3E3E3] rounded-md font-bold text-[16px] leading-7 text-[#000000] text-nowrap'>Copy Link</button>

                                    <button onClick={handleInviteViaWhatsApp} className='outline-none px-3 py-2 bg-main hover:bg-mainHover rounded-md centered gap-[10px] flex-grow'>
                                        <div className="size-[25px] centered">
                                            <img src={WhatsApp} alt="" />
                                        </div>

                                        <p className='font-bold text-[16px] leading-7 text-[#000000] text-nowrap'>Invite via whatsapp</p>
                                    </button>
                                </div>
                            </div>

                            <div className="w-full flex flex-col gap-1 border border-[#BFBFBF] p-4 rounded-md">
                                <h1 className='text-heading3 text-[#000000]'>How Does it work?</h1>

                                <ol type='1' className='list-decimal px-4 text-body1 text-[#666666]'>
                                    <li>Invite your friends to register on Hyprcom through WhatsApp or sharing your referral link.</li>

                                    <li>Your referral should order and get delivered the first product.</li>

                                    <li>Your referral money will be credited to your account within 2 business days.</li>
                                </ol>
                            </div>
                        </>
                    }

                    {
                        section === 'STATUS' && 
                        <div className="flex flex-col gap-3"></div>
                    }
                </div>
            </div>
        </div>
    );
}

export default ReferAndEarn;
