import React, { useRef } from 'react';
import Cross from '../../../../../Assets/Cross.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useOutsideClickHandler } from '../../../../../Hooks/useOutsideClick';
import { closeModal } from '../../../../../Features/profileSlice';
import { formatBadge, getCachedMediaUrl } from '../../../../../lib/utils/utils';
import { getMilestoneImage } from '../../../../../Components/MilestoneImage/MilestoneImage';

function Milestone() {
    const dispatch = useDispatch();

    const containerRef = useRef(null);

    const { profile } = useSelector((state) => state.profile.profile);

    useOutsideClickHandler(containerRef, () => {
        dispatch(closeModal());
    });
        
    function handleCloseModal() {
        dispatch(closeModal());
    }

    return (
        <div ref={containerRef} className='w-[821px] bg-[#FFFFFF] pb-4 rounded-lg overflow-hidden border border-[#BFBFBF] flex items-center gap-4 relative'>
            <button onClick={handleCloseModal} className="z-10 absolute top-4 right-4 size-8 rounded-full bg-[#FFFFFF] outline-none centered">
                <img src={Cross} alt="" />
            </button>

            <div className="w-[45%] py-4 flex flex-col items-center gap-4">
                <div className="flex flex-col items-center gap-2 px-4">
                    <h1 className='text-title1 text-[#000000]'>{profile?.achieve ? formatBadge(profile?.achieve?.achievement) : formatBadge(profile.user.badge || 'No Review')}</h1>

                    <button className='px-6 py-1 rounded-full bg-[#AFB7FF] text-label1 text-[#121212] outline-none'>First review</button>

                    <p className='text-body1 text-[#666666] text-center'>Welcome to the community! Your first reviews make you a Beginner Reviewer. Keep sharing your thoughts and experiences to help others make informed choices.</p>
                </div>

                <div className=""></div>
            </div>

            <div className="w-[55%] aspect-square">
                <div className={`w-full h-full rounded-bl-full rounded-br-full overflow-hidden centered ${profile.hasOwnProperty('achieve') ? (profile.achieve ? '' : 'bg-main centered h-[253px]') : ''}`}>
                    {
                        profile.hasOwnProperty('achieve') ? 
                        (
                            profile.achieve ? 
                            <img className="w-full h-auto" src={getCachedMediaUrl(profile.achieve.coverImgUrl)} alt="" /> : 
                            <img className="w-full h-auto blur-sm" src={getMilestoneImage('RISING_CRITIC')} alt="" />
                        ) : 
                        (
                            profile?.user?.badge ? 
                            <img className={`w-full h-auto ${profile.user.badge === 'No Review' && 'blur-sm'}`} src={getMilestoneImage(profile.user.badge)} alt="" /> : 
                            <img className="w-full h-auto blur-sm" src={getMilestoneImage('RISING_CRITIC')} alt="" />
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default Milestone;
