import React from 'react';
import OfferCard from './OfferCard';

function PersonalizedOffers({ offersData }) {
    return (
        <div className='flex flex-col gap-4'>
            <h1 className='text-title2 text-[#666666] px-4'>{offersData?.name}</h1>

            <div className="w-full flex gap-2 px-4 overflow-x-auto scrollbar-hide">
                {offersData?.offersList.map(offer => {
                    return (
                        <OfferCard 
                            key={offer.id} 
                            offer={offer} 
                        />
                    )
                })}
            </div>
        </div>
    );
}

export default PersonalizedOffers;
