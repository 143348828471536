import React from 'react';
import AddressBox from './AddressBox';

function AddressDetails() {
    return (
        <div className="px-6 flex flex-col gap-2">
            <h1 className='font-semibold text-[20px] leading-8 text-[#666666]'>Order Details</h1>

            <AddressBox 
                type={'Shipping address'} 
            />

            <AddressBox 
                type={'Billing address'} 
            />
        </div>
    );
}

export default AddressDetails;
