import React from 'react';
import Chevron from '../../../../Assets/ChevronGray.svg';
import ArrowLeft from '../../../../Assets/ArrowLeft.svg';
import { setSettingsSection } from '../../../../Features/settingsSlice';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

function OptionLink({ link, title }) {
    return (
        <Link to={`/${link}`} className='py-2 border-b border-[#BFBFBF] flex items-center justify-between'>
            <p>{title}</p>

            <div className="size-6 centered">
                <img className='-rotate-90' src={Chevron} alt="" />
            </div>
        </Link>
    )
}

function AboutHyprcom() {
    const dispatch = useDispatch();

    function handleBack() {
        dispatch(setSettingsSection(null));
    }

    return (
        <div className={`w-full h-fit border border-[#BFBFBF] rounded-lg p-4 mt-6 flex flex-col gap-2 max-[768px]:rounded-none max-[768px]:border-none max-[768px]:mt-0`}>
            <div className="flex items-center gap-2">
                <button onClick={handleBack} className="size-6 max-[768px]:flex items-center justify-center outline-none hidden">
                    <img src={ArrowLeft} alt="" />
                </button>

                <h1 className='text-title1 text-[#000000]'>About</h1>
            </div>

            <div className="flex flex-col gap-2 text-title2 text-[#666666]">
                {/* <div className='py-2 border-b border-[#BFBFBF] flex items-center justify-between'>
                    <p>Dark theme</p>

                    <div className="w-[51px] h-[31px] rounded-3xl bg-main relative">
                        <div className="absolute size-[27px] rounded-full top-[2px] right-[2px] bg-[#FFFFFF] shadow-[0_3px_8px_0_#00000026]"></div>
                    </div>
                </div> */}

                {/* <OptionLink 
                    link='about-hyprcom' 
                    title='About Hyprcom'
                />

                <OptionLink 
                    link='eula' 
                    title='End User License Agreement &#40;EULA&#41;'
                /> */}

                <OptionLink 
                    link='privacy-policy' 
                    title='Privacy Policy'
                />

                <OptionLink 
                    link='terms-and-conditions' 
                    title='Terms & Conditions'
                />
            </div>
        </div>
    );
}

export default AboutHyprcom;
