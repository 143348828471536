import React, { useEffect, useState } from 'react';
import { formatBadge, getCachedMediaUrl, getInitials, getMilestoneBackground } from '../../../lib/utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfile, openModal } from '../../../Features/profileSlice';
import { useParams } from 'react-router-dom';
import { followUser, getUserFollowStatus, unfollowUser } from '../../../lib/utils/apiCalls';

function UserCard() {
    const dispatch = useDispatch();
    const { userId } = useParams();

    const { profile } = useSelector((state) => state.profile.profile);
    const { user } = useSelector((state) => state.user.user);

    const [following, setFollowing] = useState(false);

    const defaultUserInfo = {
        picUrl: '',
        name: '',
        badge: '',
        noOfReviews: '',
        noOfPosts: ''
    }

    const [userInfo, setUserInfo] = useState(defaultUserInfo);

    function handleOpenProfileEditForm() {
        dispatch(openModal('profile-edit'));
    }

    function handleOpenFollowingList() {
        dispatch(openModal('following-list'));
    }

    function handleOpenFollowersList() {
        dispatch(openModal('followers-list'));
    }

    async function fetchFollowStatus(id) {
        try {
            const response = await getUserFollowStatus(id);
            if(response.status === 200) {
                setFollowing(response.data.data.checkForFollowed);
            }
        }
        catch(err) {
            console.log(err);
        }
    }

    useEffect(() => {
        if(userId === 'me') {
            setUserInfo({
                picUrl: profile?.user?.profilePicUrl,
                name: profile?.user?.fullname,
                badge: profile?.achieve?.achievement || '',
                noOfReviews: profile?.review?.length,
                noOfPosts: profile?.post?.length
            });
        }
        else {
            setUserInfo({
                picUrl: profile?.user?.profilePic,
                name: profile?.user?.name,
                badge: profile?.user?.badge || '',
                noOfReviews: profile?.noOfReviews,
                noOfPosts: profile?.noOfPosts
            });

            if(Object.keys(user).length > 0) {
                fetchFollowStatus(userId);
            }
        }
    }, [userId, profile, user]);

    async function handleFollowButtonClick() {
        if(following) {
            try {
                const response = await unfollowUser(userId);
                if(response.status === 200) {
                    setFollowing(false);
                    dispatch(fetchProfile(userId));
                }
            }
            catch(err) {
                console.log(err);
            }
        }
        else {
            try {
                const callBody = {
                    followTo: userId,
                }

                const response = await followUser(callBody);
                if(response.status === 200) {
                    setFollowing(true);
                    dispatch(fetchProfile(userId));
                }
            }
            catch(err) {
                console.log(err);
            }
        }
    }

    return (
        <div className="w-full border border-[#E3E3E3] rounded-lg p-4 flex flex-col gap-2 shadow-[0_0_6px_0_#0000001F]">
            <div className="flex items-center gap-2">
                <div className="size-[100px] rounded-full overflow-hidden bg-[#E3E3E3] centered relative">
                    {
                        userInfo?.picUrl ? 
                        <img className='size-full object-cover' src={getCachedMediaUrl(userInfo?.picUrl || '')} alt="" /> : 
                        <h1 className='text-display text-[#666666]'>{getInitials(userInfo?.name || '')}</h1>
                    }
                </div>

                <div className="flex flex-col gap-1">
                    <h1 className='text-title1 text-[#000000]'>{userInfo?.name || ''}</h1>

                    <div className={`px-4 py-[6px] rounded-full w-fit ${getMilestoneBackground(userInfo?.badge || '')}`}>
                        <p className='font-semibold text-[12px] leading-5'>{formatBadge(userInfo?.badge || '')}</p>
                    </div>
                </div>
            </div>

            <div className="flex items-center gap-3 text-[#666666]">
                <button onClick={handleOpenFollowersList} className="flex items-center gap-1 cursor-pointer outline-none">
                    <p className='text-label1'>{profile?.followers?.length}</p>

                    <p className='text-body2'>Followers</p>
                </button>

                <button onClick={handleOpenFollowingList} className="flex items-center gap-1 cursor-pointer outline-none">
                    <p className='text-label1'>{profile?.follows?.length}</p>

                    <p className='text-body2'>Following</p>
                </button>

                <div className="flex items-center gap-1">
                    <p className='text-label1'>{userInfo?.noOfReviews || 0}</p>

                    <p className='text-body2'>Reviews</p>
                </div>

                <div className="flex items-center gap-1">
                    <p className='text-label1'>{userInfo?.noOfPosts || 0}</p>

                    <p className='text-body2'>Posts</p>
                </div>
            </div>

            {
                userId === 'me' && 
                <button onClick={handleOpenProfileEditForm} className='outline-none px-4 py-1 bg-[#BFBFBF] rounded-full w-fit text-label1 text-[#000000]'>Edit profile</button>
            }

            {
                userId !== 'me' && 
                <button onClick={handleFollowButtonClick} className={`hover-transition ${following ? 'border-2 border-[#BFBFBF] bg-[#F3F3F3] text-[#666666]' : 'bg-main hover:bg-mainHover text-[#000000]'} w-fit px-4 py-1 rounded-full text-label1`}>{following ? 'Following' : 'Follow'}</button>
            }
        </div>
    );
}

export default UserCard;
