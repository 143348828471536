import axios from "axios";
import api from "../../Auth/Interceptor";

const ACCESS_TOKEN = localStorage.getItem("accessToken");

const headers = {
    Authorization: `Bearer ${ACCESS_TOKEN}`,
};

async function getUserProfile(id) {
    try {
        const response = await api.get(`user/profile/${id}`);

        if (!response || response.status !== 200) {
            throw new Error('Failed to get user profile.');
        }
        return response;
    }
    catch (err) {
        throw err;
    }
}

async function addProductToWishlist(callBody) {
    try {
        const response = await api.post('wishlist', callBody);

        if (!response || response.status !== 200) {
            throw new Error('Failed to add product to wishlist.');
        }
        return response;
    }
    catch (err) {
        throw err;
    }
}

async function removeProductFromWishlist(productId, varientId) {
    try {
        const response = await api.delete(`wishlist?productId=${productId}&varientId=${varientId}`);

        if (!response || response.status !== 200) {
            throw new Error('Failed to remove product from wishlist.');
        }
        return response;
    }
    catch (err) {
        throw err;
    }
}

async function checkProductWishlistStatus(productId, varientId) {
    try {
        const response = await api.get(`wishlist/check?productId=${productId}&varientId=${varientId}`);

        if (!response || response.status !== 200) {
            throw new Error('Failed to check product wishlist status.');
        }
        return response;
    }
    catch (err) {
        throw err;
    }
}

async function addProductToCart(callBody) {
    try {
        const response = await api.post('userCart', callBody);

        if (!response || response.status !== 200) {
            throw new Error('Failed to add product to cart.');
        }
        return response;
    }
    catch (err) {
        throw err;
    }
}

async function updateProductQuantityInCart(callBody) {
    try {
        const response = await api.put('userCart', callBody);

        if (!response || response.status !== 200) {
            throw new Error('Failed to update product quaantity in cart.');
        }
        return response;
    }
    catch (err) {
        throw err;
    }
}

async function removeProductFromCart(id, callBody) {
    try {
        const response = await api.delete(`userCart?id=${id}`, { data: callBody});

        if (!response || response.status !== 200) {
            throw new Error('Failed to remove product from cart.');
        }
        return response;
    }
    catch (err) {
        throw err;
    }
}

async function emptyCartItems() {
    try {
        const response = await api.delete('userCart');

        if (!response || response.status !== 200) {
            throw new Error('Failed to empty cart.');
        }
        return response;
    }
    catch (err) {
        throw err;
    }
}

async function createAddress(callBody) {
    try {
        const response = await api.post('address', callBody);

        if (!response || response.status !== 200) {
            throw new Error('Failed to add address.');
        }
        return response;
    }
    catch (err) {
        throw err;
    }
}

async function deleteAddress(id) {
    try {
        const response = await api.delete(`address?id=${id}`, { data: { id: id }});

        if (!response || response.status !== 200) {
            throw new Error(`Failed to delete address with ID = ${id}.`);
        }
        return response;
    }
    catch (err) {
        throw err;
    }
}

async function fetchSellerCoupons(productIds) {
    const extend = productIds.map(id => `productId=${id}`).join('&');
    try {
        const response = await api.get(`product/coupon/product?${extend}`);

        if (!response || response.status !== 200) {
            throw new Error('Failed to fetch seller coupons.');
        }
        return response;
    }
    catch (err) {
        throw err;
    }
}

async function getOtpFromOtpless(callBody) {
    try {
        const response = await api.post('otpless/signOtp', callBody);

        if (!response || response.status !== 200) {
            throw new Error('Failed to send otp request.');
        }
        return response;
    }
    catch (err) {
        throw err;
    }
}

async function getOtpFromOtplessForSignup(callBody) {
    try {
        const response = await api.post('otpless/signUp', callBody);

        if (!response || response.status !== 200) {
            throw new Error('Failed to send otp request.');
        }
        return response;
    }
    catch (err) {
        throw err;
    }
}

async function signinWithOtpFromOtpless(callBody) {
    try {
        const response = await api.put('otpless/verifyOtp', callBody);

        if (!response || response.status !== 200) {
            throw new Error('Failed to sign in with otp.');
        }
        return response;
    }
    catch (err) {
        throw err;
    }
}

async function signupWithOtpFromOtpless(callBody) {
    try {
        const response = await api.put('otpless/signUpVerify', callBody);

        if (!response || response.status !== 200) {
            throw new Error('Failed to sign up with otp.');
        }
        return response;
    }
    catch (err) {
        throw err;
    }
}

async function createOrder(callBody) {
    try {
        const response = await api.post('newOrder/create', callBody);

        if (!response || response.status !== 200) {
            throw new Error('Failed to create new order.');
        }
        return response;
    }
    catch (err) {
        throw err;
    }
}

async function requestFeature(callBody) {
    try {
        const response = await api.post('feature', callBody);

        if (!response || response.status !== 200) {
            throw new Error('Failed to send your message.');
        }
        return response;
    }
    catch (err) {
        throw err;
    }
}

async function checkStoreIsLiked(storeId) {
    try {
        const response = await api.get(`favourite/check?storeId=${storeId}`);

        if (!response || response.status !== 200) {
            throw new Error('Failed to check store like status.');
        }
        return response;
    }
    catch (err) {
        throw err;
    }
}

async function likeStore(callBody) {
    try {
        const response = await api.post('favourite/add', callBody);

        if (!response || response.status !== 200) {
            throw new Error('Failed to add store to liked.');
        }
        return response;
    }
    catch (err) {
        throw err;
    }
}

async function removeStoreLike(storeId) {
    try {
        const response = await api.delete(`favourite/remove?storeId=${storeId}`);

        if (!response || response.status !== 200) {
            throw new Error('Failed to remove store from liked.');
        }
        return response;
    }
    catch (err) {
        throw err;
    }
}

async function searchProducts(storeType, searchTerm) {
    try {
        const response = await api.get(`usersearch/marketplace/${storeType}?q=${searchTerm}`);

        if (!response || response.status !== 200) {
            throw new Error('Failed to find search results.');
        }
        return response;
    }
    catch (err) {
        throw err;
    }
}

async function searchUsers(searchTerm) {
    try {
        const response = await api.get(`userSearch/account?q=${searchTerm}`);

        if (!response || response.status !== 200) {
            throw new Error('Failed to find search results.');
        }
        return response;
    }
    catch (err) {
        throw err;
    }
}

async function addProductToDroptrack(callBody) {
    try {
        const response = await api.post('droptrack', callBody);

        if (!response || response.status !== 200) {
            throw new Error('Failed to add product to droptrack.');
        }
        return response;
    }
    catch (err) {
        throw err;
    }
}

async function checkProductInDroptrack(productId) {
    try {
        const response = await api.get(`droptrack/check/${productId}`);

        if (!response || response.status !== 200) {
            throw new Error('Failed to find product droptrack status.');
        }
        return response;
    }
    catch (err) {
        throw err;
    }
}

async function removeProductFromDroptrack(productId) {
    try {
        const response = await api.delete(`droptrack/delete/${productId}`);

        if (!response || response.status !== 200) {
            throw new Error('Failed to delete product from droptrack.');
        }
        return response;
    }
    catch (err) {
        throw err;
    }
}

async function fetchAreaDetails(areaName) {
    try {
        const response = await axios.get(`https://api.postalpincode.in/postoffice/${areaName}`);

        if (!response || response.status !== 200) {
            throw new Error('Failed to find area details.');
        }
        return response;
    }
    catch (err) {
        throw err;
    }
}

async function addUserPreferences(callBody) {
    try {
        const response = await api.post('onboarding', callBody);

        if (!response || response.status !== 200) {
            throw new Error('Failed to add user preferences.');
        }
        return response;
    }
    catch (err) {
        throw err;
    }
}

// Use axios (for no login)
async function fetchCollection(collectionId) {
    try {
        const response = await axios.get(`${process.env.REACT_APP_HYPRCOM_API}userstore/collection/${collectionId}`);

        if (!response || response.status !== 200) {
            throw new Error('Failed to get collection.');
        }
        return response;
    }
    catch (err) {
        throw err;
    }
}

async function updateProfile(callBody) {
    try {
        const response = await api.put('user/profile', callBody);

        if (!response || response.status !== 200) {
            throw new Error('Failed to update profile.');
        }
        return response;
    }
    catch (err) {
        throw err;
    }
}

async function fetchPreSignedURL(extension, type) {
    try {
        const response = await axios.post(`${process.env.REACT_APP_HYPRCOM_API}upload`, 
            {
                contentType: type,
                ext: extension,
                isPublic: true,
            },
            {
                headers: headers
            }
        );
        return response.data.data;
    }
    catch (error) {
        console.log(error);
        throw error;
    }
}

async function uploadFileToPresignedURL(file, fileUrl) {
    try {
        await fetch(fileUrl, {
            method: "PUT",
            body: file,
        });
    }
    catch (error) {
        throw error;
    }
}

async function getProductDetails(productId) {
    try {
        const response = await api.get(`product/${productId}`);

        if (!response || response.status !== 200) {
            throw new Error('Failed to fetch product details.');
        }
        return response;
    }
    catch (err) {
        throw err;
    }
}

async function getUserFollowStatus(userId) {
    try {
        const response = await api.get(`follow/${userId}`);

        if (!response || response.status !== 200) {
            throw new Error('Failed to fetch user follow status.');
        }
        return response;
    }
    catch (err) {
        throw err;
    }
}

async function followUser(callBody) {
    try {
        const response = await api.post('follow', callBody);

        if (!response || response.status !== 200) {
            throw new Error('Failed to follow user.');
        }
        return response;
    }
    catch (err) {
        throw err;
    }
}

async function unfollowUser(userId) {
    try {
        const response = await api.delete(`follow/${userId}`);

        if (!response || response.status !== 200) {
            throw new Error('Failed to unfollow user.');
        }
        return response;
    }
    catch (err) {
        throw err;
    }
}

async function checkUsernameAvailable(username) {
    try {
        const response = await api.get(`username/${username}/check`);

        if (!response || response.status !== 200) {
            throw new Error('Failed to check username availability.');
        }
        return response;
    }
    catch (err) {
        throw err;
    }
}

export {
    addProductToWishlist,
    removeProductFromWishlist,
    checkProductWishlistStatus,
    addProductToCart,
    updateProductQuantityInCart,
    removeProductFromCart,
    emptyCartItems,
    getUserProfile,
    createAddress,
    deleteAddress,
    fetchSellerCoupons,
    getOtpFromOtpless,
    signinWithOtpFromOtpless,
    createOrder,
    requestFeature,
    checkStoreIsLiked,
    likeStore,
    removeStoreLike,
    searchProducts,
    searchUsers,
    addProductToDroptrack,
    checkProductInDroptrack,
    removeProductFromDroptrack,
    fetchAreaDetails,
    getOtpFromOtplessForSignup,
    signupWithOtpFromOtpless,
    addUserPreferences,
    fetchCollection,
    updateProfile,
    fetchPreSignedURL,
    uploadFileToPresignedURL,
    getProductDetails,
    getUserFollowStatus,
    followUser,
    unfollowUser,
    checkUsernameAvailable,
}
