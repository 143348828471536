import React from 'react';

function AddressBox({ type }) {
    return (
        <div className="flex flex-col gap-1 p-3 bg-[#F3F3F3] rounded-lg">
            <h1 className='font-semibold text-[14px] leading-[26px] text-[#666666]'>{type}</h1>

            <div className="flex flex-col font-normal text-[14px] leading-[26px] text-[#000000]">
                <p>Saiful Ameen</p>

                <p>M137, St.no 25 Amarphali Sapphire,</p>

                <p>Sarojini Nager,</p>

                <p>New Delhi - 110011</p>

                <p>+91 8761239875</p>
            </div>
        </div>
    );
}

export default AddressBox;
