import React, { useEffect, useRef, useState } from 'react';
import ArrowLeft from '../../Assets/ArrowLeft.svg';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import ProductCard from './Components/ProductCard/ProductCard';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStoreCategoryData } from '../../Features/storeDetailsSlice';
import Sort from '../../Assets/Sort.svg';
import Filter from '../../Assets/Filter.svg';
import ProductFilters from './Components/ProductFilters/ProductFilters';
import SortKebab from './Components/SortKebab/SortKebab';
import { useOutsideClickForTwoRef } from '../../Hooks/useOutsideClickForTwoRef';
import SortSlide from './Components/SortSlide/SortSlide';
import { openSidebar } from '../../Features/appSlice';

function sortProducts(products, filter) {
    const sortedProducts = [ ...products ];

    switch(filter) {
        case 'high-to-low':
            sortedProducts.sort((a, b) => b.productMRP - a.productMRP);
            break;
        case 'low-to-high':
            sortedProducts.sort((a, b) => a.productMRP - b.productMRP);
            break;
        // case 'new':
        //     sortedProducts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            // break;
        default:
            break;
    }

    return sortedProducts;
}

function StoreCategory() {
    const { categoryId } = useParams();
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();

    const [kebabVisible, setKebabVisible] = useState(false);
    const [activeCollection, setActiveCollection] = useState('');
    const [collectionProductsList, setCollectionProductsList] = useState([]);

    const kebabButtonRef = useRef(null);
    const kebabRef = useRef(null);

    useOutsideClickForTwoRef(kebabRef, kebabButtonRef, () => {
        setKebabVisible(false);
    });

    const { storeCategory } = useSelector((state) => state.storeDetails.storeCategory);

    useEffect(() => {
        if(categoryId) {
            dispatch(fetchStoreCategoryData(categoryId));
        }
    }, [categoryId]);

    useEffect(() => {
        if(Object.keys(storeCategory).length > 0) {
            if(storeCategory.category.collectionCount > 0) {
                setActiveCollection(storeCategory.collections[0].CollectionName);

                const categorySortType = searchParams.get('sort');

                if(categorySortType) {
                    const sortedProducts = sortProducts(storeCategory.collections[0].product, categorySortType);
                    setCollectionProductsList(sortedProducts);
                }
                else {
                    setCollectionProductsList(storeCategory.collections[0].product);
                }
            }
        }
    }, [storeCategory]);

    function handleCollectionClick(collectionName) {
        if(collectionName !== activeCollection) {
            setActiveCollection(collectionName);
            const selectedCollection = storeCategory.collections.filter(collection => collection.CollectionName === collectionName);
            setCollectionProductsList(selectedCollection[0].product);
        }
    }

    function handleOpenKebab() {
        setKebabVisible(!kebabVisible);
    }

    useEffect(() => {
        const categorySortType = searchParams.get('sort');

        if(categorySortType) {
            const sortedProducts = sortProducts(collectionProductsList, categorySortType);
            setCollectionProductsList(sortedProducts);
        }
    }, [searchParams]);

    function closeKebabHandler() {
        setKebabVisible(false);
    }

    function handleOpenFilterSidebar() {
        dispatch(openSidebar('filters'));
    }

    function goBack() {
        window.history.back();
    };

    return (
        <div className="flex flex-col w-[1536px] max-[1536px]:w-full mx-auto">
            <div className='flex pt-20 max-[810px]:pt-14 min-h-[calc(100vh-73px)] max-[768px]:min-h-[calc(100vh-41px)] max-[426px]:min-h-screen'>
                <ProductFilters />

                <div className="w-3/4 max-[810px]:w-full pr-[50px] max-[1280px]:pr-[35px] max-[810px]:pr-4">
                    <div className="w-full py-6 pl-6 max-[810px]:pl-4 flex flex-col gap-6 max-[426px]:gap-4">
                        <div className="flex flex-col gap-4">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center gap-2">
                                    <div onClick={goBack} className="size-8 max-[426px]:size-6 centered cursor-pointer">
                                        <img src={ArrowLeft} alt="" />
                                    </div>

                                    <h1 className='text-heading2 max-[426px]:text-title2 text-[#000000]'>{storeCategory?.category?.categoryName} &#40;{collectionProductsList.length} result{collectionProductsList.length > 1 && 's'}&#41;</h1>
                                </div>

                                <div className="flex items-center">
                                    <div ref={kebabButtonRef} onClick={handleOpenKebab} className="size-8 max-[426px]:w-10 max-[426px]:h-9 centered cursor-pointer relative">
                                        <img className='size-full max-[810px]:size-[80%]' src={Sort} alt="" />

                                        <SortKebab 
                                            boxRef={kebabRef} 
                                            kebabOpen={kebabVisible} 
                                        />
                                    </div>

                                    <div onClick={handleOpenFilterSidebar} className="hidden max-[810px]:flex size-8 max-[426px]:w-10 max-[426px]:h-9 items-center justify-center cursor-pointer relative">
                                        <img className='w-4 h-auto' src={Filter} alt="" />
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-wrap items-center gap-2">
                                {storeCategory?.collections?.map(collection => {
                                    return (
                                        <button onClick={() => handleCollectionClick(collection.CollectionName)} key={collection.id} className={`outline-none px-4 py-2 max-[810px]:px-2 max-[810px]:py-1 rounded-md ${activeCollection === collection.CollectionName ? 'bg-main hover:bg-mainHover' : 'bg-[#E3E3E3]'}`}>
                                            <p className='text-body1 max-[810px]:text-body3 text-[#000000]'>{collection.CollectionName}</p>
                                        </button>
                                    );
                                })}
                            </div>
                        </div>

                        <div className="grid grid-cols-4 max-[1200px]:grid-cols-3 max-[810px]:grid-cols-2 gap-x-6 max-[810px]:gap-x-4 gap-y-6 max-[810px]:gap-y-4">
                            {collectionProductsList.map(product => {
                                return (
                                    <ProductCard 
                                        key={product.productId} 
                                        product={product} 
                                        productId={product.productId} 
                                        urlPrefix={'../../products/'} 
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>

                <div className={`${kebabVisible ? 'max-[810px]:flex hidden' : 'hidden'} w-screen h-[calc(100vh-56px)] bg-[#00000080] backdrop-blur-sm fixed bottom-0 left-0`}></div>

                <SortSlide 
                    boxRef={kebabRef} 
                    kebabOpen={kebabVisible} 
                    closeKebab={closeKebabHandler}
                />
            </div>
        </div>
    );
}

export default StoreCategory;
