import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import EstimatedPrice from '../../../../Components/EstimatedPrice/EstimatedPrice';

function BillingDetails() {
    const navigate = useNavigate();

    const selectedAddressId = useSelector((state) => state.checkout.selectedAddressId);

    function selectAddressPage() {
        navigate('../payment');
    }

    return (
        <div className="w-2/5 h-fit p-6 bg-[#FFFFFF] border border-[#E3E3E3] shadow-[0_0_8px_0_#0000001F] rounded-2xl flex flex-col gap-4">
            <div className="flex flex-col gap-2">
                <h1 className='font-semibold text-[18px] leading-[30px] text-[#666666]'>Order Details</h1>

                <EstimatedPrice />
            </div>

            <button onClick={selectAddressPage} className={`w-full px-8 py-4 rounded-lg font-semibold text-[20px] leading-8 ${selectedAddressId ? 'text-[#000000] bg-main hover:bg-mainHover' : 'bg-[#F3F3F3] text-[#999999] cursor-not-allowed'}`}>Select Address</button>
        </div>
    )
}

export default BillingDetails;
