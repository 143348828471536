import React from 'react';
import TickMark from '../../../Assets/TickMark.svg';

function DropdownOption({ value, inputOptions }) {
    function handleClick(e) {
        e.stopPropagation();
    };

    return (
        <label onClick={handleClick} htmlFor={inputOptions.id} className="px-3 py-4 flex items-center gap-[10px] cursor-pointer">
            <div className={`size-6 centered border bg-[#F3F3F3] rounded-md ${inputOptions.checked ? 'border-main' : 'border-[#BFBFBF]'}`}>
                <img src={inputOptions.checked ? TickMark : null} alt="" />
            </div>

            <input 
                { ...inputOptions } 
                className='hidden' 
            />

            <p className='w-[calc(100%-24px)] text-left'>{value}</p>
        </label>
    );
}

export default DropdownOption;
