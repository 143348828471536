import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useOutsideClickHandler } from '../../../Hooks/useOutsideClick';
import { closeSizeChart } from '../../../Features/productSlice';
import Cross from '../../../Assets/Cross.svg';

function SizeChart() {
    const dispatch = useDispatch();

    const containerRef = useRef(null);

    useOutsideClickHandler(containerRef, () => {
        dispatch(closeSizeChart());
    });

    function handleCloseSizeChart() {
        dispatch(closeSizeChart());
    }

    return (
        <div ref={containerRef} className='w-[42%] pb-4 rounded-2xl overflow-hidden bg-[#FFFFFF]'>
            <div className="p-4 flex items-center justify-between bg-[#F3F3F3] border-b border-[#BFBFBF]">
                <h1 className='font-semibold text-[20px] leading-8 text-[#000000]'>Size Chart</h1>

                <button onClick={handleCloseSizeChart} className="size-8 centered outline-none">
                    <img src={Cross} alt="" />
                </button>
            </div>
        </div>
    );
}

export default SizeChart;
