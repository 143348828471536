import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchStoreDetails } from '../../Features/storeDetailsSlice';
import { checkStoreIsLiked, likeStore, removeStoreLike } from '../../lib/utils/apiCalls';
import { getCachedMediaUrl } from '../../lib/utils/utils';
import StoreSection from './Components/StoreSection/StoreSection';
import AboutSection from './Components/AboutSection/AboutSection';
import Heart from '../../Assets/Heart.svg';
import HeartRed from '../../Assets/HeartRed.svg';
import { showToast } from '../../Components/Toaster/Toaster';

function TempStorePage() {
    const { storeId } = useParams();
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.user.user);

    const mediaContainerRef = useRef(null);

    const [activeSection, setActiveSection] = useState('store');
    const [liked, setLiked] = useState(false);
    const [mediaContainerHeight, setMediaContainerHeight] = useState(0);

    const { store } = useSelector((state) => state.storeDetails.store);

    useEffect(() => {
        const updateHeight = () => {
            if (mediaContainerRef.current) {
                setMediaContainerHeight(mediaContainerRef.current.clientWidth);
            }
        };

        const resizeObserver = new ResizeObserver(() => {
            updateHeight();
        });

        if (mediaContainerRef.current) {
            resizeObserver.observe(mediaContainerRef.current);
        }

        updateHeight();

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    async function fetchStoreLikedStatus(id) {
        try {
            const response = await checkStoreIsLiked(id);
            if(response.status === 200) {
                setLiked(response.data.data);
            }
        }
        catch(err) {
            console.log(err);
        }
    }

    async function handleStoreLikeClick() {
        if(Object.keys(user).length > 0) {
            if(liked) {
                try {
                    const response = await removeStoreLike(storeId);
                    if(response.status === 200) {
                        setLiked(false);
                    }
                }
                catch(err)
                {
                    console.log(err);
                }
            }
            else {
                try {
                    const callBody = {
                        storeId,
                    }
    
                    const response = await likeStore(callBody);
                    if(response.status === 200) {
                        setLiked(true);
                    }
                }
                catch(err)
                {
                    console.log(err);
                }
            }
        }
        else {
            showToast('Log in to continue', 'warning');
        }
    }

    useEffect(() => {
        if(storeId) {
            dispatch(fetchStoreDetails(storeId));

            if(Object.keys(user).length > 0) {
                fetchStoreLikedStatus(storeId);
            }
        }
    }, [storeId]);

    return (
        <div className='flex flex-col pt-16 max-[810px]:pt-10 max-w-[426px] mx-auto'>
            <div ref={mediaContainerRef} className="relative mt-4 w-full bg-[#FFFFFF]" style={{ height: `${mediaContainerHeight}px` }}>
                <img className='size-full object-cover' src={getCachedMediaUrl(store?.storeFront?.BackGround)} alt="" />

                <div className="absolute size-[100px] rounded-full overflow-hidden -bottom-[50px] left-1/2 -translate-x-1/2 bg-white border border-[#BFBFBF]">
                    <img className='size-full object-cover' src={getCachedMediaUrl(store?.storeFront?.Logo)} alt={store?.storeName} />
                </div>

                <button onClick={handleStoreLikeClick} className="outline-none absolute -bottom-14 right-4 px-[13px] py-[13.5px] bg-[#F5F5F5] rounded-full">
                    <img src={liked ? HeartRed : Heart} alt="" />
                </button>
            </div>

            <div className="w-full flex justify-center items-center gap-1 px-2 mt-20 text-title3">
                <button onClick={() => {setActiveSection('store')}} className={`select-none outline-none w-1/2 hover:bg-[#F5F5F5] py-1 border-b-2 ${activeSection === 'store' ? 'border-main text-[#000000]' : 'border-[#FFFFFF] text-[#666666]'}`}>Store and Deals</button>
                <button onClick={() => {setActiveSection('about')}} className={`select-none outline-none w-1/2 hover:bg-[#F5F5F5] py-1 border-b-2 ${activeSection === 'about' ? 'border-main text-[#000000]' : 'border-[#FFFFFF] text-[#666666]'}`}>About us</button>
            </div>

            {
                activeSection === 'store' ? 
                <StoreSection /> : <AboutSection />
            }

            <div className="w-full h-6"></div>
        </div>
    );
}

export default TempStorePage;
