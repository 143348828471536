import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOutsideClickHandler } from '../../../../../Hooks/useOutsideClick';
import { closeModal } from '../../../../../Features/profileSlice';
import Cross from '../../../../../Assets/Cross.svg';
import UserBar from '../../UserBar/UserBar';

function FollowingList() {
    const dispatch = useDispatch();

    const containerRef = useRef(null);

    const { profile } = useSelector((state) => state.profile.profile);

    useOutsideClickHandler(containerRef, () => {
        dispatch(closeModal());
    });
    
    function handleCloseModal() {
        dispatch(closeModal());
    }

    return (
        <div ref={containerRef} className='w-[500px] rounded-3xl overflow-hidden bg-[#FFFFFF]'>
            <div className="p-6 flex items-center justify-between bg-[#FFFFFF] border border-[#E3E3E3] shadow-[0_0_6px_0_#0000001F]">
                <p className='text-heading1 text-[#000000]'>Following</p>

                <button onClick={handleCloseModal} className="size-10 centered outline-none">
                    <img src={Cross} alt="" />
                </button>
            </div>

            <div className="p-6 pt-4 flex flex-col gap-3 max-h-80 overflow-y-scroll scrollbar-hide">
                {profile?.follows?.map(user => {
                    return (
                        <UserBar 
                            key={user.userId} 
                            user={user} 
                        />
                    )
                })}
            </div>
        </div>
    );
}

export default FollowingList;
