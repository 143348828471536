import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveImageUrl } from '../../../../Features/appSlice';
import { getCachedMediaUrl } from '../../../../lib/utils/utils';

function ImageCard({ image }) {
    const dispatch = useDispatch();

    const images = useSelector((state) => state.app.images);

    function updateImage() {
        dispatch(setActiveImageUrl(image));
    }

    return (
        <div onMouseOver={updateImage} className={`w-full h-[80px] rounded-lg overflow-hidden cursor-pointer border-2 ${image === images.activeImageUrl ? 'border-main' : 'border-transparent'}`}>
            <img className='size-full object-cover hover:opacity-85' src={getCachedMediaUrl(image)} alt="" />
        </div>
    );
}

export default ImageCard;
