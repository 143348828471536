import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDroptrackModal, fetchDroptrackProducts } from '../../../../Features/appSlice';
import { removeProductFromDroptrack } from '../../../../lib/utils/apiCalls';

function DeleteConfirm() {
    const dispatch = useDispatch();

    const { productId } = useSelector((state) => state.app.droptrackModal);

    function handleCancel() {
        dispatch(closeDroptrackModal());
    }

    async function handleRemoveProduct() {
        try {
            const response = await removeProductFromDroptrack(productId);
            if(response.status === 200) {
                dispatch(fetchDroptrackProducts());
                handleCancel();
            }
        }
        catch(err) {
            console.log(err);
        }
    }

    return (
        <div className='w-2/5 p-5 rounded-xl bg-[#FFFFFF] flex flex-col gap-3'>
            <p className='text-heading2 text-[#000000]'>Are you sure you want to Remove this from DropTrack?</p>

            <div className="flex items-center gap-3 ml-auto">
                <button onClick={handleCancel} className='select-none outline-none border border-[#D1D5DB] bg-[#FFFFFF] px-[17px] py-[9px] rounded-md font-medium text-[14px] leading-5 text-[#374151]'>Cancel</button>

                <button onClick={handleRemoveProduct} className='select-none outline-none bg-[#DC2626] px-[17px] py-[9px] rounded-md font-medium text-[14px] leading-5 text-[#FFFFFF] shadow-[0px_1px_2px_0px_#0000000D]'>Remove</button>
            </div>
        </div>
    );
}

export default DeleteConfirm;
