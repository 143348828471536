import React, { useEffect } from 'react';
import HomePage from '../../Pages/HomePage/HomePage';
import Footer from '../../Components/Footer/Footer';
import { useDispatch } from 'react-redux';
import { fetchHomepage } from '../../Features/homepageSlice';

function HomePageRoute() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchHomepage('home'));
    }, []);

    return (
        <>
            <HomePage />

            <Footer />
        </>
    );
}

export default HomePageRoute;
