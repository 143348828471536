import React, { useRef, useState } from 'react'
import { getOtpFromOtpless, signupWithOtpFromOtpless } from '../../../lib/utils/apiCalls';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { showToast } from '../../../Components/Toaster/Toaster';

function SignupOtpInput({ openPersonalizeMessage, updateSignupSuccess }) {
    const navigate = useNavigate();
    const location = useLocation();

    const [searchParams] = useSearchParams();
    const phoneNumber = searchParams.get('phoneNumber');
    const countryCode = searchParams.get('code');

    const [otp, setOtp] = useState(['', '', '', '']);
    const inputsRef = useRef([]);

    function handleChange(e, index) {
        const { value } = e.target;
        if (!/^[0-9]?$/.test(value)) return;

        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (value && index < otp.length - 1) {
            inputsRef.current[index + 1].focus();
        }
    };

    function handleBackspace(e, index) {
        if (e.key === 'Backspace' && otp[index] === '' && index > 0) {
            inputsRef.current[index - 1].focus();
        }
    };

    async function handleVerifyOtp() {
        const otpValue = otp.join('');
        if(otpValue.length > 0) {
            if(otpValue.length < 4) {
                showToast('Enter full OTP', 'warning');
            }
            else {
                try {
                    const callBody = {
                        phoneNumber,
                        otp: otpValue,
                    };

                    const response = await signupWithOtpFromOtpless(callBody);
                    if(response.status === 200) {
                        if(response.data.message === 'WrongOTP') {
                            showToast('Wrong OTP', 'warning');
                        }
                        else {
                            localStorage.setItem(
                                "accessToken",
                                response.data.data.accessToken
                            );
                            localStorage.setItem(
                                "refreshToken",
                                response.data.data.refreshToken
                            );
                            updateSignupSuccess(true);
                            openPersonalizeMessage(true);
                            navigate({
                                pathname: location.pathname,
                                search: '',
                            });
                        }
                    }
                }
                catch(err) {
                    console.log(err);
                }
            }
        }
        else {
            showToast('No input found', 'warning');
        }
    }

    async function handleResendCode() {
        try {
            const callBody = {
                phoneNumber,
                country_code: `+${countryCode}`,
            };

            const response = await getOtpFromOtpless(callBody);
            if(response.status === 200) {
                showToast('Code sent', 'success');
            }
        }
        catch(err) {
            console.log(err);
        }
    }

    return (
        <div className='flex flex-col items-center gap-8 max-[600px]:gap-6 w-full'>
            <div className="flex flex-col gap-4 w-full">
                <div className="flex flex-col gap-2 max-[600px]:gap-1">
                <h1 className='text-display max-[600px]:text-[24px] max-[600px]:leading-9 text-[#000000] select-none'>Welcome to <span className='text-main'>Hyprcom</span></h1>

                    <p className='text-body1 max-[600px]:text-[12px] max-[600px]:leading-[22px] text-[#666666]'>We've sent an OTP to +{countryCode}{phoneNumber}. Please enter the code to verify your account.</p>
                </div>

                <div className="flex items-center gap-3 mx-auto">
                    {otp.map((_, index) => (
                        <input
                            key={index}
                            type="text"
                            value={otp[index]}
                            maxLength="1"
                            onChange={(e) => handleChange(e, index)}
                            onKeyDown={(e) => handleBackspace(e, index)}
                            ref={(el) => (inputsRef.current[index] = el)}
                            className="size-20 max-[600px]:size-16 border border-[#BFBFBF] bg-[#F3F3F3] rounded-2xl outline-none text-center text-title1 text-[#000000] focus:border-main" 
                        />
                    ))}
                </div>

                <button onClick={handleResendCode} className="text-label1 text-main">Resend again</button>
            </div>

            <div className="flex flex-col gap-4 max-[600px]:gap-2 w-full">
                <button onClick={handleVerifyOtp} className='select-none outline-none px-8 max-[600px]:px-6 py-4 max-[600px]:py-3 bg-main hover:bg-mainHover rounded-lg text-heading2 max-[600px]:text-[16px] max-[600px]:leading-7 text-[#000000]'>Let’s get in</button>

                <p className='text-body2 text-[#666666]'>By signing up, you agree to Hyprcom's Terms of Service and Privacy Policy.</p>
            </div>
        </div>
    );
}

export default SignupOtpInput;
