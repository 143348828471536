import React from 'react';
import CarouselBanner from './CarouselBanner';
import SectionTitle from '../../../../Components/SectionTitle/SectionTitle';
import { Link } from 'react-router-dom';

const OPTIONS = { loop: true }

function StoreDiscoveryHub({ storeDiscovery, button }) {
    return (
        // <div className='w-full flex flex-col gap-8 max-[426px]:gap-6 items-center'>
        //     <SectionTitle 
        //         title='Store Discovery Hub' 
        //     />

        //     <CarouselBanner 
        //         slides={storeDiscovery} 
        //         options={OPTIONS} 
        //     />

        //     {
        //         button && 
        //         <Link to='/stores' className='bg-[#FFFFFF] border-2 border-[#999999] px-4 py-2 max-[426px]:px-2 max-[426px]:py-1 rounded-lg font-medium text-[16px] leading-7 max-[426px]:font-normal max-[426px]:text-[10px] max-[426px]:leading-[22px] text-[#000000]'>Explore more</Link>
        //     }
        // </div>
        null
    );
}

export default StoreDiscoveryHub;
