import React, { useEffect, useRef, useState } from 'react';
import Cross from '../../../Assets/Cross.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useOutsideClickHandler } from '../../../Hooks/useOutsideClick';
import { closeSidebar } from '../../../Features/appSlice';
// import NotificationBar from './NotificationBar';

function Notifications() {
    const dispatch = useDispatch();

    const containerRef = useRef(null);

    useOutsideClickHandler(containerRef, () => {
        setCartSidebarOpen(false);
        setTimeout(() => {
            dispatch(closeSidebar());
        }, 300);
    });

    const { open } = useSelector((state) => state.app.sidebar);

    const [cartSidebarOpen, setCartSidebarOpen] = useState(false);

    function closeSidebarClick() {
        setCartSidebarOpen(false);
        setTimeout(() => {
            dispatch(closeSidebar());
        }, 300);
    }

    useEffect(() => {
        if(open) {
            setCartSidebarOpen(open);
        }
    }, [open]);

    return (
        <div ref={containerRef} className={`absolute transition-all duration-500 delay-0 ease-in-out top-0 ${cartSidebarOpen ? 'right-0' : '-right-1/3 max-[1024px]:-right-[50%] max-[600px]:-right-[100%]'} h-full w-1/3 max-[1024px]:w-1/2 max-[600px]:w-full bg-[#FFFFFF] flex flex-col`}>
            <div className="p-6 flex items-center justify-between bg-[#FFFFFF] border-b border-[#E3E3E3] shadow-[0_0_8px_0_#0000001F]">
                <h1 className='font-bold text-[24px] leading-9 text-[#000000]'>Notification</h1>

                <button onClick={closeSidebarClick} className="size-10 centered cursor-pointer">
                    <img src={Cross} alt="" />
                </button>
            </div>

            <div className="h-[calc(100%-84px)] w-full flex flex-col overflow-y-scroll scrollbar-hide">
                {/* <NotificationBar /> */}
            </div>
        </div>
    );
}

export default Notifications;
