import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { getCachedMediaUrl } from '../../../../lib/utils/utils';

function ProductCard({ product, urlPrefix, productId }) {
    const [hoverOnDiv, setHoverOnDiv] = useState(false);
    const [buttonsVisible, setButtonsVisible] = useState(false);

    const [imageIndex, setImageIndex] = useState(0);
    const mediaContainerRef = useRef(null);

    const [mediaContainerHeight, setMediaContainerHeight] = useState(0);

    useEffect(() => {
        const updateHeight = () => {
            if (mediaContainerRef.current) {
                setMediaContainerHeight((1296 / 1080) * mediaContainerRef.current.clientWidth);
            }
        };

        const resizeObserver = new ResizeObserver(() => {
            updateHeight();
        });

        if (mediaContainerRef.current) {
            resizeObserver.observe(mediaContainerRef.current);
        }

        updateHeight();

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    useEffect(() => {
        if(hoverOnDiv) {
            setButtonsVisible(true);
        } else {
            setButtonsVisible(false);
        }
    }, [hoverOnDiv]);

    useEffect(() => {
        if(hoverOnDiv) {
            if(product?.productImg?.length > 1) {
                setImageIndex(1);
            }
        }
        else {
            setImageIndex(0);
        }
    }, [hoverOnDiv, product]);

    return (
        <Link 
            to={`${urlPrefix}${productId}`} 
            onMouseEnter={() => setHoverOnDiv(true)} 
            onMouseLeave={() => setHoverOnDiv(false)} 
            ref={mediaContainerRef} 
            className='w-full rounded-2xl h-fit bg-[#FFFFFF] overflow-hidden border-2 border-[#E3E3E3] shadow-[0px_0px_6px_0px_#0000001F]' 
        >
            <div style={{ height: `${mediaContainerHeight}px` }} className="w-full relative">
                <img className='w-full h-full object-cover' src={getCachedMediaUrl(product?.productImg[imageIndex])} alt="" />
            </div>

            <div className="w-full flex flex-col p-2 pb-4">
                <div className="flex items-center gap-[2.59px]">
                    <span className='text-[#000000] text-[12px] leading-5 font-medium'>₹{product?.productPrice}</span>

                    {
                        product.productPrice < product.productMRP && 
                        <>
                            <span className='text-[#666666] line-through text-[10px] leading-[18px] font-normal'>₹{product?.productMRP}</span>

                            <span className='text-main text-[10px] leading-[18px] font-semibold'>{product?.productDiff}% off</span>
                        </>
                    }
                </div>

                <p className='w-full text-[12px] leading-[18px] font-bold text-[#000000] line-clamp-1'>{product?.productName}</p>

                <p 
                    className='w-full line-clamp-1 text-[10px] leading-[18px] font-medium text-[#666666]'
                    dangerouslySetInnerHTML={{ __html: product?.productDesc }}
                ></p>
            </div>
        </Link>
    );
}

export default ProductCard;
