import React from 'react';
import { formatBadge, getCachedMediaUrl, getInitials } from '../../../../lib/utils/utils';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { closeModal } from '../../../../Features/profileSlice';

function UserBar({ user }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    function handleUserClick() {
        dispatch(closeModal());
        navigate(`/user/${user.userId}`);
    }

    return (
        <button onClick={handleUserClick} className='w-full px-4 py-1 flex items-center gap-2 outline-none'>
            <div className="size-[52px] rounded-full overflow-hidden bg-[#E3E3E3] centered">
                {
                    user.img ? 
                    <img className='size-full object-cover' src={getCachedMediaUrl(user.img || '')} alt="" /> : 
                    <p className='font-bold text-[16px] leading-[22px] text-[#666666]'>{getInitials(user?.name)}</p>
                }
            </div>

            <div className="flex flex-col w-[calc(100%-52px)] text-left">
                <h1 className='text-title3 text-[#000000]'>{user?.name}</h1>

                <p className='text-body2 text-[#666666]'>{formatBadge(user.badge || '')}</p>
            </div>
        </button>
    );
}

export default UserBar;
