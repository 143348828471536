import useEmblaCarousel from 'embla-carousel-react';
import React from 'react';
import './imagePostEmbla.css';

const user = 'https://s3-alpha-sig.figma.com/img/18db/5ab2/aee7fdb52c34a5549eb251d3dfcd13f3?Expires=1729468800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ahjACbuxgZFbFpiFQFrUafJp1Vq2pc2oflgSmIwzNGa18PpjvN5498hUCT8frM76i-htEIOiQC8dH4AN7dUjdBX~Lj2aTrYmT6oYqluGD6-67WmrXD1poh5KDl58ZZ2vKjN~vc1-28XQelx0H~u9QjK6pIVLHE5bzudEOZZ-pYMhWcR04Xn7sS77xbBIRv7xnw4tYfgbRovi8GPqXu~nVTg2vdgWOjITBTD~BfyWt5aXe6aCv98pk4U8JMwrL3m-90or3xkexnUy5Ap2zhdsJnrxKH-uLgmbCzgNnlVUDZgmNXcrixBTiGKqixGD6eJ6V~AMyuhh~bFGiI9l55YNmA__';

function ImagePost(props) {
    const { slides, options, userId } = props;
    const [emblaRef, emblaApi] = useEmblaCarousel(options, []);

    return (
        <div className="w-[460px] border border-[#BFBFBF] bg-[#FFFFFF] p-4 rounded-lg flex flex-col gap-2">
            <div className=""></div>

            <div className="flex flex-col gap-1">
                <h1></h1>
            </div>

            <div className=""></div>
        </div>
    );
}

export default ImagePost;
