import React, { useContext, useEffect, useState } from 'react';
import GenderDropdown from './GenderDropdown';
import ShoppingCategory from './ShoppingCategory';
import FashionTaste from './FashionTaste';
import Offers from './Offers';
import BankCards from './BankCards';
import ArrowLeft from '../../../../Assets/ArrowLeft.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setSettingsSection } from '../../../../Features/settingsSlice';
import { PreferenceFormContext } from '../../../../Context/preferenceFormContext';
import { bankCardsList, fashionTasteList, genders, offersList, shoppingCategories } from '../../../../lib/arrays/settingsArrays';
import { updateProfile } from '../../../../lib/utils/apiCalls';
import { fetchUser } from '../../../../Features/userSlice';

function areArraysEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) return false;
    if (arr1.length === 0 && arr2.length === 0) return true;

    const sortedArr1 = arr1.map(obj => JSON.stringify(obj)).sort();
    const sortedArr2 = arr2.map(obj => JSON.stringify(obj)).sort();

    return sortedArr1.every((str, index) => str === sortedArr2[index]);
}

function MyPreference() {
    const dispatch = useDispatch();

    function handleBack() {
        dispatch(setSettingsSection(null));
    }

    const { user } = useSelector((state) => state.user.user);

    const [buttonAvailable, setButtonAvailable] = useState(false);

    const {
        gender: selectedGender,
        productCategories,
        fashionTaste,
        offerType,
        paymentType,
        setProductCategories,
        setFashionTaste,
        setOfferType,
        setGender,
        setPaymentType,
    } = useContext(PreferenceFormContext);

    useEffect(() => {
        if(Object.keys(user).length > 0) {
            const { user: userInfo } = user;

            const gender = genders.find(item => item.id === userInfo.gender);
            setGender(gender);

            const fashionTaste = fashionTasteList.filter(item => userInfo.fashion_taste.includes(item.id));
            setFashionTaste(fashionTaste);

            const shoppingCategory = shoppingCategories.filter(item => userInfo.product_category.includes(item.id));
            setProductCategories(shoppingCategory);

            const offers = offersList.filter(item => userInfo.offers_type.includes(item.id));
            setOfferType(offers);

            const cards = bankCardsList.filter(item => userInfo.payment_type.includes(item.id));
            setPaymentType(cards);
        }
    }, [user]);

    useEffect(() => {
        if(Object.keys(user).length > 0) {
            const { user: userInfo } = user;

            const gender = genders.find(item => item.id === userInfo.gender);
            const shoppingCategory = shoppingCategories.filter(item => userInfo.product_category.includes(item.id));
            const fashionTasteData = fashionTasteList.filter(item => userInfo.fashion_taste.includes(item.id));
            const offers = offersList.filter(item => userInfo.offers_type.includes(item.id));
            const cards = bankCardsList.filter(item => userInfo.payment_type.includes(item.id));

            const changeInGender = gender.id !== selectedGender.id;
            const changeInProductCategories = !areArraysEqual(shoppingCategory, productCategories);
            const changeInFashionTaste = !areArraysEqual(fashionTasteData, fashionTaste);
            const changeInOffers = !areArraysEqual(offers, offerType);
            const changeInPaymentType = !areArraysEqual(cards, paymentType);

            if(changeInGender || changeInProductCategories || changeInFashionTaste || changeInOffers || changeInPaymentType) {
                setButtonAvailable(true);
            }
            else {
                setButtonAvailable(false);
            }
        }
    }, [user, selectedGender, productCategories, fashionTaste, offerType, paymentType]);

    async function handleUpdatePreferences() {
        if(buttonAvailable) {
            try {
                const callBody = {
                    product_category: productCategories.map(item => item.id),
                    fashion_taste: fashionTaste.map(item => item.id),
                    gender: selectedGender.id,
                    payment_type: paymentType.map(item => item.id),
                    offers_type: offerType.map(item => item.id)
                }

                const response = await updateProfile(callBody);
                if(response.status === 200) {
                    dispatch(fetchUser());
                }
            }
            catch(err) {
                console.log(err);
            }
        }
    }

    return (
        <div className={`w-full h-fit border border-[#BFBFBF] rounded-lg p-4 mt-6 flex flex-col gap-4 max-[768px]:rounded-none max-[768px]:border-none max-[768px]:mt-0`}>
            <div className="flex items-center gap-2">
                <button onClick={handleBack} className="size-6 max-[768px]:flex items-center justify-center outline-none hidden">
                    <img src={ArrowLeft} alt="" />
                </button>

                <h1 className='text-title1 text-[#000000]'>My Preference</h1>
            </div>

            <GenderDropdown />

            <ShoppingCategory />

            <FashionTaste />

            <Offers />

            <BankCards />

            <button onClick={handleUpdatePreferences} className={`${buttonAvailable ? 'bg-main hover:bg-mainHover text-[#000000]' : 'bg-[#F3F3F3] text-[#999999] cursor-not-allowed'} max-[768px]:w-full px-4 py-2 rounded-lg text-title2 w-fit ml-auto outline-none select-none hover-transition`}>Update Preferences</button>
        </div>
    );
}

export default MyPreference;
