import React from 'react';
import Marketplace from '../../Pages/Marketplace/Marketplace.Page';
import Footer from '../../Components/Footer/Footer';

function MarketplaceRoute() {
    return (
        <>
            <Marketplace />

            <Footer />
        </>
    );
}

export default MarketplaceRoute;
