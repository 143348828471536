import React from 'react';
import { camelCaseToReadable } from '../../../../lib/utils/utils';

function SpecificationInfo({ title, value }) {
    return (
        <div className='flex items-center justify-between text-body1 text-[#666666]'>
            <p>{camelCaseToReadable(title)}</p>

            <p>{value}</p>
        </div>
    );
}

export default SpecificationInfo;
