import React from 'react'

function SignupMessage({ closeSignupMessage }) {
    function handleButtonClick() {
        closeSignupMessage();
    }

    return (
        <div className='w-full flex flex-col gap-8'>
            <div className="flex flex-col gap-4">
                <h1 className='text-title1 text-[#000000]'>Welcome to Your Personalized Shopping Haven!</h1>

                <p className='text-body1 text-[#666666]'>At Hyprcom, we're all about making your shopping experience as unique as you are! Your preferences are the key to unlocking exclusive deals, tailored recommendations, and a feed designed just for you.</p>

                <p className='text-body1 text-[#666666]'>Get ready to enjoy a curated journey that celebrates your style and needs. Your choices, your way—because your satisfaction is our top priority. Let's dive in and create a shopping experience that feels like it's made just for you!</p>
            </div>

            <button onClick={handleButtonClick} className='select-none outline-none px-8 max-[600px]:px-6 py-4 max-[600px]:py-3 bg-main hover:bg-mainHover rounded-lg text-heading2 max-[600px]:text-[16px] max-[600px]:leading-7 text-[#000000]'>Personalize Now!</button>
        </div>
    );
}

export default SignupMessage;
