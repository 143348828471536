import React, { useEffect, useState } from 'react';
import ProductCard from './Components/ProductCard';
import Review from './Components/Review';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProduct, setSelectedVariant, setProductVarients, setImages, changeBagQuantity } from '../../Features/productSlice';
import { getCachedMediaUrl, sortSizes } from '../../lib/utils/utils';
import ImageSection from './Components/ImageSection/ImageSection';
import ProductDetailsSection from './Components/ProductDetailsSection/ProductDetailsSection';
import ProductImageCarousel from './Components/ProductImageCarousel/ProductImageCarousel';
import ModalContainer from '../../Components/ModalContainer/ModalContainer';
import SizeChart from './Components/SizeChart';
import DropTrack from '../../Assets/DropTrack.svg';
import { addProductToCart, addProductToDroptrack } from '../../lib/utils/apiCalls';
import { fetchCart } from '../../Features/cartSlice';
import { showToast } from '../../Components/Toaster/Toaster';
import { v4 as uuidv4 } from 'uuid';
import { addToLocalCartList, openSidebar } from '../../Features/appSlice';

const OPTIONS = { loop: true, duration: 60, }

function ProductDetails() {
    const { productId } = useParams();
    const dispatch = useDispatch();

    const product = useSelector((state) => state.product.product.product);
    const selectedVariant = useSelector((state) => state.product.selectedVariant);
    const variants = useSelector((state) => state.product.variants);
    const images = useSelector((state) => state.product.images);

    const sizeChartOpen = useSelector((state) => state.product.sizeChartOpen);
    const { user } = useSelector((state) => state.user.user);

    const { variantId, quantity: stock, size, color } = useSelector((state) => state.product.selectedVariant);
    const bagQuantity = useSelector((state) => state.product.bagQuantity);

    const [ratings, setRatings] = useState([]);
    const [imagesArray, setImagesArray] = useState([]);
    const [productInDroptrack, setProductInDroptrack] = useState(false);

    useEffect(() => {
        productId && dispatch(fetchProduct(productId));
    }, [productId]);

    useEffect(() => {
        setRatings([]);
        const generateSizes = (product) => {
            if(product.varient.length > 0) {
                let sizeList = product.varient.filter(variant => variant.VarientSize!== null).map(varient => varient.VarientSize.trim());
                let sortedList = sortSizes([...new Set(sizeList)]);
                return sortedList;
            }
            else {
                let sizeList = [product.product.productSpecs.size]
                return sizeList;
            }
        }

        const generateColors = (product) => {
            if(product.varient.length > 0) {
                const uniqueProducts = [];
                const seenColors = new Set();

                product.varient.forEach(product => {
                    if (!seenColors.has(product.VarientColor)) {
                        seenColors.add(product.VarientColor);
                        let colorObject = {
                            id: product.id,
                            color: product.VarientColor,
                            imageUrl: product.VarientImgUrl[0]
                        }
                        uniqueProducts.push(colorObject);
                    }
                });

                return uniqueProducts
            }
            else {
                let colorObject = {
                    id: product.product.id,
                    color: product.product.productSpecs.color,
                    imageUrl: product.product.productImgUrls[0]
                }

                return [ colorObject ];
            }
        }

        if(Object.keys(product).length > 0) {
            const sizes = generateSizes(product)
            const colors = generateColors(product);
            dispatch(setImages({
                variantImages: product.product.productImgUrls,
                activeImageUrl: product.product.productImgUrls[0],
            }));

            dispatch(setProductVarients({
                sizes: sizes,
                colors: colors,
            }));
            dispatch(setSelectedVariant({
                size: product.product.productSpecs.size,
                color: product.product.productSpecs.color,
                sellingPrice: product.product.productSellingPrice,
                MRP: product.product.productMRP,
                quantity: product.product.productStock,
                variantId: product.product.id,
            }));

            if(product.NoOfRatings > 0) {
                let list = {
                    'Excellent': { count: 0 },
                    'Very Good': { count: 0 },
                    'Average': { count: 0 },
                    'Poor': { count: 0 },
                    'Very Bad': { count: 0 }
                };

                product.reviews.forEach(review => {
                    switch (review.ratings) {
                        case 5:
                            list['Excellent'].count++;
                            break;
                        case 4:
                            list['Very Good'].count++;
                            break;
                        case 3:
                            list['Average'].count++;
                            break;
                        case 2:
                            list['Poor'].count++;
                            break;
                        case 1:
                            list['Very Bad'].count++;
                            break;
                        default:
                            break;
                    }
                });

                setRatings(list);
            }
        }
    }, [product]);

    useEffect(() => {
        const generateSizes = (color) => {
            let variants = product.varient.filter(variant => variant.VarientColor === color);
            let sizeList = variants.map(varient => varient.VarientSize.trim());
            let sortedList = sortSizes([...new Set(sizeList)]);
            return sortedList;
        }

        if(selectedVariant.size && selectedVariant.color) {
            dispatch(changeBagQuantity(1));
            const sizes = generateSizes(selectedVariant.color);
            dispatch(setProductVarients({
                ...variants,
                sizes: sizes,
            }));

            const size = sizes.includes(selectedVariant.size) ? selectedVariant.size : sizes[sizes.length - 1];

            if((product.product.productSpecs.size === size) && (product.product.productSpecs.color === selectedVariant.color)) {
                dispatch(setSelectedVariant({
                    ...selectedVariant,
                    sellingPrice: product.product.productSellingPrice,
                    MRP: product.product.productMRP,
                    quantity: product.product.productStock,
                    variantId: product.product.id,
                }));
            }
            else {
                const filteredVariant = product.varient.filter(variant => variant.VarientColor.trim() === selectedVariant.color.trim() && variant.VarientSize.trim() === size.trim());
                dispatch(setSelectedVariant({
                    ...selectedVariant,
                    sellingPrice: filteredVariant[0].VarientPrice,
                    MRP: filteredVariant[0].VarientMrp,
                    quantity: filteredVariant[0].VarientQuantity,
                    variantId: filteredVariant[0].id,
                }));
            }
        }
    }, [selectedVariant.size, selectedVariant.color]);

    useEffect(() => {
        if(selectedVariant.size && selectedVariant.color) {
            if(!variants.sizes.includes(selectedVariant.size)) {
                const size = variants.sizes.includes(selectedVariant.size) ? selectedVariant.size : variants.sizes[variants.sizes.length - 1];

                dispatch(setSelectedVariant({
                    ...selectedVariant,
                    size: size,
                }));

                const filteredVariant = product.varient.filter(variant => variant.VarientColor.trim() === selectedVariant.color.trim() && variant.VarientSize.trim() === size.trim());
                dispatch(setImages({
                    variantImages: filteredVariant[0].VarientImgUrl,
                    activeImageUrl: filteredVariant[0].VarientImgUrl[0],
                }));
            }
        }
    }, [variants.sizes]);

    useEffect(() => {
        if(images?.variantImages.length > 0) {
            const formattedImages = images?.variantImages?.map((image, index) => ({
                id: index,
                link: getCachedMediaUrl(image),
            }));
            setImagesArray(formattedImages);
        }
    }, [images]);

    async function handleAddToCart() {
        if(Object.keys(user).length > 0) {
            if(stock > 0) {
                if(bagQuantity > 0) {
                    try {
                        const callBody = {
                            productId: productId,
                            varaint: variantId,
                            quantity: bagQuantity,
                            reference: false,
                        }
            
                        const response = await addProductToCart(callBody);
                        if(response.status === 200) {
                            dispatch(fetchCart());
                            dispatch(changeBagQuantity(1));
                        }
                    }
                    catch(err) {
                        console.log(err);
                    }
                }
                else {
                    showToast('Add quantity first', 'warning');
                }
            }
            else {
                showToast('Variant out of stock', 'warning');
            }
        }
        else {
            if(stock > 0) {
                dispatch(addToLocalCartList({ id: uuidv4(), product, quantity: 1, variantId: variantId, size, color }));
                dispatch(openSidebar('my-cart'));
            }
            else {
                showToast('Variant out of stock', 'warning');
            }
        }
    }

    async function handleAddProductToDroptrack() {
        if(Object.keys(user).length > 0) {
            try {
                const callBody = {
                    ProductId: productId,
                };
    
                const response = await addProductToDroptrack(callBody);
                if(response.status === 200) {
                    setProductInDroptrack(true);
                }
            }
            catch(err) {
                console.log(err);
            }
        }
        else {
            showToast('Log in to continue', 'warning');
        }
    }

    return (
        Object.keys(product).length > 0 ?
        <div className='w-[1536px] max-[1536px]:w-full mx-auto flex flex-col pt-20 max-[768px]:pt-12 max-[426px]:pt-8 max-[426px]:pb-12'>
            <div className='mt-6 flex flex-col gap-8 pb-12'>
                <div className="flex gap-8 max-[1280px]:gap-4 mx-[50px] max-[1280px]:mx-[35px] max-[426px]:mx-0 max-[426px]:flex-col">
                    <ImageSection />

                    <div className="hidden max-[426px]:flex w-full">
                        <ProductImageCarousel 
                            slides={imagesArray} 
                            options={OPTIONS} 
                        />
                    </div>

                    <ProductDetailsSection />
                </div>

                <div className="flex flex-col gap-2">
                    <h1 className='text-title1 max-[426px]:text-title2 text-[#000000] mx-[50px] max-[1280px]:mx-[35px] max-[426px]:mx-4'>Related Products</h1>

                    <div className="w-full flex gap-3 px-[50px] max-[1280px]:px-[35px] max-[426px]:px-4 overflow-x-scroll scrollbar-hide">
                        {product?.relatedProduct?.map(product => {
                            return (
                                <ProductCard 
                                    key={product.id} 
                                    product={product} 
                                    urlPrefix={'../'} 
                                    productId={product.id} 
                                    productImageUrl={product.productImgUrls[0]}
                                />
                            );
                        })}
                    </div>
                </div>

                {
                    product?.NoOfRatings > 0 ? 
                    <div className="flex flex-col gap-2">
                        <h1 className='text-title1 max-[426px]:text-title2 text-[#000000] mx-[50px] max-[1280px]:mx-[35px] max-[426px]:mx-4'>Reviews</h1>

                        <div className="flex items-center gap-6 max-[426px]:gap-2 mx-[50px] max-[1280px]:mx-[35px] max-[426px]:mx-4">
                            <div className="size-[140px] max-[426px]:size-[120px] rounded-[35px] max-[426px]:rounded-2xl centered bg-[#E3E3E3] max-[426px]:flex-shrink-0">
                                {
                                    product.avgRatings ? 
                                    <div className="flex flex-col text-center text-[#000000]">
                                        <p className='text-title2'>{product.avgRatings}</p>
                                        <p className='text-title3'>out of 5</p>
                                    </div> :
                                    <p className="text-[#000000] text-title3">No reviews</p>
                                }
                            </div>

                            <div className="flex flex-col w-[320px] max-[426px]:w-[calc(100%-120px)] text-label2 max-[426px]:text-label3 text-[#000000]">
                                {Object.keys(ratings).map(key => {
                                    const value = ratings[key];

                                    const percentage = (value.count/product.NoOfRatings)*100;

                                    return (
                                        <div key={key} className="w-full flex items-center max-[426px]:justify-between">
                                            <h1 className='w-[calc(100%-240px)] max-[426px]:text-nowrap'>{key}</h1>

                                            <div className="w-[240px] max-[426px]:w-[130px] h-[10px] rounded-[45px] bg-[#E3E3E3] relative">
                                                <div style={{ width: `${percentage}%` }} className="absolute h-full w-1/2 rounded-[45px] left-0 top-0 bg-[#BFBFBF]"></div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        {
                            product?.NoOfRatings > 0 && 
                            <>
                                {/* Product Lens Section */}
                                <div className="w-full flex gap-3 px-[50px] max-[1280px]:px-[35px] max-[426px]:px-4 overflow-x-auto scrollbar-hide"></div>

                                {/* Product Reviews */}
                                <div className="flex flex-col gap-3 mx-[50px] max-[1280px]:mx-[35px] max-[426px]:mx-4">
                                    {product?.reviews.map(review => {
                                        return (
                                            <Review 
                                                key={review.id} 
                                                review={review} 
                                            />
                                        );
                                    })}
                                </div>
                            </>
                        }
                    </div> : 
                    null
                }
            </div>

            <div className="hidden max-[426px]:flex w-full fixed bottom-0 left-0 p-4 shadow-[0px_-1px_3px_0px_#0000001F] bg-[#FFFFFF] items-center gap-2">
                <button onClick={handleAddToCart} className={`${productInDroptrack ? 'w-full' : 'w-[calc(100%-64px)]'} hover-transition px-6 py-3 bg-main hover:bg-mainHover flex-grow rounded-lg font-bold text-[16px] leading-7 text-[#000000]`}>Add to bag</button>

                {
                    !productInDroptrack && 
                    <button onClick={handleAddProductToDroptrack} className='size-[52px] centered bg-[#BFBFBF] rounded-lg'>
                        <img src={DropTrack} alt="" />
                    </button>
                }
            </div>

            {
                sizeChartOpen && 
                <ModalContainer>
                    <SizeChart />
                </ModalContainer>
            }
        </div> : 
        null
    );
}

export default ProductDetails;
