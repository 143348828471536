import React from 'react';
import ArrowLeft from '../../Assets/ArrowLeft.svg';
import { Link, useParams } from 'react-router-dom';
import BillingDetails from './Components/BillingDetails';
import AddressDetails from './Components/AddressDetails/AddressDetails';
import ProductDetails from './Components/ProductDetails/ProductDetails';
import OrderInfo from './Components/OrderInfo';
import ModalContainer from '../../Components/ModalContainer/ModalContainer';
import CancelOrderConfirm from './Components/OrderCancellation/CancelOrderConfirm';
import { useSelector } from 'react-redux';
import CancelReasonSelect from './Components/OrderCancellation/CancelReasonSelect';
import CancelSuccess from './Components/OrderCancellation/CancelSuccess';
import TrackOrder from './Components/Tracking/TrackOrder';
import ReturnOrderConfirm from './Components/OrderReturn/ReturnOrderConfirm';
import ReturnReasonSelect from './Components/OrderReturn/ReturnReasonSelect';
import ReturnOption from './Components/OrderReturn/ReturnOption';
import ReturnSuccess from './Components/OrderReturn/ReturnSuccess';
import SelectNewSize from './Components/OrderReturn/SelectNewSize';
import SelectNewColor from './Components/OrderReturn/SelectNewColor';
import TrackRefundStatus from './Components/Tracking/TrackRefundStatus';
import TrackExchangeStatus from './Components/Tracking/TrackExchangeStatus';

function OrderDetails() {
    const params = useParams();

    const { type, open } = useSelector((state) => state.orderDetails.modal);

    return (
        <div className='flex flex-col pt-20 max-[810px]:pt-14'>
            <div className="flex gap-6 mx-[50px] max-[1280px]:mx-[35px] my-6">
                <div className="flex flex-col gap-4 w-3/5">
                    <div className="flex items-center gap-2">
                        <Link to='../..' className="size-6 centered">
                            <img src={ArrowLeft} alt="" />
                        </Link>

                        <p className='font-semibold text-[20px] leading-8 text-[#000000]'>#{params.orderId?.slice(-6)}</p>
                    </div>

                    <div className="border border-[#BFBFBF] rounded-3xl overflow-hidden flex flex-col pb-6">
                        <OrderInfo />

                        <ProductDetails />

                        <AddressDetails />
                    </div>
                </div>

                <BillingDetails />
            </div>

            {
                open && 
                <ModalContainer>
                    {type === 'confirm-cancel' && <CancelOrderConfirm />}

                    {type === 'select-cancel-reason' && <CancelReasonSelect />}

                    {type === 'cancel-success' && <CancelSuccess />}

                    {type === 'track-order' && <TrackOrder />}

                    {type === 'confirm-return' && <ReturnOrderConfirm />}

                    {type === 'select-return-reason' && <ReturnReasonSelect />}

                    {type === 'select-return-option' && <ReturnOption />}

                    {type === 'return-success' && <ReturnSuccess />}

                    {type === 'exchange-size' && <SelectNewSize />}

                    {type === 'exchange-color' && <SelectNewColor />}

                    {type === 'refund-status' && <TrackRefundStatus />}

                    {type === 'exchange-status' && <TrackExchangeStatus />}
                </ModalContainer>
            }
        </div>
    );
}

export default OrderDetails;
