import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../Auth/Interceptor';

const initialStateValue = {
    modal: {
        type: '',
        open: false,
    },
    addresses: {
        addresses: [],
        loading: 'idle',
        error: null,
    },
    estimatedPrice: {
        estimatedPrice: {},
        loading: 'idle',
        error: null,
    },
    hyprcomMoney: {
        hyprcomMoney: {},
        loading: 'idle',
        error: null,
    },
    sellerId: null,
    selectedAddressId: null,
    paymentModeId: null,
}

const fetchAddresses = createAsyncThunk('checkout/fetchAddresses', async () => {
    try {
        const response = await api.get('address/getAll');
        return response.data.data;
    }
    catch(error) {
        throw error;
    }
});

const fetchEstimatedPrice = createAsyncThunk('checkout/fetchEstimatedPrice', async (callBody) => {
    try {
        const response = await api.post('price/newEstimator', callBody);
        return response.data.data;
    }
    catch(error) {
        throw error;
    }
});

const fetchHyprcomMoney = createAsyncThunk('checkout/fetchHyprcomMoney', async () => {
    try {
        const response = await api.get('transaction');
        return response.data.data;
    }
    catch(error) {
        throw error;
    }
});

const checkoutSlice = createSlice({
    name: 'checkout',
    initialState: initialStateValue,
    reducers: {
        openCheckoutModal: (state, action) => {
            state.modal.type = action.payload;
            state.modal.open = true;
        },
        closeCheckoutModal: (state, _) => {
            state.modal = initialStateValue.modal;
        },
        setSelectedAddressId: (state, action) => {
            state.selectedAddressId = action.payload;
        },
        setPaymentModeId: (state, action) => {
            state.paymentModeId = action.payload;
        },
        setSellerId: (state, action) => {
            state.sellerId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchAddresses.pending, (state) => {
            state.addresses.loading = 'loading';
        })
        .addCase(fetchAddresses.fulfilled, (state, action) => {
            state.addresses.loading = 'succeeded';
            state.addresses.addresses = action.payload;
        })
        .addCase(fetchAddresses.rejected, (state, action) => {
            state.addresses.loading = 'failed';
            state.addresses.error = action.error.message;
        })
        .addCase(fetchEstimatedPrice.pending, (state) => {
            state.estimatedPrice.loading = 'loading';
        })
        .addCase(fetchEstimatedPrice.fulfilled, (state, action) => {
            state.estimatedPrice.loading = 'succeeded';
            state.estimatedPrice.estimatedPrice = action.payload;
        })
        .addCase(fetchEstimatedPrice.rejected, (state, action) => {
            state.estimatedPrice.loading = 'failed';
            state.estimatedPrice.error = action.error.message;
        })
        .addCase(fetchHyprcomMoney.pending, (state) => {
            state.hyprcomMoney.loading = 'loading';
        })
        .addCase(fetchHyprcomMoney.fulfilled, (state, action) => {
            state.hyprcomMoney.loading = 'succeeded';
            state.hyprcomMoney.hyprcomMoney = action.payload;
        })
        .addCase(fetchHyprcomMoney.rejected, (state, action) => {
            state.hyprcomMoney.loading = 'failed';
            state.hyprcomMoney.error = action.error.message;
        });
    },
});

export const {
    openCheckoutModal,
    closeCheckoutModal,
    setSelectedAddressId,
    setPaymentModeId,
    setSellerId,
} = checkoutSlice.actions;

export {
    fetchAddresses,
    fetchEstimatedPrice,
    fetchHyprcomMoney,
}

export default checkoutSlice.reducer;
