import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./customToastifyStyles.css";
import { SuccessIcon, ErrorIcon, WarningIcon } from "./CustomIcons";

const Toaster = () => {
    return (
        <ToastContainer
            hideProgressBar={true}
            autoClose={3000}
            closeButton={false}
        />
    );
};

export const showToast = (message, type = "default") => {
    const baseOptions = {
        icon: null,
    };

    switch (type) {
        case "success":
            toast.success(message, { ...baseOptions, icon: <SuccessIcon /> });
            break;
        case "error":
            toast.error(message, { ...baseOptions, icon: <ErrorIcon /> });
            break;
        case "warning":
            toast.warn(message, { ...baseOptions, icon: <WarningIcon /> });
            break;
        default:
            toast(message);
    }
};

export default Toaster;
