import React from 'react';
import ChevronGray from '../../../../Assets/ChevronGray.svg';
import ChevronGreen from '../../../../Assets/ChevronGreen.svg';
import { useDispatch } from 'react-redux';
import { setSettingsSection } from '../../../../Features/settingsSlice';

function MenuOption({ option, border, active }) {
    const dispatch = useDispatch();

    function handleOptionClick() {
        dispatch(setSettingsSection(option.id));
    }

    return (
        <div onClick={handleOptionClick} className={`select-none ${border && 'border-b border-[#BFBFBF]'} py-4 flex items-center gap-2 cursor-pointer`}>
            <div className="size-6 centered">
                <img src={active ? option.iconGreen : option.icon} alt={option.title} />
            </div>

            <p className={`w-[calc(100%-48px)] font-semibold text-[18px] leading-[30px] ${active ? 'text-main' : 'text-[#666666]'}`}>{option.title}</p>

            <div className="size-6 centered">
                <img className={`${active ? '' : '-rotate-90'}`} src={active ? ChevronGreen : ChevronGray} alt="" />
            </div>
        </div>
    );
}

export default MenuOption;
