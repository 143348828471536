import axios from "axios";

const BASE_URL = process.env.REACT_APP_HYPRCOM_API;
const REFRESH_ENDPOINT = "userRefresh/refresh";

const api = axios.create({
    baseURL: BASE_URL,
});

api.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                const oldRefreshToken = localStorage.getItem("refreshToken");
                if (!oldRefreshToken) {
                    throw new Error("No refresh token available");
                }

                const response = await axios.post(
                    `${BASE_URL}${REFRESH_ENDPOINT}`,
                    { refreshToken: oldRefreshToken },
                    {
                        headers: {
                            Authorization: `Bearer ${oldRefreshToken}`,
                        },
                    }
                );

                const { accessToken, refreshToken } = response.data;

                localStorage.setItem("accessToken", accessToken);
                localStorage.setItem("refreshToken", refreshToken);

                originalRequest.headers.Authorization = `Bearer ${accessToken}`;

                return api(originalRequest);
            } catch (refreshError) {
                localStorage.removeItem("accessToken");
                localStorage.removeItem("refreshToken");
                console.error("Error refreshing token:", refreshError);
                window.location.href = "/";
                return Promise.reject(refreshError);
            }
        }

        return error;
    }
);

export default api;