import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import EliteBadge from '../../Assets/EliteBadge.svg';
import { useDispatch, useSelector } from 'react-redux';
import { closeAccountModal, openSidebar } from '../../Features/appSlice';
import { formatBadge, getCachedMediaUrl, getInitials } from '../../lib/utils/utils';
import { setSettingsSection } from '../../Features/settingsSlice';

function AccountDropdown({ modalRef }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const { accountModalOpen } = useSelector((state) => state.app);
    const { user } = useSelector((state) => state.user.user);

    const [modalOpen, setModalOpen] = useState(false);

    function handleMyAccountClick() {
        dispatch(closeAccountModal());
        navigate('user/me');
    }

    function handleMyOrdersClick() {
        dispatch(closeAccountModal());
        dispatch(openSidebar('my-orders'));
    }

    function handleHyprcomMoneyClick() {
        dispatch(closeAccountModal());
        dispatch(setSettingsSection('HYPRCOM_MONEY'));
        navigate('/settings');
    }

    function handleReferClick() {
        dispatch(closeAccountModal());
        dispatch(setSettingsSection('REFER_AND_EARN'));
        navigate('/settings');
    }

    function handleSettingsClick() {
        dispatch(closeAccountModal());
        navigate('/settings');
    }

    function handleLogOut() {
        dispatch(closeAccountModal());
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');

        if (location.pathname !== '/') {
            navigate('/');
        }
        window.location.reload();
    }

    useEffect(() => {
        setModalOpen(accountModalOpen);
    }, [accountModalOpen]);

    return (
        <div ref={modalRef} className={`absolute transition-all duration-300 delay-0 ease-linear right-[50px] max-[1280px]:right-[35px] flex flex-col w-fit ${modalOpen ? 'top-0' : '-top-80'} select-none`}>
            <div 
                className="
                    ml-auto mr-4
                    w-0 h-0 
                    border-l-[8px] border-l-transparent 
                    border-r-[8px] border-r-transparent 
                    border-b-[14px] border-b-[#FFFFFF]
                "
            ></div>

            <div className="w-[260px] bg-[#FFFFFF] p-4 rounded-lg flex flex-col gap-2">
                <div className={`${user?.subscriber === 'NORMAL' ? 'pb-1' : 'pb-3'} w-full flex items-center gap-1 border-b border-[#E3E3E3]`}>
                    <div className="select-none size-[50px] rounded-full bg-[#E3E3E3] centered relative">
                        {
                            user?.user?.profilePicUrl ? 
                            <img className='size-full rounded-full object-cover' src={getCachedMediaUrl(user?.user?.profilePicUrl)} alt='' /> : 
                            <h1 className='font-bold text-[16px] leading-[22px] text-[#666666]'>{getInitials(user?.user?.fullname)}</h1>
                        }

                        {
                            user?.subscriber === 'NORMAL' ? 
                            null : 
                            <div className="absolute -bottom-[10px] left-1/2 -translate-x-1/2 size-5 centered rounded-full overflow-hidden bg-[#F3F3F3]">
                                <img className='rounded-full' src={EliteBadge} alt="" />
                            </div>
                        }
                    </div>

                    <div className="flex flex-col select-none">
                        <h1 className='text-label1 text-[#000000]'>{user?.user?.fullname}</h1>

                        {
                            user?.achieve && 
                            <p className='text-body2 text-[#666666]'>{formatBadge(user?.achieve?.achievement)}</p>
                        }
                    </div>
                </div>

                <ul className='text-label1 text-[#666666] flex flex-col gap-1'>
                    <li className='pb-1 border-b border-[#E3E3E3]'>
                        <button onClick={handleMyAccountClick}>My Account</button>
                    </li>
                    <li className='pb-1 border-b border-[#E3E3E3]'>
                        <button onClick={handleMyOrdersClick}>My Orders</button>
                    </li>
                    <li className='pb-1 border-b border-[#E3E3E3]'>
                        <button onClick={handleHyprcomMoneyClick}>Hyprcom Money</button>
                    </li>
                    <li className='pb-1 border-b border-[#E3E3E3]'>
                        <button onClick={handleReferClick}>Refer & Earn</button>
                    </li>
                    <li className='pb-1 border-b border-[#E3E3E3]'>
                        <button onClick={handleSettingsClick}>Settings</button>
                    </li>
                    <li>
                        <button className='text-[#EF4444]' onClick={handleLogOut}>Log-out</button>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default AccountDropdown;
