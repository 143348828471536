import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useOutsideClickHandler } from '../../../../Hooks/useOutsideClick';
import { closeOrderModal } from '../../../../Features/orderDetailsSlice';
import Cross from '../../../../Assets/Cross.svg';

const imageUrl = 'https://s3-alpha-sig.figma.com/img/3da7/5034/33934ac100a3c07900707fd3a179f2c2?Expires=1730678400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Y-KPNBv41~9G8nfL9S4gpSx4zyj8qe-3XvegWBnFbmjbhRQJgm~CsJsR9tAXgZcWWNEY2Hw6ckVC3R-FUc6g4RUKcYSu9ARpYuuu5YLrYBkSnJWKUH40ps3hYQBYy~6VHEyQjYscvhOhMwg1wl~4RPEdT~hWhutxyvW7LhDfdZMyYpTLyKntlXajCa3rS0f1r-1dhoSXRrS4gzbfJyAaE2JgjcEGSx2QrdW0TURDH-GT0LdKP3upFgP~-SUmjYUEi~M1hSMLtv03PnWnkOD0fpO0WPpGYUIo34jTONbfzqo0X~u3C2RD2QMYptR0qgdvSE4VIOj1H20I3Koyo~~bbw__';

function SelectNewSize() {
    const containerRef = useRef(null);
    const dispatch = useDispatch();

    useOutsideClickHandler(containerRef, () => {
        dispatch(closeOrderModal());
    });

    const [buttonAvailable, setButtonAvailable] = useState(false);

    function handleCloseModal() {
        dispatch(closeOrderModal());
    }

    function confirmNewSize() {
        if(buttonAvailable) {
            // 
        }
    }

    return (
        <div ref={containerRef} className='w-[35%] max-[1280px]:w-[39%] max-[1024px]:w-[42%] bg-[#FFFFFF] rounded-2xl overflow-hidden flex flex-col gap-4'>
            <div className="p-6 flex items-center justify-between bg-[#F3F3F3]">
                <h1 className='font-bold text-[24px] leading-9 text-[#000000]'>Return Order</h1>

                <button onClick={handleCloseModal} className="size-10 centered outline-none">
                    <img src={Cross} alt="" />
                </button>
            </div>

            <div className="pb-6 flex flex-col gap-4">
                <div className="px-6 flex flex-col gap-1">
                    <h1 className='font-medium text-[16px] leading-7 text-[#000000]'>Exchange with right size</h1>

                    <p className='font-normal text-[14px] leading-[26px] text-[#666666]'>Choose the right size</p>
                </div>

                <div className="px-6 flex gap-2">
                    <div className="size-[74px] rounded-lg opacity-75 overflow-hidden">
                        <img className='size-full object-cover' src={imageUrl} alt="" />
                    </div>

                    <div className="w-[calc(100%-74px)] flex flex-col font-normal">
                        <h1 className='text-[14px] leading-[26px] text-[#000000] line-clamp-1'>Prada</h1>

                        <p className='text-[12px] leading-6 text-[#666666] line-clamp-2'>Women White And Blue Perforated Lightweight Sneakers</p>
                    </div>
                </div>

                <div className="flex flex-col gap-1">
                    <h1 className='px-6 font-semibold text-[14px] leading-[26px] text-[#666666]'>Size</h1>

                    <div className="px-6 w-full flex gap-[10px] overflow-x-scroll scrollbar-hide">
                        <button className={`outline-none p-2 min-w-[44px] h-11 centered rounded-[56px] bg-[#F3F3F3]`}>
                            <p className='font-semibold text-[14px] leading-[26px] text-[#000000]'>S</p>
                        </button>
                    </div>
                </div>

                <div className="flex flex-col gap-1">
                    <h1 className='px-6 font-semibold text-[14px] leading-[26px] text-[#666666]'>Order Details</h1>

                    <div className="mx-6 p-4 rounded-2xl flex flex-col gap-2 bg-[#F3F3F3] font-normal text-[14px] leading-[26px] text-[#000000]">
                        <div className="flex items-center justify-between">
                            <h1>Price</h1>

                            <p>₹2299</p>
                        </div>

                        <div className="flex items-center justify-between">
                            <h1>Tax</h1>

                            <p>₹50</p>
                        </div>

                        <div className="flex items-center justify-between">
                            <h1>Price Adjustment</h1>

                            <p className='text-main'>-₹2349</p>
                        </div>

                        <div className="flex items-center justify-between">
                            <h1>Shipping Charge</h1>

                            <p>FREE</p>
                        </div>

                        <div className="w-full border-b border-dashed border-[#BFBFBF]"></div>

                        <div className="flex items-center justify-between font-semibold">
                            <h1>Total Price</h1>

                            <p>₹300</p>
                        </div>
                    </div>
                </div>

                <button onClick={confirmNewSize} className={`mx-6 px-8 py-4 rounded-lg font-semibold text-[20px] leading-8 ${buttonAvailable ? 'text-[#FFFFFF] bg-[#EF4444]' : 'bg-[#F3F3F3] text-[#999999] cursor-not-allowed'}`}>Place Exchange Order</button>
            </div>
        </div>
    );
}

export default SelectNewSize;
