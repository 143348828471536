import React, { useContext, useRef, useState } from 'react';
import DropdownOption from '../DropdownOption';
import Chevron from '../../../../Assets/ChevronDark.svg';
import { useOutsideClickForTwoRef } from '../../../../Hooks/useOutsideClickForTwoRef';
import { genders } from '../../../../lib/arrays/settingsArrays';
import { PreferenceFormContext } from '../../../../Context/preferenceFormContext';

function GenderDropdown() {
    const dropdownButtonRef = useRef(null);
    const dropdownListRef = useRef(null);

    const [dropdownOpen, setDropdownOpen] = useState(false);

    useOutsideClickForTwoRef(dropdownButtonRef, dropdownListRef, () => {
        setDropdownOpen(false);
    });

    const {
        gender: selectedGender,
        setGender,
    } = useContext(PreferenceFormContext);

    function handleDropdownButton() {
        setDropdownOpen(!dropdownOpen);
    }

    function handleChange(e) {
        const selectedOption = genders.find(gender => gender.id === e.target.value);
        setGender(selectedOption);
    }

    return (
        <div className="flex flex-col gap-2">
            <p className='text-title3 text-[#000000]'>Gender</p>

            <div onClick={handleDropdownButton} ref={dropdownButtonRef} className="outline-none select-none bg-[#F3F3F3] border border-[#BFBFBF] rounded-lg flex items-center justify-between px-3 py-4 relative cursor-pointer">
                <p className='text-title3 text-[#000000]'>{selectedGender?.gender}</p>

                <div className="size-6 centered">
                    <img src={Chevron} alt="" />
                </div>

                {
                    dropdownOpen && 
                    <div ref={dropdownListRef} className="absolute z-10 left-0 top-16 w-full flex flex-col border border-[#BFBFBF] rounded-xl overflow-hidden bg-[#FFFFFF]">
                        {genders.map(gender => {
                            return (
                                <DropdownOption 
                                    key={gender.id} 
                                    inputOptions={{
                                        id: gender.id,
                                        value: gender.id,
                                        type: 'radio',
                                        checked: selectedGender.id === gender.id,
                                        onChange: handleChange,
                                    }} 
                                    value={gender.gender} 
                                />
                            )
                        })}
                    </div>
                }
            </div>
        </div>
    );
}

export default GenderDropdown;
