import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import api from '../Auth/Interceptor';
import axios from 'axios';

const initialStateValue = {
    feedPosts: {
        feedPosts: [],
        loading: 'idle',
        error: null,
    },
}

const fetchLensFeed = createAsyncThunk('homepage/fetchLensFeed', async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_HYPRCOM_API}userpost/getAll`);
        return response.data.data;
    }
    catch(error) {
        throw error;
    }
});

const lensSlice = createSlice({
    name: 'lens',
    initialState: initialStateValue,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchLensFeed.pending, (state) => {
            state.feedPosts.loading = 'loading';
        })
        .addCase(fetchLensFeed.fulfilled, (state, action) => {
            state.feedPosts.loading = 'succeeded';
            state.feedPosts.feedPosts = action.payload;
        })
        .addCase(fetchLensFeed.rejected, (state, action) => {
            state.feedPosts.loading = 'failed';
            state.feedPosts.error = action.error.message;
        });
    },
});

export const {
} = lensSlice.actions;

export {
    fetchLensFeed,
}

export default lensSlice.reducer;
