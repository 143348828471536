import React from 'react';
import useCheckoutPage from '../../Hooks/useCheckoutPage';

function CheckoutProgress() {
    const { summaryWidth, addressWidth, checkoutPage } = useCheckoutPage();

    return (
        <div className="p-4 mx-[50px] max-[1280px]:mx-[35px] max-[426px]:mx-4 border-b border-[#BFBFBF] centered">
            <div className="flex items-center gap-44 max-[426px]:gap-40">
                <div className="relative">
                    <div className={`size-6 rounded-full border-2 border-main relative ${checkoutPage === 'order-summary' ? 'border-dashed animate-spin-slow' : ''}`}>
                        <div className="size-3 rounded-full bg-main absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"></div>
                    </div>

                    <div className={`transition-width duration-1000 ease-in ${summaryWidth} max-[426px]:w-40 h-1 absolute ${checkoutPage === 'order-summary' ? 'bg-[#999999]' : 'bg-main'} left-6 top-1/2 -translate-y-1/2`}></div>
                </div>

                <div className="relative">
                    <div className={`size-6 rounded-full border-2 relative ${checkoutPage === 'payment' || checkoutPage === 'address' ? 'border-main' : 'border-[#999999]'} ${checkoutPage === 'address' ? 'border-dashed animate-spin-slow' : ''}`}>
                        <div className={`size-3 rounded-full ${checkoutPage === 'payment' || checkoutPage === 'address' ? 'bg-main' : 'bg-[#999999]'} absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2`}></div>
                    </div>

                    <div className={`transition-width duration-1000 ease-in ${addressWidth} max-[426px]:w-40 h-1 absolute ${checkoutPage === 'payment' ? 'bg-main' : 'bg-[#999999]'} left-6 top-1/2 -translate-y-1/2`}></div>
                </div>

                <div className="relative">
                    <div className={`size-6 rounded-full border-2 ${checkoutPage === 'payment' ? 'border-main border-dashed animate-spin-slow' : 'border-[#999999]'} relative`}>
                        <div className={`size-3 rounded-full ${checkoutPage === 'payment' ? 'bg-main' : 'bg-[#999999]'} absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2`}></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CheckoutProgress;
