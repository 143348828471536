import React from 'react';
import Filter from './Filter';
import CrossGray from '../../../../Assets/CrossGray.svg';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

function ProductFilters() {
    const { type, value } = useSelector((state) => state.storeDetails.filter);

    const navigate = useNavigate();
    const { pathname, search } = useLocation();
    const [searchParams] = useSearchParams();
    const colorFilter = searchParams.get('color');
    const materialFilter = searchParams.get('materials');
    const styleFilter = searchParams.get('style');
    const discountFilter = searchParams.get('discount');

    function removeFilters() {
        if(search.includes('sort')) {
            const categorySortType = searchParams.get('sort');
            navigate(`${pathname}?sort=${categorySortType}`);
        }
        else {
            navigate(`${pathname}`);
        }
    }

    function removeColorFilter() {
        const updatedSearchParams = new URLSearchParams(searchParams);
        updatedSearchParams.delete('color');
        navigate(`${pathname}?${updatedSearchParams.toString()}`);
    }

    function removeMaterialFilter() {
        const updatedSearchParams = new URLSearchParams(searchParams);
        updatedSearchParams.delete('materials');
        navigate(`${pathname}?${updatedSearchParams.toString()}`);
    }

    function removeStyleFilter() {
        const updatedSearchParams = new URLSearchParams(searchParams);
        updatedSearchParams.delete('style');
        navigate(`${pathname}?${updatedSearchParams.toString()}`);
    }

    function removeDiscountFilter() {
        const updatedSearchParams = new URLSearchParams(searchParams);
        updatedSearchParams.delete('discount');
        navigate(`${pathname}?${updatedSearchParams.toString()}`);
    }

    return (
        <div className="w-1/4 border-r border-[#BFBFBF] bg-[#FFFFFF] max-[810px]:hidden">
            <div className="pt-6 pr-6 pl-[50px] max-[1280px]:pl-[35px]">
                <div className="flex flex-col gap-4">
                    <h1 className='font-semibold text-[20px] leading-8 text-[#000000]'>Filters</h1>

                    {
                        (colorFilter || materialFilter || styleFilter || discountFilter) && 
                        <div className="flex flex-col gap-2">
                            <div className="flex items-center justify-between">
                                <h1 className='text-label1 text-[#000000]'>Filtered by</h1>

                                <button onClick={removeFilters} className='text-body1 text-[#1055EB] underline outline-none border-none'>Clear all</button>
                            </div>

                            <div className="flex flex-col gap-1">
                                {
                                    colorFilter &&
                                    <div className="flex items-center justify-between">
                                        <p className='text-body1 text-[#666666]'>Color: <span className='text-label1 text-[#000000]'>{colorFilter}</span></p>

                                        <button onClick={removeColorFilter} className='size-[26px] centered outline-none border-none'>
                                            <img src={CrossGray} alt="" />
                                        </button>
                                    </div>
                                }

                                {
                                    materialFilter &&
                                    <div className="flex items-center justify-between">
                                        <p className='text-body1 text-[#666666]'>Material: <span className='text-label1 text-[#000000]'>{materialFilter}</span></p>

                                        <button onClick={removeMaterialFilter} className='size-[26px] centered outline-none border-none'>
                                            <img src={CrossGray} alt="" />
                                        </button>
                                    </div>
                                }

                                {
                                    styleFilter &&
                                    <div className="flex items-center justify-between">
                                        <p className='text-body1 text-[#666666]'>Style: <span className='text-label1 text-[#000000]'>{styleFilter}</span></p>

                                        <button onClick={removeStyleFilter} className='size-[26px] centered outline-none border-none'>
                                            <img src={CrossGray} alt="" />
                                        </button>
                                    </div>
                                }

                                {
                                    discountFilter &&
                                    <div className="flex items-center justify-between">
                                        <p className='text-body1 text-[#666666]'>Discount: <span className='text-label1 text-[#000000]'>{`>`}{discountFilter}%</span></p>

                                        <button onClick={removeDiscountFilter} className='size-[26px] centered outline-none border-none'>
                                            <img src={CrossGray} alt="" />
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>

                <div className="flex flex-col">
                    <Filter 
                        name='Color' 
                        border
                    />

                    {/* <Filter 
                        name='Size' 
                        border
                    /> */}

                    <Filter 
                        name='Material' 
                        border
                    />

                    {/* <Filter 
                        name='Category' 
                        border
                    /> */}

                    <Filter 
                        name='Style' 
                        border
                    />

                    <Filter 
                        name='Discount' 
                        // border
                    />

                    {/* <Filter 
                        name='Price Range' 
                    /> */}
                </div>
            </div>
        </div>
    );
}

export default ProductFilters;
