import React from 'react';
import { useNavigate } from 'react-router-dom';
import EstimatedPrice from '../../../Components/EstimatedPrice/EstimatedPrice';

function BillingDetails() {
    const navigate = useNavigate();

    function selectAddressPage() {
        navigate('../address');
    }

    return (
        <div className="w-2/5 max-[426px]:w-full h-fit p-6 max-[426px]:p-0 max-[426px]:border-none bg-[#FFFFFF] border border-[#E3E3E3] shadow-[0_0_8px_0_#0000001F] rounded-2xl max-[426px]:rounded-none flex flex-col gap-4">
            <div className="flex flex-col gap-2">
                <h1 className='font-semibold text-[18px] leading-[30px] text-[#666666]'>Order Details</h1>

                <EstimatedPrice />
            </div>

            <button onClick={selectAddressPage} className='hover-transition w-full px-8 py-4 rounded-lg font-semibold text-[20px] leading-8 text-[#000000] bg-main hover:bg-mainHover'>Select Address</button>
        </div>
    )
}

export default BillingDetails;
