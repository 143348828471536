import React from 'react';

function AboutHyprcom() {
    return (
        <div className='flex flex-col pt-20 max-[810px]:pt-14'>
            AboutHyprcom
        </div>
    );
}

export default AboutHyprcom;
