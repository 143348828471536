import React, { useEffect, useRef } from 'react';
import Cross from '../../Assets/Cross.svg';
import { useDispatch, useSelector } from 'react-redux';
import { changeBagQuantity, closeProductModal, fetchModalProduct, setImages, setProductVarients, setSelectedVariant } from '../../Features/appSlice';
import { useOutsideClickHandler } from '../../Hooks/useOutsideClick';
import ImageSection from './Components/ImageSection/ImageSection';
import ProductDetailsSection from './Components/ProductDetailsSection/ProductDetailsSection';
import { sortSizes } from '../../lib/utils/utils';

function ProductModal() {
    const dispatch = useDispatch();

    const containerRef = useRef(null);

    const productId = useSelector((state) => state.app.productModal.productId);
    const product = useSelector((state) => state.app.product.product);
    const selectedVariant = useSelector((state) => state.app.selectedVariant);
    const variants = useSelector((state) => state.app.variants);

    useOutsideClickHandler(containerRef, () => {
        dispatch(closeProductModal());
    });

    function handleCloseButton() {
        dispatch(closeProductModal());
    }

    useEffect(() => {
        if(productId) {
            dispatch(fetchModalProduct(productId));
        }
    }, [productId]);

    useEffect(() => {
        const generateSizes = (product) => {
            if(product.varient.length > 0) {
                let sizeList = product.varient.filter(variant => variant.VarientSize!== null).map(varient => varient.VarientSize.trim());
                let sortedList = sortSizes([...new Set(sizeList)]);
                return sortedList;
            }
            else {
                let sizeList = [product.product.productSpecs.size]
                return sizeList;
            }
        }

        const generateColors = (product) => {
            if(product.varient.length > 0) {
                const uniqueProducts = [];
                const seenColors = new Set();

                product.varient.forEach(product => {
                    if (!seenColors.has(product.VarientColor)) {
                        seenColors.add(product.VarientColor);
                        let colorObject = {
                            id: product.id,
                            color: product.VarientColor,
                            imageUrl: product.VarientImgUrl[0]
                        }
                        uniqueProducts.push(colorObject);
                    }
                });

                return uniqueProducts
            }
            else {
                let colorObject = {
                    id: product.product.id,
                    color: product.product.productSpecs.color,
                    imageUrl: product.product.productImgUrls[0]
                }

                return [ colorObject ];
            }
        }

        if(Object.keys(product).length > 0) {
            const sizes = generateSizes(product);
            const colors = generateColors(product);
            dispatch(setImages({
                variantImages: product.product.productImgUrls,
                activeImageUrl: product.product.productImgUrls[0],
            }));

            dispatch(setProductVarients({
                sizes: sizes,
                colors: colors,
            }));
            dispatch(setSelectedVariant({
                size: product.product.productSpecs.size,
                color: product.product.productSpecs.color,
                sellingPrice: product.product.productSellingPrice,
                MRP: product.product.productMRP,
                quantity: product.product.productStock,
                variantId: product.product.id,
            }));
        }
    }, [product]);

    useEffect(() => {
        const generateSizes = (color) => {
            let variants = product.varient.filter(variant => variant.VarientColor === color);
            let sizeList = variants.map(varient => varient.VarientSize.trim());
            let sortedList = sortSizes([...new Set(sizeList)]);
            return sortedList;
        }

        if(selectedVariant.size && selectedVariant.color) {
            dispatch(changeBagQuantity(1));
            const sizes = generateSizes(selectedVariant.color);
            dispatch(setProductVarients({
                ...variants,
                sizes: sizes,
            }));

            const size = sizes.includes(selectedVariant.size) ? selectedVariant.size : sizes[sizes.length - 1];

            if((product.product.productSpecs.size === size) && (product.product.productSpecs.color === selectedVariant.color)) {
                dispatch(setSelectedVariant({
                    ...selectedVariant,
                    sellingPrice: product.product.productSellingPrice,
                    MRP: product.product.productMRP,
                    quantity: product.product.productStock,
                    variantId: product.product.id,
                }));
            }
            else {
                const filteredVariant = product.varient.filter(variant => variant.VarientColor.trim() === selectedVariant.color.trim() && variant.VarientSize.trim() === size.trim());
                dispatch(setSelectedVariant({
                    ...selectedVariant,
                    sellingPrice: filteredVariant[0].VarientPrice,
                    MRP: filteredVariant[0].VarientMrp,
                    quantity: filteredVariant[0].VarientQuantity,
                    variantId: filteredVariant[0].id,
                }));
            }
        }
    }, [selectedVariant.size, selectedVariant.color]);

    useEffect(() => {
        if(selectedVariant.size && selectedVariant.color) {
            if(!variants.sizes.includes(selectedVariant.size)) {
                const size = variants.sizes.includes(selectedVariant.size) ? selectedVariant.size : variants.sizes[variants.sizes.length - 1];

                dispatch(setSelectedVariant({
                    ...selectedVariant,
                    size: size,
                }));

                const filteredVariant = product.varient.filter(variant => variant.VarientColor.trim() === selectedVariant.color.trim() && variant.VarientSize.trim() === size.trim());
                dispatch(setImages({
                    variantImages: filteredVariant[0].VarientImgUrl,
                    activeImageUrl: filteredVariant[0].VarientImgUrl[0],
                }));
            }
        }
    }, [variants.sizes]);

    return (
        <div ref={containerRef} className='w-3/4 h-[90%] rounded-2xl bg-[#FFFFFF] p-6 relative'>
            <div className="size-[55px] centered absolute top-0 right-0">
                <button onClick={handleCloseButton} className="size-6 centered outline-none">
                    <img src={Cross} alt="" />
                </button>
            </div>

            <div className="flex gap-4 size-full overflow-y-scroll scrollbar-hide">
                <ImageSection />

                <ProductDetailsSection />
            </div>
        </div>
    );
}

export default ProductModal;
