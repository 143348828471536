import React from 'react';
import { useSelector } from 'react-redux';
import { getCachedMediaUrl } from '../../../../lib/utils/utils';

function AboutSection() {
    const { store } = useSelector((state) => state.storeDetails.store);

    return (
        <div className='flex flex-col gap-6 mt-4'>
            {
                store?.about?.storeData?.map(about => {
                    if(about.TypeData === 'ABOUT1') {
                        return (
                            <div key={about.TypeData} className="w-full flex flex-col gap-2">
                                <img src={getCachedMediaUrl(about.imageLink)} alt={about.altText} />

                                <div className="flex flex-col gap-1 px-4">
                                    <h1 className='font-medium text-xl text-[#666666]'>{about.Heading}</h1>

                                    <p 
                                        className='text-body1 text-[#666666]' 
                                        dangerouslySetInnerHTML={{ __html: about.Description }} 
                                    ></p>
                                </div>
                            </div>
                        );
                    }
                    else if(about.TypeData === 'ABOUT2') {
                        return (
                            <div key={about.TypeData} className="flex flex-col gap-1 px-4">
                                <h1 className='font-medium text-xl text-[#666666]'>{about.Heading}</h1>

                                <p 
                                    className='text-body1 text-[#666666]' 
                                    dangerouslySetInnerHTML={{ __html: about.Description }} 
                                ></p>
                            </div>
                        )
                    }
                    else if(about.TypeData === 'ABOUT3') {
                        return (
                            <div key={about.TypeData} className="w-full relative">
                                <img className='w-full' src={getCachedMediaUrl(about.imageLink)} alt={about.altText} />

                                <div className="absolute top-0 left-0 w-full h-full bg-[#0000008A] centered flex flex-col gap-1 px-4">
                                    <h1 className='w-full font-medium text-xl text-[#F5F5F5] text-left'>{about.Heading}</h1>

                                    <p 
                                        className='text-body1 text-[#F5F5F5]' 
                                        dangerouslySetInnerHTML={{ __html: about.Description }} 
                                    ></p>
                                </div>
                            </div>
                        )
                    }
                    else if(about.TypeData === 'FEATURE1') {
                        return (
                            <div key={about.TypeData} className="flex flex-col gap-3">
                                {about.featureItems.map((feature, index) => {
                                    return (
                                        <div key={index} className="flex gap-3">
                                            <div className="size-7">
                                                <img src={getCachedMediaUrl(feature.imageLink)} alt={feature.altText} />
                                            </div>

                                            <div className="w-[calc(100%-28px)]">
                                                <h1 className='w-full font-medium text-xl text-[#666666] text-left'>{feature.Heading}</h1>

                                                <p 
                                                    className='text-body1 text-[#666666]' 
                                                    dangerouslySetInnerHTML={{ __html: feature.Description }} 
                                                ></p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    }
                    else return null;
                })
            }

            {/* <div className="flex flex-col gap-1 px-4">
                <h1 className='font-medium text-xl text-[#666666]'>{store?.about?.storeData[0].Heading}</h1>

                <p 
                    className='text-body1 text-[#666666]'
                    dangerouslySetInnerHTML={{ __html: store?.about?.storeData[0]?.Description }}
                ></p>
            </div>

            <div className="flex gap-2"></div>

            <div ref={mediaContainerRef} className="w-full relative" style={{ height: `${mediaContainerHeight}px` }}>
                <img className='size-full object-cover' src="" alt="" />

                <div className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 flex flex-col items-center gap-1 w-4/5">
                    <h1 className='text-heading2 text-[#FFFFFF]'>Heading 1</h1>

                    <p className='text-body1 text-[#FFFFFF] text-center'>The serene majesty of a mountain range bathed in the warm glow of sunlight. The rugged peaks, coated with a pristine layer of snow, are accentuated by the light, creating a sharp contrast against the softer hues of the surrounding sky. Wisps of clouds gently embrace the mountainsides, adding to the ethereal quality of the scene.</p>
                </div>
            </div>

            <div className="grid grid-cols-2 gap-x-4 gap-y-4"></div>

            <div className=""></div> */}
        </div>
    );
}

export default AboutSection;
