import React, { useEffect, useRef, useState } from 'react';
import { useOutsideClickHandler } from '../../../Hooks/useOutsideClick';
import { useDispatch, useSelector } from 'react-redux';
import { closeSidebar } from '../../../Features/appSlice';
import Cross from '../../../Assets/Cross.svg';
import Filter from './Filter';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import CrossGray from '../../../Assets/CrossGray.svg';

function Filters() {
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const { pathname, search } = useLocation();
    const [searchParams] = useSearchParams();
    const colorFilter = searchParams.get('color');
    const materialFilter = searchParams.get('materials');
    const styleFilter = searchParams.get('style');
    const discountFilter = searchParams.get('discount');

    const containerRef = useRef(null);

    const { open } = useSelector((state) => state.app.sidebar);

    useOutsideClickHandler(containerRef, () => {
        // if(window.innerWidth > 600) {
            setFiltersOpen(false);
            setTimeout(() => {
                dispatch(closeSidebar());
            }, 300);
        // }
    });

    const [filtersOpen, setFiltersOpen] = useState(false);

    useEffect(() => {
        if(open) {
            setFiltersOpen(open);
        }
    }, [open]);

    function closeSidebarClick() {
        setFiltersOpen(false);
        setTimeout(() => {
            dispatch(closeSidebar());
        }, 300);
    }

    function removeFilters() {
        if(search.includes('sort')) {
            const categorySortType = searchParams.get('sort');
            navigate(`${pathname}?sort=${categorySortType}`);
        }
        else {
            navigate(`${pathname}`);
        }
    }

    function removeColorFilter() {
        const updatedSearchParams = new URLSearchParams(searchParams);
        updatedSearchParams.delete('color');
        navigate(`${pathname}?${updatedSearchParams.toString()}`);
    }

    function removeMaterialFilter() {
        const updatedSearchParams = new URLSearchParams(searchParams);
        updatedSearchParams.delete('materials');
        navigate(`${pathname}?${updatedSearchParams.toString()}`);
    }

    function removeStyleFilter() {
        const updatedSearchParams = new URLSearchParams(searchParams);
        updatedSearchParams.delete('style');
        navigate(`${pathname}?${updatedSearchParams.toString()}`);
    }

    function removeDiscountFilter() {
        const updatedSearchParams = new URLSearchParams(searchParams);
        updatedSearchParams.delete('discount');
        navigate(`${pathname}?${updatedSearchParams.toString()}`);
    }

    return (
        <div ref={containerRef} className={`p-4 flex flex-col gap-2 absolute transition-all duration-500 delay-0 ease-in-out top-0 ${filtersOpen ? 'right-0' : '-right-1/3 max-[810px]:-right-[50%] max-[600px]:-right-[90%]'} h-full w-1/3 max-[810px]:w-1/2 max-[600px]:w-[90%] bg-[#FFFFFF] flex flex-col`}>
            <div className="flex flex-col gap-4">
                <div className="flex items-center gap-2">
                    <button onClick={closeSidebarClick} className="size-8 centered outline-none border-none">
                        <img src={Cross} alt="" />
                    </button>

                    <p className='text-title1 text-[#000000]'>Filters</p>
                </div>

                {
                    (colorFilter || materialFilter || styleFilter || discountFilter) && 
                    <div className="flex flex-col gap-2">
                        <div className="flex items-center justify-between">
                            <p className='text-label1 text-[#000000]'>Filtered by</p>

                            <button onClick={removeFilters} className='text-body1 text-[#1055EB] underline outline-none border-none'>Clear all</button>
                        </div>

                        <div className="flex flex-col gap-1">
                            {
                                colorFilter &&
                                <div className="flex items-center justify-between">
                                    <p className='text-body1 text-[#666666]'>Color: <span className='text-label1 text-[#000000]'>{colorFilter}</span></p>

                                    <button onClick={removeColorFilter} className='size-[26px] centered outline-none border-none'>
                                        <img src={CrossGray} alt="" />
                                    </button>
                                </div>
                            }

                            {
                                materialFilter &&
                                <div className="flex items-center justify-between">
                                    <p className='text-body1 text-[#666666]'>Material: <span className='text-label1 text-[#000000]'>{materialFilter}</span></p>

                                    <button onClick={removeMaterialFilter} className='size-[26px] centered outline-none border-none'>
                                        <img src={CrossGray} alt="" />
                                    </button>
                                </div>
                            }

                            {
                                styleFilter &&
                                <div className="flex items-center justify-between">
                                    <p className='text-body1 text-[#666666]'>Style: <span className='text-label1 text-[#000000]'>{styleFilter}</span></p>

                                    <button onClick={removeStyleFilter} className='size-[26px] centered outline-none border-none'>
                                        <img src={CrossGray} alt="" />
                                    </button>
                                </div>
                            }

                            {
                                discountFilter &&
                                <div className="flex items-center justify-between">
                                    <p className='text-body1 text-[#666666]'>Discount: <span className='text-label1 text-[#000000]'>{`>`}{discountFilter}%</span></p>

                                    <button onClick={removeDiscountFilter} className='size-[26px] centered outline-none border-none'>
                                        <img src={CrossGray} alt="" />
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>

            <div className="flex flex-col py-4">
                <Filter 
                    name='Color' 
                    border
                />

                {/* <Filter 
                    name='Size' 
                    border
                /> */}

                <Filter 
                    name='Material' 
                    border
                />

                {/* <Filter 
                    name='Category' 
                    border
                /> */}

                <Filter 
                    name='Style' 
                    border
                />

                <Filter 
                    name='Discount' 
                    // border
                />

                {/* <Filter 
                    name='Price Range' 
                /> */}
            </div>
        </div>
    );
}

export default Filters;
