import React from 'react'

function Title({ title, subtitle }) {
    return (
        <div className="flex flex-col gap-2">
            <h1 className='font-bold text-[24px] leading-7 max-[810px]:text-xl text-[#000000]'>{title}</h1>

            {
                subtitle && 
                <p className='font-normal text-[16px] leading-5 max-[810px]:text-[14px] max-[810px]:leading-[18px] text-[#121212]'>{subtitle}</p>
            }
        </div>
    );
}

function TextMainContainer({ children }) {
    return (
        <div className="flex flex-col gap-1 font-normal text-[16px] leading-8 max-[810px]:text-[14px] max-[810px]:leading-7 text-[#454545]">{children}</div>
    )
}

function SubText({ children }) {
    return (
        <ul className='list-disc pl-4'>{children}</ul>
    )
}

export {
    Title,
    TextMainContainer,
    SubText,
};
