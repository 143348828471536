import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { closeSidebar } from '../../../Features/appSlice';
import { getCachedMediaUrl, formatDate } from '../../../lib/utils/utils';

function OrderBar({ order, status }) {
    // console.log(order);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    function handleOrderClick() {
        navigate(`my-orders/${order.orderId}`);
        dispatch(closeSidebar());
    }

    return (
        <div onClick={handleOrderClick} className='w-full p-3 flex items-center gap-2 rounded-2xl bg-[#F3F3F3] cursor-pointer'>
            <div className="size-20 rounded-lg overflow-hidden opacity-75">
                <img className='size-full object-cover' src={getCachedMediaUrl(order['Product.productImgUrls'][0])} alt={order['Product.productName']} />
            </div>

            <div className="w-[calc(100%-80px)] flex flex-col font-normal">
                <div className="flex flex-col">
                    <h1 className='text-[14px] leading-[26px] text-[#000000] line-clamp-1'>{order['Product.productName']}</h1>

                    <p className='text-[12px] leading-6 text-[#666666] line-clamp-1'>{order['Product.productDesc']}</p>
                </div>

                <p style={{ color: status.color }} className='text-[12px] leading-6'>{status.text} {order.orderStatus !== 'READY_FOR_PICKUP' ? `` : `${formatDate(order.createdAt)}`}</p>
            </div>
        </div>
    );
}

export default OrderBar;
