import React from 'react';
import { formatDescription } from '../../../../lib/utils/utils';
import Specifications from '../Specifications/Specifications';
// import Coupon from './Coupon';
import Pricing from './Pricing';
import { useDispatch, useSelector } from 'react-redux';
import ColorButton from './ColorButton';
import SizeButton from './SizeButton';
import { changeBagQuantity } from '../../../../Features/appSlice';

function ProductDetailsSection() {
    const dispatch = useDispatch();

    const product = useSelector((state) => state.app.product.product);
    const variants = useSelector((state) => state.app.variants);
    const selectedVariant = useSelector((state) => state.app.selectedVariant);
    const bagQuantity = useSelector((state) => state.app.bagQuantity);

    function handleQuantityMinus() {
        if(bagQuantity > 0) {
            dispatch(changeBagQuantity(bagQuantity-1));
        }
    }

    function handleQuantityPlus() {
        dispatch(changeBagQuantity(bagQuantity+1));
    }

    return (
        <div className="w-[55%] flex flex-col gap-4">
            <div className="flex flex-col">
                <h1 className='text-heading2 text-[#000000]'>{product?.storeName}</h1>

                <p className='text-title3 text-[#666666]'>{product?.product?.productName}</p>

                <div className="flex flex-col gap-2">
                    <Pricing 
                        SellingPrice={selectedVariant.sellingPrice} 
                        MRP={selectedVariant.MRP} 
                        discount={Math.round(((selectedVariant.MRP - selectedVariant.sellingPrice) / selectedVariant.MRP) * 100)}
                    />

                    {
                        selectedVariant.quantity > 0 ? 
                        <div className="flex items-center gap-2 bg-[#F3F3F3] rounded-3xl overflow-hidden w-fit leading-7">
                            <button onClick={handleQuantityMinus} className='size-8 font-semibold text-[20px]'>-</button>

                            <p className='font-semibold text-[16px] text-[#000000]'>{bagQuantity}</p>

                            <button onClick={handleQuantityPlus} className='size-8 font-semibold text-[20px]'>+</button>
                        </div> : 
                        <p className='text-2xl text-[#EF4444] select-none'>Out of stock!</p>
                    }
                </div>
            </div>

            <div className="flex flex-col gap-2">
                <h1 className='font-semibold text-[20px] leading-8 text-[#666666]'>Colors</h1>

                <div className="flex flex-wrap items-center gap-3">
                    {variants?.colors?.map(color => {
                        return (
                            <ColorButton 
                                key={color.id} 
                                color={color} 
                            />
                        )
                    })}
                </div>
            </div>

            <div className="flex flex-col gap-2">
                <h1 className='text-heading2 text-[#666666]'>Size</h1>

                <div className="flex items-center gap-3 flex-wrap">
                    {variants?.sizes?.map(size => {
                        return (
                            <SizeButton 
                                key={size} 
                                size={size} 
                            />
                        )
                    })}
                </div>
            </div>

            {/* <div className="flex flex-col gap-2">
                <Coupon />
                <Coupon />
                <Coupon />
            </div> */}

            <div className="flex flex-col gap-2">
                <h1 className='text-title3 text-[#000000]'>Product Description</h1>

                <p className='text-body1 text-[#666666]'>
                    <span dangerouslySetInnerHTML={{ __html: formatDescription(product?.product?.productDesc) }} />
                </p>
            </div>

            {
                Object.keys(product).length > 0 && 
                <Specifications />
            }            
        </div>
    );
}

export default ProductDetailsSection;
