import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import api from '../Auth/Interceptor';
import axios from 'axios';

const initialStateValue = {
    homepage: {
        homepage: {},
        loading: 'idle',
        error: null,
    },
}

const fetchHomepage = createAsyncThunk('homepage/fetchHomepage', async (category) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_HYPRCOM_API}product?tab=${category}`);
        const data = response.data.data;

        const reorderedData = {
            webBanner: data.webBanner,
            heroBanner: data.heroBanner,
            ...Object.fromEntries(
                Object.entries(data).filter(([key]) => 
                    !['webBanner', 'heroBanner'].includes(key)
                )
            )
        };

        return reorderedData;
    }
    catch(error) {
        throw error;
    }
});

const homepageSlice = createSlice({
    name: 'homepage',
    initialState: initialStateValue,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchHomepage.pending, (state) => {
            state.homepage.loading = 'loading';
        })
        .addCase(fetchHomepage.fulfilled, (state, action) => {
            state.homepage.loading = 'succeeded';
            state.homepage.homepage = action.payload;
        })
        .addCase(fetchHomepage.rejected, (state, action) => {
            state.homepage.loading = 'failed';
            state.homepage.error = action.error.message;
        });
    },
});

export const {
} = homepageSlice.actions;

export {
    fetchHomepage,
}

export default homepageSlice.reducer;
