import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useCheckoutPage = () => {
    const location = useLocation();
    
    const [summaryWidth, setSummmaryWidth] = useState('w-0');
    const [addressWidth, setAddressWidth] = useState('w-0');
    const [checkoutPage, setCheckoutPage] = useState('order-summary');

    useEffect(() => {
        setTimeout(() => {
            setSummmaryWidth('w-44');
        }, 1150 * 0);
        
        setTimeout(() => {
            setAddressWidth('w-44');
        }, 1150 * 1);

        if (location.pathname.includes('address')) {
            setCheckoutPage('address');
        } else if (location.pathname.includes('payment')) {
            setCheckoutPage('payment');
        } else {
            setCheckoutPage('order-summary');
        }
    }, [location.pathname]);

    return { summaryWidth, addressWidth, checkoutPage };
};

export default useCheckoutPage;
