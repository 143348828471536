import React from 'react'
import SpecificationInfo from './SpecificationInfo';
import { useSelector } from 'react-redux';

function Specifications() {
    const product = useSelector((state) => state.product.product.product);

    return (
        <div className="bg-[#F3F3F3] p-3 rounded-lg flex flex-col gap-2 max-[426px]:mx-4">
            <h1 className='text-label1 text-[#000000]'>Specification</h1>

            <div className="flex flex-col gap-2">
                {Object.keys(product?.product?.productSpecs).map(key => {
                    const value = product.product.productSpecs[key];
                    if (!value) return null;
                    return (
                        <SpecificationInfo 
                            key={key} 
                            title={key} 
                            value={value} 
                        />
                    );
                })}
            </div>
        </div>
    );
}

export default Specifications;
