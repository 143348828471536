import React from 'react';

function AddressRadioOption({ address, inputOptions }) {
    return (
        <label htmlFor={inputOptions.id} className={`flex bg-[#FFFFFF] border ${inputOptions.checked ? 'border-main' : 'border-[#E3E3E3]'} rounded-xl cursor-pointer`}>
            <div className="size-11 centered">
                <div className={`size-6 rounded-full border-2 ${inputOptions.checked ? 'border-main' : 'border-[#BFBFBF]'} relative`}>
                    {
                        inputOptions.checked && 
                        <div className="size-3 rounded-full bg-main absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"></div>
                    }
                </div>
            </div>

            <div className="py-[10px] pr-3 flex flex-col gap-1 w-[calc(100%-44px)]">
                <h1 className='font-semibold text-[14px] leading-[26px] text-[#000000]'>{address?.name}</h1>

                <div className="flex flex-col font-normal text-[12px] leading-6 text-[#666666]">
                    <p>{address?.addressMain} {address?.locality}</p>

                    <p>{address?.city}</p>

                    <p>{address?.state} - {address?.pinCode}</p>
                </div>

                <input 
                    { ...inputOptions } 
                    className='hidden' 
                />
            </div>
        </label>
    );
}

export default AddressRadioOption;
