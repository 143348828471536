import React from 'react';
import { camelCaseToReadable } from '../../../../lib/utils/utils';

function SpecificationInfo({ title, value }) {
    return (
        <div className='flex items-center justify-between text-[14px] leading-[26px] text-[#666666] font-normal'>
            <p>{camelCaseToReadable(title)}</p>

            <p>{value}</p>
        </div>
    );
}

export default SpecificationInfo;
