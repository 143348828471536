import React, { useContext, useRef, useState } from 'react';
import { useOutsideClickForTwoRef } from '../../../../Hooks/useOutsideClickForTwoRef';
import Chevron from '../../../../Assets/ChevronDark.svg';
import DropdownOption from '../DropdownOption';
import { shoppingCategories } from '../../../../lib/arrays/settingsArrays';
import { PreferenceFormContext } from '../../../../Context/preferenceFormContext';

function ShoppingCategory() {
    const dropdownButtonRef = useRef(null);
    const dropdownListRef = useRef(null);

    const [dropdownOpen, setDropdownOpen] = useState(false);

    useOutsideClickForTwoRef(dropdownButtonRef, dropdownListRef, () => {
        setDropdownOpen(false);
    });

    const {
        productCategories: selectedCategories,
        setProductCategories,
    } = useContext(PreferenceFormContext);

    function handleDropdownButton() {
        setDropdownOpen(!dropdownOpen);
    }

    function handleChange(e) {
        const selectedOption = shoppingCategories.find(category => category.id === e.target.value);
        if(!selectedCategories.includes(selectedOption)) {
            const list = [ ...selectedCategories, selectedOption ];
            setProductCategories(list);
        }
        else {
            const filteredList = selectedCategories.filter(category => category.id !== selectedOption.id);
            setProductCategories(filteredList);
        }
    }

    return (
        <div className="flex flex-col gap-2">
            <p className='text-title3 text-[#000000]'>Which product category do you shop for the most?</p>

            <div onClick={handleDropdownButton} ref={dropdownButtonRef} className="outline-none select-none bg-[#F3F3F3] border border-[#BFBFBF] rounded-lg flex items-center justify-between px-3 py-4 relative cursor-pointer">
                <p className='text-title3 text-[#000000] line-clamp-1'>{selectedCategories.map(category => category.category).join(', ')}</p>

                <div className="size-6 centered">
                    <img src={Chevron} alt="" />
                </div>

                {
                    dropdownOpen && 
                    <div ref={dropdownListRef} className="absolute z-10 left-0 top-16 w-full flex flex-col border border-[#BFBFBF] rounded-xl overflow-hidden bg-[#FFFFFF]">
                        {shoppingCategories.map(category => {
                            const checked = selectedCategories.includes(category);

                            return (
                                <DropdownOption 
                                    key={category.id} 
                                    inputOptions={{
                                        id: category.id,
                                        value: category.id,
                                        type: 'checkbox',
                                        checked: checked,
                                        onChange: handleChange,
                                    }} 
                                    value={category.category} 
                                />
                            )
                        })}
                    </div>
                }
            </div>
        </div>
    );
}

export default ShoppingCategory;
