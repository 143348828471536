import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../Auth/Interceptor';

const initialStateValue = {
    user: {
        user: {},
        loading: 'idle',
        error: null,
    },
    modal: {
        type: '',
        open: false,
    },
}

const fetchUser = createAsyncThunk('users/fetchUser', async () => {
    try {
        const response = await api.get('user/profile');
        return response.data.data;
    }
    catch(error) {
        throw error;
    }
});

const userSlice = createSlice({
    name: 'user',
    initialState: initialStateValue,
    reducers: {
        openModal: (state, action) => {
            state.modal.type = action.payload;
            state.modal.open = true;
        },
        closeModal: (state, _) => {
            state.modal = initialStateValue.modal;
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchUser.pending, (state) => {
            state.user.loading = 'loading';
        })
        .addCase(fetchUser.fulfilled, (state, action) => {
            state.user.loading = 'succeeded';
            state.user.user = action.payload;
        })
        .addCase(fetchUser.rejected, (state, action) => {
            state.user.loading = 'failed';
            state.user.error = action.error.message;
        });
    },
});

export const {
    openModal,
    closeModal,
} = userSlice.actions;

export {
    fetchUser,
}

export default userSlice.reducer;
