import Preference from '../../Assets/Settings/Preference.svg';
import PreferenceGreen from '../../Assets/Settings/PreferenceGreen.svg';

import AddressBook from '../../Assets/Settings/AddressBook.svg';
import AddressBookGreen from '../../Assets/Settings/AddressBookGreen.svg';

import HyprcomMoney from '../../Assets/Settings/HyprcomMoney.svg';
import HyprcomMoneyGreen from '../../Assets/Settings/HyprcomMoneyGreen.svg';

import ReferAndEarn from '../../Assets/Settings/ReferAndEarn.svg';
import ReferAndEarnGreen from '../../Assets/Settings/ReferAndEarnGreen.svg';

import RequestAFeature from '../../Assets/Settings/RequestAFeature.svg';
import RequestAFeatureGreen from '../../Assets/Settings/RequestAFeatureGreen.svg';

import HyprcomSupport from '../../Assets/Settings/HyprcomSupport.svg';
import HyprcomSupportGreen from '../../Assets/Settings/HyprcomSupportGreen.svg';

import RefundStatus from '../../Assets/Settings/RefundStatus.svg';
import RefundStatusGreen from '../../Assets/Settings/RefundStatusGreen.svg';

import AboutHyprcom from '../../Assets/Settings/AboutHyprcom.svg';
import AboutHyprcomGreen from '../../Assets/Settings/AboutHyprcomGreen.svg';

const genders = [
    {
        id: 'MALE',
        gender: 'Male',
    },
    {
        id: 'FEMALE',
        gender: 'Female',
    }
];

const shoppingCategories = [
    {
        id: 'FASHION_AND_ACCESSORIES',
        category: 'Fashion and Accessories'
    },
    {
        id: 'BEAUTY_AND_PERSONAL_CARE',
        category: 'Beauty and Personal Care'
    },
    {
        id: 'WATCHES',
        category: 'Watches'
    },
    // {
    //     id: 'electronics',
    //     category: 'Electronics'
    // },
    // {
    //     id: 'books',
    //     category: 'Books and E-books'
    // },
    // {
    //     id: 'health',
    //     category: 'Health and Fitness'
    // },
    // {
    //     id: 'home',
    //     category: 'Home and Kitchen'
    // },
];

const fashionTasteList = [
    {
        id: 'CASUAL',
        taste: 'Casual'
    },
    {
        id: 'FORMAL',
        taste: 'Formal'
    },
    {
        id: 'SPORTY',
        taste: 'Sporty'
    },
    {
        id: 'BOHEMIAN',
        taste: 'Bohemian'
    },
    {
        id: 'CLASSIC',
        taste: 'Classic'
    },
    {
        id: 'TRENDY',
        taste: 'Trendy'
    },
];

const offersList = [
    {
        id: 'PERCENTAGE_DISCOUNTS',
        offer: 'Percentage Discounts'
    },
    {
        id: 'CASHBACK_OFFERS',
        offer: 'Cashback Offers'
    },
    {
        id: 'BUY_ONE_GET_ONE',
        offer: 'Buy One, Get One'
    },
    {
        id: 'BANK_CARD_DISCOUNTS',
        offer: 'Bank Card Discounts'
    },
    {
        id: 'EXCLUSIVE_BRAND_OFFER',
        offer: 'Exclusive Brand Offers'
    },
];

const bankCardsList = [
    {
        id: 'HDFC',
        bank: 'HDFC'
    },
    {
        id: 'SBI',
        bank: 'SBI'
    },
    {
        id: 'BOB',
        bank: 'BOB'
    },
    {
        id: 'ICICI',
        bank: 'ICICI'
    },
    {
        id: 'AMERICAN_EXPRESS',
        bank: 'American Express'
    },
    {
        id: 'HSBC',
        bank: 'HSBC'
    },
    {
        id: 'IDFC',
        bank: 'IDFC'
    },
    {
        id: 'FEDERAL_BANK',
        bank: 'Federal Bank'
    },
    {
        id: 'INDUSLND_BANK',
        bank: 'IndusInd Bank'
    },
];

const menuOptions = [
    {
        id: 'MY_PREFERENCE',
        title: 'My Preference',
        icon: Preference,
        iconGreen: PreferenceGreen,
    },
    {
        id: 'ADDRESS_BOOK',
        title: 'Address Book',
        icon: AddressBook,
        iconGreen: AddressBookGreen,
    },
    {
        id: 'HYPRCOM_MONEY',
        title: 'Hyprcom Money',
        icon: HyprcomMoney,
        iconGreen: HyprcomMoneyGreen,
    },
    {
        id: 'REFER_AND_EARN',
        title: 'Refer and Earn',
        icon: ReferAndEarn,
        iconGreen: ReferAndEarnGreen
    },
    {
        id: 'REQUEST_A_FEATURE',
        title: 'Request a Feature',
        icon: RequestAFeature,
        iconGreen: RequestAFeatureGreen
    },
    {
        id: 'HYPRCOM_SUPPORT',
        title: 'Hyprcom Support',
        icon: HyprcomSupport,
        iconGreen: HyprcomSupportGreen
    },
    {
        id: 'REFUND_STATUS',
        title: 'Refund Status',
        icon: RefundStatus,
        iconGreen: RefundStatusGreen
    },
    {
        id: 'ABOUT_HYPRCOM',
        title: 'About Hpyrcom',
        icon: AboutHyprcom,
        iconGreen: AboutHyprcomGreen
    },
];

export {
    genders,
    shoppingCategories,
    fashionTasteList,
    offersList,
    bankCardsList,
    menuOptions,
}
