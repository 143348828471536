import { useEffect } from 'react';

export function useOutsideClickHandler(ref, handleClickOutside) {
    useEffect(() => {
        function handleClick(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                handleClickOutside();
            }
        }

        document.addEventListener("mousedown", handleClick);

        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, [ref, handleClickOutside]);
}