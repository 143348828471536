import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeOrderModal, openOrderModal, setReturnReason, setReturnReasonText } from '../../../../Features/orderDetailsSlice';
import { useOutsideClickHandler } from '../../../../Hooks/useOutsideClick';
import ReasonRadio from '../ReasonRadio';
import Cross from '../../../../Assets/Cross.svg';

const imageUrl = 'https://s3-alpha-sig.figma.com/img/3da7/5034/33934ac100a3c07900707fd3a179f2c2?Expires=1730678400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Y-KPNBv41~9G8nfL9S4gpSx4zyj8qe-3XvegWBnFbmjbhRQJgm~CsJsR9tAXgZcWWNEY2Hw6ckVC3R-FUc6g4RUKcYSu9ARpYuuu5YLrYBkSnJWKUH40ps3hYQBYy~6VHEyQjYscvhOhMwg1wl~4RPEdT~hWhutxyvW7LhDfdZMyYpTLyKntlXajCa3rS0f1r-1dhoSXRrS4gzbfJyAaE2JgjcEGSx2QrdW0TURDH-GT0LdKP3upFgP~-SUmjYUEi~M1hSMLtv03PnWnkOD0fpO0WPpGYUIo34jTONbfzqo0X~u3C2RD2QMYptR0qgdvSE4VIOj1H20I3Koyo~~bbw__';

const returnReasons = [
    { id: 'no-match', reason: 'Item doesn\'t match the description' },
    { id: 'wrong-item', reason: 'Received the wrong item' },
    { id: 'wrong-size', reason: 'Received the wrong size' },
    { id: 'wrong-color', reason: 'Received the wrong color' },
    { id: 'damage', reason: 'Item damaged or defective' },
    { id: 'other', reason: 'Others' },
];

function ReturnReasonSelect() {
    const containerRef = useRef(null);
    const dispatch = useDispatch();

    const [selectedReturnReasonId, setSelectedReturnReasonId] = useState('');
    const [buttonAvailable, setButtonAvailable] = useState(false);
    const [reasonText, setReasonText] = useState('');

    useOutsideClickHandler(containerRef, () => {
        dispatch(closeOrderModal());
    });

    function handleCloseModal() {
        dispatch(closeOrderModal());
    }

    function handleOptionChange(e) {
        setSelectedReturnReasonId(e.target.value);
    }

    function handleReasonChange(e) {
        setReasonText(e.target.value);
    }

    function confirmReturnReason() {
        if(buttonAvailable) {
            // Return Logic and API call
            const selectedReason = returnReasons.filter(reason => reason.id === selectedReturnReasonId);
            dispatch(setReturnReason(selectedReason[0]));
            selectedReturnReasonId === 'other' && dispatch(setReturnReasonText(reasonText));
            dispatch(openOrderModal('select-return-option'));
        }
    }

    useEffect(() => {
        if (selectedReturnReasonId) {
            if (selectedReturnReasonId !== 'other') {
                setButtonAvailable(true);
            } else {
                if (reasonText) {
                    setButtonAvailable(true);
                } else {
                    setButtonAvailable(false);
                }
            }
        } else {
            setButtonAvailable(false);
        }
    }, [selectedReturnReasonId, reasonText]);

    return (
        <div ref={containerRef} className='w-[35%] max-[1280px]:w-[39%] max-[1024px]:w-[42%] bg-[#FFFFFF] rounded-2xl overflow-hidden flex flex-col gap-4'>
            <div className="p-6 flex items-center justify-between bg-[#F3F3F3]">
                <h1 className='font-bold text-[24px] leading-9 text-[#000000]'>Return Order</h1>

                <button onClick={handleCloseModal} className="size-10 centered outline-none">
                    <img src={Cross} alt="" />
                </button>
            </div>

            <div className="px-6 pb-6 flex flex-col gap-4">
                <div className="flex gap-2">
                    <div className="size-[74px] rounded-lg opacity-75 overflow-hidden">
                        <img className='size-full object-cover' src={imageUrl} alt="" />
                    </div>

                    <div className="w-[calc(100%-74px)] flex flex-col font-normal">
                        <h1 className='text-[14px] leading-[26px] text-[#000000] line-clamp-1'>Prada</h1>

                        <p className='text-[12px] leading-6 text-[#666666] line-clamp-2'>Women White And Blue Perforated Lightweight Sneakers</p>
                    </div>
                </div>

                <div className="flex flex-col gap-2 font-semibold text-[#666666]">
                    <h1 className='text-[18px] leading-[30px]'>Reason for return</h1>

                    <div className="flex flex-col text-[14px] leading-[26px]">
                        {returnReasons.map(reason => {
                            return (
                                <ReasonRadio 
                                    key={reason.id} 
                                    reason={reason.reason} 
                                    inputOptions={{
                                        id: reason.id,
                                        value: reason.id,
                                        type: 'radio',
                                        checked: selectedReturnReasonId === reason.id,
                                        onChange: handleOptionChange,
                                    }} 
                                />
                            )
                        })}
                    </div>

                    {
                        selectedReturnReasonId === 'other' && 
                        <textarea 
                            className='bg-[#E3E3E3] outline-none rounded-lg p-[10px] font-normal text-[14px] leading-[26px] text-[#000000] placeholder:text-[#999999]' 
                            placeholder='Write your reason' 
                            name="" 
                            id="" 
                            value={reasonText} 
                            onChange={handleReasonChange} 
                        />
                    }
                </div>

                <button onClick={confirmReturnReason} className={`px-8 py-4 rounded-lg font-semibold text-[20px] leading-8 ${buttonAvailable ? 'text-[#FFFFFF] bg-[#EF4444]' : 'bg-[#F3F3F3] text-[#999999] cursor-not-allowed'}`}>Next</button>
            </div>
        </div>
    );
}

export default ReturnReasonSelect;
