import React from 'react';
import Search from '../../../Assets/Search.svg';
import ArrowSlanted from '../../../Assets/ArrowSlanted.svg';

function ProductSearchResult() {
    return (
        <div className="p-4 flex items-center">
            <div className="w-[calc(100%-24px)] flex items-center gap-[7px]">
                <div className="size-6 centered">
                    <img src={Search} alt="" />
                </div>

                <p className='w-[calc(100%-24px)] line-clamp-1 text-body1 text-[#666666]'>black shirt under rs 1500</p>
            </div>

            <div className="size-6 centered">
                <img src={ArrowSlanted} alt="" />
            </div>
        </div>
    );
}

export default ProductSearchResult;
