import React from 'react';
import MenuOption from './MenuOption';
import { menuOptions } from '../../../../lib/arrays/settingsArrays';
import { useSelector } from 'react-redux';

function SectionMenu() {
    const settingsSection = useSelector((state) => state.settings.settingsSection);

    return (
        <div className={`${settingsSection ? 'w-[30%] max-[768px]:hidden' : 'w-[30%] max-[768px]:w-full'} h-fit ml-[50px] max-[768px]:ml-0 border max-[768px]:border-none border-[#BFBFBF] rounded-lg max-[768px]:rounded-none px-4 mt-6 flex flex-col gap-2 max-[768px]:mt-2`}>
            {menuOptions.map((option, index) => {
                const active = option.id === settingsSection;

                return (
                    <MenuOption 
                        key={option.id} 
                        option={option} 
                        border={index !== menuOptions.length - 1} 
                        active={active} 
                    />
                )
            })}
        </div>
    );
}

export default SectionMenu;
