import React from 'react';
import { useSelector } from 'react-redux';

function DropRate() {
    const { droptrackProductDetails } = useSelector((state) => state.app.droptrackProductDetails);

    return (
        <div className="w-full bg-[#E3E3E3] grid grid-cols-3 gap-x-3 rounded-lg overflow-hidden p-3">
            <div className="flex flex-col gap-2">
                <h1 className='text-title3 text-[#666666]'>Original Price</h1>

                <p className='text-title1 text-[#000000]'>₹{droptrackProductDetails.originalPrice}</p>
            </div>

            <div className="flex flex-col gap-2">
                <h1 className='text-title3 text-[#666666]'>Current Price</h1>

                <p className='text-title1 text-[#000000]'>₹{droptrackProductDetails.currentPrice}</p>
            </div>

            <div className="flex flex-col gap-2">
                <h1 className='text-title3 text-[#666666]'>% Diff Rate</h1>

                <p className='text-title1 text-[#000000]'>{droptrackProductDetails.diff}%</p>
            </div>
        </div>
    );
}

export default DropRate;
