import React, { useEffect, useRef, useState } from 'react';
import LikeWhite from '../../../../Assets/Lens/LikeWhite.svg';
import CommentWhite from '../../../../Assets/Lens/CommentWhite.svg';
import { formatCount, getCachedMediaUrl } from '../../../../lib/utils/utils';

function PostBox({ postData }) {
    const containerRef = useRef(null);

    const [mediaContainerHeight, setMediaContainerHeight] = useState(0);

    useEffect(() => {
        const updateHeight = () => {
            if (containerRef.current) {
                setMediaContainerHeight(360/202 * containerRef.current.clientWidth);
            }
        };

        const resizeObserver = new ResizeObserver(() => {
            updateHeight();
        });

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        updateHeight();

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    return (
        <div ref={containerRef} style={{ height: `${mediaContainerHeight}px` }} className='cursor-pointer bg-[#FFFFFF] border border-[#E3E3E3] rounded-lg overflow-hidden centered shadow-[0_0_6px_0_#0000001F] relative'>
            {
                postData.imageUrls && postData.imageUrls.length > 0 && 
                <img src={getCachedMediaUrl(postData.imageUrls[0])} alt={postData.postTitle} />
            }

            {
                postData.videoUrls && 
                <video src={postData.videoUrls} />
            }

            <div className="w-full pt-8 pb-4 absolute bottom-0 left-0 bg-[linear-gradient(180deg,_rgba(0,0,0,0)_0%,_#000000_141.86%)] flex">
                <div className="mx-auto flex items-center gap-4 text-body1 text-[#FFFFFF]">
                    <div className="flex items-center gap-1">
                        <div className="size-6 centered">
                            <img src={LikeWhite} alt="" />
                        </div>

                        <p>{formatCount(postData.noOflikes)}</p>
                    </div>

                    <div className="flex items-center gap-1">
                        <div className="size-6 centered">
                            <img src={CommentWhite} alt="" />
                        </div>

                        <p>0</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PostBox;
