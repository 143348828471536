import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Login from '../../Pages/Auth/Login.Page';
import Signup from '../../Pages/Auth/Signup.Page';

function AuthRoute() {
    return (
        <Routes>
            <Route index element={<Navigate to=".." replace />} />

            <Route path='login' element={<Login />} />

            <Route path='signup' element={<Signup />} />
        </Routes>
    );
}

export default AuthRoute;
