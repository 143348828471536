import React, { useEffect, useRef, useState } from 'react';
import Cross from '../../../Assets/Cross.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useOutsideClickHandler } from '../../../Hooks/useOutsideClick';
import { closeSidebar } from '../../../Features/appSlice';
import ItemCheckout from './ItemCheckout';
import ItemWishlist from './ItemWishlist';
import { useNavigate } from 'react-router-dom';
import { fetchCart, fetchWishlist } from '../../../Features/cartSlice';
import { addProductToCart, removeProductFromCart, removeProductFromWishlist } from '../../../lib/utils/apiCalls';
import ArrowLeft from '../../../Assets/ArrowLeft.svg';
import ItemLocalCheckout from './ItemLocalCheckout';

function MyCart() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const containerRef = useRef(null);

    useOutsideClickHandler(containerRef, () => {
        if(window.innerWidth > 600) {
            setCartSidebarOpen(false);
            setTimeout(() => {
                dispatch(closeSidebar());
            }, 300);
        }
    });

    const { open } = useSelector((state) => state.app.sidebar);
    const { wishlist } = useSelector((state) => state.cart.wishlist);
    const { cart } = useSelector((state) => state.cart.cart);
    const { user } = useSelector((state) => state.user.user);
    const localCartList = useSelector((state) => state.app.localCartList);

    const [cartSidebarOpen, setCartSidebarOpen] = useState(false);
    const [activeSection, setActiveSection] = useState('checkout');

    function closeSidebarClick() {
        setCartSidebarOpen(false);
        setTimeout(() => {
            dispatch(closeSidebar());
        }, 300);
    }

    function handleSectionButtonClick(selection) {
        setActiveSection(selection);
    }

    function redirectToCheckout() {
        setCartSidebarOpen(false);
        setTimeout(() => {
            dispatch(closeSidebar());
            navigate('/checkout/order-summary');
        }, 300);
    }

    useEffect(() => {
        if(open) {
            setCartSidebarOpen(open);
        }
    }, [open]);

    useEffect(() => {
        if(Object.keys(user).length > 0) {
            dispatch(fetchWishlist());
        }
    }, [user]);

    async function handleEmptyCart() {
        const removePromises = cart.map(item => removeProductFromCart(item.id, { id: item.id }));

        const removeAllPromise = Promise.all(removePromises);

        try {
            await removeAllPromise;
            dispatch(fetchCart());
        }
        catch(err) {
            console.log(err);
        }
    }

    async function handleEmptyWishlist() {
        const removePromises = wishlist.map(item => removeProductFromWishlist(item['Product.id'], item['ProductVarient.id']));

        const removeAllPromise = Promise.all(removePromises);

        try {
            await removeAllPromise;
            dispatch(fetchWishlist());
        }
        catch(err) {
            console.log(err);
        }
    }

    async function addAllToCheckout() {
        const addPromises = wishlist.map(item => addProductToCart({
            productId: item['Product.id'],
            varaint: item['ProductVarient.id'],
            quantity: 1,
            reference: false,
        }));

        const addAllPromise = Promise.all(addPromises);

        try {
            await addAllPromise;
            handleEmptyWishlist();
            dispatch(fetchCart());
        }
        catch(err) {
            console.log(err);
        }
    }

    return (
        <div ref={containerRef} className={`select-none absolute transition-all duration-500 delay-0 ease-in-out top-0 ${cartSidebarOpen ? 'right-0' : '-right-1/3 max-[1024px]:-right-[50%] max-[600px]:-right-[100%]'} h-full w-1/3 max-[1024px]:w-1/2 max-[600px]:w-full bg-[#FFFFFF] flex flex-col`}>
            <div className="p-6 max-[600px]:p-4 flex items-center justify-between bg-[#FFFFFF] border-b border-[#E3E3E3] shadow-[0_0_8px_0_#0000001F]">
                <div className="flex items-center gap-2">
                    <button onClick={closeSidebarClick} className="size-6 centered">
                        <img src={ArrowLeft} alt="" />
                    </button>

                    <h1 className='font-bold text-[24px] leading-9 max-[600px]:font-semibold max-[600px]:text-[18px] max-[600px]:leading-[30px] text-[#000000]'>My Cart</h1>
                </div>

                <button onClick={closeSidebarClick} className="size-10 centered cursor-pointer max-[600px]:hidden">
                    <img src={Cross} alt="" />
                </button>

                {
                    cart.length > 0 && 
                    <button onClick={handleEmptyCart} className='hidden max-[600px]:flex text-label1 text-[#999999]'>Empty cart</button>
                }
            </div>

            <div className="h-[calc(100%-84px)] w-full px-6 max-[600px]:px-4 py-3 flex flex-col gap-4">
                <div className="flex items-center">
                    <button onClick={() => handleSectionButtonClick('checkout')} className={`hover-transition w-1/2 py-1 border-b-2 ${activeSection === 'checkout' ? 'border-main' : 'border-[#FFFFFF]'} font-medium text-[16px] leading-7 text-[#000000] hover:bg-[#F5F5F5] outline-none`}>Checkout</button>

                    <button onClick={() => handleSectionButtonClick('wishlist')} className={`hover-transition w-1/2 py-1 border-b-2 ${activeSection === 'wishlist' ? 'border-main' : 'border-[#FFFFFF]'} font-medium text-[16px] leading-7 text-[#000000] hover:bg-[#F5F5F5] outline-none`}>Wishlist</button>
                </div>

                {
                    activeSection === 'checkout' && 
                    <>
                        <div className="flex flex-col gap-2 pb-40 overflow-y-scroll scrollbar-hide">
                            {
                                cart?.length > 0 && 
                                <button onClick={handleEmptyCart} className='w-fit font-semibold text-[14px] leading-[26px] text-[#999999] outline-none max-[600px]:hidden'>Empty cart</button>
                            }

                            {
                                cart?.length > 0 ? 
                                <>
                                    {cart?.map(product => {
                                        return (
                                            <ItemCheckout 
                                                key={product.variantId} 
                                                product={product} 
                                            />
                                        )
                                    })}
                                </> : 
                                <>
                                    {localCartList?.map(item => {
                                        return (
                                            <ItemLocalCheckout 
                                                key={item.id} 
                                                id={item.id} 
                                                product={item.product} 
                                                item={item}
                                            />
                                        )
                                    })}
                                </>
                            }
                        </div>

                        {
                            cart?.length > 0 &&
                            <div className="p-6 pt-3 absolute bottom-0 left-0 w-full flex flex-col gap-2 bg-[#FFFFFF]">
                                {/* <p className='font-normal text-[14px] leading-[26px] text-[#666666]'>Note: If you missed applying the coupon on the product page, you can still apply it on the checkout page.</p> */}

                                <button onClick={redirectToCheckout} className='hover-transition px-8 py-4 max-[1024px]:px-3 max-[1024px]:py-2 rounded-lg w-full font-semibold text-[20px] leading-8 max-[768px]:text-base text-[#000000] bg-main hover:bg-mainHover outline-none'>Proceed to Checkout</button>
                            </div>
                        }
                    </>
                }

                {
                    activeSection === 'wishlist' && 
                    <>
                        <div className="flex flex-col gap-2 pb-28 overflow-y-scroll scrollbar-hide">
                            {wishlist?.map(product => {
                                return (
                                    <ItemWishlist 
                                        key={product['ProductVarient.id']} 
                                        product={product} 
                                    />
                                )
                            })}
                        </div>

                        {
                            wishlist?.length > 0 &&
                            <div className="p-6 pt-3 absolute bottom-0 left-0 w-full flex flex-col gap-2 bg-[#FFFFFF]">
                                <button onClick={addAllToCheckout} className='hover-transition px-8 py-4 max-[1024px]:px-3 max-[1024px]:py-2 rounded-lg w-full font-semibold text-[20px] leading-8 max-[768px]:text-base text-[#000000] bg-main hover:bg-mainHover outline-none'>Add all to checkout</button>
                            </div>
                        }
                    </>
                }
            </div>
        </div>
    );
}

export default MyCart;
