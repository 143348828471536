import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { closeOrderModal, openOrderModal } from '../../../../Features/orderDetailsSlice';
import { useOutsideClickHandler } from '../../../../Hooks/useOutsideClick';

function CancelOrderConfirm() {
    const containerRef = useRef(null);
    const dispatch = useDispatch();

    useOutsideClickHandler(containerRef, () => {
        dispatch(closeOrderModal());
    });

    function handleCancelOrder() {
        dispatch(openOrderModal('select-cancel-reason'));
    }

    function handleCloseModal() {
        dispatch(closeOrderModal());
    }

    return (
        <div ref={containerRef} className="w-[38%] max-[1280px]:w-[42%] max-[1024px]:w-[45%] p-6 flex flex-col gap-4 bg-[#FFFFFF] rounded-2xl">
            <div className="flex flex-col gap-1">
                <h1 className='font-semibold text-[20px] leading-8 text-[#000000]'>Are you sure you want to Cancel the order?</h1>

                <p className='font-normal text-[14px] leading-[26px] text-[#666666]'>This action cannot be undone. By cancelling, you will receive a refund according to our refund policy.</p>
            </div>

            <div className="flex flex-col gap-2 font-semibold text-[20px] leading-8">
                <button onClick={handleCancelOrder} className='px-8 py-4 bg-[#BFBFBF] rounded-lg text-[#000000]'>Cancel Order</button>

                <button onClick={handleCloseModal} className='px-8 py-4 bg-[#EF4444] rounded-lg text-[#FFFFFF]'>Wait for Order</button>
            </div>
        </div>
    );
}

export default CancelOrderConfirm;
