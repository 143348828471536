import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { getCachedMediaUrl } from '../../../lib/utils/utils';
import { useDispatch } from 'react-redux';
import { openProductModal } from '../../../Features/appSlice';

function ProductCard({ product, urlPrefix, productId }) {
    const dispatch = useDispatch();
    const mediaContainerRef = useRef(null);

    const [hoverOnDiv, setHoverOnDiv] = useState(false);
    const [buttonsVisible, setButtonsVisible] = useState(false);

    const [imageIndex, setImageIndex] = useState(0);
    const [mediaContainerHeight, setMediaContainerHeight] = useState(0);

    useEffect(() => {
        if(hoverOnDiv) {
            setButtonsVisible(true);
        } else {
            setButtonsVisible(false);
        }
    }, [hoverOnDiv]);

    function handleQuickView(e) {
        e.preventDefault();
        e.stopPropagation();
        dispatch(openProductModal(productId));
    }

    useEffect(() => {
        if(hoverOnDiv) {
            if(product?.productImg?.length > 1) {
                setImageIndex(1);
            }
        }
        else {
            setImageIndex(0);
        }
    }, [hoverOnDiv, product]);

    useEffect(() => {
        const updateHeight = () => {
            if (mediaContainerRef.current) {
                setMediaContainerHeight((1296 / 1080) * mediaContainerRef.current.clientWidth);
            }
        };

        const resizeObserver = new ResizeObserver(() => {
            updateHeight();
        });

        if (mediaContainerRef.current) {
            resizeObserver.observe(mediaContainerRef.current);
        }

        updateHeight(); // Initial update

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    return (
        <Link 
            to={`${urlPrefix}${productId}`} 
            onMouseEnter={() => setHoverOnDiv(true)} 
            onMouseLeave={() => setHoverOnDiv(false)} 
            ref={mediaContainerRef} 
            className='w-full rounded-2xl h-fit bg-[#FFFFFF] overflow-hidden border-2 border-[#E3E3E3] shadow-[0px_0px_6px_0px_#0000001F]' 
        >
            <div className="w-full relative" style={{ height: `${mediaContainerHeight}px` }}>
                <img className='w-full h-full object-cover' src={getCachedMediaUrl(product?.productImg[imageIndex])} alt="" />

                <button onClick={handleQuickView} className={`${buttonsVisible ? 'bottom-2 opacity-100' : 'bottom-0 opacity-0'} hover-transition absolute w-[calc(100%-32px)] left-4 px-4 py-2 bg-main hover:bg-mainHover rounded-lg text-title3 text-[#000000] max-[1024px]:hidden max-[1200px]:px-3 max-[1200px]:py-[6px] max-[1200px]:text-[14px] max-[1200px]:leading-[26px]`}>Quick View</button>
            </div>

            <div className="w-full p-3 flex flex-col pb-4 max-[768px]:p-2 max-[768px]:pb-4">
                <div className="flex items-center gap-[6px] max-[768px]:gap-1">
                    <span className='text-[#000000] text-title1 max-[426px]:text-[14px] max-[426px]:leading-[26px]'>₹{product?.productPrice}</span>

                    {
                        product.productPrice < product.productMRP && 
                        <>
                            <span className='text-title2 text-[#666666] line-through max-[426px]:text-[12px] max-[426px]:leading-6'>₹{product?.productMRP}</span>

                            <span className='text-title3 text-main max-[426px]:text-[10px] max-[426px]:leading-[22px]'>{product?.productDiff.toFixed(0)}% off</span>
                        </>
                    }
                </div>

                <p className='w-full text-left text-label1 max-[768px]:text-[12px] max-[768px]:leading-[18px] text-[#000000] line-clamp-1'>{product?.productName}</p>

                <p 
                    className='w-full text-left line-clamp-1 text-label2 max-[768px]:text-[10px] max-[768px]:leading-[18px] text-[#666666]'
                    dangerouslySetInnerHTML={{ __html: product?.productDesc }}
                ></p>
            </div>
        </Link>
    );
}

export default ProductCard;
