import { createContext, useState } from "react";

export const PersonalizationFormContext = createContext();

export const PersonalizationFormProvider = ({ children }) => {
    const [productCategories, setProductCategories] = useState([]);
    const [fashionTaste, setFashionTaste] = useState([]);
    const [offerType, setOfferType] = useState([]);
    const [gender, setGender] = useState("");
    const [paymentType, setPaymentType] = useState([]);

    function resetValues() {
        setProductCategories([]);
        setFashionTaste([]);
        setOfferType([]);
        setGender('');
        setPaymentType([]);
    }

    const contextValue = {
        productCategories,
        fashionTaste,
        offerType,
        gender,
        paymentType,
        setProductCategories,
        setFashionTaste,
        setOfferType,
        setGender,
        setPaymentType,
        resetValues
    };

    return (
        <PersonalizationFormContext.Provider value={ contextValue }>
            { children }
        </PersonalizationFormContext.Provider>
    );
}