import React from 'react';
import Bucket from '../../../Assets/Bucket.svg';
import { getCachedMediaUrl } from '../../../lib/utils/utils';
import { removeProductFromCart, updateProductQuantityInCart } from '../../../lib/utils/apiCalls';
import { useDispatch } from 'react-redux';
import { fetchCart } from '../../../Features/cartSlice';
import { showToast } from '../../../Components/Toaster/Toaster';

function ItemCheckout({ product }) {
    const dispatch = useDispatch();

    async function handleDecreaseQuantity() {
        if(product.quantity > 1) {
            try {
                const callBody = {
                    id: product.id,
                    quantity: product.quantity - 1,
                };
    
                const response = await updateProductQuantityInCart(callBody);
                if(response.status === 200) {
                    if(response.data.data) {
                        dispatch(fetchCart());
                    }
                }
            }
            catch(err) {
                console.log(err);
            }
        }
        else {
            handleDeleteProductFromCart();
        }
    }

    async function handleIncreaseQuantity() {
        if(product.quantity < 5) {
            try {
                const callBody = {
                    id: product.id,
                    quantity: product.quantity + 1,
                };

                const response = await updateProductQuantityInCart(callBody);
                if(response.status === 200) {
                    if(response.data.data) {
                        dispatch(fetchCart());
                    }
                }
            }
            catch(err) {
                showToast(err, 'error');
            }
        }
        else {
            showToast('Max quantity is 5 for one product.', 'warning');
        }
    }

    async function handleDeleteProductFromCart() {
        try {
            const callBody = {
                id: product.id
            }

            const response = await removeProductFromCart(product.id, callBody);
            if(response.status === 200) {
                dispatch(fetchCart());
            }
        }
        catch(err) {
            showToast(err, 'error');
        }
    }

    return (
        <div className='p-3 bg-[#FFFFFF] border border-[#E3E3E3] shadow-[0_0_8px_0_#0000001F] rounded-2xl flex flex-col gap-2 relative'>
            <div className="flex gap-2 w-full">
                <div className="size-[74px] rounded-lg overflow-hidden opacity-75">
                    <img className='size-full object-cover' src={getCachedMediaUrl(product['ProductVarient.VarientImgUrl'][0])} alt="" />
                </div>

                <div className="w-[calc(100%-74px)] flex flex-col font-normal">
                    <h1 className='text-[14px] leading-[26px] text-[#000000] line-clamp-1'>{product['Product.productName']}</h1>
                    
                    <p className='line-clamp-2 text-[12px] leading-6 text-[#666666]'>{product['Product.productDesc']}</p>

                    <div className="flex items-center gap-[9px] text-[12px] leading-6">
                        <div className="flex items-center gap-1">
                            <h1 className='text-[#666666]'>Color:</h1>

                            <p className='text-[#000000]'>{product['ProductVarient.VarientColor']}</p>
                        </div>

                        <div className="flex items-center gap-1">
                            <h1 className='text-[#666666]'>Size:</h1>

                            <p className='text-[#000000]'>{product['ProductVarient.VarientSize']}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex items-center justify-between">
                <div className="flex items-center gap-1">
                    <p className='font-semibold text-[20px] leading-8 text-[#000000]'>₹{product['Product.productSellingPrice']}</p>

                    {
                        product['Product.productSellingPrice'] < product['Product.productMRP'] && 
                        <>
                            <p className='font-medium text-[16px] leading-7 text-[#666666] line-through'>₹{product['Product.productMRP']}</p>

                            <p className='font-semibold text-[12px] leading-6 text-main'>{Math.round(((product['Product.productMRP'] - product['Product.productSellingPrice']) / product['Product.productMRP']) * 100)}% off</p>
                        </>
                    }
                </div>

                <div className="flex items-center gap-2 bg-[#F3F3F3] rounded-3xl overflow-hidden w-fit leading-7">
                    <button onClick={handleDecreaseQuantity} className='size-8 font-semibold text-[20px]'>-</button>

                    <p className='font-semibold text-[16px] text-[#000000]'>{product.quantity}</p>

                    <button onClick={handleIncreaseQuantity} className='size-8 font-semibold text-[20px]'>+</button>
                </div>
            </div>

            <button onClick={handleDeleteProductFromCart} className="absolute top-0 right-0 size-11 centered outline-none">
                <img src={Bucket} alt="" />
            </button>
        </div>
    );
}

export default ItemCheckout;
