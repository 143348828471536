import React, { useEffect } from 'react';
import SuccessGIF from '../../../../Assets/Success.gif';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { closeOrderModal } from '../../../../Features/orderDetailsSlice';

function ReturnSuccess() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            dispatch(closeOrderModal());
            navigate('/');
        }, 1950);
    }, []);

    return (
        <div className='size-full bg-[#FFFFFF] centered'>
            <div className="flex flex-col items-center gap-2 w-[38%] text-center">
                <div className="size-[200px] centered">
                    <img src={SuccessGIF} alt="" />
                </div>

                <h1 className='text-[#000000] font-bold text-[24px] leading-9'>Return order placed successfully</h1>

                <p className='text-[#666666] font-normal text-[16px] leading-7'>Once the seller has accepted your return request, our delivery partner will be at your doorstep within 2 days to collect the item. Upon receipt, we'll promptly process your refund to the original mode of transaction. Thank you for choosing Hyprcom. If you have any further questions or concerns, please feel free to reach out to our customer support. Your satisfaction is our priority<span className='font-medium'>!!</span></p>
            </div>
        </div>
    );
}

export default ReturnSuccess;
