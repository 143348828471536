import React, { useState } from 'react';
import Logo from '../../Assets/Logo.svg';
import OtpInput from './Components/OtpInput';
import PhoneInput from './Components/PhoneInput';

function Login() {
    const [otpSent, setOtpSent] = useState(false);

    return (
        <div className='w-screen h-screen flex items-center justify-center max-[600px]:items-start bg-[#FFFFFF]'>
            <div className="w-[560px] max-[600px]:w-full p-10 max-[600px]:p-4 border border-[#BFBFBF] max-[600px]:border-none rounded-2xl max-[600px]:rounded-none bg-[#FFFFFF] flex flex-col items-center gap-20 max-[600px]:gap-10">
                <div className="h-11 max-[600px]:h-6">
                    <img className='h-full w-auto' src={Logo} alt="" />
                </div>

                {
                    otpSent ? 
                    <OtpInput /> : 
                    <PhoneInput 
                        sendOtp={(value) => setOtpSent(value)} 
                    />
                }
            </div>
        </div>
    );
}

export default Login;
