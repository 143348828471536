import React, { useCallback } from 'react';
import { DotButton, useDotButton } from './EmblaCarouselDotButton';
import Autoplay from 'embla-carousel-autoplay';
import useEmblaCarousel from 'embla-carousel-react';
import './embla.css';
import { Link } from 'react-router-dom';
import { getCachedMediaUrl } from '../../../../lib/utils/utils';

Autoplay.globalOptions = {
    delay: 8000
}

function CarouselBanner(props) {
    const { slides, options } = props;
    const [emblaRef, emblaApi] = useEmblaCarousel(options, [Autoplay()]);

    const onNavButtonClick = useCallback((emblaApi) => {
        const autoplay = emblaApi?.plugins()?.autoplay
        if (!autoplay) return
    
        const resetOrStop =
          autoplay.options.stopOnInteraction === false
            ? autoplay.reset
            : autoplay.stop
    
        resetOrStop()
    }, []);

    const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(
        emblaApi,
        onNavButtonClick
    );

    function slideLink(slide) {
        if(slide.typeProduct === 'CATEGORY') {
            return `category/${slide.source}`;
        }
        else if(slide.typeProduct === 'CATEGORY_COLLECTION') {
            return `collection/${slide.source}`;
        }
        else if(slide.typeProduct === 'PRODUCT') {
            return `products/${slide.source}`;
        }
    }

    return (
        <section className="embla">
            <div className="embla__viewport" ref={emblaRef}>
                <div className="embla__container">
                    {slides?.map((slide, index) => {
                        let target = slideLink(slide);

                        return (
                            <Link to={target} className="embla__slide" key={index}>
                                <img className='w-full h-auto cursor-pointer' src={getCachedMediaUrl(slide.imageLink)} alt={slide.altText} />
                            </Link>
                        )
                    })}
                </div>
            </div>

            <div className="embla__controls">
                <div className="embla__dots">
                    {scrollSnaps.map((_, index) => (
                        <DotButton
                            key={index}
                            onClick={() => onDotButtonClick(index)}
                            className={'embla__dot'.concat(
                                index === selectedIndex ? ' embla__dot--selected' : ''
                            )}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
}

export default CarouselBanner;
