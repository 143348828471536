import React from 'react';

const imageUrl = 'https://s3-alpha-sig.figma.com/img/3da7/5034/33934ac100a3c07900707fd3a179f2c2?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=VHWEjA3J3mdQB9~RYFG0aJFQO2fZeosd7fWb5yUROMs1eRx~OBiL9bEyMf6PRdTq3g0dmSF8a45hQAlPk87Xx7aWWqPCV5-Y2aOk~8EkOKuqunSnrk3ycOYBgUQsjSf75JsBYv1tqVnqYQNtZqEj2kqDZds~x~hTZUA~GwS3-7XMCWZEtQM8YGHLcDj0ejLTeS5HHSvPi~KtlfdJBPQcJDVdC9u3eRlpTTntqTk1cn3FRgy~sDTWAc2GgbDU0OEDU3KEGfw-BepkGSaXMTng4Gq3YLuIjksgMME1YboVAmnzJocc~zk-M2SND7L4a9TxI0r5bIdMC7i1JnkcbRPefg__';

function ProductBar() {
    return (
        <div className="flex items-center gap-2">
            <div className="size-20 rounded-lg overflow-hidden opacity-75">
                <img className='size-full object-cover' src={imageUrl} alt="" />
            </div>

            <div className="w-[calc(100%-80px)] flex flex-col">
                <div className="flex flex-col">
                    <h1 className='font-semibold text-[14px] leading-[26px] text-[#000000]'>Prada</h1>

                    <p className='line-clamp-1 font-normal text-[12px] leading-[24px] text-[#666666]'>Women White And Blue Perforated Lightweight Sneakers</p>
                </div>

                <div className="flex items-center gap-[9px] font-normal text-[14px] leading-[26px]">
                    <div className="flex items-center gap-1">
                        <p className='text-[#666666]'>Color:</p>

                        <p className='text-[#000000]'>Black</p>
                    </div>

                    <div className="flex items-center gap-1">
                        <p className='text-[#666666]'>Size:</p>

                        <p className='text-[#000000]'>9</p>
                    </div>

                    <div className="flex items-center gap-1">
                        <p className='text-[#666666]'>Qty:</p>

                        <p className='text-[#000000]'>1</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductBar;
