import RISING_CRITIC from '../../Assets/Milestones/RISING_CRITIC.svg';
import TRENDSETTER from '../../Assets/Milestones/TRENDSETTER.svg';
import THE_TREND_MAGICIAN from '../../Assets/Milestones/THE_TREND_MAGICIAN.svg';
import ELITE_ADVISOR from '../../Assets/Milestones/ELITE_ADVISOR.svg';
import FASHION_ICON from '../../Assets/Milestones/FASHION_ICON.svg';
import TREND_ARCHITECT from '../../Assets/Milestones/TREND_ARCHITECT.svg';
import VISIONARY_VIRTUOSO from '../../Assets/Milestones/VISIONARY_VIRTUOSO.svg';
import INFLUENTIAL_MAVEN from '../../Assets/Milestones/INFLUENTIAL_MAVEN.svg';
import STYLE_SOVEREIGN from '../../Assets/Milestones/STYLE_SOVEREIGN.svg';
import THE_INFLUENCER from '../../Assets/Milestones/THE_INFLUENCER.svg';
import THE_BEAUTY_BARRISTER from '../../Assets/Milestones/THE_BEAUTY_BARRISTER.svg';
import COUTURE_CONQUEROR from '../../Assets/Milestones/COUTURE_CONQUEROR.svg';
import STYLE_SENTINEL from '../../Assets/Milestones/STYLE_SENTINEL.svg';
import THE_LEGEND_OF_LENS from '../../Assets/Milestones/THE_LEGEND_OF_LENS.svg';
import HYPRCOM_TITANS from '../../Assets/Milestones/HYPRCOM_TITANS.svg';

function getMilestoneImage(badge) {
    switch (badge) {
        case 'RISING_CRITIC':
            return RISING_CRITIC;
        case 'TRENDSETTER':
            return TRENDSETTER;
        case 'THE_TREND_MAGICIAN':
            return THE_TREND_MAGICIAN;
        case 'ELITE_ADVISOR':
            return ELITE_ADVISOR;
        case 'FASHION_ICON':
            return FASHION_ICON;
        case 'TREND_ARCHITECT':
            return TREND_ARCHITECT;
        case 'VISIONARY_VIRTUOSO':
            return VISIONARY_VIRTUOSO;
        case 'INFLUENTIAL_MAVEN':
            return INFLUENTIAL_MAVEN;
        case 'STYLE_SOVEREIGN':
            return STYLE_SOVEREIGN;
        case 'THE_INFLUENCER':
            return THE_INFLUENCER;
        case 'THE_BEAUTY_BARRISTER':
            return THE_BEAUTY_BARRISTER;
        case 'COUTURE_CONQUEROR':
            return COUTURE_CONQUEROR;
        case 'STYLE_SENTINEL':
            return STYLE_SENTINEL;
        case 'THE_LEGEND_OF_LENS':
            return THE_LEGEND_OF_LENS;
        case 'HYPRCOM_TITANS':
            return HYPRCOM_TITANS;
        default:
            return RISING_CRITIC;
    }
}

export {
    getMilestoneImage,
};
