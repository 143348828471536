import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { removeImageFromList } from '../../../../Features/orderDetailsSlice';

function ImagePreview({ image }) {
    const dispatch = useDispatch();

    const [hoverOnImage, setHoverOnImage] = useState(false);
    const [visible, setVisible] = useState(false);

    function handleDeleteImage() {
        dispatch(removeImageFromList(image));
    }

    useEffect(() => {
        if(hoverOnImage) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    }, [hoverOnImage]);

    return (
        <div 
            onMouseEnter={() => setHoverOnImage(true)} 
            onMouseLeave={() => setHoverOnImage(false)} 
            className='size-[106px] max-[1280px]:size-[97px] max-[1024px]:size-[88px] rounded-xl overflow-hidden bg-[#232327] relative'
        >
            <img className='size-full object-cover' src={image.url} alt="" />

            <div className={`${visible ? 'opacity-100 visible' : 'opacity-0 invisible'} transition-all delay-100 ease-in-out size-full bg-[#00000066] absolute top-0 left-0 centered`}>
                <button onClick={handleDeleteImage} className='select-none bg-[#FFFFFF] w-3/5 py-[6px] text-sm font-semibold rounded'>Delete</button>
            </div>
        </div>
    );
}

export default ImagePreview;
