import React, { useEffect } from 'react';
import ImageSection from './Components/ImageSection/ImageSection';
import DroptrackDetailsSection from './Components/DroptrackDetailsSection/DroptrackDetailsSection';
import { useDispatch } from 'react-redux';
import { changeDroptrackBagQuantity, fetchDroptrackProductDetails } from '../../Features/appSlice';
import { useParams } from 'react-router-dom';

function DroptrackProductDetails() {
    const dispatch = useDispatch();
    const { productId } = useParams();

    useEffect(() => {
        dispatch(fetchDroptrackProductDetails(productId));
        dispatch(changeDroptrackBagQuantity(0));
    }, [productId]);

    return (
        <div className='min-h-[calc(100vh-75px)] max-[810px]:min-h-[calc(100vh-42px)] pt-20 max-[810px]:pt-14'>
            <div className="flex gap-8 max-[1280px]:gap-4 mx-[50px] max-[1280px]:mx-[35px] max-[810px]:mx-4 max-[426px]:mx-0 max-[426px]:flex-col mt-6 pb-4">
                <ImageSection />

                <DroptrackDetailsSection />
            </div>
        </div>
    );
}

export default DroptrackProductDetails;
