import React, { useEffect, useState } from 'react'
import DropTrack from '../../../../Assets/DropTrack.svg';
import { fetchProductDetails, getCachedMediaUrl } from '../../../../lib/utils/utils';
import { Link } from 'react-router-dom';

function ProductCard({ productId, storeId }) {
    const [product, setProduct] = useState({});

    useEffect(() => {
        async function getProductDetails() {
            const response = await fetchProductDetails(productId);
            if(response.status === 200) {
                setProduct(response.data.data.product);
            }
        }

        if(productId) {
            getProductDetails(productId);
        }
    }, [productId]);

    return (
        <div className="w-[400px] p-4 bg-[#F3F3F3] border border-[#BFBFBF] h-fit rounded-lg flex flex-col gap-2">
            <div className="flex gap-2">
                {
                    Object.keys(product).length > 0 ? 
                    <div className="w-[100px] h-32 rounded overflow-hidden">
                        <img className='size-full object-cover' src={getCachedMediaUrl(product?.productImgUrls[0])} alt="" />
                    </div> : 
                    null
                }

                <div className="flex flex-col gap-2 w-[calc(100%-100px)]">
                    <div className="flex flex-col">
                        <h1 className='font-semibold text-[20px] leading-8 text-[#000000] line-clamp-2'>{product?.productName}</h1>

                        <p className='font-normal text-[14px] leading-[26px] text-[#666666] line-clamp-2'>{product?.productDesc}</p>
                    </div>

                    <div className="flex items-center gap-1">
                        <p className='font-semibold text-[20px] leading-8 text-[#000000]'>₹{product.productSellingPrice}</p>
                        <p className='font-medium text-[16px] leading-7 text-[#666666] line-through'>₹{product.productMRP}</p>
                        <p className='font-normal text-[14px] leading-[26px] text-main'>{Math.round(((product?.productMRP - product?.productSellingPrice) / product?.productMRP) * 100)}% off</p>
                    </div>
                </div>
            </div>

            <div className="flex flex-col items-center gap-4">
                <div className="flex items-center gap-2 w-full">
                    <button className='w-[calc(100%-46px)] px-4 py-2 rounded-lg bg-main hover:bg-mainHover font-semibold text-[18px] leading-[30px] text-[#000000]'>Add to bag</button>

                    <button className="size-[46px] centered p-2 bg-[#BFBFBF] rounded-lg">
                        <img className='h-[21px] w-auto' src={DropTrack} alt="" />
                    </button>
                </div>

                <Link to={`/stores/${storeId}/products/${productId}`} className='font-medium cursor-pointer text-[16px] leading-7 text-main'>View Full Details</Link>
            </div>
        </div>
    );
}

export default ProductCard;
