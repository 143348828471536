import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import api from '../Auth/Interceptor';
import axios from 'axios';

const initialStateValue = {
    product: {
        product: {},
        loading: 'idle',
        error: null,
    },
    variants: {
        sizes: [],
        colors: [],
    },
    selectedVariant: {
        size: '',
        color: '',
        sellingPrice: '',
        MRP: '',
        quantity: '',
        variantId: '',
    },
    images: {
        variantImages: [],
        activeImageUrl: '',
    },
    bagQuantity: 1,
    sizeChartOpen: false,
}

const fetchProduct = createAsyncThunk('products/fetchProduct', async (productId) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_HYPRCOM_API}product/${productId}`);
        return response.data.data;
    }
    catch(error) {
        throw error;
    }
});

const productSlice = createSlice({
    name: 'product',
    initialState: initialStateValue,
    reducers: {
        setProductVarients: (state, action) => {
            state.variants = action.payload;
        },
        setSelectedVariant: (state, action) => {
            state.selectedVariant = action.payload;
        },
        setImages: (state, action) => {
            state.images = action.payload;
        },
        setActiveImageUrl: (state, action) => {
            state.images.activeImageUrl = action.payload;
        },
        openSizeChart: (state, _) => {
            state.sizeChartOpen = true;
        },
        closeSizeChart: (state, _) => {
            state.sizeChartOpen = false;
        },
        changeBagQuantity: (state, action) => {
            state.bagQuantity = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchProduct.pending, (state) => {
            state.product.loading = 'loading';
        })
        .addCase(fetchProduct.fulfilled, (state, action) => {
            state.product.loading = 'succeeded';
            state.product.product = action.payload;
        })
        .addCase(fetchProduct.rejected, (state, action) => {
            state.product.loading = 'failed';
            state.product.error = action.error.message;
        });
    },
});

export const {
    setProductVarients,
    setSelectedVariant,
    setImages,
    setActiveImageUrl,
    openSizeChart,
    closeSizeChart,
    changeBagQuantity,
} = productSlice.actions;

export {
    fetchProduct,
}

export default productSlice.reducer;
