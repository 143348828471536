import React from 'react';
import { Link } from 'react-router-dom';
import Pricing from './Pricing';
import { useDispatch, useSelector } from 'react-redux';
import DropRate from './DropRate';
import DroptrackGraph from './Droptrack.Graph';
import Alert from './Alert';
import { changeDroptrackBagQuantity } from '../../../../Features/appSlice';

function DroptrackDetailsSection() {
    const dispatch = useDispatch();

    const { droptrackProductDetails } = useSelector((state) => state.app.droptrackProductDetails);

    const {
        sellingPrice,
        MRP,
        quantity,
        product,
    } = useSelector((state) => state.app.droptrackProductVariant);

    const droptrackBagQuantity = useSelector((state) => state.app.droptrackBagQuantity);

    function handleQuantityMinus() {
        if(droptrackBagQuantity > 0) {
            dispatch(changeDroptrackBagQuantity(droptrackBagQuantity-1));
        }
    }
    
    function handleQuantityPlus() {
        dispatch(changeDroptrackBagQuantity(droptrackBagQuantity+1));
    }

    return (
        <div className="w-1/2 max-[426px]:w-full flex flex-col gap-4">
            {
                droptrackProductDetails.diff > 20 && 
                <Alert />
            }

            <div className="flex flex-col">
                <div className="flex items-center justify-between max-[426px]:mx-4">
                    <Link to={`/stores/${product.SellerUserId}`} className='text-title1 max-[426px]:text-title2 text-[#000000]'>{product.storeName}</Link>
                </div>

                <p className='text-title3 text-[#666666] max-[426px]:mx-4'>{product?.productName}</p>

                <div className="flex flex-col gap-2 max-[426px]:mx-4">
                    <Pricing 
                        SellingPrice={sellingPrice} 
                        MRP={MRP} 
                        discount={Math.round(((MRP - sellingPrice) / MRP) * 100)}
                    />

                    {
                        quantity > 0 ? 
                        <div className="flex items-center gap-2 bg-[#F3F3F3] rounded-3xl overflow-hidden w-fit leading-7">
                            <button onClick={handleQuantityMinus} className='size-8 font-semibold text-[20px]'>-</button>

                            <p className='font-semibold text-[16px] text-[#000000]'>{droptrackBagQuantity}</p>

                            <button onClick={handleQuantityPlus} className='size-8 font-semibold text-[20px]'>+</button>
                        </div> : 
                        <p className='text-2xl text-[#EF4444] select-none'>Out of stock!</p>
                    }
                </div>
            </div>

            <DropRate />

            <DroptrackGraph />
        </div>
    );
}

export default DroptrackDetailsSection;
