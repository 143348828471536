import React from 'react';

function FormInput({ labelText, inputOptions, errorText }) {
    return (
        <div className="w-full flex flex-col gap-2">
            <div className="flex items-center justify-between">
                <label htmlFor={inputOptions.id} className='text-title3 text-[#000000]'>{labelText}</label>

                {
                    errorText && 
                    <p className='text-[12px] leading-5 text-[#DC2626]'>{errorText}</p>
                }
            </div>

            <input 
                { ...inputOptions } 
                className='w-full outline-none border border-[#E3E3E3] focus:border-[#666666] bg-[#FFFFFF] px-3 py-[11px] rounded-[9px] font-normal text-[14px] leading-[26px] text-[#121212] placeholder:text-[#666666] shadow-[0px_0px_6px_0px_#0000001F]' 
            />
        </div>
    );
}

export default FormInput;
