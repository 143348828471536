import React, { useEffect, useState } from 'react';
import { formatBadge, getCachedMediaUrl, getInitials } from '../../../lib/utils/utils';
import { getUserProfile } from '../../../lib/utils/apiCalls';

function Review({ review }) {
    const [user, setUser] = useState({});

    useEffect(() => {
        async function fetchUserProfile() {
            try {
                const response = await getUserProfile(review.UserId);
                if(response.status === 200) {
                    setUser(response.data.data);
                }
            }
            catch(err) {
                console.log(err);
            }
        }

        if(Object.keys(review).length > 0) {
            fetchUserProfile();
        }
    }, [review]);

    return (
        user?.isBlocked ? 
        null : 
        <div className='p-6 max-[426px]:p-4 rounded-3xl max-[426px]:rounded-2xl flex flex-col gap-[10px] max-[426px]:gap-3 bg-[#F3F3F3]'>
            <div className="flex flex-col gap-2">
                <div className="flex items-center gap-1">
                    <div className="size-[54px] rounded-full bg-[#E3E3E3] centered overflow-hidden">
                        {
                            user?.user?.profilePic ? 
                            <img className='size-full object-cover' src={getCachedMediaUrl(user?.user.profilePic)} alt="" /> : 
                            <h1 className='font-bold text-[16px] leading-[22px] text-[#666666]'>{getInitials(user?.user?.name)}</h1>
                        }
                    </div>

                    <div className="flex flex-col">
                        <h1 className='font-medium text-[16px] leading-7 text-[#000000]'>{user?.user?.name}</h1>

                        <p className='font-normal text-[14px] leading-[26px] text-[#666666]'>{formatBadge(user?.user?.badge)}</p>
                    </div>
                </div>

                <div className="flex flex-col gap-1">
                    <div className=""></div>

                    <h1 className='font-medium text-[16px] leading-7 text-[#000000]'>{review.postTitle}</h1>

                    <p className='font-normal text-[14px] leading-[26px] text-[#666666]'>{review.postDesc}</p>
                </div>
            </div>

            {review.imageUrls && review.imageUrls.length > 0 && (
                <div className="flex items-center gap-[10px] max-[426px]:flex-wrap"> 
                    {review.imageUrls.map(imageUrl => ( 
                        <div key={imageUrl} className="size-[120px] max-[426px]:size-[90px] rounded-lg overflow-hidden"> 
                            <img className='size-full object-cover' src={getCachedMediaUrl(imageUrl)} alt="" /> 
                        </div> 
                    ))} 
                </div> 
            )}
        </div>
    );
}

export default Review;
