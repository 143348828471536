import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, setAddressId } from '../../../../Features/settingsSlice';
import { deleteAddress } from '../../../../lib/utils/apiCalls';
import { fetchAddresses } from '../../../../Features/checkoutSlice';
import { useOutsideClickHandler } from '../../../../Hooks/useOutsideClick';

function AddressDeleteConfirm() {
    const dispatch = useDispatch();

    const containerRef = useRef(null);

    useOutsideClickHandler(containerRef, () => {
        handleCancel();
    });

    const selectedAddressId = useSelector((state) => state.settings.selectedAddressId);

    function handleCancel() {
        dispatch(closeModal());
        dispatch(setAddressId(null));
    }

    async function handleDeleteAddress() {
        try {
            const response = await deleteAddress(selectedAddressId);
            if(response.status === 200) {
                dispatch(fetchAddresses());
                handleCancel();
            }
        }
        catch(err) {
            console.log(err);
        }
    }

    return (
        <div ref={containerRef} className='w-[500px] max-[532px]:w-[calc(100%-32px)] px-8 py-4 bg-[#F3F3F3] rounded-xl shadow-[0px_0px_3px_0px_#FFFFFF40] flex flex-col gap-4'>
            <div className="flex flex-col items-center gap-2">
                <h1 className='text-title1 text-[#000000]'>Delete address</h1>

                <p className='text-body1 text-[#666666] text-center'>Are you sure you want to permanently remove this address from Hyprcom</p>
            </div>

            <div className="flex items-center gap-1">
                <button onClick={handleDeleteAddress} className='outline-none w-1/2 p-[10px] text-title2 text-[#000000]'>Delete</button>

                <button onClick={handleCancel} className='outline-none w-1/2 p-[10px] text-title2 text-[#EF4444]'>Cancel</button>
            </div>
        </div>
    );
}

export default AddressDeleteConfirm;
