import React from 'react';
import { getCachedMediaUrl } from '../../../lib/utils/utils';
import { Link } from 'react-router-dom';

function BrandCard({ brand }) {
    return (
        <Link to={`/stores/${brand.id}`} className='hover-transition min-[1024px]:hover:scale-105 mx-auto max-[1024px]:m-0 flex-shrink-0 w-full max-[1024px]:w-[30%] max-[900px]:w-[35%] max-[810px]:w-2/5 flex flex-col items-center bg-[#FFFFFF] rounded-3xl max-[810px]:rounded-2xl select-none border-2 border-[#E3E3E3] shadow-[0px_0px_6px_0px_#00000014]'>
            <div 
                className="rounded-3xl max-[810px]:rounded-2xl overflow-hidden w-full h-auto" 
            >
                <img className='size-full object-cover' src={getCachedMediaUrl(brand?.img)} alt="" />
            </div>

            <div className="py-4 flex flex-col gap-2 w-fit max-w-[90%]">
                {/* <div className="w-1/3 mx-auto">
                    <img src={getCachedMediaUrl(brand?.img)} alt="" />
                </div> */}

                <p className='line-clamp-1 font-mulish font-semibold text-[18px] leading-[30px] max-[810px]:text-[14px] max-[810px]:leading-[26px] text-[#121212] max-[426px]:font-medium max-[426px]:text-[10px] max-[426px]:leading-[22px]'>{brand.name}</p>
            </div>
        </Link>
    );
}

export default BrandCard;
