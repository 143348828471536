import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import api from '../Auth/Interceptor';
import axios from 'axios';

const initialStateValue = {
    stores: {
        stores: [],
        loading: 'idle',
        error: null,
    },
    activeStoreCategory: 'home',
}

const fetchStores = createAsyncThunk('stores/fetchStores', async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_HYPRCOM_API}userstore/getAll`);
        return response.data.data
        ?.filter(store => store.backgroundImg && store.logo)
        ?.sort((a, b) => a?.sellerData?.storeName.localeCompare(b?.sellerData?.storeName));
    }
    catch(error) {
        throw error;
    }
});

const storesSlice = createSlice({
    name: 'stores',
    initialState: initialStateValue,
    reducers: {
        changeStoreCategory: (state, action) => {
            state.activeStoreCategory = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchStores.pending, (state) => {
            state.stores.loading = 'loading';
        })
        .addCase(fetchStores.fulfilled, (state, action) => {
            state.stores.loading = 'succeeded';
            state.stores.stores = action.payload;
        })
        .addCase(fetchStores.rejected, (state, action) => {
            state.stores.loading = 'failed';
            state.stores.error = action.error.message;
        });
    },
});

export const {
    changeStoreCategory,
} = storesSlice.actions;

export {
    fetchStores,
}

export default storesSlice.reducer;
